import { Component, Vue } from 'vue-property-decorator';
import template from './ribbon.html';
import './ribbon.scss';

@Component({
    template: template,
})
export default class Ribbon extends Vue {
    public get ribbonEnv(): string {
        return process.env.VUE_APP_appmode;
    }

    public get ribbonEnabled(): boolean {
        return process.env.VUE_APP_appmode !== 'production';
    }
}

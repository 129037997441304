import Vue from 'vue';
import Component from 'vue-class-component';
import template from './flow.html';
import './flow.scss';
import {NextFunction, Route} from 'vue-router';
import {ProjectMeta, TeamMeta} from 'respresso';
import {FlowEditorViewer} from '../../../plugin/flow-editor/flow-editor';
import {DialogBuilder} from '../../common/dialog/dialog';
import RespressoApi from '../../../api/respresso-api';
import ErrorHandler from '../../../services/error-handler';
import {teamModule} from '../../../store/modules/team/index';

export interface FlowData {
	content: string;
}

@Component({
	components: {
		'flow-editor-viewer': FlowEditorViewer,
	},
	template: template,
})
export default class Flow extends Vue {
	protected teamId = '';
	protected projectId = '';
	protected resourceId = '';
	protected version = '';

	protected flowEditorViewer: FlowEditorViewer | null = null;

	/* Routing */
	beforeRouteEnter(to: Route, from: Route, next: NextFunction): void {
		next((vm) => {
			const resource = vm as Flow;
			resource.load(resource, to, from, false);
		});
	}

	async beforeRouteLeave(to: Route, from: Route, next: NextFunction): Promise<void> {
		const canLeave = await this.canLeave();
		if (!canLeave) {
			next(false);
			return;
		}
		next();
	}

	protected canLeave(): Promise<boolean> {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		return new Promise<boolean>((resolve, reject): void => {
			if (!this.flowEditorViewer || !this.flowEditorViewer.isModified()) {
				resolve(true);
			} else {
				DialogBuilder.yesNoCancel(
					'#dialog.save.title',
					'#dialog.save.message',
					async () => {
						// YES
						const result = await this.save(false);
						if (!result) {
							resolve(false);
						}
						resolve(true);
					},
					() => {
						// NO
						resolve(true);
					},
					() => {
						// CANCEL
						resolve(false);
					},
				);
			}
		});
	}

	get team(): TeamMeta | undefined {
		if (teamModule.team) {
			return teamModule.team;
		}
	}

	get project(): ProjectMeta | Record<string, unknown> {
		const moduleGetterTeam = teamModule.team;
		if (!moduleGetterTeam || !moduleGetterTeam.projects) {
			return {};
		}
		const project = moduleGetterTeam.projects.filter((p: ProjectMeta) => {
			return p.id === this.projectId;
		});
		if (project.length) {
			return project[0];
		} else {
			return {};
		}
	}

	/* Loading */
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	private async load(resource: Flow, to: Route, from: Route, update: boolean): Promise<void> {
		resource.teamId = to.params.teamId;
		resource.projectId = to.params.projectId;
		resource.resourceId = to.params.resourceId;
		resource.version = to.params.versionNumber;

		resource.flowEditorViewer = resource.$refs.flowEditorView as FlowEditorViewer;

		resource.flowEditorViewer.init({
			resourceId: this.resourceId,
			teamId: this.teamId,
			projectId: this.projectId,
			version: this.version,
		});
	}

	protected async save(rerun: boolean): Promise<boolean | undefined> {
		if (this.flowEditorViewer) {
			return await this.flowEditorViewer.save(rerun);
		}
	}

	protected presentResetToDefaultDialog(): void {
		DialogBuilder.yesNoCancel('#flow.reset.dialog.title', '#flow.reset.areYouSure', () => {
			this.resetToDefault();
		});

		// DialogBuilder.confirm("#flow.reset.dialog.title", "#flow.reset.areYouSure", () => {
		// 	this.resetToDefault();
		// });
	}

	private async resetToDefault(): Promise<void> {
		if (this.flowEditorViewer) {
			const selectedFlow = this.flowEditorViewer.getSelectedFlow();
			if (selectedFlow) {
				const id = selectedFlow.text;
				const defaultFlow = await ErrorHandler.tryRequest(() => RespressoApi.getDefaultFlow(id), {
					respressoErrorHandler: () => {
						return true;
					},
				});
				if (defaultFlow) {
					this.flowEditorViewer.overwriteFlow(selectedFlow, defaultFlow.content);
				}
			}
		}
	}
}

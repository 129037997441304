import Vue from 'vue';
import Component from 'vue-class-component';
import template from './activate.html';
import './activate.scss';
import UserService from '../../../../services/user-service';
import ErrorHandler from '../../../../services/error-handler';
import LoadingScreen from '../../../../decorators/loading-screen';

@Component({
	props: {
		authUserId: String,
		inputToken: String,
		inviteToken: String,
	},
	template: template,
})
export default class Activate extends Vue {
    private authUserId: string | undefined;
    private inputTokenValue: string | undefined | null = null;
    private triedTokens: string[] = [];
    private errors: {
        [field: string]: string;
    } = {};
    private inviteToken: string | undefined;
    private inputToken: string | undefined;

	private created(): void {
		if (this.inputToken) {
			this.inputTokenValue = this.inputToken || null;
			this.onTokenChanged();
		}
	}

	private mounted(): void {
		(this.$refs.inputToken as HTMLInputElement).focus();
	}

	@LoadingScreen({ fullScreen: true })
	private async activate(): Promise<void> {
		if (this.validate()) {
			if (!this.authUserId) {
				//TODO
			} else {
				const result = await ErrorHandler.tryRequest(() =>
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					UserService.activateUser(this.authUserId!, this.inputTokenValue!),
				);
				if (result) {
					if (this.inviteToken) {
						const teamId = await ErrorHandler.tryRequest(() =>
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							UserService.joinByInvitation(this.inviteToken!),
						);
						if (teamId) {
							this.$router.push({ name: 'dashboard', params: { teamId } });
						}
					} else {
						this.$router.push({ name: 'manageTeams' });
					}
				}
			}
		}
	}

	private onTokenChanged() {
		this.$nextTick(() => {
			const token = this.inputTokenValue ? this.inputTokenValue.trim().split(/\s+/g).join('') : undefined;
			if (token && token.match(/[0-9]{4}\s*[0-9]{4}/g)) {
				if (this.triedTokens.indexOf(token) < 0) {
					this.triedTokens.push(token);
					this.activate();
				}
			}
		});
	}

	private validate(): boolean {
		let valid = true;
		this.errors = {};

		if (!this.inputTokenValue) {
			valid = false;
			this.errors['inputToken'] = this.$t('registration.validationErrors.required') as string;
		}

		return valid;
	}
}

import Vue from 'vue';
import Component from 'vue-class-component';
import template from './change-project-subscription.html';
import './change-project-subscription.scss';
import {NextFunction, Route} from 'vue-router';
import RespressoApi from '../../../api/respresso-api';
import {
    ProjectMeta,
    ProjectSubscriptionPlan,
    ProjectSubscriptionPlanFee,
    ProjectSubscriptionPlanLimitViolation,
    ProjectSubscriptionPlanOption,
    StartProjectSubscriptionCheckoutResponse,
    TeamMeta,
} from 'respresso';
import ErrorHandler from '../../../services/error-handler';
import LoadingScreen from '../../../decorators/loading-screen';
import {teamModule} from '../../../store/modules/team';
import {DialogBuilder, NotificationBuilder} from '../../common/dialog/dialog';
import {SlimSelectOption} from '../../common/vue-slim-select/vue-slim-select';
import {initPaddle, PaddleCheckoutEvent} from '../../../paddle';
import UserService from '../../../services/user-service';
import {sleep} from '../../../utils';

@Component({
	template: template,
})
export default class ChangeProjectSubscription extends Vue {
	private teamId = '';
	private projectId = '';
	private subscriptionOptions: ProjectSubscriptionPlanOption[] = [];
	private current?: ProjectSubscriptionPlan | null = null;
	private currentFee?: ProjectSubscriptionPlanFee;
	private canSelect = false;
	private selected?: ProjectSubscriptionPlan | null = null;
	private selectedFee?: ProjectSubscriptionPlanFee;
	private violations?: ProjectSubscriptionPlanLimitViolation[];
	private checkoutState: 'checkout' | 'polling' | 'success' | 'failed' = 'checkout';

	private lastVerificationToken: string | undefined;
	private limitViolationType: LimitViolationType = null;
	private limitViolationCategory: null | string = null;
	private limitViolationValue: null | number = null;

	private team: TeamMeta = {
		id: '',
		title: '',
	} as TeamMeta;

	private project?: ProjectMeta | null = null;

	beforeRouteEnter(to: Route, from: Route, next: NextFunction): void {
		next((vm) => {
			const res = vm as ChangeProjectSubscription;
			res.teamId = to.params.teamId;
			res.projectId = to.params.projectId;
			res.limitViolationType = to.query.lvt as LimitViolationType;
			res.limitViolationCategory = to.query.lvc as string | null;
			res.limitViolationValue =
				typeof to.query.lvv === 'string'
					? Number.parseFloat(to.query.lvv)
					: typeof to.query.lvv === 'number'
					? to.query.lvv
					: null;
			res.loadSubscriptions();
		});
	}

	private getProject(): ProjectMeta | null {
		const moduleGetterTeam = teamModule.team;
		if (!moduleGetterTeam || !moduleGetterTeam.projects) {
			return null;
		}
		const project = moduleGetterTeam.projects.filter((p: ProjectMeta) => {
			return p.id === this.projectId;
		});
		if (project.length) {
			return project[0];
		} else {
			return null;
		}
	}

	@LoadingScreen({ showImmediately: true })
	private async loadSubscriptions(): Promise<void> {
		const team = teamModule.team;
		const project = this.getProject();
		if (team && project) {
			this.team = team;
			this.project = project;
			const options = await ErrorHandler.tryRequest(() =>
				RespressoApi.loadProjectSubscriptionPlanOptions(this.teamId!, this.projectId!),
			);
			if (options) {
				this.current = options.current.plan;
				this.currentFee = options.current.fee;
				let selected: ProjectSubscriptionPlanOption | undefined = undefined;
				const applicable = options.options.filter(
					(option) => option.applicable && option.plan.id !== this.current?.id,
				);
				if (this.limitViolationType != null && this.limitViolationValue != null) {
					const requiredLimit = this.limitViolationValue;
					if (this.limitViolationType !== 'resCount' || this.limitViolationCategory != null) {
						const category = this.limitViolationCategory;
						let applicableWithLimitViolation: ProjectSubscriptionPlanOption[] = [];
						switch (this.limitViolationType) {
							case 'locLangCount':
								applicableWithLimitViolation = applicable.filter((option) => {
									return (
										option.plan.limitLocalizationLanguagesPerVersion < 0 ||
										option.plan.limitLocalizationLanguagesPerVersion >= requiredLimit
									);
								});
								break;
							case 'memberCount':
								applicableWithLimitViolation = applicable.filter((option) => {
									return option.plan.limitMembers < 0 || option.plan.limitMembers >= requiredLimit;
								});
								break;
							case 'versionCount':
								applicableWithLimitViolation = applicable.filter((option) => {
									return (
										option.plan.limitVersionsPerCategory < 0 ||
										option.plan.limitVersionsPerCategory >= requiredLimit
									);
								});
								break;
							case 'resCount':
								applicableWithLimitViolation = applicable.filter((option) => {
									const planLimit = option.plan.limitResourcesPerVersion[category!];
									return planLimit < 0 || planLimit >= requiredLimit;
								});
								break;
						}
						// sort ascending
						const suggestedOptions = applicableWithLimitViolation.sort((a, b) => a.fee.price - b.fee.price);
						if (suggestedOptions.length > 0) {
							selected = suggestedOptions[0];
						}
					}
				}
				if (!selected) {
					const higherPrice = applicable.filter((value) => value.fee.price > options.current.fee.price);
					// sort ascending
					const ascendingByPrice = higherPrice.sort((a, b) => a.fee.price - b.fee.price);
					if (ascendingByPrice.length > 0) {
						selected = ascendingByPrice[0];
					}
				}
				if (!selected) {
					selected = options.options.find((it) => it.plan.id === this.current?.id);
				}
				if (!selected) {
					selected = options.options.find((it) => it.applicable);
				}
				if (!selected && options.options.length > 0) {
					selected = options.options[0];
				}
				this.subscriptionOptions = options.options;
				if (selected) {
					this.updateSelectedPlan(selected);
				}
				this.$forceUpdate();
			} else {
				NotificationBuilder.error(this.$t('error.changeProjectSubscription.failedToFetchOptions') as string);
			}
		} else {
			// TODO proper handling
			this.$router.back();
		}
	}

	formatLimit(value?: number) {
		if (typeof value === 'number') {
			if (value >= 0) {
				return `${value}`;
			}
		}
		return this.$t('team.settings.subscriptions.limit.unlimited');
	}

	getCategories() {
		const categories = new Set<string>(['localization', 'color', 'image', 'appIcon', 'font', 'raw']);
		this.collectLimitedCategories(categories, this.current!);
		this.collectLimitedCategories(categories, this.selected!);
		return Array.from(categories);
	}

	private collectLimitedCategories(categories: Set<string>, plan?: ProjectSubscriptionPlan) {
		if (plan && plan.limitResourcesPerVersion) {
			for (const category in plan.limitResourcesPerVersion) {
				if (plan.limitResourcesPerVersion.hasOwnProperty(category)) {
					categories.add(category);
				}
			}
		}
	}

	private createPlanSelectOptions(): SlimSelectOption[] {
		if (this.subscriptionOptions) {
			return this.subscriptionOptions.map((option) => {
				const selectOption: SlimSelectOption = {
					text: option.plan.name,
					selected: this.selected ? this.selected.id === option.plan.id : false,
					value: option.plan.id,
				};
				return selectOption;
			});
		}
		return [];
	}

	private computeChangeStyle(current: number, selected: number): 'unchanged' | 'worse' | 'better' | '' {
		if (current < 0 && selected < 0) {
			return 'unchanged';
		} else if (current < 0) {
			return 'worse';
		} else if (selected < 0) {
			return 'better';
		} else if (selected > current) {
			return 'better';
		} else if (current > selected) {
			return 'worse';
		} else {
			return 'unchanged';
		}
	}

	private computeChangeLabel(current: number, selected: number): string {
		if (current < 0 && selected < 0) {
			return this.$i18n.t('team.settings.subscriptions.compare.bothUnlimited') as string;
		} else if (current < 0) {
			return this.$i18n.t('team.settings.subscriptions.compare.newLimit', { limit: selected }) as string;
		} else if (selected < 0) {
			return this.$i18n.t('team.settings.subscriptions.compare.limitRemoved') as string;
		} else if (selected > current) {
			return `+${selected - current}`;
		} else if (current > selected) {
			return `-${current - selected}`;
		} else {
			return this.$i18n.t('team.settings.subscriptions.compare.sameLimit') as string;
		}
	}

	private planChanged(event: any) {
		const id = event.value;
		if (this.subscriptionOptions && id) {
			const selected = this.subscriptionOptions.find((it) => it.plan.id === id);
			if (selected) {
				this.updateSelectedPlan(selected);
				this.$forceUpdate();
			}
		}
	}

	private updateSelectedPlan(selected: ProjectSubscriptionPlanOption) {
		this.canSelect = selected.applicable && this.current?.id !== selected.plan.id;
		this.selected = selected.plan;
		this.selectedFee = selected.fee;
		this.violations = selected.limitViolations;
	}

	private startCheckout() {
		if (this.canSelect) {
			switch (this.selected?.provider) {
				case 'INTERNAL':
					this.checkoutWithInternalPlan(this.teamId, this.projectId, this.selected?.id);
					break;
				case 'PADDLE':
					this.checkoutWithPaddlePlan(this.teamId, this.projectId, this.selected?.id);
					break;
				default:
					NotificationBuilder.error('Unsupported provider');
					break;
			}
		} else {
			NotificationBuilder.warning(this.$t('error.changeProjectSubscription.selectAvailablePlan') as string);
		}
	}

	private async checkoutWithInternalPlan(team: string, project: string, plan: string) {
		await this.checkoutWithVerificationCallback(team, project, plan, async (response) => {
			await ErrorHandler.tryRequest(() => RespressoApi.proceedInternalCheckout(response.verify!));
			NotificationBuilder.success(
				this.$t('team.settings.changeProjectSubscription.action.checkout.success') as string,
			);
			this.goBackToSubscriptions();
		});
	}

	private async checkoutWithPaddlePlan(team: string, project: string, plan: string) {
		await this.checkoutWithVerificationCallback(team, project, plan, async (response) => {
			const verify = response.verify!;
			if (
				response.type === 'UPDATE' &&
				response.instructions &&
				(response.instructions as PaddleCheckoutInstructions).hasSubscription
			) {
				this.confirmPaddlePlanChange(team, project, plan, response);
			} else if (response.instructions) {
				const instructions: PaddleCheckoutInstructions = response.instructions;
				initPaddle(instructions.vendorId, this.onCheckoutEvent);
				// @ts-ignore
				Paddle.Checkout.open({
					product: Number.parseInt(instructions.planId, 10),
					passthrough: verify,
					email: UserService.getUser()?.email,
				});
			}
			return true;
		});
	}

	private onCheckoutEvent(event: PaddleCheckoutEvent) {
		switch (event.event) {
			// case "Checkout.PaymentComplete":
			//
			// 	break;
			// case "Checkout.Close":
			// 	break;
			case 'Checkout.Complete':
				this.checkoutFinished();
				break;
			// default:
			// 	console.log("Paddle checkout event", JSON.stringify(event));
			// 	break;
		}
	}

	private async confirmPaddlePlanChange(
		team: string,
		project: string,
		plan: string,
		response: StartProjectSubscriptionCheckoutResponse,
	) {
		await DialogBuilder.confirm(
			'#team.settings.changeProjectSubscription.action.checkout.confirm.title',
			'#team.settings.changeProjectSubscription.action.checkout.confirm.message',
			async () => {
				await this.proceedWithConfirmedPaddlePlanChange(team, project, plan, response);
			},
		);
	}

	@LoadingScreen()
	private async proceedWithConfirmedPaddlePlanChange(
		eam: string,
		project: string,
		plan: string,
		response: StartProjectSubscriptionCheckoutResponse,
	) {
		await ErrorHandler.tryRequest(() => RespressoApi.proceedPaddleCheckout(response.verify!));
		NotificationBuilder.success(
			this.$t('team.settings.changeProjectSubscription.action.checkout.success') as string,
		);
		this.checkoutFinished();
	}

	private checkoutFinished() {
		this.checkoutState = 'polling';
		this.pollCheckoutState();
	}

	private async pollCheckoutState(retry = 0) {
		if (this.checkoutState !== 'polling') return;
		if (retry >= 100) {
			this.checkoutState = 'failed';
			return;
		}
		let success = false;
		try {
			const response = await RespressoApi.pollCheckoutSuccess(this.teamId, this.projectId, this.selected!.id!);
			if (response && response.matching) {
				success = true;
				this.checkoutState = 'success';
			}
		} catch (e) {
			console.error(e);
		} finally {
			if (!success) {
				await sleep(1000);
				await this.pollCheckoutState(retry + 1);
			}
		}
	}

	@LoadingScreen()
	private async checkoutWithVerificationCallback(
		team: string,
		project: string,
		plan: string,
		callback: (response: StartProjectSubscriptionCheckoutResponse) => Promise<any>,
	) {
		const response = await this.loadCheckoutInfo(team, project, plan);
		if (response) {
			const verify = response.verify;
			if (verify) {
				await callback(response);
			} else {
				NotificationBuilder.error(this.$t('error.projectCheckout.checkoutRejected') as string);
			}
		} else {
			NotificationBuilder.error(this.$t('error.projectCheckout.failedToFetchInfo') as string);
		}
	}

	private async loadCheckoutInfo(team: string, project: string, plan: string) {
		return await ErrorHandler.tryRequest(() => RespressoApi.loadCheckoutInfo(team, project, plan!));
	}

	private goBackToSubscriptions() {
		return this.$router.replace({
			name: 'teamSubscriptions',
			params: { team: this.teamId },
		});
	}

	private createContactUsUrl() {
		const subject = `Project subscription for '${this.project?.title}'`;
		const content =
			`The transaction failed during project subscription update.\n` +
			`Details (Please do not modify):\n` +
			`  When: ${new Date().toISOString()}\n` +
			`  Team: ${this.team?.title} (${this.teamId})\n` +
			`  Project: ${this.project?.title} (${this.projectId})\n` +
			`  Current plan: ${this.current?.name} (${this.current?.id}/${this.current?.providerPlanId})\n` +
			`  Desired plan: ${this.selected?.name} (${this.selected?.id}/${this.selected?.providerPlanId})\n` +
			`  Verification: ${this.lastVerificationToken}\n`;
		return `mailto:info@respresso.io?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(content)}`;
	}
}

interface PaddleCheckoutInstructions {
	vendorId: string;
	planId: string;
	hasSubscription: boolean;
}

export type LimitViolationType = null | 'memberCount' | 'versionCount' | 'resCount' | 'locLangCount';

import Vue from 'vue';
import Component from 'vue-class-component';
import template from './paddle-team-subscription-receipts.html';
import './paddle-team-subscription-receipts.scss';
import {NextFunction, Route} from 'vue-router';
import RespressoApi from '../../../api/respresso-api';
import {PaddleProjectSubscriptionPayment, TeamMeta} from 'respresso';
import ErrorHandler from '../../../services/error-handler';
import LoadingScreen from '../../../decorators/loading-screen';
import {teamModule} from '../../../store/modules/team';
import {NotificationBuilder} from '../../common/dialog/dialog';
import SubscriptionDateFilter from '../../../filters/subscription-date-filter';

@Component({
	template: template,
})
export default class PaddleTeamSubscriptionReceipts extends Vue {
	private teamId = '';
	private loaded = false;
	private payments: PaddleProjectSubscriptionPayment[] = [];

	private team: TeamMeta = {
		id: '',
		title: '',
	} as TeamMeta;

	beforeRouteEnter(to: Route, from: Route, next: NextFunction): void {
		next((vm) => {
			const res = vm as PaddleTeamSubscriptionReceipts;
			res.teamId = to.params.teamId;
			res.loadReceipts();
		});
	}

	@LoadingScreen({ showImmediately: true })
	private async loadReceipts(): Promise<void> {
		this.loaded = false;
		const team = teamModule.team;
		if (team) {
			this.team = team;
			const response = await ErrorHandler.tryRequest(() => RespressoApi.getPaddleTeamReceipts(this.team.id));
			if (response) {
				this.payments = response;
				this.loaded = true;
			} else {
				NotificationBuilder.error(this.$t('error.teamSubscriptionReceipts.failedToFetchPayments') as string);
			}
		} else {
			// TODO proper handlings
			this.$router.back();
		}
	}

	private formatDate(date: any) {
		return SubscriptionDateFilter(date);
	}
}

import Vue from 'vue';
import Component from 'vue-class-component';
import template from './create-project-invite.html';
import './create-project-invite.scss';
import {IDialogComponent} from "@ponte/dialog-vue";
import {IDialog} from '@ponte/dialog';
import DialogTemplate from "../../common/dialog/dialogs/dialog-template/dialog-template";
import {ProjectRole} from "respresso";
import {SlimSelectOption} from "../../common/vue-slim-select/vue-slim-select";
import {translate} from "../../../main";

export interface CreateProjectInviteResponse {
	name: string;
	email: string;
	role: ProjectRole;
}

@Component({
	components: {
		'dialog-template': DialogTemplate,
	},
	template: template,
})
export default class CreateProjectInvite extends Vue implements IDialogComponent<CreateProjectInviteResponse | undefined> {
	dialog!: IDialog<CreateProjectInviteResponse | undefined>;

	public name: string = "";
	public email: string = "";
	public role: ProjectRole = "VIEWER";

	public mounted(): void {
		window.addEventListener('keyup', this.handleKeyUp);
	}

	public unmounted(): void {
		window.removeEventListener('keyup', this.handleKeyUp);
	}

	private handleKeyUp(event: KeyboardEvent) {
		switch (event.key || event.keyCode) {
			case "Escape":
			case 27:
				this.closeDialog();
				break;
			case "Enter":
			case 13:
				if (this.saveable()) {
					this.createInvite();
				}
				break;
		}
	}

	createInvite() {
		if (this.dialog.dialogController) {
			this.dialog.dialogController.close({
				name: this.name,
				email: this.email,
				role: this.role
			});
		}
	}


	protected createRoleSelectOption(): SlimSelectOption[] {
		const roles: ProjectRole[] = ["VIEWER", "MEMBER", "EDITOR", "ADMIN"];
		return roles.map(role => {
			const option: SlimSelectOption = {
				text: this.getRole(role),
				value: role,
				selected: this.role === role
			};
			return option;
		});
	}

	private getRole(role: string): string {
		return translate(`project.role.${role.toLowerCase()}.name`);
	}

	protected async roleChanged(event: any) {
		this.role = event.value as ProjectRole;
		this.$forceUpdate();
	}

	closeDialog() {
		if (this.dialog.dialogController) {
			this.dialog.dialogController.close(undefined);
		}
	}

	saveable(): boolean {
		return !!this.name && !!this.name.trim() && !!this.email && !!this.email.trim();
	}
}

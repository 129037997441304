import RespressoApi, {UserWorkRole} from '../api/respresso-api';
import Vue from 'vue';
import Component from 'vue-class-component';
import {InvitationVerifiedRegistrationResponse, JoinableTeams, RegistrationResponse, TeamInfo, TeamMeta, User} from 'respresso';
import StorageService from './storage-service';
import {ProjectsList} from '../components/view/connect-project/join-project';
import ErrorHandler from './error-handler';
import {PassModResponse} from '../components/view/new-password/new-password';
import {app} from '../main';
import {userModule} from '.././store/modules/user/index';
import {teamModule} from '.././store/modules/team/index';
import {menuModule} from '.././store/modules/menu/index';

export type TutorialStatus = 'UPLOAD' | 'INTEGRATION' | 'SYNC' | 'DONE';
export type SelectedPlatform = '' | 'iOS' | 'Android' | 'Vue' | 'Angular' | 'React' | 'VanillaJS' | 'Web';

@Component
export class UserServiceImpl extends Vue {
    private initProm: Promise<void> | undefined;

    public async init(): Promise<void> {
        if (!this.initProm) {
            const initPromFunc = async (): Promise<void> => {
                const user = await ErrorHandler.tryRequest(() => RespressoApi.getCurrentUser(), {
                    respressoErrorHandler: (error) => {
                        return !app.$route.meta?.auth && error.type === 'AuthenticationException';
                    },
                });
                if (user) {
                    this.saveUser(user);
                    await this.loadTeams();
                } else {
                    this.removeSavedUser();
                    this.unloadTeams();
                }
            };

            this.initProm = initPromFunc();
        }
        return this.initProm;
    }

    public async refreshData(): Promise<void> {
        await this.loadTeams();
    }

    public async register(
        email: string,
        fullName: string,
        password: string,
        newsletter: boolean,
        recaptcha: string,
        workRole: UserWorkRole,
        inviteToken?: string,
    ): Promise<RegistrationResponse | InvitationVerifiedRegistrationResponse> {
        const response = await RespressoApi.register(email, fullName, password, newsletter, recaptcha, workRole, inviteToken);
        const casted = response as InvitationVerifiedRegistrationResponse;
        if (casted && casted.user) {
            this.saveUser(casted.user);
            await this.loadTeams();
        }
        return response;
    }

    public async login(email: string, password: string): Promise<User> {
        const user = await RespressoApi.login(email, password);
        this.saveUser(user);
        await this.loadTeams();
        return user;
    }

    public async updateTutorialStatus(tutorialStatus: TutorialStatus): Promise<User> {
        return RespressoApi.updateTutorialStatus(tutorialStatus);
    }

    public async getRandomProfilePicture(): Promise<void> {
        return RespressoApi.getRandomProfilePicture();
    }

    public async sendResetPassword(email: string): Promise<string> {
        return RespressoApi.sendResetPassword(email);
    }

    public async resetPassword(passToken: string, newPassword: string): Promise<PassModResponse> {
        return RespressoApi.resetPassword(passToken, newPassword);
    }

    public async activateUser(authUserId: string, inputToken: string): Promise<User> {
        const user = await RespressoApi.activateUser(authUserId, inputToken);
        this.saveUser(user);
        return user;
    }

    public async getJoinableTeams(): Promise<JoinableTeams> {
        return RespressoApi.getJoinableTeams();
    }

    public async getJoinableProjects(teamId: string): Promise<ProjectsList> {
        return RespressoApi.getJoinableProjects(teamId);
    }

    public async joinTeam(team: TeamInfo): Promise<void> {
        teamModule.addTeam(team);
        return RespressoApi.joinTeam(team);
    }

    public async joinByInvitation(inviteToken: string): Promise<string> {
        return RespressoApi.joinByInvitation(inviteToken);
    }

    public async joinByInvitationLater(team: TeamInfo): Promise<string> {
        teamModule.addTeam(team);
        return RespressoApi.joinByInvitationLater(team);
    }

    public async logout(): Promise<void> {
        const user = this.getUser();
        this.removeSavedUser();
        if (user) {
            await RespressoApi.logout();
        }
        this.unloadTeams();
    }

    public getUser(): User | null {
        if (userModule.user) {
            return userModule.user;
        }
        return StorageService.getUser();
    }

    public async isLoggedInAsync(): Promise<boolean> {
        await this.init();
        return this.isLoggedIn();
    }

    public isLoggedIn(): boolean {
        return this.getUser() !== null;
    }

    public async loadTeam(teamId: string): Promise<TeamMeta | null> {
        const newTeam = await ErrorHandler.tryRequest(() => RespressoApi.getTeam(teamId));
        if (newTeam) {
            teamModule.SET_TEAM(newTeam);
            StorageService.setTeam(newTeam);
        }
        return newTeam;
    }

    public saveUser(user: User): void {
        userModule.setUser(user);
        StorageService.setUser(user);
        app.$ga.set('userId', user.id);
    }

    private removeSavedUser(): void {
        userModule.remove();
        StorageService.removeUser();
        app.$ga.set('userId', null);
    }

    public async loadTeams(): Promise<TeamInfo[] | null> {
        const teams = await ErrorHandler.tryRequest(() => RespressoApi.getTeams());
        if (teams) {
            if (teams.teams) {
                teamModule.SET_TEAMS(teams.teams);
                return teams.teams;
            }
        }
        return null;
    }

    public async getOrLoadTeams(loadDefaultTeam = true): Promise<TeamInfo[] | null> {
        if (!teamModule.teams) {
            const teams = await this.loadTeams();
            if (loadDefaultTeam) {
                await this.loadDefaultTeamIfNecessary();
            }
        }
        return teamModule.teams;
    }

    public async loadDefaultTeamIfNecessary(): Promise<void> {
        const teams = teamModule.teams;
        if (!teamModule.team && teams && teams.length > 0) {
            let team = teams[0];
            const teamId = StorageService.getLastIntegrationTeam();
            if (teamId) {
                const matchingTeam = teams.find(it => it.id === teamId);
                if (matchingTeam) {
                    team = matchingTeam;
                }
            }
            const teamMeta = await this.loadTeam(team.id);
            if (teamMeta) {
                teamModule.SET_TEAM(teamMeta);
            }
        }
    }

    private unloadTeams(): void {
        teamModule.SET_TEAM(null);
        teamModule.SET_TEAMS(null);

        StorageService.removeTeam();
    }

    public async hasPermission(teamName: string, projectName: string, permissionType: string): Promise<boolean> {
        const permissionResponse = await RespressoApi.hasPermission(teamName, projectName, permissionType);

        return permissionResponse.hasPermission;
    }

    public async deleteAccount(): Promise<void> {
        await ErrorHandler.tryRequest(() => RespressoApi.deleteAccount());
        teamModule.removeTeam();
        userModule.remove();
        teamModule.removeProject();
        menuModule.remove();
        StorageService.clear();
    }
}

const UserService = new UserServiceImpl();
export default UserService;

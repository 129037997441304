export function initMautic(url: string): void {
	if (!url) {
		return;
	}

	(function (w: any, d: any, t: any, u: any, n: any, a?: any, m?: any) {
		w['MauticTrackingObject'] = n;
		(w[n] =
			w[n] ||
			function () {
				(w[n].q = w[n].q || []).push(arguments);
			}),
			(a = d.createElement(t)),
			(m = d.getElementsByTagName(t)[0]);
		a.async = 1;
		a.src = u;
		m.parentNode.insertBefore(a, m);
	})(window, document, 'script', url, 'mt');
}

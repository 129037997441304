/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */

import VueDialogService, {IDialogComponent} from '@ponte/dialog-vue';
import {createVueVMInstance, translate} from '../../../main';
import {IDialogController} from '@ponte/dialog';
import Alert, {AlertDialogInput} from './dialogs/alert/alert';
import Confirm, {ConfirmDialogInput} from './dialogs/confirm/confirm';
import YesNoCancel, {YesNoCancelDialogInput} from './dialogs/yes-no-cancel/yes-no-cancel';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';

alertify.defaults = {
    notifier: {
        delay: 5,
        position: 'top-center',
        closeButton: false,
    },
};

const innerTranslate = function(text: string): string {
    if (text.indexOf('#') === 0) {
        return translate(text.replace('#', ''));
    }
    return text;
};

type DialogResultFromDialogInstance<T extends IDialogComponent<any>> =
    T extends IDialogComponent<infer TResult> ? TResult : never;

export interface VueDialogController<TInstance extends IDialogComponent<any>> extends IDialogController<DialogResultFromDialogInstance<TInstance>> {
    vueInstance: TInstance;
}

export class DialogBuilder {
    static createVueDialog<T extends new (...args: any) => IDialogComponent<any>>(
        viewModel: T,
        extra?: Record<string, unknown>,
    ): VueDialogController<InstanceType<T>> {
        const component = createVueVMInstance(viewModel, extra) as InstanceType<T>;
        const dialogController = VueDialogService.openDialog(component) as VueDialogController<InstanceType<T>>;
        dialogController.vueInstance = component;
        return dialogController;
    }

    static alert(title: string, message: string, okCb?: () => void): VueDialogController<Alert> {
        const inputData: AlertDialogInput = {
            title: innerTranslate(title),
            message: innerTranslate(message),
            okCb,
        };
        return DialogBuilder.createVueDialog(Alert, {
            propsData: { inputData },
        });
    }

    static confirm(title: string, message: string, okCb?: () => void, cancelCb?: () => void): Promise<boolean> {
        const inputData: ConfirmDialogInput = {
            title: innerTranslate(title),
            message: innerTranslate(message),
            okCb,
            cancelCb,
        };
        const dialogController = VueDialogService.openDialog(
            createVueVMInstance(Confirm, {
                propsData: { inputData },
            }),
        );

        return new Promise<boolean>((resolve): void => {
            dialogController.closeResult.then(res => {
                resolve(res);
            });
        });
    }

    static yesNoCancel(
        title: string,
        message: string,
        yesCb?: () => void,
        noCb?: () => void,
        cancelCb?: () => void,
    ): Promise<boolean | undefined> {
        const inputData: YesNoCancelDialogInput = {
            title: innerTranslate(title),
            message: innerTranslate(message),
            yesCb,
            noCb,
            cancelCb,
        };
        const dialogController = VueDialogService.openDialog(
            createVueVMInstance(YesNoCancel, {
                propsData: { inputData },
            }),
        );

        return new Promise<boolean | undefined>((resolve): void => {
            dialogController.closeResult.then(res => {
                resolve(res);
            });
        });
    }
}

export class NotificationBuilder {
    static success(text: string): void {
        alertify.success(innerTranslate(text));
    }

    static error(text: string): void {
        if (text === '#serverError.unknownError' || text.startsWith('#')) {
            alertify.error(innerTranslate(text));
        } else {
            alertify.error(innerTranslate('#serverError.respressoError.' + text));
        }
    }

    static warning(text: string): void {
        alertify.warning(innerTranslate(text));
    }
}

/* eslint-disable */
import {CustomError} from '../util/errors';
import 'whatwg-fetch';

export interface RespressoErrorResponse<T> {
    type: 'internal_server_error' | string;
    key?: string;
    params?: {
        [key: string]: string;
    };
    data?: T;
}

export interface HttpService {
    get(path: string, headers?: Map<string, string>): Promise<any>;

    post(path: string, body?: any, headers?: Map<string, string>): Promise<any>;

    postRaw(path: string, body?: any, userheaders?: any): Promise<any>;

    put(path: string, body?: any, headers?: Map<string, string>): Promise<any>;

    delete(path: string, body?: any, headers?: Map<string, string>): Promise<any>;

    head(path: string, headers?: Map<string, string>): Promise<any>;
}

export class UnknownServerError extends CustomError {
    constructor() {
        super('Unknown Error');
    }
}

export class NetworkError extends CustomError {
    constructor() {
        super('Network Error');
    }
}

export class NotFoundError extends CustomError {
    constructor() {
        super('Not Found');
    }
}

export class InternalServerError extends CustomError {
    constructor() {
        super('Internal Server Error');
    }
}

export class RespressoError<T> extends CustomError implements RespressoErrorResponse<T> {
    readonly type: string;
    readonly key?: string;
    readonly params?: { [key: string]: string };
    readonly data?: T;

    constructor(serverResponse: Response, errorResponse: RespressoErrorResponse<T>) {
        super(serverResponse.statusText);
        this.type = errorResponse.type;
        this.key = errorResponse.key;
        this.params = errorResponse.params;
        this.data = errorResponse.data;
    }
}

export class FetchHttpService implements HttpService {
    public async get(path: string, headers?: Map<string, string>): Promise<any> {
        return this.doRequest('GET', path, null, headers);
    }

    public async post(path: string, body?: any, headers?: Map<string, string>): Promise<any> {
        return this.doRequest('POST', path, body, headers);
    }

    public async postRaw(path: string, body?: any, userheaders?: any): Promise<any> {
        let response;
        const headers = Object.assign({}, userheaders, {
            'X-Requested-With': 'Respresso',
        });
        try {
            response = await window.fetch(path, {
                method: 'POST',
                credentials: 'same-origin',
                headers,
                body,
            });
        } catch (e) {
            throw new NetworkError(); //Csak ha a szerver nem is érhető el (TCP error, protocol error)
        }
        return this.handleResponse(response);
    }

    public async put(path: string, body?: any, headers?: Map<string, string>): Promise<any> {
        return this.doRequest('PUT', path, body, headers);
    }

    public async delete(path: string, body?: any, headers?: Map<string, string>): Promise<any> {
        return this.doRequest('DELETE', path, body, headers);
    }

    public async head(path: string, headers?: Map<string, string>): Promise<any> {
        return this.doRequest('HEAD', path, null, headers);
    }

    private async doRequest(method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'HEAD', path: string, body?: any, headers?: Map<string, string>) {
        let response;
        try {
            response = await window.fetch(path, this.getRequestInitData(method, body, headers));
        } catch (e) {
            throw new NetworkError(); //Csak ha a szerver nem is érhető el (TCP error, protocol error)
        }
        return this.handleResponse(response);
    }

    private getRequestInitData(method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'HEAD', body?: any, additionalHeaders?: Map<string, string>): RequestInit {
        const init: RequestInit = {
            method,
        };

        const headers = new Headers();

        headers.set('X-Requested-With', 'Respresso');
        if (additionalHeaders) {
            additionalHeaders.forEach((value, key) => {
                headers.set(key, value);
            });
        }

        if (body) {
            init.body = JSON.stringify(body);
            headers.set('Content-Type', 'application/json');
        }

        init.headers = headers;
        init.credentials = 'same-origin';

        return init;
    }

    private async handleResponse(response: Response): Promise<any> {
        //TODO handle all status codes vagy response.ok
        if (response.status < 200 || response.status >= 400) {
            if (response.status === 404) {
                throw new NotFoundError();
            }

            let errorData: RespressoErrorResponse<any>;

            try {
                errorData = await response.json();
            } catch (e) {
                throw new UnknownServerError();
            }

            if (errorData && errorData.type) {
                if (errorData.type === 'internal_server_error') {
                    throw new InternalServerError();
                } else {
                    throw new RespressoError(response, errorData);
                }
            } else {
                throw new UnknownServerError();
            }
        }

        return response.json();
    }
}

import Vue from 'vue';
import Component from 'vue-class-component';
import template from './project.html';
import './project.scss';
import {NextFunction, Route} from 'vue-router';
import RespressoApi from '../../../api/respresso-api';
import ErrorHandler from '../../../services/error-handler';
import UserService from '../../../services/user-service';
import {DialogBuilder, NotificationBuilder} from '../../common/dialog/dialog';
import {copyTextToClipboard} from '../../../util/clipboard';
import LoadingScreen from '../../../decorators/loading-screen';
import {BulkImportTargets, CategoryStatMeta, ProjectMeta, TeamMeta} from 'respresso';
import {teamModule} from '../../../store/modules/team/index';
import AccessService from '../../../services/access-service';
import ProjectLastUpdatesList from '../../common/project/project-last-updates-list';
import ProjectPendingImportsBanner from '../../common/project/project-pending-imports-banner';
import StorageService from "../../../services/storage-service";
import {landingHost} from "../../../env";
import ProjectInviteMembersBanner from "../../common/project/project-invite-members-banner";

export interface LoadProjectResourcesMeta {
	resource: string;
	lastModifiedDate: number;
	modifier: string;
}

export interface LastupdatedProjectResult {
	lastUpdatedFiles: LastupdatedMeta[];
	lastUpdates: ProjectUpdateMeta[];
	totalElements: number;
}

export interface LastupdatedMeta {
	resource: string;
	lastModifiedDate: number;
	modifier: string;
	version: string;
}

export interface ProjectUpdateMeta {
	type: 'projectSaved' | 'resourceSaved' | 'fileSaved' | 'conversion_finished' | 'user' | string; // event_name = projectSaved | resourceSaved | fileSaved | conversion_finished
	event: string; // event_name.subevent_name -> used for localization
	eventTime: number;

	// Optional user id
	modifier: string;

	// Optional resource category
	category: string;

	// Optional resource version
	version: string;

	// Optional file name
	fileName: string;

	// Optional file size
	fileSize: number;

	// Optional additional params
	params: Map<string, string>;
}

@Component({
	template: template,
	components: {
		'project-last-updates-list': ProjectLastUpdatesList,
		'project-pending-imports-banner': ProjectPendingImportsBanner,
	    "project-invite-members-banner": ProjectInviteMembersBanner
    },
})
export default class Project extends Vue {
	private teamId = '';
	private projectId = '';
	private version = '';
	private resourceId = '';
	private forceHideGetStarted = false;

	private resources: LoadProjectResourcesMeta[] = [];
	private latestModifiedFiles: LastupdatedMeta[] = [];
	private latestUpdates: ProjectUpdateMeta[] = [];
	private permission = false;

	private pendingImports: BulkImportTargets | null = null;

	beforeRouteEnter(to: Route, from: Route, next: NextFunction): void {
		next((vm) => {
			const res = vm as Project;
			res.loadProjectResources(to);
		});
	}

	async beforeRouteUpdate(to: Route, from: Route, next: NextFunction): Promise<void> {
		await this.loadProjectResources(to);

		next();
	}

	@LoadingScreen({ showImmediately: true })
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	private async loadProjectResources(to: Route): Promise<void> {
		this.teamId = to.params.teamId;
		this.projectId = to.params.projectId;
		this.version = to.params.version;
		this.resourceId = to.params.resourceId;

		const readPermission = await ErrorHandler.tryRequest(() =>
			UserService.hasPermission(to.params.teamId, to.params.projectId, 'viewer'),
		);

		if (!readPermission) {
			if (readPermission === false) {
				DialogBuilder.alert(
					'#project.permissionDeniedTitle',
					this.$t('project.permissionDenied', {
						projectName: this.projectId,
					}) as string,
				);
				UserService.loadTeam(this.teamId);
				this.$router.push({ name: 'dashboard', params: { teamId: this.teamId } });
			}
			return;
		}

		const [writePermission, resourcesReps, lastUpdates, pendingImports] = await Promise.all([
			ErrorHandler.tryRequest(() => UserService.hasPermission(to.params.teamId, to.params.projectId, 'member')),
			ErrorHandler.tryRequest(() =>
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				RespressoApi.loadProjectResourcesMeta(this.teamId!, this.projectId!),
			),
			ErrorHandler.tryRequest(() =>
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				RespressoApi.loadLastUpdatedProjectMeta(this.teamId!, this.projectId!),
			),
			ErrorHandler.tryRequest(() =>
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				RespressoApi.getPendingImports(this.teamId!, this.projectId!),
			),
			UserService.loadTeam(this.teamId), // FIXME this is here to update project stats -> this is heavy and slows the load down -> should be replaced somehow
		]);
		if (writePermission !== null) {
			this.permission = writePermission;
		}

		if (resourcesReps) {
			this.resources = resourcesReps;
		}
		if (lastUpdates) {
			this.latestModifiedFiles = lastUpdates.lastUpdatedFiles;
			this.latestUpdates = lastUpdates.lastUpdates;
		}

		if (pendingImports) {
			this.pendingImports = pendingImports.imports;
		}

		// Don't bother the user with a blocking dialog...
		// if (userModule.tutorialStatus === 'UPLOAD' || userModule.tutorialStatus === null) {
		//     this.openGetStartedDialog();
		// }
	}

	get projectName(): string | undefined {
		if (teamModule.project) {
			return teamModule.project.title;
		}
	}

	get hasActiveSubscription(): boolean {
		if (teamModule.project) {
			return AccessService.hasActiveSubscription(teamModule.project);
		} else {
			return true; // Do not show warning until project is not loaded
		}
	}

	get canEditSubscriptions(): boolean {
		if (teamModule.project) {
			return AccessService.canEditTeamSubscriptions(teamModule.team);
		} else {
			return false;
		}
	}

    get canEditPermissions(): boolean {
        const project = this.project;
        if (project) {
            return AccessService.canEditProjectPermissions(project);
        } else {
            return false;
        }
    }

	get canEditResources(): boolean {
		if (teamModule.project) {
			return AccessService.canImportResources(teamModule.project);
		} else {
			return false;
		}
	}

	get team(): TeamMeta | null {
		return teamModule.team;
	}

	get project(): ProjectMeta | null {
		const moduleGetterTeam = teamModule.team;
		if (!moduleGetterTeam || !moduleGetterTeam.projects) {
			return null;
		}
		const project = moduleGetterTeam.projects.filter((p: ProjectMeta) => {
			return p.id === this.projectId;
		});
		if (project.length) {
			return project[0];
		} else {
			return null;
		}
	}

    get shouldInviteProjectMembers(): boolean {
        const userCount = this.project?.userCount || 0;
        return this.canEditSubscriptions && userCount === 1;
    }

	private async copyProjectToken(): Promise<void> {
		if (this.project) {
			const success = await copyTextToClipboard(this.project.token);
			if (success) {
				NotificationBuilder.success('#project.clipboard.copied');
			} else {
				NotificationBuilder.error('#project.clipboard.copyFailed');
			}
		}
	}

	private get hasResources() {
		return this.anyCatStatMatches((stat => stat.max > 0));
	}

	private get hasMultipleVersions() {
		return this.anyCatStatMatches((stat => stat.versions > 1));
	}

	private get wasSynced() {
		return this.project?.stat?.synced === true;
	}

	private get showGetStarted() {
		return !this.forceHideGetStarted
			&& this.project?.stat
			&& !StorageService.getGetStartedHiddenStatus(this.teamId, this.projectId)
			&& this.canEditResources
			&& (!this.hasResources || !this.wasSynced || !this.hasMultipleVersions);
	}

	private anyCatStatMatches(tester: (stat: CategoryStatMeta) => boolean): boolean {
		if(this.project && this.project.stat && this.project.stat.categories) {
			for (let category in this.project.stat.categories) {
				if (this.project.stat.categories.hasOwnProperty(category)) {
					const categoryStat = this.project.stat.categories[category];
					if(tester(categoryStat)) {
						return true;
					}
				}
			}
		}
		return false;
	}

	private async hideGetStartedForProject() {
		StorageService.setGetStartedHiddenStatus(this.teamId, this.projectId);
		this.forceHideGetStarted = true;
	}


	private get landingHost() {
		return landingHost;
	}
}

import Component from 'vue-class-component';
import template from './font-resource.html';
import './font-resource.scss';
import {ItemState, Resource, ResourceItem} from './resource';
import ResourceList from '../../common/resource-list/resource-list';
import {NotificationBuilder} from '../../common/dialog/dialog';
import RespressoApi from '../../../api/respresso-api';
import ErrorHandler from '../../../services/error-handler';
import {IFileDescriptor} from '@ponte/file-upload-js';
import MultiUploadBlock, {UploadState} from '../../common/upload/multi-upload-block';
import LoadingScreen from '../../../decorators/loading-screen';

export interface GetFontDataResponse {
	versionNumber: string;
	versionEditable: boolean;
	fontData: FontResourceItem[];
}

export interface FontResourceToSave {
	id?: string;
	groupName?: string;
	styleFiles?: FontStyleFile[];
	state: string;
}

export interface FontResourceItem extends ResourceItem {
	groupName?: string;
	styleFiles?: FontStyleFile[];
	deletedStyleFiles?: FontStyleFile[];
}

export interface FontStyleFile {
	style: string;
	fileId: string;
	fileSize: number;
	extension: string;
	state: FontDataState;
}

export enum FontDataState {
	NEW = 'NEW',
	UNCHANGED = 'UNCHANGED',
	UPDATED = 'UPDATED',
	DELETED = 'DELETED',
}

export interface FontUploadResult {
	fileId: string;
	mimeType: string;
	name: string;
	total: number;
	fontStyle: string;
	fontFamily: string;
	extension: string;
}

@Component({
	components: {
		'resource-list': ResourceList,
	},
	template: template,
})
export default class FontResource extends Resource {
	protected items: FontResourceItem[] = [];
	protected newItems: FontResourceItem[] = [];
	protected cssElement: HTMLLinkElement | undefined;
	protected uploader?: MultiUploadBlock;
	private uploadState: UploadState = 'empty';
	private uploaderTypes: string[] = ['ttf', 'otf'];

	private getUploader() {
		if (!this.uploader) {
			this.uploader = this.$refs.uploadBlock as MultiUploadBlock;
			this.uploadState = this.uploader.getState();
		}
		return this.uploader;
	}

	protected async loadData<FontResource>(): Promise<void> {
		this.cssElement = document.createElement('link');
		this.cssElement.rel = 'stylesheet';
		this.refreshCssLink();
		const headElement = document.querySelector('head');
		if (headElement) {
			headElement.appendChild(this.cssElement);
		}

		const resp = await ErrorHandler.tryRequest(
			() => RespressoApi.getFontVersion(this.teamId, this.projectId, this.version),
			{
				loadingScreen: true,
			},
		);

		if (resp) {
			this.setItemFromServer(resp.fontData);
			this.editable = resp.versionEditable;
		}
		this.$nextTick(() => {
			this.startTour(this.$refs.resourceList as ResourceList);
		});
	}

	protected onLoaded() {
		const uploadBlock = this.getUploader();
		if (uploadBlock) {
			uploadBlock.onUploadSucceeded(async (upload) => {
				const result = await upload.uploader.uploadResult;
				await this.handleUploadedFile(result as FontUploadResult);
			});
		}
	}

	@LoadingScreen({ fullScreen: true })
	protected async doSave(): Promise<boolean> {
		const uploader = this.getUploader();
		if (uploader && uploader.getState() === 'active') {
			await uploader.onIdle();
		}
		const items = this.items.slice(0);
		items.push(...this.newItems);

		const styleFilesMapper = (val: FontResourceItem): FontStyleFile[] => {
			const result = val.styleFiles ? val.styleFiles.slice(0) : [];
			if (val.deletedStyleFiles) {
				result.push(...val.deletedStyleFiles);
			}

			result.forEach((value) => {
				if (value.state === FontDataState.UPDATED) {
					value.state = FontDataState.NEW;
				}
			});

			return result;
		};

		const itemsToSave: FontResourceToSave[] = items.map(
			(value: FontResourceItem) =>
				({
					id: value.id,
					groupName: value.groupName,
					styleFiles: styleFilesMapper(value),
					state: value.state.toUpperCase(),
				} as FontResourceToSave),
		);

		const saveResp = await ErrorHandler.tryRequest(
			() => RespressoApi.saveFontVersion(this.teamId, this.projectId, this.version, itemsToSave),
			{
				loadingScreen: true,
				loadingMessage: '#loading.processingFonts',
			},
		);
		if (saveResp) {
			this.setItemFromServer(saveResp);
			NotificationBuilder.success('#messages.saved');

			this.refreshCssLink();

			return true;
		} else {
			return false;
		}
	}

	private refreshCssLink(): void {
		if (this.cssElement) {
			const value = `/api/ui/font/${this.teamId}/${this.projectId}/${
				this.version
			}/handle?date=${new Date().getTime()}`;
			this.cssElement.setAttribute('href', value);
		}
	}

	protected getResourceId(): string {
		return 'font';
	}

	private uploadStateChanged(state: UploadState) {
		this.uploadState = state;
		this.resumeTour();
	}

	private setItemFromServer(data: FontResourceItem[]): void {
		this.lastListId = 0;
		this.items = data.map((item) => {
			item.state = ItemState.UNCHANGED;
			item.listId = this.lastListId++;
			if (item.styleFiles) {
				item.styleFiles.forEach((value) => (value.state = FontDataState.UNCHANGED));
			}
			item.deletedStyleFiles = [];

			return item;
		});

		this.items.sort((a, b) => {
			if (!a.groupName && !b.groupName) {
				return 0;
			}
			if (!a.groupName) {
				return -1;
			}
			if (!b.groupName) {
				return 1;
			}

			return a.groupName.localeCompare(b.groupName);
		});
	}

	private findByGroupName(groupName: string): FontResourceItem | null {
		for (let i = 0; i < this.items.length; i++) {
			if (this.items[i].groupName === groupName) {
				return this.items[i];
			}
		}

		return null;
	}

	private findStyle(res: FontResourceItem, styleName: string): FontStyleFile | null {
		if (!res.styleFiles) {
			return null;
		}

		for (let i = 0; i < res.styleFiles.length; i++) {
			if (res.styleFiles[i].style === styleName) {
				return res.styleFiles[i];
			}
		}

		return null;
	}

	// Csak tesztelésre kell
	/*public getFontUrl(fileId: string) {
		return `/api/ui/font/preview/${this.teamId}/${this.projectId}/${this.version}/${fileId}`;
	}*/

	uploadFiles(file?: IFileDescriptor): void {
		const uploader = this.getUploader();
		if (uploader) {
			if (file && file.file) {
				uploader.submitUpload(file.file);
			} else {
				uploader.triggerFileSelect();
			}
		}
	}

	private uploadSelectedFile(file: IFileDescriptor) {
		this.uploadFiles(file);
	}

	private async handleUploadedFile(uploadedFile: FontUploadResult) {
		let item: FontResourceItem | null = this.findByGroupName(uploadedFile.fontFamily);
		if (!item) {
			item = this.makeNewItem();
			this.items.push(item);
		}

		item.groupName = uploadedFile.fontFamily;
		item.styleFiles = item.styleFiles || [];

		const findStyle = this.findStyle(item, uploadedFile.fontStyle);
		console.log('Font uploaded', item, findStyle);
		if (findStyle) {
			if (!item.deletedStyleFiles) {
				item.deletedStyleFiles = [];
			}

			item.deletedStyleFiles.push({
				style: findStyle.style,
				fileId: findStyle.fileId,
				fileSize: findStyle.fileSize,
				extension: findStyle.extension,
				state: FontDataState.DELETED,
			});

			findStyle.style = uploadedFile.fontStyle;
			findStyle.fileId = uploadedFile.fileId;
			findStyle.fileSize = uploadedFile.total;
			findStyle.extension = uploadedFile.extension;
			findStyle.state = FontDataState.UPDATED;
		} else {
			item.styleFiles.push({
				style: uploadedFile.fontStyle,
				fileId: uploadedFile.fileId,
				fileSize: uploadedFile.total,
				extension: uploadedFile.extension,
				state: FontDataState.NEW,
			});
		}

		if (item.state !== ItemState.NEW) {
			item.state = ItemState.UPDATED;
		}
		this.$forceUpdate();
	}

	private uploadMethod(uploadedFiles: any[]): void {
		uploadedFiles.forEach(this.handleUploadedFile);
	}

	protected tourTryUpload(): (() => void) | null {
		return this.uploadFiles;
	}
}

import Vue from 'vue';
import template from './text-search.html';
import './text-search.scss';
import Component from 'vue-class-component';

@Component({
	template: template,
})
export default class TextSearch extends Vue {
	private search = '';

	private searchChanged(): void {
		this.$emit('search-changed', this.search);
	}

	public focus(): void {
		(this.$refs.searchInput as HTMLElement).focus();
	}

	public select(): void {
		(this.$refs.searchInput as HTMLInputElement).setSelectionRange(0, this.search.length);
	}
}

import Vue from 'vue';
import Component from 'vue-class-component';
import template from './zeplin-integration.html';
import './zeplin-integration.scss';
import {NextFunction, Route} from 'vue-router';
import LoadingScreen from '../../../../decorators/loading-screen';
import {
	Category,
	ColorType,
	ExecuteImportResultSubMessage,
	Message,
	PreviewImportResultSubMessage,
	PreviewImportSubMessage,
	ScreenOpenedSubMessage,
	SubMessage,
} from '../integration-ws-types';
import ErrorHandler from '../../../../services/error-handler';
import RespressoApi, {
	AppliedResourceChangeStructure,
	AppliedResourceChangeType,
	IntegrationCategoryInfo,
	IntegrationImportPreviewResponse,
	IntegrationTokenInfo,
	LocalizationConfigStoredStructure,
} from '../../../../api/respresso-api';
import {SlimSelectOption} from '../../../common/vue-slim-select/vue-slim-select';
import StorageService from '../../../../services/storage-service';
import {DialogBuilder, NotificationBuilder} from '../../../common/dialog/dialog';
import {ProjectMeta, TeamMeta} from 'respresso';
import {teamModule} from '../../../../store/modules/team';

type ScreenState = 'none' | 'open' | 'previewed' | 'imported';

interface ImportPreviewSummary {
	categoryId: string;
	version: string;
	configChanged: boolean;
	create: number;
	update: number;
	remove: number;
}

interface PossibleImportCategory {
	selected: boolean;
	categoryId: string;
	count: number | string;
	versions: PossibleImportCategoryVersion[];
	selectedVersion?: string;
	selectedLanguage?: string;
	selectedColorType?: ColorType;
}

interface PossibleImportCategoryVersion {
	categoryId: string;
	version: string;
	config?: any;
}

@Component({
	template: template,
})
export default class ZeplinIntegration extends Vue {
	private teamId = '';
	private projectId = '';
	private connectionId = '';
	private newConnectionId = '';
	private integrationToken = '';
	private categories: PossibleImportCategory[] = [];
	private session?: WebSocket;
	private sessionId?: number;
	private live = false;
	private screenImageUrl?: string;
	private showLargePreview = false;

	private screenState: ScreenState = 'none';
	private screenName = '';
	private pending = false;
	private localization = 0;
	private colorPalette = 0;
	private colorDesign = 0;
	private image = 0;
	private appIcon = 0;
	private reconnectDuration = 3000;
	private wsUrl = '';

	private closedSessionsOnPurpose: number[] = [];

	private changes: ImportPreviewSummary[] = [];

	beforeRouteEnter(to: Route, from: Route, next: NextFunction): void {
		next((vm) => {
			const res = vm as ZeplinIntegration;
			res.loadDataAndConnect(to);
		});
	}

	beforeRouteLeave(to: Route, from: Route, next: NextFunction) {
		if (
			this.session &&
			this.session.readyState !== WebSocket.CLOSED &&
			this.session.readyState !== WebSocket.CLOSING
		) {
			if (this.sessionId !== undefined) {
				this.closedSessionsOnPurpose.push(this.sessionId);
			}
			this.session.close();
		}
		next();
	}

	private beforeRouteUpdate(to: Route, from: Route, next: NextFunction) {
		const res = this as ZeplinIntegration;
		if (res && res.resetConnection) {
			res.resetConnection();
		}
		next();
	}

	private resetConnection() {
		if (
			this.session &&
			this.session.readyState !== WebSocket.CLOSED &&
			this.session.readyState !== WebSocket.CLOSING
		) {
			if (this.sessionId !== undefined) {
				this.closedSessionsOnPurpose.push(this.sessionId);
			}
			this.session.close();
		}
		this.live = false;
		this.pending = false;
		this.screenState = 'none';
		this.screenImageUrl = undefined;
		this.openWsConnection();
	}

	get team(): TeamMeta | null {
		return teamModule.team;
	}

	get project(): ProjectMeta | null {
		const moduleGetterTeam = teamModule.team;
		if (!moduleGetterTeam || !moduleGetterTeam.projects) {
			return null;
		}
		const project = moduleGetterTeam.projects.filter((p: ProjectMeta) => {
			return p.id === this.projectId;
		});
		if (project.length) {
			return project[0];
		} else {
			return null;
		}
	}

	@LoadingScreen({ showImmediately: true })
	private async loadDataAndConnect(to: Route): Promise<void> {
		this.teamId = to.params.teamId;
		this.projectId = to.params.projectId;
		this.connectionId = to.params.connectionId;
		StorageService.setLastIntegrationTeam(this.teamId);
		StorageService.setLastIntegrationProject(this.projectId);
		// TODO: 3 type of requests could be bundled together for better performance...
		//  (create token, get token info, fetch localization configs)
		const [token, wsInfo] = await Promise.all([
			ErrorHandler.tryRequest(() => RespressoApi.getIntegrationToken(this.teamId!, this.projectId!)),
			ErrorHandler.tryRequest(() => RespressoApi.getWebsocketConnectionInfo()),
		]);
		if (wsInfo && wsInfo.integrationWebsocketUrl) {
			this.wsUrl = wsInfo.integrationWebsocketUrl;
		}
		if (token) {
			this.integrationToken = token;
			const tokenInfo = await ErrorHandler.tryRequest(() => RespressoApi.getIntegrationTokenInfo(token));
			if (tokenInfo) {
				this.categories = await this.prepareCategories(tokenInfo, token);
			} else {
				NotificationBuilder.error('#error.integration.zeplin.fetchInfo');
			}
			this.openWsConnection();
		} else {
			NotificationBuilder.error('#error.integration.zeplin.fetchInfo');
		}
	}

	private openWsConnection() {
		if (
			!this.session ||
			this.session.readyState === WebSocket.CLOSING ||
			this.session.readyState === WebSocket.CLOSED
		) {
			const session = new WebSocket(this.wsUrl);
			session.onopen = (ev) => {
				this.reconnectDuration = 3000;
				this.onWsOpen(ev);
			};
			session.onmessage = (ev) => {
				this.onWsMessage(ev);
			};
			session.onclose = (ev) => {
				this.onWsClose(ev);
			};
			this.session = session;
			this.sessionId = Math.random();
		}
	}

	private async prepareCategories(tokenInfo: IntegrationTokenInfo, token: string): Promise<PossibleImportCategory[]> {
		return await Promise.all(
			tokenInfo.categories.map(async (category) => {
				return await this.prepareVersions(category, token);
			}),
		);
	}

	private async prepareVersions(category: IntegrationCategoryInfo, token: string): Promise<PossibleImportCategory> {
		const editable = category.versions.filter((version) => version.editable);
		const selected = editable.length > 0 ? editable[editable.length - 1].version : undefined;
		const possibleCategory: PossibleImportCategory = {
			selected: true,
			count: 0,
			categoryId: category.categoryId,
			selectedVersion: selected,
			versions: await Promise.all(
				editable.map(async (v) => {
					let config = undefined;
					if (category.categoryId === 'localization') {
						const response = await ErrorHandler.tryRequest(() =>
							RespressoApi.getIntegrationCategoryConfigInfo(token, category.categoryId, v.version),
						);
						config = response?.config;
					}
					return {
						categoryId: category.categoryId,
						version: v.version,
						config: config,
					};
				}),
			),
		};
		if (category.categoryId === 'localization') {
			const selectedVersion = possibleCategory.versions.find((it) => it.version === selected);
			if (selectedVersion && selectedVersion.config) {
				const config = selectedVersion.config as LocalizationConfigStoredStructure;
				possibleCategory.selectedLanguage = config.defaultLanguage;
			}
		}
		if (category.categoryId === 'color') {
			possibleCategory.selectedColorType = 'PALETTE';
		}
		return possibleCategory;
	}

	private onWsOpen(e: Event): void {
		this.sendWsMessage({
			type: 'CONNECT_FRONTEND',
			connectionId: this.connectionId,
		});
	}

	private onWsClose(e: CloseEvent): void {
		const cId = this.sessionId;
		if (cId !== undefined && !this.closedSessionsOnPurpose.some((value) => value === cId)) {
			this.live = false;
			this.pending = false;
			this.screenState = 'none';
			this.screenImageUrl = undefined;
			NotificationBuilder.error(
				('#' +
					this.$t('integration.zeplin.error.connectionLost', { code: e.code, reason: e.reason })) as string,
			);
			this.scheduleReconnect(cId);
		}
	}

	private scheduleReconnect(cId: number) {
		setTimeout(() => {
			if (cId && this.sessionId === cId) {
				this.reconnectDuration = this.reconnectDuration * 1.5;
				this.openWsConnection();
			}
		}, this.reconnectDuration);
	}

	private onWsMessage(e: MessageEvent): void {
		const message: Message = JSON.parse(e.data);
		if (!message) return;
		switch (message.type) {
			case 'CONNECTION_ESTABLISHED_WITH_INTEGRATION':
				this.live = true;
				this.reconnectDuration = 3000;
				break;
			case 'CONNECTION_LOST_WITH_INTEGRATION':
				this.live = false;
				this.pending = false;
				this.screenState = 'none';
				this.screenImageUrl = undefined;
				break;
			case 'FORWARD_TO_FRONTEND':
				this.handleWsSubMessage(message.payload as SubMessage);
				break;
			case 'ERROR':
				this.live = false;
				this.pending = false;
				this.screenState = 'none';
				this.screenImageUrl = undefined;
				DialogBuilder.alert(
					this.$t('integration.zeplin.error.serverError') as string,
					JSON.stringify(message.payload),
					() => {
						this.resetConnection();
					},
				);
				break;
		}
	}

	private handleWsSubMessage(message: SubMessage) {
		switch (message.type) {
			case 'SCREEN_OPENED':
				this.screenState = 'open';
				const screenMessage = message as ScreenOpenedSubMessage;
				this.screenName = screenMessage.screen;
				this.localization = screenMessage.localization;
				this.image = screenMessage.image;
				this.colorPalette = screenMessage.colorPalette;
				this.colorDesign = screenMessage.colorDesign;
				this.appIcon = screenMessage.appIcon;
				this.screenImageUrl = screenMessage.screenImageUrl;
				this.categories.forEach((category) => {
					switch (category.categoryId) {
						case 'localization':
							category.count = this.localization;
							break;
						case 'color':
							category.count = `${this.colorPalette}/${this.colorDesign}`;
							category.selectedColorType = this.colorPalette ? 'PALETTE' : 'DESIGN';
							break;
						case 'image':
							category.count = this.image;
							break;
						case 'appIcon':
							category.count = this.appIcon;
							break;
					}
				});
				break;
			case 'PREVIEW_IMPORT_RESULT':
				const previewResponse = (message as PreviewImportResultSubMessage).response;
				this.pending = false;
				this.screenState = 'previewed';
				this.updateImportPreviewChanges(previewResponse);
				break;
			case 'EXECUTE_IMPORT_RESULT':
				const importResponse = (message as ExecuteImportResultSubMessage).response;
				this.pending = false;
				if (importResponse === 'OK') {
					this.screenState = 'imported';
				} else {
					// TODO proper error handling
					DialogBuilder.alert(
						this.$t('integration.zeplin.error.executeImport') as string,
						JSON.stringify(importResponse),
					);
				}
				break;
			case 'ERROR':
				DialogBuilder.alert(
					this.$t('integration.zeplin.error.extensionError') as string,
					JSON.stringify(message),
				);
				this.pending = false; // TODO identify if it was a related issue
				break;
		}
	}

	private updateImportPreviewChanges(previewResponse: IntegrationImportPreviewResponse) {
		const changes: ImportPreviewSummary[] = [];
		previewResponse.categoryImportPreviews.forEach((preview) => {
			const change: ImportPreviewSummary = {
				categoryId: preview.categoryId,
				version: preview.version,
				configChanged: false,
				create: 0,
				update: 0,
				remove: 0,
			};
			if (preview.importPreview && preview.importPreview.changeResult) {
				const result = preview.importPreview.changeResult;
				change.configChanged = result.configChanged;
				const changes = result.changes;
				for (const id in changes) {
					if (changes.hasOwnProperty(id)) {
						const resourceChange: AppliedResourceChangeStructure = changes[id];
						switch (resourceChange.type) {
							case AppliedResourceChangeType.CREATE:
								change.create++;
								break;
							case AppliedResourceChangeType.DELETE:
								change.remove++;
								break;
							case AppliedResourceChangeType.UPDATE:
							case AppliedResourceChangeType.UPDATE_ONLY_DATA:
							case AppliedResourceChangeType.UPDATE_ONLY_FILES:
								change.update++;
								break;
						}
					}
				}
			}
			changes.push(change);
		});
		this.changes = changes;
	}

	previewImport() {
		if (this.screenState !== 'open') {
			NotificationBuilder.error('Please (re)open a screen in Zeplin');
			return;
		}
		if (this.pending) {
			NotificationBuilder.error('Please await previous action to finish.');
			return;
		}
		const categories = this.categories.filter((it) => it.selected && this.isApplicableCategory(it.categoryId));
		if (categories.length === 0) {
			NotificationBuilder.warning(this.$t('integration.zeplin.validate.noSelectedCategory') as string);
			return;
		}
		const message: PreviewImportSubMessage = {
			type: 'PREVIEW_IMPORT',
			integrationToken: this.integrationToken,
			serverUrl: window.location.origin,
			categories: categories.map((category) => {
				return {
					categoryId: category.categoryId,
					version: category.selectedVersion,
					language: category.selectedLanguage,
					colorType: category.selectedColorType,
				} as Category;
			}),
		};
		this.pending = true;
		this.sendMessageToIntegration(message);
	}

	stepBackToPreview() {
		this.screenState = 'open';
	}

	executeImport() {
		if (this.screenState !== 'previewed') {
			NotificationBuilder.error('Please preview the screen before import');
			return;
		}
		if (this.pending) {
			NotificationBuilder.error('Please await previous action to finish.');
			return;
		}
		this.pending = true;
		this.sendMessageToIntegration({ type: 'EXECUTE_IMPORT' });
	}

	isApplicableCategory(category: string) {
		const applicable = [];
		if (this.localization > 0) {
			applicable.push('localization');
		}
		if (this.colorPalette > 0 || this.colorDesign > 0) {
			applicable.push('color');
		}
		if (this.image > 0) {
			applicable.push('image');
		}
		if (this.appIcon > 0) {
			applicable.push('appIcon');
		}
		return applicable.some((applicable) => applicable === category);
	}

	private sendWsMessage(message: Message) {
		if (this.session && this.session.readyState === WebSocket.OPEN) {
			try {
				if (!message.connectionId) {
					message.connectionId = this.connectionId;
				}
				this.session.send(JSON.stringify(message));
			} catch (e) {
				console.log(e);
			}
		}
	}

	private sendMessageToIntegration(message: SubMessage) {
		this.sendWsMessage({
			type: 'FORWARD_TO_INTEGRATION',
			connectionId: this.connectionId,
			payload: message,
		});
	}

	createCategoryVersionSelectOptions(category: PossibleImportCategory) {
		return category.versions.map((version) => {
			const option: SlimSelectOption = {
				disabled: !category.selected,
				text: version.version,
				value: version.version,
				selected: category.selectedVersion === version.version,
			};
			return option;
		});
	}

	categoryVersionChanged(category: PossibleImportCategory, event: any) {
		const version = category.versions.find((it) => it.version === event.value);
		if (version) {
			category.selectedVersion = version.version;
			this.$forceUpdate();
		}
	}

	createCategoryVersionLanguageSelectOptions(category: PossibleImportCategory) {
		if (category.categoryId === 'localization') {
			const version = category.versions.find((it) => it.version === category.selectedVersion);
			if (version && version.config) {
				const localizationConfig = version.config as LocalizationConfigStoredStructure;
				if (localizationConfig.languages) {
					return localizationConfig.languages.map((language) => {
						const option: SlimSelectOption = {
							disabled: !category.selected,
							text: language,
							value: language,
							selected: category.selectedLanguage === language,
						};
						return option;
					});
				}
			}
		}
		return [];
	}

	createColorTypeSelectOptions(category: PossibleImportCategory) {
		if (category.categoryId === 'color') {
			const options: SlimSelectOption[] = [
				{
					text: this.$t('integration.zeplin.colorType.palette', { count: this.colorPalette }) as string,
					value: 'PALETTE' as ColorType,
					selected: category.selectedColorType === 'PALETTE',
					disabled: !this.colorPalette,
				},
				{
					text: this.$t('integration.zeplin.colorType.design', { count: this.colorDesign }) as string,
					value: 'DESIGN' as ColorType,
					selected: category.selectedColorType === 'DESIGN',
					disabled: !this.colorDesign,
				},
			];
			return options;
		}
		return [];
	}

	categoryVersionLanguageChanged(category: PossibleImportCategory, event: any) {
		const language = event.value;
		if (language) {
			category.selectedLanguage = language;
			this.$forceUpdate();
		}
	}

	categoryColorChanged(category: PossibleImportCategory, event: any) {
		const colorType = event.value;
		if (colorType) {
			category.selectedColorType = colorType;
			this.$forceUpdate();
		}
	}

	private openIntegration() {
		if (this.newConnectionId) {
			this.$router.replace({
				name: 'zeplinIntegration',
				params: {
					teamId: this.teamId!,
					projectId: this.projectId!,
					connectionId: this.newConnectionId!.trim(),
				},
				replace: true,
			});
		} else {
			alert('Missing selection');
		}
	}
}

import Component from 'vue-class-component';
import template from './structure-enum-input.html';
import './structure-enum-input.scss';
import Vue from 'vue';
import {Prop} from 'vue-property-decorator';
import {StructureTypeDefinition} from 'respresso';
import {SlimSelectInfo} from '../vue-slim-select/vue-slim-select';

@Component({
	template: template,
	props: {
		name: String,
		definition: Object,
		value: String,
	},
})
export default class StructureEnumInput extends Vue {
	@Prop()
	name!: string;
	@Prop()
	definition!: StructureTypeDefinition;
	@Prop()
	value?: string;

	private get selectorOptions(): SlimSelectInfo[] {
		if (this.definition && this.definition.type === 'Enum' && this.definition.occurringValues) {
			const options: SlimSelectInfo[] = [];
			const defaultBehaviourEnum = this.definition.defaultBehaviour
				? this.definition.defaultBehaviour.toLowerCase()
				: '';
			const nullable =
				this.definition.optional && this.definition.occurringValues.indexOf(defaultBehaviourEnum) < 0;
			if (nullable) {
				options.push({
					text: this.$t('input.structure.enum.option.default', this.definition) as string,
					selected: this.value === undefined,
					value: 'default',
				});
			}
			this.definition.occurringValues.forEach((enumValue) => {
				options.push({
					text: enumValue + (defaultBehaviourEnum === enumValue ? ' (default)' : ''),
					selected: this.value === enumValue || (!nullable && defaultBehaviourEnum === enumValue && !this.value),
					value: enumValue,
				});
			});
			return options;
		} else {
			return [];
		}
	}

	public async selectionChanged(selected: SlimSelectInfo): Promise<void> {
		let newValue: string | undefined = this.value;
		if (selected.value === 'default') {
			newValue = undefined;
		} else {
			newValue = selected.value;
		}
		if (this.value !== newValue) {
			this.$emit('value-changed', newValue);
		}
	}
}

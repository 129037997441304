/* eslint-disable @typescript-eslint/no-non-null-assertion */
import 'whatwg-fetch';
import {ItemState, Resource, ResourceItem} from './resource';
import {DialogBuilder, NotificationBuilder} from '../../common/dialog/dialog';
import {EditImageDialog, ImageUploadButton, ImageUploadDialogConfig, ImageUploadResult,} from '../../common/image/edit-image-dialog';
import {translate} from '../../../main';
import Component from 'vue-class-component';
import template from './image-resource.html';
import ResourceList from '../../common/resource-list/resource-list';
import UserService from '../../../services/user-service';
import RespressoApi from '../../../api/respresso-api';
import ErrorHandler from '../../../services/error-handler';
import './image-resource.scss';
import {IFileDescriptor} from '@ponte/file-upload-js';
import MultiUploadBlock, {UploadState} from '../../common/upload/multi-upload-block';
import LoadingScreen from '../../../decorators/loading-screen';
import ResourceImportDialog, {ResourceImportDialogConfig,} from '../../common/resource-import-dialog/resource-import-dialog';
import {convertImageSizesToPickedSizes, convertPickedSizesToImageSizes, PickedImageSize} from "../tools/image-size-picker/image-size-picker.vue";
import AndroidImageSizePicker from "../tools/image-size-picker/android-image-size-picker.vue";
import IosImageSizePicker from "../tools/image-size-picker/ios-image-size-picker.vue";
import WebImageSizePicker from "../tools/image-size-picker/web-image-size-picker.vue";
import ReactNativeImageSizePicker from "../tools/image-size-picker/react-native-image-size-picker.vue";
import StructureEditorDialog, {StructureEditorDialogConfig} from "../../common/structure-editor-dialog/structure-editor-dialog";

export interface ImageDataToSave {
	id: string;
	fileId: string;
	originalFileId: string;
	name?: string;
	state?: string;
	config?: ImageDataToPlatform;
	tags?: string[];
}

export interface LoadImageResourceResponse {
	versionNumber: string;
	versionIsEditable: boolean;
	data: ImageResourceItem[];
}

/* Original config structure */
export interface ImageResourceItem extends ResourceItem {
	config: ImageDataToPlatform;
	fileId: string;
	originalFileId?: string;
	name?: string;
	size?: number;
	lastModified?: number;
	lastModifier?: string;
}

export interface ImageDataToPlatform {
	android: {
		sizes: ImageSize[];
	};
	ios: {
		sizes: ImageSize[];
	};
	web: {
		sizes: ImageSize[];
	};
	reactNative: {
		sizes: ImageSize[];
	};
}

export interface ImageSize {
	type?: 'original' | 'height' | 'width';
	width?: number | null;
	height?: number | null;
	format?: string;
}

@Component({
	components: {
		'resource-list': ResourceList,
		'android-image-size-picker': AndroidImageSizePicker,
		'ios-image-size-picker': IosImageSizePicker,
		'web-image-size-picker': WebImageSizePicker,
		'react-native-image-size-picker': ReactNativeImageSizePicker,
	},
	template: template,
})
export default class ImageResource extends Resource {
	protected items: ImageResourceItem[] = [];
	protected newItems: ImageResourceItem[] = [];
	protected uploader?: MultiUploadBlock;
	private uploadState: UploadState = 'empty';

	private importerTypes: string[] = ['css'];
	private uploaderTypes: string[] = ['svg', 'drawable', 'pdf', 'png', 'jpg', 'webp'];
	private acceptUploadFiles: string[] = ['.svg', '.xml', '.pdf', '.png', '.jpg', '.webp'];
	private acceptFiles: string[] = [...this.acceptUploadFiles, '.css'];

	public platform!: string;

	private getUploader() {
		if (!this.uploader) {
			this.uploader = this.$refs.uploadBlock as MultiUploadBlock;
			this.uploadState = this.uploader.getState();
		}
		return this.uploader;
	}

	protected onLoaded() {
		const uploadBlock = this.getUploader();
		if (uploadBlock) {
			uploadBlock.onUploadSucceeded(async (upload) => {
				uploadBlock.onIdle().then(() => this.resumeTour('upload'));
				const result = await upload.uploader.uploadResult;
				await this.handleUploadedFile(result as ImageUploadResult);
			});
		}
	}

	protected async loadData<ImageResource>(): Promise<void> {
		await this.updateData();
		this.$nextTick(() => {
			this.startTour(this.$refs.resourceList as ResourceList);
		});
	}

	protected async updateData(): Promise<void> {
		const resp = await ErrorHandler.tryRequest(
			() => RespressoApi.getImageVersion(this.teamId, this.projectId, this.version),
			{
				loadingScreen: true,
			},
		);
		if (resp) {
			this.setItemFromServer(resp.data);
			this.editable = resp.versionIsEditable;
		}
	}

	@LoadingScreen({ fullScreen: true })
	protected async doSave(): Promise<boolean> {
		const uploader = this.getUploader();
		if (uploader && uploader.getState() === 'active') {
			await uploader.onIdle();
		}
		const itemsToSave = this.items.slice(0);
		itemsToSave.push(...this.newItems);

		const toSave: ImageDataToSave[] = itemsToSave.map((value: ImageResourceItem): ImageDataToSave => {
			return {
				name: value.name,
				id: value.id ? value.id : '',
				fileId: value.fileId,
				originalFileId: value.originalFileId ? value.originalFileId : '',
				tags: value.tags,
				state: value.state.toUpperCase(),
				config: value.config,
			};
		});

		const saveResp = await ErrorHandler.tryRequest(
			() => RespressoApi.saveImageVersion(this.teamId, this.projectId, this.version, toSave),
			{
				loadingScreen: true,
				loadingMessage: '#loading.processingImages',
			},
		);

		if (saveResp) {
			this.setItemFromServer(saveResp);
			NotificationBuilder.success('#messages.saved');
			this.$nextTick(() => this.resumeTour('save'));
			return true;
		} else {
			return false;
		}
	}

	private setItemFromServer(data: ImageResourceItem[]): void {
		this.lastListId = 0;
		this.items = data.map((item) => {
			item.originalFileId = item.fileId;
			item.state = ItemState.UNCHANGED;
			item.listId = this.lastListId++;

			if (!item.config) {
				item.config = {
					android: {
						sizes: [],
					},
					ios: {
						sizes: [],
					},
					web: {
						sizes: [],
					},
					reactNative: {
						sizes: [],
					},
				} as ImageDataToPlatform;
			}

			return item;
		});

		this.items.sort((a, b) => {
			if (!a.name && !b.name) {
				return 0;
			}
			if (!a.name) {
				return -1;
			}
			if (!b.name) {
				return 1;
			}

			return a.name.localeCompare(b.name);
		});
	}

	protected getResourceId(): string {
		return 'image';
	}

	private uploadStateChanged(state: UploadState) {
		this.uploadState = state;
	}

	private uploadFiles(file?: IFileDescriptor): void {
		const uploader = this.getUploader();
		if (uploader) {
			if (file && file.file) {
				uploader.submitUpload(file.file);
			} else {
				uploader.triggerFileSelect();
			}
		}
	}

	private async handleUploadedFile(uploadedFile: ImageUploadResult) {
		this.items.unshift({
			fileId: uploadedFile.fileId,
			name: this.removeExtension(uploadedFile.name),
			size: uploadedFile.total,
			state: ItemState.NEW,
			config: {
				android: {
					sizes: [{ type: "original", format: "auto" }],
				},
				ios: {
					sizes: [{ type: "original", format: "auto" }],
				},
				web: {
					sizes: [{ type: "original", format: "auto" }],
				},
				reactNative: {
					sizes: [],
				},
			},
			lastModified: new Date().getTime(),
			lastModifier: UserService.getUser()!.id,
		});
	}

	private uploadMethod(uploadedFiles: ImageUploadResult[]): void {
		uploadedFiles.forEach(this.handleUploadedFile);
	}

	protected importFiles(file: IFileDescriptor): void {
		const dialogConfig: ResourceImportDialogConfig = {
			title: translate('resource.image.importTitle'),
			resource: this.getResourceId(),
			resourceImporterTypes: this.importerTypes,
			uploadHandler: 'ImageImportUploadHandler',
			accept: this.acceptFiles,
			fileDescriptor: file,
			executeImport: async (uploadResult, selectedImporter, config) => {
				if (uploadResult && selectedImporter) {
					const response = await ErrorHandler.tryRequest(() =>
						RespressoApi.importImage(
							this.teamId,
							this.projectId,
							this.version,
							uploadResult.fileId,
							selectedImporter,
							config,
						),
					);
					if (response) {
						await this.updateData();
						NotificationBuilder.success('#messages.imported');
						this.resumeTour('upload');
						return true;
					}
				}
				return false;
			},
		};

		const controller = DialogBuilder.createVueDialog(ResourceImportDialog, {
			propsData: {
				dialogConfig,
			},
		});
	}

	private uploadSelectedFile(file: IFileDescriptor) {
		this.uploadFiles(file);
	}

	private editItem(item: ImageResourceItem): void {
		const button: ImageUploadButton = {
			text: translate('buttons.save'),
			fn: (uploadResult) => {
				if (uploadResult) {
					item.fileId = uploadResult.fileId;
					item.name = this.removeExtension(item.name || uploadResult.name);
					item.size = uploadResult.total;
					item.state = ItemState.UPDATED;
					item.lastModified = new Date().getTime();
					item.lastModifier = UserService.getUser()!.id;
				} else {
					this.deleteItem(item);
				}
			},
		};
		const previewImageUrlHandler = (uploadResult: ImageUploadResult): string | null => {
			if (!uploadResult) {
				return null;
			}
			return this.getPreviewUrl(uploadResult.fileId);
		};
		const uploadResult: ImageUploadResult = {
			name: item.name || '',
			fileId: item.fileId || '',
			total: item.size || 0,
			mimeType: '',
		};

		const dialogConfig: ImageUploadDialogConfig = {
			button,
			fileFilter: this.acceptUploadFiles,
			message: translate('resource.image.upload.formats', {
				formats: this.uploaderTypes
					.map((it) => '- ' + this.$t('resource.uploader.type.' + this.resourceId + '.' + it))
					.join('\n'),
			}),
			title: translate('resource.image.editImage.title'),
			uploadHandler: 'ImageUploadHandler',
			previewImageUrlHandler,
			uploadResult: uploadResult,
		};

		DialogBuilder.createVueDialog(EditImageDialog, {
			propsData: {
				dialogConfig,
			},
		});
	}

	protected removeExtension(fileName: string): string {
		if (!fileName) {
			return fileName;
		}

		const removableExtensions = ['.svg', '.png', '.jpg', '.jpeg', '.xml', '.pdf'];

		if (removableExtensions.some((ext) => fileName.toLowerCase().endsWith(ext))) {
			const number = fileName.lastIndexOf('.');
			if (number !== -1) {
				fileName = fileName.substr(0, number);
			}
		}

		return fileName;
	}

	protected getPreviewUrl(fileId: string): string {
		return `/api/ui/image/preview/${this.teamId}/${this.projectId}/${this.version}/${fileId}`;
	}

	/* Size definition related functions */
	protected getImageUrl(fileId: string): string {
		return `/api/ui/image/${this.teamId}/${this.projectId}/${this.version}/${fileId}`;
	}

	public getSizes(item: ImageResourceItem, platform: 'android' | 'ios' | 'web' | 'reactNative'): ImageSize[] {
		return item.config[platform]?.sizes ?? [{type: "original"}];
	}

	public getPickedSizes(item: ImageResourceItem, platform: 'android' | 'ios' | 'web' | 'reactNative' ): PickedImageSize[] {
		return convertImageSizesToPickedSizes(this.getSizes(item, platform));
	}

	public setPickedSizes(item: ImageResourceItem, platform: 'android' | 'ios' | 'web' | 'reactNative', pickedSizes: PickedImageSize[]) {
		let platformConfig = item.config[platform];
		if(!platformConfig) {
			platformConfig = {sizes: []};
			item.config[platform] = platformConfig
		}
		platformConfig.sizes = convertPickedSizesToImageSizes(pickedSizes);
		this.getPickedSizes(item, platform);
		if (item.state !== ItemState.NEW) {
			item.state = ItemState.UPDATED;
		}
	}

	private fileSelected(file: IFileDescriptor) {
		const name = (file.file.name || '').toLowerCase();
		// Handle css files separately
		if (name.endsWith('.css')) {
			this.importFiles(file);
			return true;
		}
		return false;
	}

	private async editConfig() {
		if (this.isModified()) {
			NotificationBuilder.error('#resource.image.config.editConfigDialog.savePendingChanges');
			return;
		}
		const response = await ErrorHandler.tryRequest(
			() => RespressoApi.getImageConfig(this.teamId, this.projectId, this.version),
			{
				loadingScreen: true,
			},
		);
		if (response) {
			const dialogConfig: StructureEditorDialogConfig = {
				title: 'resource.image.config.editConfigDialog.title',
				data: response.data,
				definitions: response.definition,
				editablePaths: response.editablePaths,
			};
			const controller = DialogBuilder.createVueDialog(StructureEditorDialog, {
				propsData: {
					dialogConfig,
				},
			});
			const result = await controller.closeResult;
			if (result && result.useResult) {
				const response = await ErrorHandler.tryRequest(
					() => RespressoApi.setImageConfig(this.teamId, this.projectId, this.version, result.data),
					{
						loadingScreen: true,
					},
				);
				// this.$router.go(0); // Fully reload page..
			}
		}
	}


	protected tourTryUpload(): (() => void) | null {
		return () => {
			this.uploadFiles();
		};
	}

	protected tourTrySave(): ((resourceList: ResourceList) => void) | null {
		return (resourceList: ResourceList) => {
			if (!this.canExecuteSave()) {
				if (this.canUploadResources) {
					this.submitDefaultUpload().awaitFinish.then(this.saveRequestedByUser);
				}
			} else {
				this.saveRequestedByUser();
			}
		};
	}

	private submitDefaultUpload() {
		return this.getUploader().submitUpload({
			blob: new Blob(
				[
					'<?xml version="1.0" encoding="UTF-8"?><svg fill="none" viewBox="0 0 398 156" xmlns="http://www.w3.org/2000/svg">' +
						'<rect width="398" height="155.37" rx="8" fill="#fff"/>' +
						'<path d="M118.428 88.246c-1.017-1.013-2.325-1.447-3.777-1.447H26.034C24.872 86.8 24 87.812 24 88.97c0 1.303.145 2.46.29 3.618h23.97a1.3 1.3 0 011.308 1.302c0 .29-.145.724-.436.868-.29.29-.58.435-.871.435H24.87c.146 1.013.437 2.025.727 3.038h23.97a1.3 1.3 0 011.308 1.303c0 .289-.146.723-.436.868-.29.289-.581.434-.872.434H26.47c.436.868.871 1.881 1.307 2.894h23.97a1.3 1.3 0 011.308 1.302c0 .29-.146.724-.436.868-.29.29-.581.434-.872.434H29.085c.58 1.013 1.307 2.026 1.888 3.039h23.68a1.3 1.3 0 011.307 1.302c0 ' +
						'.29-.145.724-.436.869-.29.289-.58.434-.871.434H32.862c.871 1.013 1.743 1.881 2.615 2.894.145 0 .145.144.29.144h23.244a1.3 1.3 0 011.307 1.303c0 .289-.145.723-.435.868-.291.289-.582.434-.872.434H38.673a39.717 39.717 0 004.358 3.039h22.227c.726 0 1.307.578 1.307 1.302 0 .289-.145.723-.436.868-.29.29-.58.434-.871.434H48.115a40.682 40.682 0 0015.835 3.184c22.808 0 43.001-11.866 54.478-29.664.145-.144.29-.434.436-.579.726-1.302 1.162-2.604 1.162-4.196-.146-1.591-.727-2.894-1.598-3.762zm-6.392 7.38c-4.213 5.932-9.443 ' +
						'10.997-15.4 15.048-.145 0-.145.145-.29.145-.29 0-.58-.434-.29-.723 3.34-4.631 5.811-10.13 6.973-15.917.145-1.013 1.017-1.592 2.034-1.592h5.375c1.598-.145 2.615 1.736 1.598 3.039z" fill="url(#a)"/><path d="M98.09 82.748a3.898 3.898 0 01-3.923-3.907c0-.434.145-.868.29-1.302L82.837 65.963v9.405c1.307.724 2.179 2.026 2.179 3.473a3.898 3.898 0 01-3.923 3.907 3.898 3.898 0 01-3.922-3.907c0-1.592.872-2.894 2.18-3.473v-9.984c-1.308-.579-2.18-2.026-2.18-3.473 0-.434.145-.868.29-1.302L65.84 49.033v26.335c1.307.724 ' +
						'2.179 2.026 2.179 3.473a3.898 3.898 0 01-3.923 3.907 3.898 3.898 0 01-3.922-3.907c0-.29 0-.723.145-1.013L47.97 67.41c-.436.29-1.017.29-1.598.29-.581 0-1.162-.146-1.598-.29L32.426 77.828c.145.29.145.579.145 1.013a3.898 3.898 0 01-3.922 3.907 3.898 3.898 0 01-3.923-3.907 3.898 3.898 0 013.923-3.907c.58 0 1.162.145 1.598.29l12.348-10.419c-.145-.29-.145-.723-.145-1.013 0-1.591.871-2.894 2.179-3.473V48.31c-1.308-.578-2.18-2.025-2.18-3.472a3.898 3.898 0 013.923-3.907c.436 0 .872.145 1.307.29l12.64-12.155c-.146-.435-' +
						'.146-.869-.146-1.158A3.898 3.898 0 0164.095 24a3.898 3.898 0 013.923 3.907c0 1.592-.872 2.894-2.18 3.473v9.984c1.308.723 2.18 2.026 2.18 3.473 0 .434-.145.868-.29 1.302l12.057 12.01c.436-.145.872-.29 1.307-.29a3.898 3.898 0 013.923 3.907c0 .435-.145.869-.29 1.303l12.057 12.01c.436-.145.872-.29 1.307-.29a3.899 3.899 0 013.923 3.907c.145 2.315-1.744 4.052-3.923 4.052zm-35.883-7.67V48.166c-1.308-.579-2.18-2.026-2.18-3.473 0-1.592.872-2.894 2.18-3.473v-9.405L49.859 43.679c.145.434.145.868.145 1.158 0 1.591-.872 2.894' +
						'-2.18 3.472v12.01c1.308.58 2.18 2.026 2.18 3.473 0 .29 0 .724-.145 1.013L62.207 75.08zM152.251 72l-3.727-10.731h-.07c.1 1.593.15 3.087.15 4.482V72h-1.908V59.15h2.962l3.569 10.222h.052l3.674-10.222h2.971V72h-2.022v-6.355c0-.638.015-1.47.044-2.496.035-1.025.065-1.646.088-1.863h-.07L154.105 72h-1.854zM171.279 72l-1.283-3.568h-4.913L163.826 72h-2.215l4.808-12.902h2.285L173.512 72h-2.233zm-1.837-5.379l-1.204-3.498a25.965 25.965 0 01-.685-2.215c-.158.72-.39 1.509-.695 2.364l-1.16 3.35h3.744zM185.658 65.452c0 2.121-.589 ' +
						'3.744-1.766 4.87-1.178 1.118-2.874 1.678-5.089 1.678h-3.595V59.15h3.973c2.045 0 3.635.551 4.772 1.653 1.137 1.101 1.705 2.651 1.705 4.65zm-2.215.07c0-3.076-1.438-4.614-4.315-4.614h-1.819v9.325h1.494c3.093 0 4.64-1.57 4.64-4.71zM195.695 72h-7.268V59.15h7.268v1.776h-5.168v3.498h4.843v1.758h-4.843v4.034h5.168V72zM215.014 72h-2.285l-2.18-7.664a30.054 30.054 0 01-.352-1.441 16.526 16.526 0 01-.255-1.319c-.058.375-.155.861-.29 1.46a19.05 19.05 0 01-.325 1.335L207.2 72h-2.285l-1.661-6.434-1.688-6.416h2.145l1.837 7.489a32.37 ' +
						'32.37 0 01.615 3.181c.065-.498.161-1.054.29-1.67.129-.615.246-1.113.352-1.494l2.091-7.506h2.083l2.145 7.541c.205.698.422 1.74.65 3.13.088-.839.299-1.905.633-3.2l1.828-7.47h2.127L215.014 72zM220.19 72V59.15h2.101V72h-2.101zM230.131 72h-2.101V60.952h-3.779V59.15h9.659v1.802h-3.779V72zM246.021 72h-2.109v-5.792h-5.924V72h-2.1V59.15h2.1v5.256h5.924V59.15h2.109V72zM373.092 103.874c-.581-1.736-1.453-3.039-2.615-4.341-1.162-1.158-2.47-2.026-4.068-2.75-1.598-.578-3.341-1.012-5.375-1.012-2.033 0-3.777.29-5.375 1.013-1.598.578-3.05 ' +
						'1.591-4.213 2.749-1.162 1.158-2.033 2.605-2.615 4.341-.581 1.736-.871 3.617-.871 5.643s.29 3.907.871 5.644c.582 1.736 1.598 3.183 2.615 4.341 1.163 1.157 2.47 2.025 4.213 2.749 1.598.579 3.487 1.013 5.375 1.013 2.034 0 3.777-.29 5.375-1.013 1.598-.579 3.051-1.592 4.068-2.749 1.162-1.158 2.034-2.605 2.615-4.341.581-1.737.872-3.618.872-5.644.145-2.026-.146-3.907-.872-5.643zm-7.264 12.155c-1.016 1.447-2.76 2.17-4.939 2.17-2.179 0-3.922-.723-4.939-2.17s-1.598-3.618-1.598-6.512c0-2.894.581-5.064 1.598-6.511 1.017-1.447 2.76-2.315 ' +
						'4.939-2.315 2.179 0 3.777.723 4.939 2.17 1.017 1.447 1.598 3.618 1.598 6.512 0 3.038-.435 5.209-1.598 6.656zm-21.936-4.341c-.436-.724-.872-1.447-1.453-2.026-.581-.579-1.307-1.013-2.033-1.302-.727-.29-1.598-.724-2.325-.869-.726-.289-1.598-.578-2.324-.723-.727-.289-1.453-.434-2.034-.723-.581-.29-1.162-.579-1.453-1.013-.436-.434-.581-.869-.581-1.447 0-.869.436-1.592 1.017-2.026.726-.579 1.743-.724 2.906-.724.871 0 1.452.145 2.179.29.581.144 1.162.434 1.598.579.435.289.871.434 1.162.578.29.145.726.29 1.017.29.29 0 .581 0 .726-.145.' +
						'145-.145.436-.289.581-.579l1.453-2.315c-1.017-1.013-2.324-1.736-3.777-2.315-1.453-.579-3.196-.868-4.939-.868-1.598 0-3.051.29-4.359.723-1.307.434-2.324 1.013-3.196 1.737-.871.723-1.452 1.591-1.888 2.604-.436 1.013-.581 2.026-.581 3.039 0 1.157.145 2.17.581 3.038.436.869.871 1.592 1.452 2.026.582.579 1.308 1.013 2.034 1.447.727.434 1.598.724 2.47 1.013.872.29 1.598.579 2.324.724.727.289 1.453.434 2.034.723.581.29 1.162.579 1.453 1.013.436.434.581.868.581 1.592 0 .434-.145.868-.291 1.157-.145.434-.435.724-.726 1.013-.29.29-.726.' +
						'579-1.307.724-.581.144-1.163.289-1.889.289a9.168 9.168 0 01-2.324-.289 5.377 5.377 0 01-1.598-.724c-.436-.289-.872-.434-1.162-.723-.291-.29-.727-.29-1.163-.29-.436 0-.726.145-1.017.29-.29.144-.435.434-.726.723l-1.453 2.46 1.744 1.302c.726.435 1.452.724 2.179 1.013.871.29 1.598.579 2.469.724.872.144 1.744.289 2.615.289 1.744 0 3.196-.289 4.504-.723 1.307-.434 2.469-1.013 3.341-1.881.872-.724 1.598-1.737 2.034-2.75.436-1.013.726-2.315.726-3.472 0-1.737-.145-2.75-.581-3.473zm-22.663 0c-.435-.724-.871-1.447-1.452-2.026-.581-.579-1.308' +
						'-1.013-2.034-1.302-.726-.29-1.598-.724-2.324-.869-.727-.289-1.598-.578-2.325-.723-.726-.289-1.453-.434-2.034-.723-.581-.29-1.162-.579-1.452-1.013-.436-.434-.582-.869-.582-1.447 0-.869.291-1.592 1.017-2.026.727-.579 1.744-.724 2.906-.724.871 0 1.453.145 2.034.29.581.144 1.162.434 1.598.579.436.289.871.434 1.162.578.29.145.726.29 1.017.29.29 0 .581 0 .726-.145.145-.145.436-.289.581-.579l1.453-2.315c-1.017-1.013-2.179-1.736-3.777-2.315-1.453-.579-3.196-.868-4.939-.868-1.598 0-3.051.29-4.359.723-1.307.434-2.324 1.013-3.196 1.737-.871.' +
						'723-1.452 1.591-1.888 2.604-.436 1.013-.581 2.026-.581 3.039 0 1.157.145 2.17.581 3.038.436.869.872 1.592 1.453 2.026.581.579 1.307 1.013 2.033 1.447.727.434 1.598.724 2.325 1.013.871.29 1.598.579 2.469.724.727.289 1.453.434 2.034.723.581.29 1.162.579 1.453 1.013.436.434.581.868.581 1.592 0 .434-.145.868-.29 1.157-.146.434-.436.724-.727 1.013-.29.29-.726.579-1.307.724-.581.144-1.162.289-1.889.289a9.168 9.168 0 01-2.324-.289 5.377 5.377 0 01-1.598-.724c-.436-.289-.872-.434-1.162-.723-.291-.29-.727-.29-1.163-.29-.435 0-.726.145-1.017.29' +
						'-.29.144-.435.434-.726.723l-1.453 2.46 1.744 1.302c.726.435 1.452.724 2.179 1.013.726.29 1.598.579 2.469.724.872.144 1.744.289 2.615.289 1.744 0 3.196-.289 4.504-.723 1.307-.434 2.469-1.013 3.341-1.881.872-.724 1.598-1.737 2.034-2.75.436-1.013.726-2.315.726-3.472 0-1.737-.145-2.75-.436-3.473zm-25.568 4.196c-.435 0-.871.145-1.162.434-.436.29-.871.434-1.453.724-.581.289-1.162.578-2.033.868-.727.289-1.598.289-2.615.289-1.017 0-2.034-.144-2.906-.434-.871-.289-1.598-.723-2.179-1.447a5.853 5.853 0 01-1.453-2.46c-.435-1.013-.581-2.17-.726-3' +
						'.473h16.416c.581 0 1.017-.144 1.162-.434.145-.289.291-1.013.291-2.025 0-1.881-.291-3.618-.872-5.065-.581-1.447-1.307-2.749-2.324-3.762s-2.179-1.737-3.632-2.315c-1.453-.579-2.906-.724-4.649-.724-2.034 0-3.777.29-5.375 1.013-1.598.724-2.906 1.592-4.068 2.75-1.162 1.157-1.888 2.604-2.469 4.196a14.85 14.85 0 00-.872 5.064c0 2.315.291 4.341 1.017 6.078.726 1.736 1.598 3.183 2.76 4.485 1.162 1.158 2.615 2.026 4.213 2.75 1.598.578 3.341.868 5.085.868.871 0 1.888 0 2.905-.145s2.034-.434 2.906-.723c1.016-.29 1.888-.724 2.76-1.303.871-.579 1.598-' +
						'1.302 2.324-2.026l-1.888-2.315c-.146-.579-.582-.868-1.163-.868zm-12.348-13.746c1.017-1.013 2.47-1.592 4.358-1.592 1.017 0 1.744.145 2.47.434.726.289 1.307.724 1.743 1.302.436.579.872 1.158 1.017 1.881.291.724.291 1.447.291 2.316h-12.058c.436-1.881 1.017-3.328 2.179-4.341zm-12.639-6.222c-1.598 0-2.905.434-4.067 1.302-1.163.868-2.179 2.17-2.906 3.762l-.436-3.039c-.145-.579-.29-1.013-.581-1.302-.29-.29-.726-.29-1.307-.29H257.6v26.481h6.392v-16.207c.29-.723.581-1.302.871-1.881.291-.579.727-1.013 1.163-1.447.435-.434.871-.723 1.452-.868.581-.' +
						'145 1.162-.289 1.889-.289.436 0 1.017 0 1.453.144.435.145.871.145 1.162.145.29 0 .436 0 .726-.145.145-.144.291-.289.436-.723l.436-4.775c-.727-.579-1.743-.868-2.906-.868zm-18.74 7.669c-.436-1.737-1.162-3.039-2.034-4.197-.871-1.157-1.888-2.026-3.051-2.604-1.162-.579-2.469-.868-3.922-.868-1.888 0-3.486.434-4.794 1.157-1.453.724-2.615 1.737-3.632 2.894l-.581-2.46c-.29-.723-.726-1.157-1.598-1.157H228.4v35.017h6.392v-10.853c.871.869 1.743 1.447 2.76 1.882 1.017.434 2.324.723 3.777.723 1.743 0 3.341-.289 4.794-1.013 1.453-.723 2.615-1.592 3.632-2.' +
						'894s1.743-2.749 2.324-4.341c.581-1.736.727-3.473.727-5.498-.146-2.171-.436-4.052-.872-5.788zm-6.247 9.839c-.29 1.158-.726 2.026-1.307 2.749-.581.724-1.162 1.303-2.034 1.592-.726.434-1.598.579-2.615.579-1.017 0-1.888-.145-2.76-.579-.872-.434-1.598-1.013-2.324-1.881v-11.865a12.493 12.493 0 012.614-2.315c.872-.579 2.034-.869 3.342-.869a6.05 6.05 0 012.324.434c.726.29 1.162.724 1.743 1.447.436.724.872 1.592 1.017 2.605.291 1.013.436 2.315.436 3.907 0 1.736-.145 3.039-.436 4.196zm-22.808-1.736c-.435-.724-.871-1.447-1.452-2.026-.581-.579-1.308-1.013' +
						'-2.034-1.302-.727-.29-1.598-.724-2.325-.869-.726-.289-1.598-.578-2.324-.723-.726-.289-1.453-.434-2.034-.723-.581-.29-1.162-.579-1.453-1.013-.435-.434-.581-.869-.581-1.447 0-.869.291-1.592 1.017-2.026.727-.579 1.744-.724 2.906-.724.871 0 1.453.145 2.034.29.581.144 1.162.434 1.598.579.435.289.871.434 1.162.578.436.145.726.29 1.017.29.29 0 .581 0 .726-.145.145-.145.436-.289.581-.579l1.453-2.315c-1.017-1.013-2.179-1.736-3.777-2.315-1.453-.579-3.196-.868-4.939-.868-1.598 0-3.051.29-4.359.723-1.307.434-2.324 1.013-3.196 1.737-.871.723-1.452 1.591-1.888 ' +
						'2.604-.436 1.013-.581 2.026-.581 3.039 0 1.157.145 2.17.581 3.038.436.869.871 1.592 1.452 2.026.582.579 1.308 1.013 2.034 1.447.727.434 1.598.724 2.47 1.013.872.29 1.598.579 2.47.724.726.289 1.452.434 2.033.723.582.29 1.163.579 1.453 1.013.436.434.581.868.581 1.592 0 .434-.145.868-.29 1.157-.146.434-.436.724-.727 1.013-.29.29-.726.579-1.307.724-.581.144-1.162.289-1.889.289a9.168 9.168 0 01-2.324-.289 5.377 5.377 0 01-1.598-.724c-.436-.289-.872-.434-1.162-.723-.291-.29-.727-.29-1.162-.29-.436 0-.727.145-1.017.29-.291.144-.436.434-.727.723l-1.452 2.46 ' +
						'1.743 1.302c.726.435 1.453.724 2.179 1.013.726.29 1.598.579 2.47.724.871.144 1.743.289 2.614.289 1.744 0 3.197-.289 4.504-.723 1.307-.434 2.47-1.013 3.341-1.881.872-.724 1.598-1.737 2.034-2.75.436-1.013.726-2.315.726-3.472 0-1.737-.145-2.75-.581-3.473zm-25.422 4.196c-.436 0-.872.145-1.163.434-.435.29-.871.434-1.452.724-.581.289-1.163.578-2.034.868-.727.289-1.598.289-2.76.289-1.017 0-2.034-.144-2.761-.434-.871-.289-1.598-.723-2.179-1.447a5.841 5.841 0 01-1.452-2.46c-.436-1.013-.582-2.17-.727-3.473h16.416c.581 0 1.017-.144 1.162-.434.146-.289.291-1.013' +
						'.291-2.025 0-1.881-.291-3.618-.872-5.065-.581-1.447-1.307-2.749-2.324-3.762s-2.179-1.737-3.632-2.315c-1.453-.579-2.906-.724-4.649-.724-2.034 0-3.777.29-5.375 1.013-1.598.724-2.905 1.592-4.068 2.75-1.162 1.157-1.888 2.604-2.469 4.196a14.85 14.85 0 00-.872 5.064c0 2.315.291 4.341 1.017 6.078.726 1.736 1.598 3.183 2.76 4.485 1.162 1.158 2.615 2.026 4.213 2.75 1.598.578 3.341.868 5.085.868.871 0 1.888 0 2.905-.145s2.034-.434 2.906-.723c1.017-.29 1.888-.724 2.76-1.303.872-.579 1.598-1.302 2.324-2.026l-1.888-2.315c-.291-.579-.581-.868-1.162-.868zm-12.494-13' +
						'.746c1.017-1.013 2.47-1.592 4.358-1.592 1.017 0 1.743.145 2.47.434.726.289 1.307.724 1.743 1.302.436.579.872 1.158 1.017 1.881.291.724.291 1.447.291 2.316h-12.058c.436-1.881 1.162-3.328 2.179-4.341zm-17.723 6.945c-.291-.434-.727-.868-1.017-1.302-.436-.289-.727-.724-1.308-.868 1.308-.434 2.47-1.013 3.487-1.592 1.017-.723 1.888-1.447 2.615-2.315.726-.868 1.162-1.881 1.598-3.039.436-1.157.581-2.315.581-3.617 0-1.592-.291-3.039-.872-4.341-.581-1.303-1.453-2.46-2.615-3.328-1.162-.869-2.615-1.737-4.503-2.17-1.889-.58-3.923-.724-6.537-.724h-11.477v37.187h6' +
						'.973V108.36h2.906c.726 0 1.162.144 1.598.289.435.145.726.434 1.016 1.013l7.845 11.865c.581.869 1.453 1.447 2.615 1.447h6.247l-9.152-13.891zm-5.085-6.222c-1.017.29-2.179.434-3.486.434h-4.359v-12.3h4.504c2.615 0 4.503.435 5.665 1.593 1.308 1.012 1.889 2.46 1.889 4.34 0 1.013-.145 1.882-.436 2.605-.29.724-.726 1.447-1.453 2.026-.581.434-1.452.868-2.324 1.302z" fill="#1D1333"/><defs> <linearGradient id="a" x1="24" x2="119.88" y1="106.62" y2="106.62" gradientUnits="userSpaceOnUse"> <stop stop-color="#20D497" offset="0"/>' +
						'<stop stop-color="#007349" offset="1"/> </linearGradient></defs></svg>',
				],
				{ type: 'image/svg+xml' },
			),
			name: 'made_with_respresso.svg',
		});
	}

	protected tourCanTrySave(): () => boolean {
		return () => {
			return this.canExecuteSave() || this.canUploadResources;
		};
	}

	protected executeDownload() {
		this.startDownload(this.getVersionDownloadUrl());
	}

	protected tourTryDownload(): ((resourceList: ResourceList) => void) | null {
		return (resourceList: ResourceList) => {
			if (!this.canExecuteDownload()) {
				if (this.canExecuteSave()) {
					this.saveRequestedByUser().then(this.executeDownload);
				} else {
					this.submitDefaultUpload().awaitFinish.then(this.saveRequestedByUser).then(this.executeDownload);
				}
			} else {
				this.executeDownload();
			}
		};
	}

	protected tourCanTryDownload(): () => boolean {
		return () => {
			return this.canExecuteDownload() || this.canExecuteSave() || this.canUploadResources;
		};
	}
}

import Component from 'vue-class-component';
import Vue from 'vue';
import {ColorKeyDataColor} from '../../view/resource/color-resource';
import template from './color-swatch.html';

@Component({
	props: {
		color: Object,
		isEnabled: Boolean,
	},
	template: template,
})
export default class ColorSwatch extends Vue {
	private color: ColorKeyDataColor | undefined;
	private isEnabled: boolean | undefined;

	get hexColor(): string {
		if (this.color) {
			return (
				'rgba(' +
				(this.color.red || 0) +
				', ' +
				(this.color.green || 0) +
				', ' +
				(this.color.blue || 0) +
				', ' +
				(this.color.alpha === 0 ? 0 : this.color.alpha / 255 || 1) +
				')'
			);
		} else {
			return '#000000';
		}
	}
}

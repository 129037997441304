import Vue from 'vue';
import Component from 'vue-class-component';
import template from './alert.html';
import './alert.scss';

import {IDialog} from '@ponte/dialog';
import {IDialogComponent} from '@ponte/dialog-vue';
import {Prop} from 'vue-property-decorator';
import DialogTemplate from '../dialog-template/dialog-template';

export interface AlertDialogInput {
	title: string;
	message: string;
	okCb?: () => void;
}

@Component({
	components: {
		'dialog-template': DialogTemplate,
	},
	template: template,
})
export default class Alert extends Vue implements IDialogComponent<boolean> {
	@Prop() inputData: AlertDialogInput | undefined;

	dialog!: IDialog<boolean>;

	closeDialog(): void {
		if (this.dialog.dialogController) this.dialog.dialogController.close(true);
		if (this.inputData && this.inputData.okCb) this.inputData.okCb();
	}
}

import Vue from 'vue';
import template from './changed-item.html';
import './changed-item.scss';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

@Component({
	props: {
		targetVersion: String,
		fromVersion: String,
		iconClass: String,
		header: Boolean,
	},
	template: template,
})
export default class ChangedItem extends Vue {
	@Prop({ required: true })
	targetVersion: string | undefined;
	@Prop({ required: false, default: '' })
	fromVersion: string | undefined;
	@Prop({ required: false, default: 'changed-item-icon-default' })
	iconClass!: string;
	@Prop({ required: false, default: false })
	header!: boolean;
}

import Vue from 'vue';
import Component from 'vue-class-component';
import template from './user-info.html';
import './user-info.scss';
import UserService from '../../../../services/user-service';
import {IRecaptchaProxy, RecaptchaUtil} from '../../../../util/recaptcha';
import {translate} from '../../../../main';
import {DialogBuilder} from '../../../common/dialog/dialog';
import ErrorHandler from '../../../../services/error-handler';
import LoadingScreen from '../../../../decorators/loading-screen';
import {RespressoError} from '../../../../services/http-service';
import {DisclaimerDialog} from '../../../../../src/components/common/disclaimer/disclaimer-dialog';
import PasswordInput from '../../../common/password-input/password-input';
import RespressoApi, {UserWorkRole} from "../../../../api/respresso-api";
import {SlimSelectInfo} from "../../../common/vue-slim-select/vue-slim-select";
import {InvitationVerifiedRegistrationResponse, RegistrationResponse} from "respresso";

const emailRegex = /^([0-9a-z\.\_\-\+]+)@([0-9a-z\.\_-]+)\.([a-z]{2,4})$/;
const passwordRegex = /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])[\w\d!@#$%_\.]{6,40}$/;

export interface Result {
	email: string;
	fullName: string;
}

@Component({
	props: {
		inviteToken: String,
		regToken: String,
	},
	template: template,
})
export default class UserInfo extends Vue {
	private fromMail = false;
	private regToken: string | undefined;

	private policy = false;
	private email = '';
	private fullName = '';
	private errors: {
		[field: string]: string;
	} = {};
	private newsletter = false;

	private inviteToken: string | undefined;

	private recaptchaProxy: IRecaptchaProxy | null = null;
	private selectedWorkRole: UserWorkRole | "noSelection" = "noSelection";

	private async beforeMount() {
        const inviteToken = this.inviteToken;
		if ( this.regToken != null) {
			const result: Result = JSON.parse(decodeURIComponent(atob(this.regToken)));
			if (result != null) {
				this.fullName = result.fullName;
				this.email = result.email;
			}
		}else if (inviteToken) {
            const response = await ErrorHandler.tryRequest(() => RespressoApi.getInvitationInfo(inviteToken), {
                loadingScreen: true
            });
            if (response) {
                if (response.name) {
                    this.fullName = response.name;
                }
                if (response.email) {
                    this.email = response.email;
                }
            }
        }
    }

    private async mounted(): Promise<void> {
        if (!this.email) {
		(this.$refs.email as HTMLInputElement).focus();} else if (!this.fullName) {
            (this.$refs.name as HTMLInputElement).focus();
        } else {
            const pwInput = this.$refs.pwInput as PasswordInput;
            if (pwInput) { // This is a lazy loaded component
                pwInput.focus();
            }
        }

		const needsRecaptcha = await RecaptchaUtil.hasRecaptchaSupport();
		if (needsRecaptcha) {
			this.recaptchaProxy = await RecaptchaUtil.render(this.$refs.recaptcha as HTMLInputElement);
		}
	}

	private get pwInput() {
		return this.$refs.pwInput as PasswordInput;
	}

	private get password() {
		return this.pwInput.typedPassword;
	}

    @LoadingScreen({ fullScreen: true })
    async register(): Promise<void> {
        if (this.validate()) {
            const response = await ErrorHandler.tryRequest(async () => {
                try {
                    const result = await UserService.register(
                        this.email,
                        this.fullName,
                        this.password,
                        this.newsletter,
                        await this.getRecaptcha(),
                        this.selectedWorkRole as UserWorkRole,
                        this.inviteToken,
                    );
                    return result;
                } catch (err: any) {
                    const key = err.key;
                    if (err instanceof RespressoError && key.indexOf('auth') === 0) {
                        if (this.recaptchaProxy != null) {
                            this.recaptchaProxy.reset();
                        }
                    }
                    throw err;
                }
            });

            if (response) {
                const castedToVerified = response as InvitationVerifiedRegistrationResponse;
                const castedToReg = response as RegistrationResponse;
                if (castedToVerified.teamId) {
                    this.$router.push({ name: 'dashboard', params: { teamId: castedToVerified.teamId } });
                } else if (castedToReg.authUserId) {
                    const query: any = { authUserId: castedToReg.authUserId };
                    if (this.inviteToken) {
                        query.inviteToken = this.inviteToken;
                    }
                    this.$router.push({ name: 'activate', query });
                }
            }

        }
    }

	private  getWorkRoleSelectorData(): SlimSelectInfo[] {
		const roles = [
			"noSelection",
			UserWorkRole.DEVELOPER,
			UserWorkRole.DESIGNER,
			UserWorkRole.MANAGER,
			UserWorkRole.TRANSLATOR,
			UserWorkRole.COPYWRITER,
			UserWorkRole.MARKETING,
			UserWorkRole.QA,
			UserWorkRole.OTHER,
		];
		return roles.map((role) => {
			return {
				text: this.$t(`registration.userInfo.info.workRole.${role}`) as string,
				value: role,
				selected: role === this.selectedWorkRole,
			};
		});
	}

	private workRoleSelectorChanged(info: SlimSelectInfo): void {
		if (info.value) {
			this.selectedWorkRole = info.value as UserWorkRole;
		}
	}

	private validate(): boolean {
		let valid = true;
		this.errors = {};

		if (!this.email) {
			valid = false;
			this.errors['email'] = translate('registration.validationErrors.required');
		} else if (!emailRegex.test(this.email)) {
			valid = false;
			this.errors['email'] = translate('registration.userInfo.validationErrors.invalidEmail');
		}

		const pwInput = this.pwInput;
		if (!pwInput || !pwInput.checkPassword()) {
			valid = false;
		}

		if (!this.fullName) {
			valid = false;
			this.errors['fullName'] = translate('registration.validationErrors.required');
		}
		if(!this.selectedWorkRole || this.selectedWorkRole === "noSelection") {
			valid = false;
			this.errors['workRole'] = translate('registration.validationErrors.required');
		}
		if (this.recaptchaProxy) {
			// We don't want to pre-execute invisible captcha as it may time out. We will execute it during the form post
			// if (!(await this.recaptchaProxy.result())) {
			//     valid = false;
			//     this.errors['recaptcha'] = translate('registration.validationErrors.captcha.required');
			// }
		}

		if (!this.policy) {
			valid = false;
			this.errors['policy'] = translate('registration.validationErrors.required');
		}

		return valid;
	}

	private async getRecaptcha(): Promise<string> {
		return this.recaptchaProxy ? await this.recaptchaProxy.result() : '';
	}

	private showPolicy(): void {
		DialogBuilder.createVueDialog(DisclaimerDialog);
	}

	private login(): void {
		const query: any = {};
		if (this.inviteToken) {
			query.redirect = this.$router.resolve({
				name: 'acceptInvite',
				query: { inviteToken: this.inviteToken },
			}).route.fullPath;
		}
		this.$router.push({ name: 'login', query });
	}

	protected handleBlur(filedName: string): void {
		if (this.errors.hasOwnProperty(filedName)) {
			this.validate();
		}
	}
}

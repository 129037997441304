import Vue from 'vue';
import template from './show-empty-values.html';
import './show-empty-values.scss';
import Component from 'vue-class-component';

@Component({
	template: template,
})
export default class ShowEmptyValues extends Vue {
	private showEmpty = false;

	private showEmptyValues(): void {
		this.showEmpty = !this.showEmpty;
		this.$emit('show-empty-values', this.showEmpty);
	}
}

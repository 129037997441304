import Component from 'vue-class-component';
import template from './app-icon-resource.html';
import './app-icon-resource.scss';
import {Resource, ResourceItem} from './resource';
import {DialogBuilder, NotificationBuilder} from '../../common/dialog/dialog';
import {EditImageDialog, ImageUploadButton, ImageUploadDialogConfig, ImageUploadResult,} from '../../common/image/edit-image-dialog';
import RespressoApi from '../../../api/respresso-api';
import ErrorHandler from '../../../services/error-handler';
import {supportedFiles} from '../../../util/file-support-tester';
import {translate} from '../../../main';
import debounce from '../../../util/debounce';
import VueI18n from 'vue-i18n';

export interface GetAppIconDataResponse {
	versionNumber: string;
	versionEditable: boolean;
	data: AppIconData;
}

type PreviewSelectionType = 'androidPreview' | 'iosPreview';
type UploadSelectionType = 'single' | 'multi';
type UploadFileType = 'single' | 'background' | 'foreground';

export interface SaveAppIconDataResponse {
	data: AppIconData[];
}

export interface PreviewAppIconDataResponse {
	data: PreviewIconData[];
}

export interface AppIconConfig {
	mode: UploadSelectionType;

	iphone: boolean | undefined;
	ipad: boolean | undefined;
	appstore: boolean | undefined;
	prerendered: boolean | undefined;

	shape: string;
	raster: string;
	crop: boolean | undefined;
	playstore: boolean | undefined;

	backgroundSize: number | undefined;
	foregroundSize: number | undefined;
}

export interface AppIconData extends ResourceItem {
	single: string;
	background: string;
	foreground: string;
	convertedFiles: ConverterFile[];
	config: AppIconConfig;
}

export interface PreviewIconData {
	image: string;
	platform: string;
	filePath: string;
}

export interface AppIconFile {
	previewUrl?: string;
	state?: string;
}
export interface ConverterFileAttr {
	isPreview?: 'true' | 'false';
}

export interface ConverterFile {
	platform: string;
	filePath: string;
	attributes?: ConverterFileAttr;
	previewUrl?: string;
	previewId?: string;
}

export interface AppIconDataForSave {
	id?: string | null;
	config: AppIconConfig;
	single: IconChange;
	background: IconChange;
	foreground: IconChange;
}

export interface IconChange {
	currentFileId: string | null;
	newFileId: string | null;
}

const vueSliderMarksGenerator = (step: number, max: number): { [key: string]: string } => {
	const result: { [key: string]: string } = {};

	for (let i = 0; i <= max; i = i + step) {
		result[`${i}`] = `${i}%`;
	}

	return result;
};

@Component({
	template: template,
})
export default class AppIconResource extends Resource {
	private DEBOUNCE_TIME = 600;

	private config: AppIconConfig = {
		mode: 'single',
		iphone: true,
		ipad: false,
		appstore: true,
		prerendered: true,
		shape: 'square',
		raster: 'png',
		crop: true,
		playstore: true,
		backgroundSize: 100,
		foregroundSize: 100,
	};

	private modified = false;

	public appIconMarks: Record<string, unknown> = vueSliderMarksGenerator(25, 200);
	public appIconMax = 200;

	public selectedPreview: PreviewSelectionType = 'androidPreview';
	public selectedType: UploadSelectionType = 'single';
	public generateHeight = 0;

	private convertedFiles: ConverterFile[] = [];
	private appIconDataForSave: AppIconDataForSave = {} as AppIconDataForSave;
	private uploadResults: {
		single: ImageUploadResult | null;
		background: ImageUploadResult | null;
		foreground: ImageUploadResult | null;
	} = {
		single: null,
		background: null,
		foreground: null,
	};

	protected getResourceId(): string {
		return 'appIcon';
	}

	protected async loadData<AppIconResource>(): Promise<void> {
		await this.loadDataPrivate();
	}

	private async loadDataPrivate(): Promise<void> {
		const appIconDataResponse = await ErrorHandler.tryRequest(
			() => RespressoApi.getAppIconVersion(this.teamId, this.projectId, this.version),
			{
				loadingScreen: true,
			},
		);

		if (appIconDataResponse) {
			this.editable = appIconDataResponse.versionEditable;
			if (appIconDataResponse.data && appIconDataResponse.data.config) {
				this.selectedType = appIconDataResponse.data.config.mode;

				this.config.mode = appIconDataResponse.data.config.mode || 'single';
				this.config.iphone = appIconDataResponse.data.config.iphone;
				this.config.ipad = appIconDataResponse.data.config.ipad;
				this.config.appstore = appIconDataResponse.data.config.appstore;
				this.config.prerendered = appIconDataResponse.data.config.prerendered;

				this.config.shape = appIconDataResponse.data.config.shape;
				this.config.raster = appIconDataResponse.data.config.raster;
				this.config.crop = appIconDataResponse.data.config.crop;
				this.config.playstore = appIconDataResponse.data.config.playstore;

				this.config.backgroundSize = appIconDataResponse.data.config.backgroundSize;
				this.config.foregroundSize = appIconDataResponse.data.config.foregroundSize;
			}
			if (appIconDataResponse.data) {
				if (appIconDataResponse.data.convertedFiles) {
					this.convertedFiles = [];
					appIconDataResponse.data.convertedFiles.forEach((cf) => {
						cf.previewUrl = this.buildPreviewUrl(cf);
						this.convertedFiles.push(cf);
					});
				}

				this.appIconDataForSave = {
					id: appIconDataResponse.data.id,
					config: this.config,
					single: {
						currentFileId: appIconDataResponse.data.single,
						newFileId: appIconDataResponse.data.single,
					},
					foreground: {
						currentFileId: appIconDataResponse.data.foreground,
						newFileId: appIconDataResponse.data.foreground,
					},
					background: {
						currentFileId: appIconDataResponse.data.background,
						newFileId: appIconDataResponse.data.background,
					},
				};
			}
		}
	}

	private uploadIcon(type: UploadFileType): void {
		const button: ImageUploadButton = {
			text: translate('buttons.upload'),
			fn: (imageUploadResult): void => {
				this.modified = true;
				const uploadResult = imageUploadResult;
				if (uploadResult) {
					this.appIconDataForSave[type].newFileId = uploadResult.fileId;
					this.uploadResults[type] = uploadResult;
				}
				this.doPreview();
			},
		};

		const fileFilter = ['.svg', '.xml', '.pdf', '.png', '.jpg'];
		const uploaderTypes: string[] = ['svg', 'drawable', 'pdf', 'png', 'jpg'];

		const uploadResult: ImageUploadResult | null = this.uploadResults[type]
			? {
					fileId: this.uploadResults[type]!.fileId || '',
					mimeType: '',
					name: this.uploadResults[type]!.name || '',
					total: this.uploadResults[type]!.total || 0,
			  }
			: null;

		const uploadHandler = this.getUploadHandlerByType(type);

		const previewImageUrlHandler = (uploadResult: ImageUploadResult): string | null => {
			if (!uploadResult) return null;
			return this.getAppIconUrl(type, uploadResult.fileId);
		};

		const dialogConfig: ImageUploadDialogConfig = {
			button,
			fileFilter,
			message: translate('resource.appIcon.uploadTypes', {
				formats: uploaderTypes.map((it) => '- ' + this.$t('resource.uploader.type.image.' + it)).join('\n'),
			}),
			previewImageUrlHandler,
			title: translate('resource.appIcon.uploadDialogHeader.' + type),
			uploadHandler,
			uploadResult,
		};

		DialogBuilder.createVueDialog(EditImageDialog, {
			propsData: {
				dialogConfig,
			},
		});
	}

	private deleteIcon(type: UploadFileType): void {
		this.appIconDataForSave[type].newFileId = null;
		this.appIconDataForSave[type].currentFileId;
	}

	private hasImage(type: UploadFileType): boolean {
		if (!this.appIconDataForSave[type]) {
			return false;
		}

		return !!this.appIconDataForSave[type].newFileId || !!this.appIconDataForSave[type].newFileId;
	}

	private modeUpdated(): void {
		this.config.mode = this.selectedType;
		this.modified = true;

		this.doPreview();
	}

	public appIconTooltipFormatter(val: string): string {
		return `${val}%`;
	}

	appIconValueChangedDebounce = debounce(() => {
		this.doPreview();
	}, this.DEBOUNCE_TIME);

	public appIconValueChanged(): void {
		this.appIconValueChangedDebounce();
		this.modified = true;
	}

	protected async doSave(): Promise<boolean> {
		const saveAppIconDataResponse = await ErrorHandler.tryRequest(
			() => RespressoApi.saveAppIconVersion(this.teamId, this.projectId, this.version, this.appIconDataForSave),
			{
				loadingScreen: true,
				loadingMessage: '#loading.processingIcons',
			},
		);

		if (saveAppIconDataResponse) {
			this.modified = false;

			await this.loadDataPrivate();

			NotificationBuilder.success('#messages.saved');
			return true;
		} else {
			return false;
		}
	}

	public doPreviewInProgressCounter = 0;
	protected async doPreview(): Promise<void> {
		this.calcualteGenerateHeight();

		const hasFile = function (ic: IconChange | null): boolean {
			if (!ic) {
				return false;
			}
			return !!ic.newFileId || !!ic.currentFileId;
		};

		if (this.appIconDataForSave.config.mode === 'single' && !hasFile(this.appIconDataForSave.single)) {
			return;
		}

		if (
			this.appIconDataForSave.config.mode === 'multi' &&
			(!hasFile(this.appIconDataForSave.background) || !hasFile(this.appIconDataForSave.foreground))
		) {
			return;
		}

		if (this.doPreviewInProgressCounter > 0) {
			this.doPreviewInProgressCounter++;
			return;
		}

		this.doPreviewInProgressCounter++;

		const saveAppIconDataResponse = await ErrorHandler.tryRequest(
			() => RespressoApi.previewAppIcon(this.teamId, this.projectId, this.version, this.appIconDataForSave),
			{
				loadingScreen: false,
				loadingMessage: '#loading.processingIcons',
			},
		);

		this.doPreviewInProgressCounter--;

		if (this.doPreviewInProgressCounter > 0) {
			this.doPreviewInProgressCounter = 0;
			await this.doPreview();
			return;
		}

		if (saveAppIconDataResponse) {
			this.convertedFiles = saveAppIconDataResponse.data.map((f) => ({
				platform: f.platform,
				attributes: {
					isPreview: 'true',
				} as ConverterFileAttr,
				previewUrl: 'data:image/png;base64,' + f.image,
				filePath: f.filePath,
			}));
		}
	}

	protected filterConvertedFiles(cf: ConverterFile): boolean {
		if (cf.platform !== this.selectedPreview) {
			return false;
		}
		if (!cf.attributes || cf.attributes.isPreview !== 'true') {
			return false;
		}

		if (cf.filePath.endsWith('.png')) {
			return true;
		}

		return this.isWebPsupported() && cf.filePath.endsWith('.webp');
	}

	private get hasConvertedFiles(): boolean {
		const nonPreviewFile = this.convertedFiles.find((file) => {
			return !file.attributes || file.attributes.isPreview !== 'true';
		});
		return nonPreviewFile != undefined;
	}

	public isWebPsupported(): boolean {
		return supportedFiles.webp === true;
	}

	private buildPreviewUrl(cf: ConverterFile): string {
		return `/api/ui/appIcon/preview/converted/${this.teamId}/${this.projectId}/${this.version}/${cf.platform}/${
			cf.filePath
		}?date=${new Date().getTime()}`;
	}

	protected isModified(): boolean {
		return this.modified;
	}

	protected getPreviewUrlByType(type: UploadFileType): string {
		const saved = this.appIconDataForSave[type];

		const fileId = saved.newFileId ? saved.newFileId : saved.currentFileId;

		if (fileId == null) {
			return '#'; //FIXME
		}

		return this.getAppIconUrl(type, fileId);
	}

	// Generálást jelző hover box méretezése
	public calcualteGenerateHeight(): void {
		const generateHeight = this.$refs.generatedImages as HTMLElement;
		if (generateHeight && generateHeight.offsetHeight > 65) {
			this.generateHeight = generateHeight.offsetHeight - 35;
		} else {
			this.generateHeight = 0;
		}
	}

	protected getAppIconUrl(type: string, fileId: string): string {
		return `/api/ui/appIcon/preview/${this.teamId}/${this.projectId}/${this.version}/${type}/${fileId}`;
	}

	// about options
	protected isIos(): boolean {
		return this.selectedPreview !== 'androidPreview';
	}

	protected handleBlur(): void {
		this.modified = true;
	}

	public toggleShapeSelect(): void {
		(this.$refs.toggleShape as HTMLElement).classList.toggle('active');
		this.modified = true;
	}

	public optionShapeSelected(val: string): void {
		this.config.shape = val;
		this.doPreview();
	}

	public optionRasterSelected(val: string): void {
		this.config.raster = val;
		this.doPreview();
	}

	public getShape(): string | VueI18n.LocaleMessages | null {
		if (this.config.shape === '') {
			return null;
		}
		return this.$t('resource.appIcon.android.shape.' + this.config.shape);
	}

	public getRaster(): string | VueI18n.LocaleMessages | null {
		if (this.config.raster === '') {
			return null;
		}
		return this.$t('resource.appIcon.android.raster.' + this.config.raster);
	}

	private getUploadHandlerByType(type: UploadFileType): string {
		let uploadHandler = 'AppIconSingleUploadHandler';
		switch (type) {
			case 'single': {
				uploadHandler = 'AppIconSingleUploadHandler';
				break;
			}
			case 'background': {
				uploadHandler = 'AppIconBackgroundUploadHandler';
				break;
			}
			case 'foreground': {
				uploadHandler = 'AppIconForegroundUploadHandler';
				break;
			}
		}
		return uploadHandler;
	}
}

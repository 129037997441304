import Vue from 'vue';
import Component from 'vue-class-component';
import template from './create-team.html';
import './create-team.scss';
import TagEditor from '../../common/vue-slim-select/tag-editor';
import {NotificationBuilder} from '../../common/dialog/dialog';
import RespressoApi, {CheckFeatureResponse} from '../../../api/respresso-api';
import UserService from '../../../services/user-service';
import ErrorHandler from '../../../services/error-handler';
import LoadingScreen from '../../../decorators/loading-screen';
import {NextFunction, Route} from 'vue-router';
import {CreateProjectRequest} from "respresso";

export interface CreateTeamData {
	teamName: string;
	teamTitle: string;
	domains: string[];
	inviteMembers: string[];
}

export interface CreateTeamResponse {
	teamId: string;
}

@Component({
	components: {
		'tag-editor': TagEditor,
	},
	template: template,
})
export default class CreateTeam extends Vue {
	public showCancel = false;

	private teamName = '';
	private projectName = '';

	private domains: string[] = [];

	private invitedUsers: string[] = [];

	private newTeamEnabled: boolean | null = null;

	private beforeRouteEnter(to: Route, from: Route, next: NextFunction): void {
		next((vm) => {
			const userPreferences = vm as CreateTeam;
			userPreferences.loadData();
		});
	}

	private async loadData(): Promise<void> {
		const featureResponse: CheckFeatureResponse = await RespressoApi.checkFeature('newTeam');

		this.newTeamEnabled = featureResponse.enabled;
	}

	protected submitCreateTeam(event: Event) {
		if (event) {
			event.preventDefault();
		}
		this.createTeam();
		return false;
	}

	@LoadingScreen({ fullScreen: true })
	protected async createTeam(): Promise<void> {
		const teamName = this.teamName.trim();
		const teamData: CreateTeamData = {
			teamName: teamName,
			teamTitle: teamName,
			domains: this.domains,
			inviteMembers: this.invitedUsers,
		};

		const teamCreationResp = await ErrorHandler.tryRequest(() => RespressoApi.createTeam(teamData));

		if (teamCreationResp) {
			NotificationBuilder.success('#success.team.created');
			const teamId = teamCreationResp.teamId;
			await UserService.refreshData();
			let projectName = this.projectName.trim();
			if (!projectName) {
				projectName = teamName;
			}
			const projectData: CreateProjectRequest = {
				title: projectName,
				name: projectName,
				users: [],
			};
			const projectCreationResp = await ErrorHandler.tryRequest(() =>
				RespressoApi.createProject(teamId, projectData),
			);
			if (projectCreationResp) {
				NotificationBuilder.success('#success.project.created');
				await UserService.loadTeam(teamId);
				this.$router.push({
					name: 'project',
					params: { teamId: teamId, projectId: projectCreationResp.projectId },
				});
			} else {
				this.$router.push({ name: 'dashboard', params: { teamId: teamId } });
			}
		}
	}

	public addDomain(domain: string): void {
		this.domains.push(domain);
	}

	public removeDomain(domain: string): void {
		const index = this.domains.indexOf(domain);
		if (index !== -1) {
			this.domains.splice(index, 1);
		}
	}

	public addNewInvite(email: string): void {
		this.invitedUsers.push(email);
	}

	public removeNewInvite(email: string): void {
		const index = this.invitedUsers.indexOf(email);
		if (index !== -1) {
			this.invitedUsers.splice(index, 1);
		}
	}
}

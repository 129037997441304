import Vue from 'vue';
import Component from 'vue-class-component';
import template from './add-project.html';
import './add-project.scss';
import {TeamMeta} from 'respresso';
import CreateProject from '../../../components/common/create-project/create-project.vue';
import {teamModule} from '../../../store/modules/team/index';

@Component({
	template: template,
	components: {
		"create-project": CreateProject
	}
})
export default class AddProject extends Vue {
	private async onProjectCreated(projectId: string) {
		await this.$router.push({
			name: 'project',
			params: { teamId: teamModule.team!.id, projectId: projectId },
		});
	}

	get team(): TeamMeta | null {
		return teamModule.team;
	}
}

import Vue from 'vue';
import Component from 'vue-class-component';
import {DefaultFileUploader} from '@ponte/file-upload-js';
import '../upload/default-file-uploader.scss'; //TODO ezt is a @ponte/file-upload-js lib-ből kellene
import {translate} from '../../main';
import template from './vue-uploader.html';

@Component({
	props: {
		uploadHandler: String,
	},
	template: template,
})
export default class VueUploader extends Vue {
	private uploadHandler: string | undefined;

	private handler: DefaultFileUploader | undefined;

	async mounted(): Promise<void> {
		this.handler = new DefaultFileUploader({
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			handler: this.uploadHandler!,
			basePath: '/api/ui/',
			element: this.$el.firstChild as HTMLElement,
			createPreviewUrl: (url: string): string | undefined => {
				return url;
			},
			createDownloadUrl: (url: string): string | undefined => {
				return url;
			},
			translate: (key: string, args?: any[]): string => {
				return translate('plugin.uploader.' + key);
			},
			headers: {
				'X-Requested-With': 'Respresso',
			},
		});
	}
}

import Component from 'vue-class-component';
import template from './progress-bar.html';
import Vue from 'vue';
import './progress-bar.scss';

@Component({
	props: {
		max: Number,
		progress: Number,
	},
	template: template,
})
export default class ProgressBar extends Vue {}

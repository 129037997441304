import Vue from 'vue';
import Component from 'vue-class-component';
import template from './tag-editor.html';
import './tag-editor.scss';
import {default as VueSlimSelect, SlimSelectOption} from './vue-slim-select';

@Component({
	props: {
		allTags: Array,
		selectedTags: Array,
		disabled: Boolean,
	},
	watch: {
		allTags(): void {
			const tagEditor: TagEditor = this as TagEditor;
			Vue.nextTick(function () {
				tagEditor.allTagsChanged();
			});
		},
	},
	template: template,
})
export default class TagEditor extends Vue {
	private allTags: string[] | undefined;
	private selectedTags: string[] | undefined;

	public allTagsChanged(): void {
		(this.$refs.vueSlimSelect as VueSlimSelect).updateData();
	}

	public clear(): void {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(this.$refs.vueSlimSelect as any).slimSelect.setData([]);
	}

	private tagAdded(item: SlimSelectOption): void {
		this.$emit('tag-added', item.text);
	}

	private tagRemoved(item: SlimSelectOption): void {
		this.$emit('tag-removed', item.text);
	}

	private get options(): SlimSelectOption[] {
		return this.mapToOptions(this.allTags);
	}

	private get selectedItems(): SlimSelectOption[] {
		return this.mapToOptions(this.selectedTags);
	}

	private mapToOptions(items: string[] | undefined): SlimSelectOption[] {
		if (!items) {
			return [];
		}
		return items.map((item) => {
			return {
				text: item,
				value: item,
			};
		});
	}
}

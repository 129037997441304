import Vue from 'vue';
import Component from 'vue-class-component';
import template from './last-team-updates.html';
import './last-team-updates.scss';
import RespressoApi, {Paging} from '../../../api/respresso-api';
import ErrorHandler from '../../../services/error-handler';
import {NextFunction, Route} from 'vue-router';
import Pager from '../../common/pager/pager';
import LoadingScreen from '../../../decorators/loading-screen';
import {TeamMeta} from 'respresso';
import {teamModule} from '../../../store/modules/team/index';
import {ProjectUpdateMeta} from '../project/project';
import ProjectLastUpdatesList from '../../common/project/project-last-updates-list';

@Component({
	components: {
		pager: Pager,
		'project-last-updates-list': ProjectLastUpdatesList,
	},
	template: template,
})
export default class LastTeamUpdates extends Vue {
	private teamId: string | undefined;
	private latestUpdates: ProjectUpdateMeta[] = [];
	private totalSize = 0;
	private paging: Paging = {
		page: 0,
		size: 10,
	};

	beforeRouteEnter(to: Route, from: Route, next: NextFunction): void {
		next((vm) => {
			const res = vm as LastTeamUpdates;
			res.teamId = to.params.teamId;
			res.loadData();
		});
	}

	@LoadingScreen()
	protected async loadData(): Promise<void> {
		const lastTeamUpdates = await ErrorHandler.tryRequest(() =>
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			RespressoApi.loadLastUpdatedTeamMeta(this.teamId!, this.paging),
		);

		if (lastTeamUpdates) {
			this.latestUpdates = lastTeamUpdates.lastupdates;
			this.totalSize = lastTeamUpdates.totalElements;
		}
	}

	get team(): TeamMeta | undefined {
		if (teamModule.team) {
			return teamModule.team;
		}
	}

	protected setPage(pageIndex: number): void {
		this.paging.page = pageIndex;
		this.loadData();
	}

	protected getClickablePages(): number[] {
		const result: number[] = [];
		const maxPageCountToShow = 3;
		const numberOfPages = this.countOfPages();

		if (numberOfPages <= numberOfPages * 2) {
			for (let i = 0; i < numberOfPages * 2; i++) {
				if (i < numberOfPages) {
					result.push(i);
				}
			}
		} else {
			const currentPage = this.currentPage();

			let left = currentPage - maxPageCountToShow;

			while (left !== currentPage) {
				if (left >= 0) {
					result.push(left);
				}
				left++;
			}

			const right = numberOfPages * 2 - result.length;
			for (let i = 0; i < right; i++) {
				if (i < numberOfPages) {
					result.push(i);
				}
			}
		}

		return result;
	}

	protected countOfPages(): number {
		if (this.totalSize === 0) {
			return 0;
		}

		return Math.ceil(this.totalSize / this.paging.size);
	}

	protected currentPage(): number {
		return this.paging.size;
	}

	eventRelated(event: string): boolean {
		return event.startsWith('projectSaved.version');
	}
}

import VueI18n from 'vue-i18n';
import en from '../respresso/localization/respresso.strings-en.json';

const messages = {
	en,
};

export function getMessages(): VueI18n.LocaleMessages {
	return messages;
}

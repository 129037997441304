import Component from 'vue-class-component';
import template from './localization-resource.html';
import './localization-resource.scss';
import {ItemState, Resource, ResourceItem} from './resource';
import {Route} from 'vue-router';
import ResourceList from '../../common/resource-list/resource-list';
import {default as VueSlimSelect, SlimSelectInfo} from '../../common/vue-slim-select/vue-slim-select';
import StorageService from '../../../services/storage-service';
import {DialogBuilder, NotificationBuilder} from '../../common/dialog/dialog';
import NewLanguageDialog, {NewLanguageDialogConfig} from '../../common/localization/new-language-dialog';
import RespressoApi from '../../../api/respresso-api';
import ErrorHandler from '../../../services/error-handler';
import FlagUtil from '../../../util/flag-util';
import {userModule} from '../../.././store/modules/user/index';
import {translate} from '../../../main';
import {DataTable, SortOrder, SortStyle} from '../../../services/sort-service';
import UserService from '../../../services/user-service';
import LoadingScreen from '../../../decorators/loading-screen';
import {IFileDescriptor} from '@ponte/file-upload-js';
import ResourceImportDialog, {ResourceImportDialogConfig,} from '../../common/resource-import-dialog/resource-import-dialog';
import {LocalizationResourceInfoResponse, LocalizationVariable, LocalizationVariableRegex} from 'respresso';
import escapeStringRegexp from 'escape-string-regexp';
import {copyTextToClipboard} from '../../../util/clipboard';
import AddOrEditLocalizationVariableDialog, {AddOrEditLocalizationVariableDialogResult,} from '../../common/localization/add-or-edit-localization-variable-dialog';
import {IDialogController} from '@ponte/dialog';
import TagEditor from '../../common/vue-slim-select/tag-editor';
import StructureEditorDialog, {StructureEditorDialogConfig,} from '../../common/structure-editor-dialog/structure-editor-dialog';
import StartResourceMergeDialog, {StartResourceMergeDialogConfig,} from '../../common/start-resource-merge-dialog/start-resource-merge-dialog';
import {Logger} from '../../../util/logger';
import {sleep} from "../../../utils";

export interface GetLocalizationData {
	hintLanguage: LocalizationResourceItem[];
	language: LocalizationResourceItem[];
	variableFormat: string;
	variableMatchers: LocalizationVariableRegex[];
}

export interface LocalizationResourceItem extends ResourceItem {
	id: string;
	key?: string;
	value?: string;
	comment?: string;
	variables?: LocalizationVariable[];
}

interface DefaultLangItem {
	key: string;
	value: string;
}

export interface LocalizationInfo extends ResourceItem {
	defaultLanguage: string;
	versionNumber: string;
	versionIsEditable: boolean;
	languages: string[];
	platforms: string[];
	importPlatforms: string[];
	statistics: { [key: string]: LocStatItem };
}

export interface LocalizationSaveResponse {
	state: string;
	data: LocalizationResourceItem[];
	statistics: { [key: string]: LocStatItem };
}

export interface LocStatItem {
	percent: number;
	missingValues: number;
}

@Component({
	components: {
		'resource-list': ResourceList,
		'tag-editor': TagEditor,
	},
	template: template,
})
export default class LocalizationResource extends Resource {
	static defItem: Partial<LocalizationResourceItem> = {
		key: 'main_screen.button.open',
		value: 'Open',
	};
	protected items: LocalizationResourceItem[] = [];
	protected newItems: LocalizationResourceItem[] = [];
	private scrollBase?: HTMLElement;
	private language = '';
	private languages: string[] = [];
	private allLanguage: string[] = [];
	private defaultLanguage = '';
	private isDefault = true;
	private importPlatforms: string[] = [];
	private defValueList: DefaultLangItem[] = [];
	private sortKey?: string = '';
	private sortOrder?: SortOrder = SortOrder.ASC;
	private statistics: { [key: string]: LocStatItem } = {};
	private acceptFiles: string[] = ['.xml', '.strings', '.json', '.xlsx'];

	private hasRegexError = false;
	private showDetailsPanel = false;
	private selectedResource: LocalizationResourceItem | null = null;
	private selectedResourceInfo: LocalizationResourceInfoResponse | undefined | null = null;
	private compareLanguage?: string; // TODO
	private resourceInfoCache: Map<string, Promise<LocalizationResourceInfoResponse | null>> = new Map();
	private variableFormat = '^.*$';
	private remoteVariableMatchers: LocalizationVariableRegex[] | null = null;
	private compareTranslation = '...';
	private addOrEditVariableDialogController: IDialogController<AddOrEditLocalizationVariableDialogResult> | undefined;

	private setShowDetailsPanel(show: boolean) {
		this.showDetailsPanel = show;
	}

	protected async loadData<LocalizationResource>(resource: LocalizationResource, to: Route): Promise<void> {
		this.language = to.params.language;
		const initData = await ErrorHandler.tryRequest(() => this.loadInitData());
		if (!initData) {
			return;
		}
		this.languages = initData.languages;
		this.defaultLanguage = initData.defaultLanguage;
		this.editable = initData.versionIsEditable;
		this.importPlatforms = initData.importPlatforms;
		this.statistics = initData.statistics;

		this.isDefault = this.language === this.defaultLanguage;
		const redirectLanguage = this.getRedirectLanguage();
		if (redirectLanguage) {
			this.redirectToLanguage(redirectLanguage);
			return;
		} else {
			if (this.language && this.languages.indexOf(this.language) === -1) {
				this.redirectToLanguageRoot();
				return;
			}
		}

		await this.loadAllLanguages();

		await this.loadLanguage();
		this.$nextTick(() => {
			this.startTour(this.$refs.resourceList as ResourceList);
		});
	}

	private async getResourceInfo(resourceId?: string): Promise<LocalizationResourceInfoResponse | undefined | null> {
		const id = resourceId ? resourceId : this.selectedResource?.id;
		if (id) {
			let cached = this.resourceInfoCache.get(id);
			if (cached == undefined) {
				cached = ErrorHandler.tryRequest(() =>
					RespressoApi.getLocalizationResourceInfo(this.teamId, this.projectId, this.version, id),
				);
				this.resourceInfoCache.set(id, cached);
			}
			return await cached;
		} else {
			return undefined;
		}
	}

	private redirectToLanguageRoot(): void {
		this.$router.push({
			name: 'localizationResource',
			params: {
				teamId: this.teamId,
				projectId: this.projectId,
				versionNumber: this.version,
			},
		});
	}

	protected tourTryImport(): (() => void) | null {
		return this.importFiles;
	}

	protected tourTryAdd(): ((resourceList: ResourceList) => void) | null {
		return () => {
			this.newItem(LocalizationResource.defItem);
			setTimeout(() => {
				this.resumeTour();
			}, 1000);
		};
	}

	protected tourTrySave(): ((resourceList: ResourceList) => void) | null {
		return () => {
			if (!this.isModified() && this.canExecuteAddResource()) {
				this.newItem(LocalizationResource.defItem);
			}
			if (this.canExecuteSave()) {
				this.save().then(() => this.resumeTour());
			} else {
				NotificationBuilder.warning(
					'Failed to try the save function. Probably due to insufficient permissions.',
				);
				this.resumeTour();
			}
		};
	}

	protected tourCanTrySave(): () => boolean {
		return () => {
			return this.canExecuteSave() || this.canExecuteAddResource();
		};
	}

	protected tourTryDownload(): ((resourceList: ResourceList) => void) | null {
		return (resourceList: ResourceList) => {
			const modified = this.isModified();
			if (modified || !resourceList.hasSavedItems) {
				if (!modified && !resourceList.hasSavedItems) {
					this.newItem(LocalizationResource.defItem);
				}
				this.save().then(() => {
					this.resumeTour();
					this.startDownload(this.getVersionDownloadUrl());
				});
			} else {
				this.resumeTour();
				this.startDownload(this.getVersionDownloadUrl());
			}
		};
	}

	protected tourCanTryDownload(): () => boolean {
		return () => {
			return this.canExecuteDownload() || (this.canExecuteAddResource() && this.canExecuteSave());
		};
	}

	private async loadInitData(): Promise<LocalizationInfo> {
		const scrollBase = document.getElementsByClassName('main')[0] as HTMLElement;
		scrollBase.addEventListener('scroll', this.addingDisabledRows, { passive: true });
		window.addEventListener('resize', this.addingDisabledRows);

		return RespressoApi.getLocalizationVersionInfo(this.teamId, this.projectId, this.version);
	}

	private async loadLanguage(): Promise<void> {
		if (!this.language) {
			this.newLanguage();
			return;
		}

		StorageService.setLastLocalizationLanguage(this.teamId, this.projectId, this.language);

		if (this.$refs.languageSelector) {
			this.updateVueSlimSelect();
		}

		const data = await ErrorHandler.tryRequest(() =>
			RespressoApi.getLocalizationVersion(this.teamId, this.projectId, this.version, this.language),
		);
		if (data) {
			this.setItemFromServer(data.language);
			this.setDefaultLanguage(data.hintLanguage);
			if (data.variableFormat) {
				this.variableFormat = data.variableFormat;
			}
			if (data.variableMatchers) {
				this.remoteVariableMatchers = data.variableMatchers;
			}
		}

		setTimeout(() => {
			this.addingDisabledRows();
		}, 1);
	}

	private setDefaultLanguage(data: LocalizationResourceItem[]): void {
		this.defValueList = data.map((item: LocalizationResourceItem) => {
			return {
				key: item.key,
				value: item.value,
			} as DefaultLangItem;
		});
	}

	public newItem(defaults?: Partial<LocalizationResourceItem>): void {
		const def = defaults || {};
		def.id = def.id || '';
		def.key = def.key || '';
		// Logger.info(`Should add new item: ${JSON.stringify(def)}`)
		const item = this.newItemToItems(def);
		// Logger.info(`Should already be added: ${JSON.stringify(def)} ${JSON.stringify(this.items.find((it) => it.listId === item.listId))}`)
		// this.$forceUpdate();
		// FIXME - when there are no items (no-items are shown), the panel won't open when newItem is called - delay helps
		sleep(this.items.length == 0 ? 100 : 0).then(() => this.$nextTick(() => {
			const newItem = this.items.find((it) => it.listId === item.listId);
			// Logger.info(`Should open new item panel: ${JSON.stringify(def)} ${JSON.stringify(newItem)}`)
			if (newItem) {
				this.openDetails(undefined, newItem);
			}
		}));
	}

	private hasDefaultLang(input: LocalizationResourceItem): boolean {
		const correspondingValue = this.defValueList.find((item: DefaultLangItem) => {
			return item.key === input.key;
		});

		return correspondingValue != null && !!correspondingValue.value;
	}

	private defaultLang(input: LocalizationResourceItem): string {
		const correspondingValue = this.defValueList.find((item: DefaultLangItem) => {
			return item.key === input.key;
		});

		let value: string | null = null;

		if (correspondingValue) {
			value = correspondingValue.value;
		}

		if (value) {
			return `${translate('language.' + this.defaultLanguage)}: ${value}`;
		} else {
			return translate('resource.placeholder.value');
		}
	}

	private async loadAllLanguages(): Promise<void> {
		const allLanguages = await ErrorHandler.tryRequest(() => RespressoApi.getLangs());
		if (allLanguages) {
			this.allLanguage = allLanguages;
		}
	}

	protected getResourceId(): string {
		return 'localization';
	}

	private isAsc(key: string): boolean {
		return this.sortOrder === SortOrder.ASC && key === this.sortKey;
	}

	private isActive(key: string): boolean {
		return key === this.sortKey;
	}

	public updated(): void {
		setTimeout(() => {
			this.addingDisabledRows();
		}, 1);
	}

	protected async doSave(): Promise<boolean> {
		let itemsToSave: LocalizationResourceItem[] = JSON.parse(JSON.stringify(this.items.slice(0)));
		itemsToSave.push(...this.newItems);

		itemsToSave = itemsToSave
			.map((value) => {
				value.state = value.state.toUpperCase() as ItemState;
				return value;
			})
			.filter((it) => it.state !== ItemState.UNCHANGED);
		const saveResp = await ErrorHandler.tryRequest(
			() =>
				RespressoApi.saveLocalizationVersion(
					this.teamId,
					this.projectId,
					this.version,
					this.language,
					itemsToSave,
				),
			{
				loadingScreen: true,
				loadingMessage: '#loading.processingLocalizations',
			},
		);
		if (saveResp) {
			this.setItemFromServer(saveResp.data);
			this.statistics = saveResp.statistics;
			this.updateVueSlimSelect();
			setTimeout((): void => {
				this.addingDisabledRows();
			}, 1);
			NotificationBuilder.success('#messages.saved');
			this.setTutorialStatus();
			return true;
		} else {
			return false;
		}
	}

	private setTutorialStatus(): void {
		if (userModule.tutorialStatus === 'UPLOAD' || userModule.tutorialStatus === null) {
			userModule.setTutorialStatus('INTEGRATION');
			UserService.updateTutorialStatus('INTEGRATION');
		}
	}

	private setItemFromServer(data: LocalizationResourceItem[]): void {
		this.lastListId = 0;
		this.items = data.map((item) => {
			item.state = ItemState.UNCHANGED;
			item.listId = this.lastListId++;
			return item;
		});
		if (this.sortKey === '' && this.items && this.items.length > 0) {
			this.sortKey = 'key';
		}
		this.updateSortedItems();
		this.setShowDetailsPanel(false);
		this.selectedResource = null;
		this.selectedResourceInfo = null;
		this.resourceInfoCache = new Map();
		this.compareLanguage = undefined; // TODO save previous ones
		this.compareTranslation = '...';
	}

	private updateSortedItems(): void {
		let items = new DataTable(this.items);
		if (this.sortOrder === SortOrder.DESC && this.sortKey) {
			items = items.desc(this.sortKey, SortStyle.String);
		} else if (this.sortOrder === SortOrder.ASC && this.sortKey) {
			items = items.asc(this.sortKey, SortStyle.String);
		}
		this.items = items.getAll();
	}

	private updateVueSlimSelect(): void {
		(this.$refs.languageSelector as VueSlimSelect).setData(
			this.getLanguageSelectorData(this.languages, this.language),
		);
	}

	private getLanguageSelectorData(languages: string[], selectedLanguage: string): SlimSelectInfo[] {
		return languages.map((lang) => {
			let langText = this.$t('language.' + lang);
			if (this.defaultLanguage === lang) {
				langText += `&nbsp;&nbsp; <small>(${this.$t('language.isDefault.text')})</small>`;
			}

			const stat = this.statistics[lang];
			const statisticsPercent = stat ? `${parseFloat(stat.percent.toFixed(1))}%` : ' ';
			const statisticsTitle = stat
				? `title="${stat.missingValues} ${translate('language.statistics.missing.key')}"`
				: '';
			return {
				text: this.$t('language.' + lang) as string,
				value: lang,
				innerHTML: `<div class='language-selector-item'>
								<div>
									<span class='flag-icon flag-${FlagUtil.getFlagByLang(lang)}'>&#8203;</span>
									<span class='language-selector-item-text'>${langText}</span>
								</div>
								<span class='language-statistics-item-text' ${statisticsTitle} >${statisticsPercent}</span>
							</div>`,
				selected: lang === selectedLanguage,
			};
		});
	}

	private flagByLanguage(lang = this.language) {
		return FlagUtil.getFlagByLang(lang);
	}

	private getRedirectLanguage(): string | null {
		this.newItems = [];
		this.resetNewItems();
		if (!this.language || this.languages.indexOf(this.language) === -1) {
			const fallbackLanguage = this.getFallbackLanguage();
			if (fallbackLanguage) {
				return fallbackLanguage;
			}
		}
		return null;
	}

	private getFallbackLanguage(): string | null {
		const lastLocalizationLanguage = StorageService.getLastLocalizationLanguage(this.teamId, this.projectId);
		if (lastLocalizationLanguage && this.languages.indexOf(lastLocalizationLanguage) !== -1) {
			return lastLocalizationLanguage;
		}
		if (this.languages.length) {
			return this.languages[0];
		}
		return null;
	}

	private languageChanged(info: SlimSelectInfo): void {
		if (info.value) {
			this.redirectToLanguage(info.value);
		}
	}

	private redirectToLanguage(language: string): void {
		this.$router.push(
			{
				name: 'localizationResourceWithLanguage',
				params: {
					teamId: this.teamId,
					projectId: this.projectId,
					versionNumber: this.version,
					language,
				},
			},
			() => {
				// TODO: empty arrow function
			},
			() => {
				this.updateVueSlimSelect();
			},
		);
	}

	private goBackToLocalization(): void {
		this.$router.push({
			name: 'versionList',
			params: {
				teamId: this.teamId,
				projectId: this.projectId,
				resourceId: this.resourceId,
				redirect: this.$route.fullPath,
			},
		});
	}

	private newLanguage(): void {
		const dialogConfig: NewLanguageDialogConfig = {
			allLanguage: this.allLanguage,
			languages: this.languages,
			addMethod: async (selectedLanguage): Promise<void> => {
				if (selectedLanguage) {
					const response = await ErrorHandler.tryRequest(
						() => RespressoApi.addLanguage(this.teamId, this.projectId, this.version, selectedLanguage),
						{
							loadingScreen: true,
							loadingMessage: '#loading.processingLocalizations',
						},
					);
					if (response) {
						this.languages.push(selectedLanguage);
						this.redirectToLanguage(selectedLanguage);
					}
				}
			},
		};

		DialogBuilder.createVueDialog(NewLanguageDialog, {
			propsData: {
				dialogConfig,
			},
		});
	}

	async deleteLanguage(language = this.language) {
		await DialogBuilder.confirm('#localization.language.delete.title', '#dialog.areYouSure.simple', async () => {
			await this.executeLanguageDelete(language);
		});
	}

	@LoadingScreen()
	private async executeLanguageDelete(language: string) {
		const response = await ErrorHandler.tryRequest(() =>
			RespressoApi.deleteLanguage(this.teamId, this.projectId, this.version, language),
		);
		if (response) {
			await this.loadData(this, this.$route);
		}
		setTimeout(() => {
			this.$forceUpdate();
		}, 3500);
	}

	async setAsDefaultLanguage(language = this.language) {
		await DialogBuilder.confirm(
			'#localization.language.setAsDefault.title',
			'#dialog.areYouSure.simple',
			async () => {
				await this.executeSetAsDefaultLanguage(language);
			},
		);
	}

	@LoadingScreen()
	private async executeSetAsDefaultLanguage(language: string) {
		const response = await ErrorHandler.tryRequest(() =>
			RespressoApi.setAsDefaultLanguage(this.teamId, this.projectId, this.version, language),
		);
		if (response) {
			await this.loadData(this, this.$route);
		}
		setTimeout(() => {
			this.$forceUpdate();
		}, 3500);
	}

	protected importFiles(preSelectedFile?: IFileDescriptor): void {
		const forbiddenPaths = ['languageCode'];
		if (this.language === this.defaultLanguage) {
			forbiddenPaths.push('skipCopies');
		}
		// TODO - loadable class Hozzáadandó, ha szeretnénk, hogy a load ikon látszódjon, mert hosszú a folyamat + lsd. behívásnál
		const dialogConfig: ResourceImportDialogConfig = {
			title: translate('resource.localization.importTitle'),
			resource: this.getResourceId(),
			resourceImporterTypes: this.importPlatforms,
			accept: this.acceptFiles,
			uploadHandler: 'LocalizationImportFile',
			fileDescriptor: preSelectedFile,
			forbiddenPaths: forbiddenPaths,
			executeImport: async (uploadResult, selectedImporter, config) => {
				if (uploadResult && selectedImporter) {
					const response = await ErrorHandler.tryRequest(() => {
						return RespressoApi.importLocalization(
							this.teamId,
							this.projectId,
							this.version,
							uploadResult.fileId,
							selectedImporter,
							this.language,
							config,
						);
					});
					if (response) {
						NotificationBuilder.success('#messages.imported');
						await this.loadLanguage();
						this.resumeTour();
						return true;
					} else {
						this.resumeTour();
						return false;
					}
				} else {
					return false;
				}
			},
		};
		const controller = DialogBuilder.createVueDialog(ResourceImportDialog, {
			propsData: {
				dialogConfig,
			},
		});
		controller.closeResult.then((result) => {
			if (!result) {
				this.resumeTour();
			}
		});
	}

	private importSelectedFile(file: IFileDescriptor): boolean {
		this.importFiles(file);
		return true;
	}

	private resize(element: HTMLElement, isOpen: boolean): number {
		if (isOpen) {
			element.removeAttribute('style');
			return -1;
		} else {
			element.style.height = 'auto';
			let heightNum = 0;
			if (!element.classList.contains('tag-editor')) {
				heightNum = element.scrollHeight;
			} else {
				const valuesList = element.getElementsByClassName('ss-values');
				if (valuesList.length !== 0) {
					heightNum = valuesList[0].scrollHeight;
				}
			}
			element.style.height = heightNum + 'px';
			return heightNum;
		}
	}

	private closeDetails(event: MouseEvent | undefined): boolean | undefined {
		this.setShowDetailsPanel(false);
		event?.stopPropagation();
		return true;
	}

	private openDetails(event: MouseEvent | undefined, item: LocalizationResourceItem): boolean | undefined {
		event?.stopPropagation();
		this.selectedResourceInfo = null;
		this.selectedResource = item;
		this.setShowDetailsPanel(true);
		this.analyzeTranslation();
		this.getResourceInfo(item.id)
			.then((resourceInfo) => {
				if (resourceInfo && resourceInfo.resource.id === this.selectedResource?.id) {
					// Logger.info(`Resource info found: ${JSON.stringify(item)} -> ${JSON.stringify(resourceInfo)}`)
					this.selectedResourceInfo = resourceInfo;
					this.compareLanguage = this.getSelectedCompareLanguage();
					this.compareTranslation = this.getCompareTranslationText();
				}
			})
			.catch(console.log);
		this.$nextTick(() => {
			// Logger.info(`Should focus key in panel: ${JSON.stringify(item)} -> ${JSON.stringify(this.selectedResourceInfo)} / ${JSON.stringify(this.selectedResource)}`)
			let inputToFocus: HTMLTextAreaElement | HTMLInputElement | undefined;
			if (item.id && item.key) {
				inputToFocus = this.$refs.detailsTranslation as HTMLTextAreaElement;
			} else {
				inputToFocus = this.$refs.detailsKey as HTMLInputElement;
			}
			if (inputToFocus) {
				inputToFocus.focus();
			} else {
				console.log('Nothing to focus');
			}
		});
		return true;
	}

	private get compareLanguages(): string[] {
		return this.languages.filter((it) => {
			return (
				it !== this.language && this.selectedResourceInfo?.resource?.translations?.hasOwnProperty(it) === true
			);
		});
	}

	private getSelectedCompareLanguage(): string | undefined {
		const compareLanguages = this.compareLanguages;
		let compareLanguage = this.compareLanguage;
		if (!compareLanguage) {
			// TODO previously selected language
			if (this.defaultLanguage !== this.language) {
				compareLanguage = this.defaultLanguage;
			} else if (compareLanguages.length > 0) {
				compareLanguage = compareLanguages[0];
			}
		}
		return compareLanguage;
	}

	private getCompareSelectorData(): SlimSelectInfo[] {
		const compareLanguages = this.compareLanguages;
		const compareLanguage = this.getSelectedCompareLanguage();
		return compareLanguages.map((lang) => {
			let langText = translate('language.' + lang);
			let hasTranslation = false;
			if (this.selectedResourceInfo?.resource?.translations) {
				hasTranslation = this.selectedResourceInfo.resource.translations[lang] != undefined;
				if (!hasTranslation) {
					langText += translate('resource.localization.language.suffix.noTranslation');
				}
			}
			return {
				text: langText,
				value: lang,
				innerHTML: `<div class='language-selector-item'>
								<div>
									<span class='flag-icon flag-${FlagUtil.getFlagByLang(lang)}'>&#8203;</span>
									<span class='language-selector-item-text'>${langText}</span>
								</img>
							</div>`,
				selected: lang === compareLanguage,
				disabled: !hasTranslation,
			};
		});
	}

	private compareLanguageChanged(info: SlimSelectInfo): void {
		if (info.value) {
			this.updateCompareLanguage(info.value);
		}
	}

	private updateCompareLanguage(language: string | undefined) {
		this.compareLanguage = language;
		this.compareTranslation = this.getCompareTranslationText();
	}

	private getCompareTranslationText(): string {
		const lang = this.getSelectedCompareLanguage();
		let text: string;
		if (
			lang &&
			this.selectedResourceInfo &&
			this.selectedResourceInfo.resource?.translations &&
			this.selectedResourceInfo.resource.translations[lang]
		) {
			text = this.selectedResourceInfo.resource.translations[lang];
		} else {
			text = 'No translation...'; // TODO translate
		}
		return text;
	}

	private showInnerTooltip(event: FocusEvent) {
		const target = event.target as HTMLElement;
		const tooltip = target.querySelector('.tooltip') as HTMLElement;
		if (tooltip) {
			// When there is no translation, the tooltip does not show.
			tooltip.style.left = `${target.getBoundingClientRect().left}px`;
			tooltip.style.top = `${target.getBoundingClientRect().top - tooltip.offsetHeight - 4}px`;
			tooltip.style.maxWidth = `${target.offsetWidth}px`;
		}
	}

	private showTooltip(event: FocusEvent) {
		const target = event.target as HTMLElement;
		const tooltip = target.nextElementSibling as HTMLElement;
		if (tooltip) {
			tooltip.style.left = `${target.getBoundingClientRect().left}px`;
			tooltip.style.top = `${target.getBoundingClientRect().top - tooltip.offsetHeight - 4}px`;
		}
	}

	// TODO - remove
	private openText(event: MouseEvent, item: LocalizationResourceItem): void {
		const target = event.target as HTMLElement;
		const parent = target.parentNode as HTMLElement;

		if (parent.classList.contains('disabled')) {
			return;
		}

		const isOpen = parent.classList.contains('open');
		parent.classList.toggle('open');

		// Ha nyitásról van szó:
		// - mindent becsukunk
		if (!isOpen && parent.parentElement != null && parent.parentElement.parentElement != null) {
			const table = parent.parentElement.parentElement.children;
			const rows = table[1].children;
			for (let index = 1; index < rows.length; index++) {
				const row = rows[index];
				row.classList.remove('open');
				(row as HTMLDivElement).style.height = '';
				(row as HTMLDivElement).style.maxHeight = '';
				(row as HTMLDivElement).style.maxHeight = 76 + 'px';
				for (let index = 0; index < row.children.length; index++) {
					const element = row.children[index];
					if (index === 0 && element != null) {
						this.resize(element as HTMLElement, true);
					}
					if (element.firstChild instanceof HTMLTextAreaElement) {
						const textarea = element.firstChild as HTMLTextAreaElement;
						this.resize(textarea, true);
					}
					if (
						element.firstElementChild != null &&
						element.firstElementChild.classList.contains('tag-editor')
					) {
						const tageditor = element.firstChild as HTMLDivElement;
						this.resize(tageditor, true);
					}
				}
			}
		}
		// - átállítjuk az utolsó nyitott sort
		if (!isOpen) {
			this.items.forEach((element) => {
				element.lastOpened = false;
			});
			item.lastOpened = true;
		}

		// Az adott sor összes celláját átállítjuk
		let maxHeight = 0;
		let height = 0;
		for (let index = 0; index < parent.children.length; index++) {
			const element = parent.children[index];
			if (element.firstChild instanceof HTMLTextAreaElement) {
				const textarea = element.firstChild as HTMLTextAreaElement;
				height = this.resize(textarea, isOpen);
			}
			if (element.firstElementChild != null && element.firstElementChild.classList.contains('tag-editor')) {
				const tageditor = element.firstChild as HTMLDivElement;
				const tagheight = this.resize(tageditor, isOpen);
				height = tagheight + 15;
			}
			if (height > maxHeight) {
				maxHeight = height;
			}
		}

		// végül magát a sort állítjuk át, ha nyitva volt, akkor becsukjuk, ha csukva, akkor kinyitjuk
		if (isOpen) {
			parent.style.height = '';
			parent.style.maxHeight = '';
			parent.style.maxHeight = 76 + 'px';
			parent.classList.remove('open');
		} else {
			parent.style.height = maxHeight + 'px';
			parent.style.maxHeight = 'unset';
			parent.classList.add('open');
		}
	}

	sortBy(sortKey: string): void {
		this.sortKey = sortKey;
		if (this.sortOrder === SortOrder.ASC) {
			this.sortOrder = SortOrder.DESC;
		} else if (this.sortOrder === SortOrder.DESC) {
			this.sortOrder = SortOrder.ASC;
		}
		this.updateSortedItems();
	}

	addingDisabledRows(): void {
		const rows = this.$el.getElementsByClassName('resource-list-content');

		for (let index = 0; index < rows.length; index++) {
			const row = rows[index];
			let disabled = true;
			if (row != undefined) {
				// megkeressük a legmagasabb sort
				for (let index = 0; index < row.children.length - 1; index++) {
					const div = row.children[index];
					const textarea = div.children[0];
					if (textarea != undefined) {
						let scrollHeight = (textarea as HTMLElement).scrollHeight;
						// a tag listázónál csak a popup nélküli rész magasságát vizsgáljuk meg
						if (textarea.classList.contains('tag-editor') && textarea.children.length !== 0) {
							const main = textarea.children;
							if (main[1]) {
								const multi = main[1].children;
								const valuesList = multi[0].children;
								const values = valuesList[0];
								scrollHeight = (values as HTMLDivElement).scrollHeight;
							}
						}
						if (scrollHeight > 60) {
							disabled = false;
						}
					}
				}
			}
			if (!disabled) {
				row.classList.remove('disabled');
			}
		}
	}

	variableMatchers: Map<string, RegExp> = new Map();
	potentialVariableMatchers: Map<string, RegExp> = new Map();
	usedVariableKeys: Set<string> = new Set();
	suggestedVariables: SuggestedVariable[] = [];

	private getVariableMatcher(key: string) {
		let cached = this.variableMatchers.get(key);
		if (!cached) {
			const regexString = `{{\\s*${escapeStringRegexp(key)}\\s*}}`;
			cached = new RegExp(regexString);
			this.variableMatchers.set(key, cached);
		}
		return cached;
	}

	private getPotentialVariableMatcher(regex: string) {
		let cached = this.potentialVariableMatchers.get(regex);
		if (!cached) {
			try {
				cached = new RegExp(regex, 'g');
				this.potentialVariableMatchers.set(regex, cached);
			} catch (e) {
				this.hasRegexError = true;
				Logger.warn(`Failed to create variable matcher regex. (${e})`);
			}
		}
		return cached;
	}

	private analyzeTranslation() {
		if (this.selectedResource) {
			const text = this.selectedResource.value || '';
			const variablesKeys: Set<string> = new Set();
			this.selectedResource.variables?.forEach((it) => variablesKeys.add(it.key));
			const usedVariables = this.selectedResource.variables?.filter((variable) => {
				return text && this.getVariableMatcher(variable.key).test(text); // Maybe exec is better, as test() may return bad values when called multiple times
			});
			if (usedVariables) {
				this.usedVariableKeys = new Set(usedVariables.map((it) => it.key));
			} else {
				this.usedVariableKeys = new Set<string>();
			}
			const suggestedVariables: SuggestedVariable[] = [];
			let regexes: LocalizationVariableRegex[] = [];
			if (this.remoteVariableMatchers) {
				regexes = this.remoteVariableMatchers;
			}
			regexes.forEach((regex) => {
				const matcher = this.getPotentialVariableMatcher(regex.regex);
				if (matcher) {
					[...text.matchAll(matcher)].forEach((matches) => {
						let matchedVariable: string | undefined = undefined;
						if (regex.variableGroup != undefined && matches[regex.variableGroup] != undefined) {
							matchedVariable = matches[regex.variableGroup];
						}
						if (matchedVariable != undefined) {
							const matchedText = matchedVariable;
							let keyOrIndex = undefined;
							if (regex.keyOrIndexGroup != undefined && matches[regex.keyOrIndexGroup] != undefined) {
								keyOrIndex = matches[regex.keyOrIndexGroup];
							}
							let position: number | undefined;
							let positionText: string | undefined;
							if (regex.positionGroup != undefined && matches[regex.positionGroup] != undefined) {
								positionText = matches[regex.positionGroup];
								position = this.tryParseInt(matches[regex.positionGroup]);
							}
							let index: number | undefined = undefined;
							let key: string | undefined = undefined;
							if (keyOrIndex != undefined) {
								index = this.tryParseInt(keyOrIndex);
								if (index == undefined) {
									key = keyOrIndex;
								}
							}
							let format: string;
							if (regex.ieeeFormatGroup != undefined && matches[regex.ieeeFormatGroup] != undefined) {
								format = matches[regex.ieeeFormatGroup];
							} else {
								if (index != undefined) {
									format = `%${index + 1}$s`;
								} else if (position != undefined) {
									format = `%${position}$s`;
								} else {
									format = '%s';
								}
							}
							// Only suggest variables that does not exist!
							if ((!key || !variablesKeys.has(key)) && !format.endsWith('%')) {
								const formatOverrides: { [key: string]: string } = {};
								if (regex.overrideFormats != undefined && regex.overrideFormats.length > 0) {
									let variableFormat = matchedText;
									let placeholder: string | undefined = undefined;
									if (keyOrIndex != undefined) {
										if (index != undefined) {
											placeholder = '{{index}}';
										} else {
											placeholder = '{{key}}';
										}
										variableFormat = variableFormat.replace(keyOrIndex, placeholder);
									} else if (position != undefined && positionText != undefined) {
										placeholder = '{{position}}';
										variableFormat = variableFormat.replace(positionText, placeholder);
									}
									regex.overrideFormats.forEach((format) => {
										formatOverrides[format] = variableFormat;
									});
								}
								let suggestedKey: string | undefined;
								if (index != undefined) {
									suggestedKey = `var_${index + 1}`;
								} else if (position != undefined) {
									suggestedKey = `var_${position}`;
								} else if (key) {
									suggestedKey = key;
								} else {
									suggestedKey = 'var';
								}
								let usedKey = suggestedKey;
								let conflictIndex = 2;
								while (variablesKeys.has(usedKey)) {
									usedKey = `${suggestedKey}_${conflictIndex++}`;
								}
								variablesKeys.add(usedKey);
								const suggestedVariable: SuggestedVariable = {
									key: usedKey,
									format: format,
									formatOverrides: formatOverrides,
									currentForm: matchedText,
								};
								suggestedVariables.push(suggestedVariable);
							}
						}
					});
				}
			});
			this.suggestedVariables = suggestedVariables;
		}
	}

	private tryParseInt(text: string) {
		try {
			const num = Number.parseInt(text, 10);
			if (Number.isFinite(num) && Number.isInteger(num)) {
				return num;
			}
		} catch (e) {
			// IGNORE
		}
		return undefined;
	}

	private addSuggestedVariable(variable: SuggestedVariable) {
		if (this.selectedResource) {
			if (variable.currentForm && this.selectedResource.value) {
				this.selectedResource.value = this.selectedResource.value.replace(
					variable.currentForm,
					`{{ ${variable.key} }}`,
				);
			}
			const vars = this.selectedResource.variables || [];
			vars.push({
				key: variable.key,
				format: variable.format,
				formatOverrides: variable.formatOverrides,
				description: variable.description,
			});
			this.selectedResource.variables = vars;
			this.selectedResource = this.selectedResource;
			this.analyzeTranslation();
			this.itemChanged(this.selectedResource);
		}
	}

	private removeVariable(variable: LocalizationVariable) {
		if (this.selectedResource && this.selectedResource.variables) {
			this.selectedResource.variables = this.selectedResource.variables.filter((it) => it.key !== variable.key);
			this.selectedResource = this.selectedResource;
			this.analyzeTranslation();
			this.itemChanged(this.selectedResource);
		}
	}

	private async addVariable() {
		await this.addOrEditVariable();
	}

	private async editVariable(variable: LocalizationVariable) {
		await this.addOrEditVariable(variable);
	}

	private async addOrEditVariable(variable?: LocalizationVariable) {
		if (this.selectedResource) {
			const dialogConfig = {
				original: variable,
				otherVariables: this.selectedResource.variables || [],
				formatRegex: this.variableFormat,
			};
			const controller = DialogBuilder.createVueDialog(AddOrEditLocalizationVariableDialog, {
				propsData: {
					dialogConfig,
				},
			});
			this.addOrEditVariableDialogController = controller;
			try {
				const dialogResult = await controller.closeResult;
				if (dialogResult.result) {
					let index: number | undefined = undefined;
					if (dialogResult.original) {
						const key = dialogResult.original.key;
						index = this.selectedResource.variables?.findIndex((it) => it.key == key);
						if (dialogResult.result.replaceOldKey && this.selectedResource.value) {
							this.selectedResource.value = this.selectedResource.value
								.split(this.getVariableMatcher(dialogResult.result.replaceOldKey))
								.join(`{{ ${dialogResult.result.key} }}`);
						}
					}
					if (
						index != undefined &&
						this.selectedResource.variables &&
						this.selectedResource.variables.length > index
					) {
						this.selectedResource.variables[index] = dialogResult.result;
					} else {
						const vars = this.selectedResource.variables || [];
						vars.push(dialogResult.result);
						this.selectedResource.variables = vars;
					}
					this.selectedResource = this.selectedResource;
					this.analyzeTranslation();
					this.itemChanged(this.selectedResource);
				}
			} finally {
				this.addOrEditVariableDialogController = undefined;
			}
		} else {
			console.log('No resource data');
		}
	}

	private onVariableClicked(variable: LocalizationVariable) {
		copyTextToClipboard(`{{ ${variable.key} }}`).then((success: boolean) => {
			if (success) {
				NotificationBuilder.success('#resource.localization.variable.action.clipboard.copied');
			} else {
				NotificationBuilder.error('#resource.localization.variable.action.clipboard.copyFailed');
			}
		});
	}

	private onDetailedTranslationChanged(item: ResourceItem) {
		this.itemChanged(item);
		this.analyzeTranslation();
	}

	protected onEscapePressed(): boolean {
		if (this.addOrEditVariableDialogController) {
			this.addOrEditVariableDialogController.close({});
			return true;
		} else if (this.showDetailsPanel) {
			this.closeDetails(undefined);
			return true;
		} else if (this) {
			return super.onEscapePressed();
		} else {
			return false;
		}
	}

	private detailsTagAdded(item: ResourceItem, tag: string): void {
		this.itemChanged(item);
		(this.$refs.resourceList as ResourceList).tagAdded(item, tag);
	}

	private detailsTagRemoved(item: ResourceItem, tag: string): void {
		this.itemChanged(item);
		(this.$refs.resourceList as ResourceList).tagRemoved(item, tag);
	}

	private async editConfig() {
		if (this.isModified()) {
			NotificationBuilder.error('#resource.localization.config.editConfigDialog.savePendingChanges');
			return;
		}
		const response = await ErrorHandler.tryRequest(
			() => RespressoApi.getLocalizationConfig(this.teamId, this.projectId, this.version),
			{
				loadingScreen: true,
			},
		);
		if (response) {
			const dialogConfig: StructureEditorDialogConfig = {
				title: 'resource.localization.config.editConfigDialog.title',
				data: response.data,
				definitions: response.definition,
				editablePaths: response.editablePaths,
			};
			const controller = DialogBuilder.createVueDialog(StructureEditorDialog, {
				propsData: {
					dialogConfig,
				},
			});
			const result = await controller.closeResult;
			if (result && result.useResult) {
				const response = await ErrorHandler.tryRequest(
					() => RespressoApi.setLocalizationConfig(this.teamId, this.projectId, this.version, result.data),
					{
						loadingScreen: true,
					},
				);
				// this.$router.go(0); // Fully reload page..
			}
		}
	}

	private async startMerge() {
		if (this.isModified()) {
			NotificationBuilder.error('#resource.localization.config.editConfigDialog.savePendingChanges');
			return;
		}
		const response = await ErrorHandler.tryRequest(
			() => RespressoApi.getResourceVersions(this.teamId, this.projectId, this.resourceId),
			{
				loadingScreen: true,
			},
		);
		if (response) {
			const dialogConfig: StartResourceMergeDialogConfig = {
				teamId: this.teamId,
				projectId: this.projectId,
				category: this.resourceId,
				currentVersion: this.version,
				versions: response,
			};
			const controller = DialogBuilder.createVueDialog(StartResourceMergeDialog, {
				propsData: {
					dialogConfig,
				},
			});
		}
	}
}

interface SuggestedVariable extends LocalizationVariable {
	currentForm?: string;
}

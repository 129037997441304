import Vue from 'vue';
import Component from 'vue-class-component';
import {ProjectMeta, ProjectRole, ProjectSubscriptionState, TeamMeta, TeamRole} from 'respresso';
import {teamModule} from '../store/modules/team/index';
import ErrorHandler from './error-handler';
import RespressoApi, {GetServerLicenseResponse} from '../api/respresso-api';

@Component
export class AccessServiceImpl extends Vue {
	private static teamRoleMap: { [index in TeamRole]: TeamRole[] } = {
		OUTSIDER: ['OUTSIDER'],
        MEMBER: ['MEMBER', 'OUTSIDER'],
		ADMIN: ['ADMIN', 'MEMBER', 'OUTSIDER'],
		OWNER: ['OWNER', 'ADMIN', 'MEMBER', 'OUTSIDER'],
	};

	private static projectRoleMap: { [index in ProjectRole]: ProjectRole[] } = {
		VIEWER: ['VIEWER'],
		MEMBER: ['MEMBER', 'VIEWER'],
		EDITOR: ['EDITOR', 'MEMBER', 'VIEWER'],
		ADMIN: ['ADMIN', 'EDITOR', 'MEMBER', 'VIEWER'],
	};

	private static activeProjectSubscriptionStates: ProjectSubscriptionState[] = ['ACTIVE', 'TRIALING', 'PAST_DUE'];

	public static serverLicense?: GetServerLicenseResponse = undefined;

	public async loadServerLicense() {
		const response = await ErrorHandler.tryRequest(() => RespressoApi.getServerLicense());
		if (response) {
			AccessServiceImpl.serverLicense = response;
		}
	}

	public getTeamRole(): TeamRole | undefined {
		if (teamModule.team) {
			return teamModule.team.role;
		}
	}

	public getProjectRole(): ProjectRole | null | undefined {
		if (teamModule.project) {
			return teamModule.project.role;
		}
	}

	public includesTeamRole(role: TeamRole, desired: TeamRole) {
		return AccessServiceImpl.teamRoleMap[role].indexOf(desired) >= 0;
	}

	public includesProjectRole(role: ProjectRole, desired: ProjectRole) {
		return AccessServiceImpl.projectRoleMap[role].indexOf(desired) >= 0;
	}

	public hasExplicitProjectRole(role: ProjectRole, project?: ProjectMeta | null) {
		if (project && project.role) {
			return AccessService.includesProjectRole(project.role, role);
		}
		return false;
	}

	public hasActiveSubscription(project?: ProjectMeta | null): boolean {
		if (project && project.subscription && project.subscription.state) {
			return AccessServiceImpl.activeProjectSubscriptionStates.includes(project.subscription.state);
		}
		return false;
	}

	public hasProjectRole(role: ProjectRole, project?: ProjectMeta | null) {
		if (this.hasActiveSubscription(project)) {
			return this.hasExplicitProjectRole(role, project);
		} else {
			if (this.hasExplicitProjectRole('VIEWER', project)) {
				return role === 'VIEWER';
			} else {
				return false;
			}
		}
	}


    public isOutsider(team?: TeamMeta | null) {
        return AccessService.hasTeamRole("OUTSIDER", team) && !AccessService.hasTeamRole("MEMBER", team);
    }

    public hasTeamRole(role: TeamRole, team?: TeamMeta | null) {
        if (team && team.role) {
            return AccessService.includesTeamRole(team.role, role);
        }
        return false;
    }

	public isSuspendedTeam(team?: TeamMeta | null): boolean {
		return AccessServiceImpl.hasServerLicenseExpired();
	}

	private static hasServerLicenseExpired() {
		const expiration = AccessService.getServerLicenseExpiration();
		if (expiration != undefined) {
			return expiration <= Date.now();
		}
		return false;
	}

	public getServerLicenseExpiration(): number | undefined {
		if (AccessServiceImpl.serverLicense == undefined) {
			this.loadServerLicense().catch((reason) => console.error(reason));
		}
		return AccessServiceImpl.serverLicense?.expires;
	}

	public isSuspendedProject(project?: ProjectMeta | null): boolean {
		return AccessServiceImpl.hasServerLicenseExpired();
	}

	public canFlow(project?: ProjectMeta | null) {
		return !this.isSuspendedProject(project) && this.hasProjectRole('ADMIN', project);
	}

	public canEditResourceCategoryConfig(project?: ProjectMeta | null) {
		return !this.isSuspendedProject(project) && this.hasProjectRole('EDITOR', project);
	}

	public canVersionControl(project?: ProjectMeta | null) {
		return !this.isSuspendedProject(project) && this.hasProjectRole('EDITOR', project);
	}

	public canImportResources(project?: ProjectMeta | null) {
		return !this.isSuspendedProject(project) && this.hasProjectRole('EDITOR', project);
	}

	public canUploadResources(project?: ProjectMeta | null) {
		return !this.isSuspendedProject(project) && this.hasProjectRole('EDITOR', project);
	}

	public canAddResource(project?: ProjectMeta | null) {
		return !this.isSuspendedProject(project) && this.hasProjectRole('EDITOR', project);
	}

	public canDeleteResource(project?: ProjectMeta | null) {
		return !this.isSuspendedProject(project) && this.hasProjectRole('EDITOR', project);
	}

	public canModifyRestrictedResourceData(project?: ProjectMeta | null) {
		return !this.isSuspendedProject(project) && this.hasProjectRole('EDITOR', project);
	}

	public canModifyResource(project?: ProjectMeta | null) {
		return !this.isSuspendedProject(project) && this.hasProjectRole('MEMBER', project);
	}

	public canDownload(project?: ProjectMeta | null) {
		return !this.isSuspendedProject(project) && this.hasProjectRole('VIEWER', project);
	}

    public canEditProjectDetails(project?: ProjectMeta | null) {
        return !this.isSuspendedProject(project) && this.hasExplicitProjectRole('ADMIN', project);
    }

    public canUseIntegrations(project?: ProjectMeta | null) {
        return this.canImportResources(project) && this.canUploadResources(project) && this.canAddResource(project) && this.canModifyRestrictedResourceData(project);
    }

    public canEditProjectPermissions(project?: ProjectMeta | null) {
        return !this.isSuspendedProject(project) && this.hasExplicitProjectRole('ADMIN', project);
    }


    public canEditTeamDetails(team?: TeamMeta | null) {
        return !this.isSuspendedTeam(team) && this.hasTeamRole('ADMIN', team);
    }

    public canEditTeamPermissions(team?: TeamMeta | null) {
        return !this.isSuspendedTeam(team) && this.hasTeamRole('ADMIN', team);
    }

	public canEditTeamSubscriptions(team?: TeamMeta | null) {
		return !this.isSuspendedTeam(team) && this.hasTeamRole('OWNER', team);
	}
}

const AccessService = new AccessServiceImpl();
export default AccessService;

import {RespressoError} from './http-service';
import {RespressoErrorHandlerAdapter} from './error-handler';
import {app, translate} from '../main';
import {DialogBuilder, NotificationBuilder} from '../components/common/dialog/dialog';
import UserService from './user-service';
import StorageService from './storage-service';
import {teamModule} from '../store/modules/team/index';

export class DefaultRespressoErrorHandlerAdapter implements RespressoErrorHandlerAdapter {
	private nonBlockingErrors = [
		"tokenValidationFilter.notFound.user",
		"tokenValidationFilter.notFound.refreshToken",
		"teamService.denied.team.read",
		"userService.user.notFound",
	]
	async handle(error: RespressoError<any>, errorMsg?: string): Promise<boolean> {
		let message: string;
		//TODO common error type handling
		if (error.type === 'invalid_token') {
			//TODO
		} else {
			if (errorMsg) {
				message = errorMsg;
			} else {
				if (error.key) {
					const trKey = `serverError.respressoError.${error.key}`;
					const trMessage = translate(`serverError.respressoError.${error.key}`, error.params);
					if (trKey === trMessage) {
						message = error.key;
					} else {
						message = trMessage;
					}
				} else {
					message = '#serverError.unknownError';
				}
			}
			const key = error.key || "";

			if(this.nonBlockingErrors.includes(key)) {
				NotificationBuilder.error(key);
			} else {
				DialogBuilder.alert('#serverError.respressoError.title', message);
			}

			if (
				error.type === 'TeamServiceException' &&
				error.key &&
				error.key.startsWith('teamService.project.notFound')
			) {
				const currentTeam = app.$store.state.team ? app.$store.state.team.id : null;
				if (currentTeam !== null) {
					await UserService.loadTeam(currentTeam);
					app.$router.push({ name: 'dashboard', params: { teamId: currentTeam } });
				} else {
					await UserService.refreshData();
					app.$router.push({ name: 'teams' });
				}
			} else if (
				error.type === 'ResourceException' &&
				error.key &&
				error.key.indexOf('resources.version.number') !== -1
			) {
				app.$router.push({
					name: 'project',
					params: {
						teamId: app.$route.params.teamId,
						projectId: app.$route.params.projectId,
					},
				});
			} else if (
				error.type === 'AuthenticationException' &&
				error.key &&
				error.key === 'teamService.denied.team.read'
			) {
				if (teamModule && teamModule.team) {
					teamModule.removeTeam();
				}
				StorageService.removeTeam();
				app.$router.push({ name: 'teams' });
			} else if (
				error.type === 'AuthenticationException' &&
				error.key &&
				error.key === 'tokenValidationFilter.notFound.user'
			) {
				await UserService.logout();
				app.$router.replace({ name: 'login' });
			}
		}
		return true;
	}

	order(): number {
		return 100;
	}

	name(): string {
		return 'DefaultRespressoErrorHandlerAdapter';
	}
}

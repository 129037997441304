import Vue from 'vue';
import Component from 'vue-class-component';
import template from './start-zeplin-integration.html';
import './start-zeplin-integration.scss';
import {NextFunction, Route} from 'vue-router';
import LoadingScreen from '../../../../decorators/loading-screen';
import ErrorHandler from '../../../../services/error-handler';
import RespressoApi, {IntegrationProjectInfo, IntegrationTeamInfo} from '../../../../api/respresso-api';
import {SlimSelectOption} from '../../../common/vue-slim-select/vue-slim-select';
import StorageService from '../../../../services/storage-service';

@Component({
	template: template,
})
export default class StartZeplinIntegration extends Vue {
	private connectionId?: string = '';
	private teams: IntegrationTeamInfo[] = [];
	private selectedTeam?: IntegrationTeamInfo = undefined;
	private selectedProject?: IntegrationProjectInfo = undefined;

	beforeRouteEnter(to: Route, from: Route, next: NextFunction): void {
		next((vm) => {
			const res = vm as StartZeplinIntegration;
			res.loadData(to);
		});
	}

	@LoadingScreen({ showImmediately: true })
	private async loadData(to: Route): Promise<void> {
		let fallback = false;
		let teamId: string | null = to.query.team as string;
		if (!teamId) {
			teamId = StorageService.getLastIntegrationTeam();
		}
		let projectId: string | null = to.query.project as string;
		if (!projectId) {
			projectId = StorageService.getLastIntegrationProject();
			fallback = true;
		}
		this.connectionId = to.query.connection as string;
		const info = await ErrorHandler.tryRequest(() => RespressoApi.getIntegrationInfo());
		if (info && info.teams && info.teams.length > 0) {
			this.teams = info?.teams;
			if (teamId) {
				const selectedTeam = this.teams.find((value) => value.id === teamId || value.name == teamId);
				if (selectedTeam) {
					this.selectedTeam = selectedTeam;
				}
			}
			if (!this.selectedTeam) {
				if (projectId) {
					const selectedTeam = this.teams.find(
						(team) =>
							team.projects &&
							team.projects.some((project) => project.id === projectId || project.name == projectId),
					);
					if (selectedTeam) {
						this.selectedTeam = selectedTeam;
					}
				}
			}
			if (!this.selectedTeam) {
				this.selectedTeam = this.teams[0];
			}
		} else {
			alert(
				'You have no permission on any project to use an integration. It requires Project Admin or Project Editor role.',
			); // TODO localize & better UX
			// this.$router.back();
			return;
		}
		if (this.selectedTeam && this.selectedTeam.projects && this.selectedTeam.projects.length > 0) {
			if (projectId) {
				const selectedProject = this.selectedTeam.projects.find(
					(value) => value.id === projectId || value.name === projectId,
				);
				if (selectedProject) {
					this.selectedProject = selectedProject;
				}
			}
			if (!this.selectedProject) {
				this.selectedProject = this.selectedTeam.projects[0];
			}
		}
		if (!fallback && this.hasAllData()) {
			this.openIntegration();
		} else {
			this.$forceUpdate();
		}
	}

	private openIntegration() {
		if (this.hasAllData()) {
			this.$router.replace({
				name: 'zeplinIntegration',
				params: { teamId: this.teamName()!, projectId: this.projectName()!, connectionId: this.connectionId! },
			});
		} else {
			alert('Missing selection');
		}
	}

	private createTeamSelectOptions(): SlimSelectOption[] {
		if (this.teams) {
			return this.teams.map((team) => {
				const option: SlimSelectOption = {
					text: team.title,
					value: team.id,
					selected: this.selectedTeam ? this.selectedTeam.id === team.id : false,
				};
				return option;
			});
		}
		return [];
	}

	private teamSelectionChanged(event: any) {
		const id = event.value;
		if (id && this.teams) {
			const selection = this.teams.find((team) => team.id === id);
			if (selection && (!this.selectedTeam || this.selectedTeam.id !== selection.id)) {
				this.selectedTeam = selection;
				this.selectedProject = this.selectedTeam.projects[0];
				this.$forceUpdate();
			}
		}
	}

	private createProjectSelectOptions(): SlimSelectOption[] {
		if (this.selectedTeam && this.selectedTeam.projects) {
			return this.selectedTeam.projects.map((project) => {
				const option: SlimSelectOption = {
					text: project.title,
					value: project.id,
					selected: this.selectedProject ? this.selectedProject.id === project.id : false,
				};
				return option;
			});
		}
		return [];
	}

	private projectSelectionChanged(event: any) {
		const id = event.value;
		if (id && this.selectedTeam && this.selectedTeam.projects) {
			const selection = this.selectedTeam.projects.find((project) => project.id === id);
			if (selection && (!this.selectedProject || this.selectedProject.id !== selection.id)) {
				this.selectedProject = selection;
				this.$forceUpdate();
				return;
			}
		}
	}

	private hasAllData(): boolean {
		return this.hasProject() && !!this.connectionId;
	}

	private projectName(): string | undefined {
		if (this.selectedProject && this.selectedProject.name) {
			return this.selectedProject.name;
		} else {
			return undefined;
		}
	}

	private teamName(): string | undefined {
		if (this.selectedTeam && this.selectedTeam.name) {
			return this.selectedTeam.name;
		} else {
			return undefined;
		}
	}

	private hasProject(): boolean {
		return this.hasTeam() && !!this.projectName();
	}

	private hasTeam(): boolean {
		return !!this.teamName();
	}
}

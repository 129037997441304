import Vue from 'vue';
import template from './add-or-edit-localization-variable-dialog.html';
import Component from 'vue-class-component';
import DialogTemplate from '../dialog/dialogs/dialog-template/dialog-template';
import {IDialogComponent} from '@ponte/dialog-vue';
import {IDialog} from '@ponte/dialog';
import {Prop, Provide} from 'vue-property-decorator';
import {LocalizationVariable} from 'respresso';
import {translate} from '../../../main';
import {SlimSelectInfo} from '../vue-slim-select/vue-slim-select';
import './add-or-edit-localization-variable-dialog.scss';

export interface AddOrEditLocalizationVariableDialogConfig {
	original?: LocalizationVariable;
	otherVariables: LocalizationVariable[];
	formatRegex: string;
}

export interface AddOrEditLocalizationVariableDialogResult {
	original?: LocalizationVariable;
	result?: LocalizationVariable;
}

@Component({
	components: {
		'dialog-template': DialogTemplate,
	},
	template: template,
})
export default class AddOrEditLocalizationVariableDialog
	extends Vue
	implements IDialogComponent<AddOrEditLocalizationVariableDialogResult>
{
	@Prop() dialogConfig!: AddOrEditLocalizationVariableDialogConfig;

	@Provide()
	variable: LocalizationVariable | null = null;

	@Provide()
	forbiddenVariableNames: Set<string> = new Set<string>();

	@Provide()
	keyError: string | null = null;

	@Provide()
	refactorKey = true;

	@Provide()
	formatError: string | null = null;

	@Provide()
	hasError = false;

	@Provide()
	newFormatTemplate = '';

	@Provide()
	newFormat: string | null = null;

	@Provide()
	formatOverrides: { format: string; template: string }[] = [];

	dialog!: IDialog<AddOrEditLocalizationVariableDialogResult>;

	public beforeMount(): void {
		if (this.dialogConfig.otherVariables) {
			this.forbiddenVariableNames = new Set();
			this.dialogConfig.otherVariables.forEach((it) => this.forbiddenVariableNames.add(it.key));
		}
		const original = this.dialogConfig.original;
		if (original != undefined) {
			this.forbiddenVariableNames.delete(original.key);
			// Make a copy to enable discard
			this.variable = {
				key: original.key,
				format: original.format,
				description: original.description,
			};
			if (original.formatOverrides) {
				for (const key in original.formatOverrides) {
					if (original.formatOverrides.hasOwnProperty(key)) {
						this.formatOverrides.push({
							format: key,
							template: original.formatOverrides[key],
						});
					}
				}
			}
		} else {
			let suggestedKey = 'var_1';
			let conflict_index = 2;
			while (this.forbiddenVariableNames.has(suggestedKey)) {
				suggestedKey = `var_${conflict_index++}`;
			}
			this.variable = {
				key: suggestedKey,
				format: '%s',
			};
		}
		this.validate();
	}

	private get originalKey(): string | undefined {
		const original = this.dialogConfig.original;
		if (original != undefined) {
			return original.key;
		}
		return undefined;
	}

	private validate() {
		if (this.variable != null && this.variable.key) {
			if (this.forbiddenVariableNames.has(this.variable.key)) {
				this.keyError = translate('resource.localization.variable.addOrEdit.key.error.alreadyUsed', {
					key: this.variable.key,
				});
			} else if (!new RegExp('^[a-zA-Z]([._:-]?[a-zA-Z0-9]+)*$').test(this.variable.key)) {
				this.keyError = translate('resource.localization.variable.addOrEdit.key.error.invalidFormat');
			} else {
				this.keyError = null;
			}
		} else {
			this.keyError = translate('resource.localization.variable.addOrEdit.key.error.required');
		}
		if (this.variable != null && !new RegExp(this.dialogConfig.formatRegex, 'g').test(this.variable.format)) {
			this.formatError = translate('resource.localization.variable.addOrEdit.format.error.invalidFormat');
		} else {
			this.formatError = null;
		}
		this.variable = this.variable;
	}

	private getFormatSelectorData(): SlimSelectInfo[] {
		const formats = ['web', 'json', 'android', 'ios'].filter(
			(it) => this.formatOverrides.find((it2) => it2.format === it) == undefined,
		);
		if (!this.newFormat && formats.length > 0) {
			this.newFormat = formats[0];
		}
		if (this.newFormat && formats.indexOf(this.newFormat) < 0) {
			formats.push(this.newFormat);
		}
		return formats.map((format) => {
			return {
				text: format, // TODO translate
				value: format,
				selected: format === this.newFormat,
			};
		});
	}

	private formatSelectorChanged(info: SlimSelectInfo): void {
		if (info.value) {
			this.newFormat = info.value;
		}
	}

	private addNewFormat() {
		if (this.newFormat && this.newFormatTemplate) {
			this.removeFormat(this.newFormat);
			this.formatOverrides.push({
				format: this.newFormat,
				template: this.newFormatTemplate,
			});
			this.newFormat = null;
			this.newFormatTemplate = '';
			this.$forceUpdate();
		}
	}

	private removeFormat(format: string) {
		if (format) {
			this.formatOverrides = this.formatOverrides.filter((it) => it.format !== format);
			this.$forceUpdate();
		}
	}

	public closeDialog(result: AddOrEditLocalizationVariableDialogResult): void {
		if (this.dialog.dialogController) this.dialog.dialogController.close(result);
	}

	public saveAndClose(): void {
		if (this.variable == null) {
			this.discard();
			return;
		}
		const oldKey = this.originalKey;
		if (this.refactorKey && oldKey) {
			this.variable.replaceOldKey = oldKey;
		}
		if (this.formatOverrides && this.formatOverrides.length > 0) {
			const o: { [format: string]: string } = {};
			this.formatOverrides.forEach((it) => (o[it.format] = it.template));
			this.variable.formatOverrides = o;
		} else {
			this.variable.formatOverrides = {};
		}
		this.closeDialog({
			original: this.dialogConfig.original,
			result: this.variable,
		});
	}

	public discard(): void {
		this.closeDialog({
			original: this.dialogConfig.original,
			result: undefined,
		});
	}
}

import {TeamInfo, User} from 'respresso';

enum StorageKey {
	USER = 'user',
	TEAM = 'team',
	LAST_LOCALIZATION_LANGUAGE = 'lastLocalizationLanguage',
	LAST_OPENED_VERSION = 'lastOpenedVersion',
	LAST_INTEGRATION_TEAM = 'lastIntegrationProject',
	LAST_INTEGRATION_PROJECT = 'lastIntegrationProject',
	SIGNED_UP_HERE = 'isSignUp',
	IMAGE_CONVERTER_LAST_USAGE = 'iclu',
	GET_STARTED_HIDDEN = 'getStartedHidden',
	DOWNLOAD_COUNT = 'downloadCount',
	TOUR = 'tour',
}

export class StorageServiceImpl {
	public setUser(user: User): void {
		localStorage.setItem(StorageKey.USER, JSON.stringify(user));
	}

	public getUser(): User | null {
		const userString = localStorage.getItem(StorageKey.USER);
		if (userString) {
			return JSON.parse(userString);
		}
		return null;
	}

	public removeUser(): void {
		localStorage.removeItem(StorageKey.USER);
	}

	public setTeam(team: TeamInfo): void {
		localStorage.setItem(StorageKey.TEAM, JSON.stringify(team));
	}

	public getTeam(): TeamInfo | null {
		const team = localStorage.getItem(StorageKey.TEAM);
		if (team) {
			return JSON.parse(team);
		}
		return null;
	}

	public removeTeam(): void {
		localStorage.removeItem(StorageKey.TEAM);
	}

	public setLastLocalizationLanguage(teamId: string, projectId: string, language: string): void {
		localStorage.setItem(`${StorageKey.LAST_LOCALIZATION_LANGUAGE}_${teamId}_${projectId}`, language);
	}

	public getLastLocalizationLanguage(teamId: string, projectId: string): string | null {
		return localStorage.getItem(`${StorageKey.LAST_LOCALIZATION_LANGUAGE}_${teamId}_${projectId}`);
	}

	public getLastOpenedVersion(teamId: string, projectId: string, category: string): string | null {
		return localStorage.getItem(`${StorageKey.LAST_OPENED_VERSION}_${teamId}_${projectId}_${category}`);
	}

	public setLastOpenedVersion(teamId: string, projectId: string, category: string, version: string): void {
		localStorage.setItem(`${StorageKey.LAST_OPENED_VERSION}_${teamId}_${projectId}_${category}`, version);
	}

	public getTourState(type: string, category: string): string | null {
		return localStorage.getItem(`${StorageKey.TOUR}_${type}_${category}`);
	}

	public setTourState(type: string, category: string, state: string): void {
		localStorage.setItem(`${StorageKey.TOUR}_${type}_${category}`, state);
	}

	public removeLastLocalizationLanguage(): void {
		localStorage.removeItem(StorageKey.LAST_LOCALIZATION_LANGUAGE);
	}

	public getLastIntegrationTeam(): string | null {
		return localStorage.getItem(StorageKey.LAST_INTEGRATION_TEAM);
	}

	public setLastIntegrationTeam(teamId: string): void {
		localStorage.setItem(StorageKey.LAST_INTEGRATION_TEAM, teamId);
	}

	public getLastIntegrationProject(): string | null {
		return localStorage.getItem(StorageKey.LAST_INTEGRATION_PROJECT);
	}

	public setLastIntegrationProject(projectId: string): void {
		localStorage.setItem(StorageKey.LAST_INTEGRATION_PROJECT, projectId);
	}

	public setIsSignedUpInThisBrowser(): void {
		localStorage.setItem(StorageKey.SIGNED_UP_HERE, "true");
	}

	public getIsSignedUpInThisBrowser(): boolean {
		return localStorage.getItem(StorageKey.SIGNED_UP_HERE) == "true";
	}

	public setImageConverterLastUsage(): void {
		localStorage.setItem(StorageKey.IMAGE_CONVERTER_LAST_USAGE, new Date().toISOString());
	}

	public getImageConverterLastUsage(): Date | null {
		const usage = localStorage.getItem(StorageKey.IMAGE_CONVERTER_LAST_USAGE);
		console.log(usage)
		if(usage) {
			return new Date(usage);
		}
		return null;
	}

	public getDownloadCount(): number {
		const counter = localStorage.getItem(StorageKey.DOWNLOAD_COUNT);
		if (counter != null) {
			try {
				return Number.parseInt(counter, 10);
			} catch (e) {
				console.log(e);
			}
		}
		return 0;
	}

	public setDownloadCount(count: number): void {
		localStorage.setItem(StorageKey.DOWNLOAD_COUNT, `${count}`);
	}

	public getGetStartedHiddenStatus(teamId: string, projectId: string): boolean {
		return localStorage.getItem(`${StorageKey.GET_STARTED_HIDDEN}_${teamId}_${projectId}`) === 'true';
	}

	public setGetStartedHiddenStatus(teamId: string, projectId: string, hidden: boolean = true) {
		localStorage.setItem(`${StorageKey.GET_STARTED_HIDDEN}_${teamId}_${projectId}`, `${hidden}`);
	}

	public clear(): void {
		localStorage.clear();
	}
}

const StorageService = new StorageServiceImpl();
export default StorageService;

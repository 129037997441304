import Vue from 'vue';
import Component from 'vue-class-component';
import template from './qr-presenter.html';
import QRCode from 'qrcode-svg';

@Component({
	props: {
		token: String,
	},
	template: template,
})
export default class QrPresenter extends Vue {
	private token: string | undefined;

	protected getQrSvg(): string {
		const size = 200;
		return new QRCode({
			content: this.token || 'ERROR',
			width: size,
			height: size,
			ecl: 'Q',
			join: true,
			pretty: false,
			container: 'svg-viewbox',
		}).svg();
	}

	// TODO: Use internal library
	// private async presentQrCode() {
	// const canvas = this.$refs.qrCanvas as HTMLCanvasElement;
	// let qr0: qrcodegen.QrCode = qrcodegen.QrCode.encodeText(this.token, qrcodegen.QrCode.Ecc.MEDIUM);
	// qr0.drawCanvas(1, 0, canvas);
	// }
}

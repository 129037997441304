import Vue from 'vue';
import Component from 'vue-class-component';
import template from './last-project-updates.html';
import './last-project-updates.scss';
import RespressoApi, {Paging} from '../../../api/respresso-api';
import ErrorHandler from '../../../services/error-handler';
import {NextFunction, Route} from 'vue-router';
import {ProjectUpdateMeta} from './project';
import Pager from '../../common/pager/pager';
import LoadingScreen from '../../../decorators/loading-screen';
import {ProjectMeta, TeamMeta} from 'respresso';
import {teamModule} from '../../../store/modules/team/index';
import ProjectLastUpdatesList from '../../common/project/project-last-updates-list';

@Component({
	components: {
		pager: Pager,
		'project-last-updates-list': ProjectLastUpdatesList,
	},
	template: template,
})
export default class LastProjectUpdates extends Vue {
	private teamId: string | undefined = '';
	private projectId: string | undefined = '';

	private latestUpdates: ProjectUpdateMeta[] = [];
	private totalSize = 0;
	private paging: Paging = {
		page: 0,
		size: 10,
	};

	beforeRouteEnter(to: Route, from: Route, next: NextFunction): void {
		next((vm) => {
			const res = vm as LastProjectUpdates;
			res.teamId = to.params.teamId;
			res.projectId = to.params.projectId;
			res.loadData();
		});
	}

	@LoadingScreen({ showImmediately: true })
	protected async loadData(): Promise<void> {
		const lastProjectUpdates = await ErrorHandler.tryRequest(() =>
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			RespressoApi.loadLastUpdatedProjectMeta(this.teamId!, this.projectId!, this.paging),
		);

		if (lastProjectUpdates) {
			this.latestUpdates = lastProjectUpdates.lastUpdates;
			this.totalSize = lastProjectUpdates.totalElements;
		}
	}

	protected setPage(pageIndex: number): void {
		this.paging.page = pageIndex;
		this.loadData();
	}

	get team(): TeamMeta | null {
		return teamModule.team;
	}

	get project(): ProjectMeta | Record<string, unknown> {
		const moduleGetterTeam = this.team;
		if (!moduleGetterTeam || !moduleGetterTeam.projects) {
			return {};
		}
		const project = moduleGetterTeam.projects.filter((p: ProjectMeta) => {
			return p.id === this.projectId;
		});
		if (project.length) {
			return project[0];
		} else {
			return {};
		}
	}
}

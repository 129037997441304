import Vue from 'vue';
import Component from 'vue-class-component';
import template from './join-team.html';
import './join-team.scss';
import {JoinableTeams, TeamInfo} from 'respresso';
import ErrorHandler from '../../../services/error-handler';
import UserService from '../../../services/user-service';
import LoadingScreen from '../../../decorators/loading-screen';

@Component({
	template: template,
})
export default class JoinTeam extends Vue {
	private joinableTeams: JoinableTeams | undefined;
	private teams: TeamInfo[] = [];
	private teamsInvited: TeamInfo[] | undefined = [];

	private loadingTeams = false;
	private joiningTeam = false;

	@LoadingScreen({ fullScreen: true })
	private async created(): Promise<void> {
		if (this.loadingTeams) {
			return;
		}

		this.loadingTeams = true;
		const joinableTeams = await ErrorHandler.tryRequest(() => UserService.getJoinableTeams());
		if (joinableTeams) {
			this.joinableTeams = joinableTeams;
			this.teams = this.joinableTeams.teams;
			this.teamsInvited = this.joinableTeams.inviteTeams;
		}
		this.loadingTeams = false;
	}

	@LoadingScreen({ fullScreen: true })
	private async join(team: TeamInfo): Promise<void> {
		if (this.joiningTeam) {
			return;
		}

		this.joiningTeam = true;
		const response = await ErrorHandler.tryRequest(() => UserService.joinTeam(team));
		if (response) {
			this.$router.push({ name: 'dashboard', params: { teamId: team.id } });
		}
		this.joiningTeam = false;
	}

	@LoadingScreen({ fullScreen: true })
	async joinByInvitation(team: TeamInfo): Promise<void> {
		if (this.joiningTeam) {
			return;
		}

		this.joiningTeam = true;
		const response = await ErrorHandler.tryRequest(() => UserService.joinByInvitationLater(team));
		if (response) {
			this.$router.push({ name: 'dashboard', params: { teamId: team.id } });
		}
		this.joiningTeam = false;
	}
}

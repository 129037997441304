import Vue from 'vue';
import Component from 'vue-class-component';
import template from './flow-error-viewer.html';
import './flow-error-viewer.scss';
import {FlowErrorData} from '../../services/flow-error-handler-adapter';
import {Prop} from 'vue-property-decorator';
import DialogTemplate from '../../../src/components/common/dialog/dialogs/dialog-template/dialog-template';
import {IDialogComponent} from '@ponte/dialog-vue';
import {IDialog} from '@ponte/dialog';

export interface FlowErrorViewerConfig {
	title: string;
	error: FlowErrorData;
}

@Component({
	components: {
		'dialog-template': DialogTemplate,
	},
	template: template,
})
export class FlowErrorViewer extends Vue implements IDialogComponent<boolean> {
	@Prop() dialogConfig!: FlowErrorViewerConfig;

	private logs: string[] = [];
	private isShowDetails = false;
	private lastLog: string | null = null;
	private error: FlowErrorData | null = null;

	dialog!: IDialog<boolean>;

	public created(): void {
		this.init(this.dialogConfig.error);
	}

	public init(error: FlowErrorData): void {
		this.error = error;
		const logsArray = error.logs.split('\n');
		this.logs = logsArray.reverse();
		this.lastLog = logsArray.find(it => it.includes("FATAL:")) ?? logsArray[0];
	}

	private get errorMessage() {
		if(this.error?.subError?.key) {
			const key = 'serverError.respressoError.' + this.error.subError.key;
			const message = this.$t(key, this.error.subError.params) as string;
			if(message !== key) {
				return message;
			} else {
				return `${this.error.subError.key}\n${JSON.stringify(this.error.subError.params, null, 2)}`
			}
		} else if(this.lastLog) {
			return this.sliceErrorSummary2(this.lastLog);
		}
		return "Unknown error";
	}

	public closeDialog(): void {
		if (this.dialog.dialogController) this.dialog.dialogController.close(true);
	}

	isErrorSummary(log: string): boolean {
		return log.indexOf('ERROR:') !== -1 || log.indexOf('FATAL:') !== -1;
	}

	trimErrorSummary(log: string): string {
		return log.substr(log.indexOf(': ') + 1);
	}

	sliceErrorSummary1(log: string): string {
		const i = log.indexOf(': ');
		return log.substr(0, i + 1);
	}

	sliceErrorSummary2(log: string): string {
		const i = log.indexOf(': ');
		return log.substr(i + 1);
	}

	replaceErrorSummarySlash(log: string): string {
		const re = /\//;
		return log.replace(re, ' ⟶ ');
	}

	replaceErrorSummaryArrow(log: string): string {
		const re = /->/;
		return log.replace(re, ' ⟶ ');
	}

	showDetails(): void {
		this.isShowDetails = !this.isShowDetails;
	}
}

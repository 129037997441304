import CodeMirror from 'codemirror';
import 'codemirror/lib/codemirror.css';

//javascript and json
import 'codemirror/mode/javascript/javascript.js';
import 'codemirror/addon/hint/javascript-hint.js';
import 'codemirror/addon/lint/javascript-lint.js';
import 'codemirror/addon/lint/json-lint.js';

//xml
import 'codemirror/mode/xml/xml.js';
import 'codemirror/addon/hint/xml-hint.js';

//yaml
import 'codemirror/mode/yaml/yaml.js';
import 'codemirror/addon/lint/yaml-lint.js';

//addons
import 'codemirror/addon/edit/closetag.js';
import 'codemirror/addon/edit/closebrackets.js';
import 'codemirror/addon/search/jump-to-line.js';
import 'codemirror/addon/scroll/simplescrollbars.js';

export default CodeMirror;

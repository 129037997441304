export enum SortStyle {
	String,
	Numeric,
	CaseSensitiveString,
}

export enum SortOrder {
	ASC,
	DESC,
}

export class SortServiceImpl {
	public key = '';
	public order: SortOrder = SortOrder.ASC;
	public style: SortStyle = SortStyle.String;

	constructor(key = '', order: SortOrder = SortOrder.ASC, style: SortStyle = SortStyle.String) {
		this.key = key;
		this.order = order;
		this.style = style;
	}

	private numericCompare(valueA: number, valueB: number): number {
		if (valueA == valueB) {
			return 0;
		}
		return this.order == SortOrder.ASC ? valueA - valueB : valueB - valueA;
	}

	private stringCompare(valueA: string, valueB: string): number {
		if (valueA == valueB) {
			return 0;
		}
		return this.order == SortOrder.ASC ? (valueA < valueB ? -1 : 1) : valueA < valueB ? 1 : -1;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
	public compare(a: any, b: any): number {
		a = DataTable.isObject(a) && this.key ? a[this.key] : a;
		b = DataTable.isObject(b) && this.key ? b[this.key] : b;
		if (a == null) {
			a = '';
		}
		if (b == null) {
			b = '';
		}
		if (this.style == SortStyle.Numeric) {
			return this.numericCompare(Number(a), Number(b));
		} else if (this.style == SortStyle.String) {
			return this.stringCompare(a.toLocaleString().toLocaleLowerCase(), b.toLocaleString().toLocaleLowerCase());
		} else if ((this.style = SortStyle.CaseSensitiveString)) {
			return this.stringCompare(a.toLocaleString(), b.toLocaleString());
		}
		return 0;
	}
}

export class DataTable<T> {
	private items: Array<T> = [];

	private sortBy: Array<SortServiceImpl> = [];

	constructor(items: Array<T> | undefined) {
		this.items = items || [];
	}

	public static isObject(item: unknown): boolean {
		return item instanceof Object;
	}

	private sorter(table: DataTable<T>) {
		return function (a: unknown, b: unknown): number {
			let val = 0;
			if (table.sortBy.length > 0) {
				table.sortBy.some(function (sortField: SortServiceImpl) {
					val = sortField.compare(a, b);
					return val != 0;
				});
			}
			return val || 0;
		};
	}

	private sort(): DataTable<T> {
		if (this.sortBy.length > 0) {
			this.items.sort(this.sorter(this));
		}
		return this;
	}

	public getSort(): Array<SortServiceImpl> {
		return this.sortBy;
	}

	public clearSort(): DataTable<T> {
		this.sortBy = [];
		return this;
	}

	public asc(fieldName: string, style: SortStyle = SortStyle.String): DataTable<T> {
		this.sortBy.push(new SortServiceImpl(fieldName, SortOrder.ASC, style));
		return this;
	}

	public desc(fieldName: string, style: SortStyle = SortStyle.String): DataTable<T> {
		this.sortBy.push(new SortServiceImpl(fieldName, SortOrder.DESC, style));
		return this;
	}

	public getAll(): Array<T> {
		return this.sort().items;
	}
}

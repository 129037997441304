import Component from 'vue-class-component';
import template from './structure-value-name.html';
import './structure-value-name.scss';
import Vue from 'vue';
import {Prop} from 'vue-property-decorator';

@Component({
	template: template,
	props: {
		name: String,
		description: String,
	},
})
export default class StructureValueName extends Vue {
	@Prop()
	name!: string;
	@Prop()
	description?: string;
}

import Vue from 'vue';
import template from './structure-editor-dialog.html';
import Component from 'vue-class-component';
import DialogTemplate from '../dialog/dialogs/dialog-template/dialog-template';
import {IDialogComponent} from '@ponte/dialog-vue';
import {IDialog} from '@ponte/dialog';
import {Prop} from 'vue-property-decorator';
import {StructureDefinitions} from 'respresso';
import './structure-editor-dialog.scss';

export interface StructureEditorDialogConfig {
	title?: string;
	cancel?: string;
	save?: string;
	data?: any;
	definitions: StructureDefinitions;
	editablePaths?: string[];
	forbiddenPaths?: string[];
}

export interface StructureEditorDialogResult {
	data?: any;
	useResult: boolean;
}

@Component({
	components: {
		'dialog-template': DialogTemplate,
	},
	template: template,
})
export default class AddOrEditLocalizationVariableDialog
	extends Vue
	implements IDialogComponent<StructureEditorDialogResult>
{
	@Prop() dialogConfig!: StructureEditorDialogConfig;

	data?: any;

	dialog!: IDialog<StructureEditorDialogResult>;

	public beforeMount(): void {
		this.data = this.dialogConfig.data;
		console.log(this.data, this.dialogConfig.definitions);
	}

	private onStructureChanged(value: any) {
		this.data = value;
	}

	public closeDialog(result: StructureEditorDialogResult): void {
		if (this.dialog.dialogController) this.dialog.dialogController.close(result);
	}

	public saveAndClose(): void {
		this.closeDialog({
			useResult: true,
			data: this.data,
		});
	}

	public discard(): void {
		this.closeDialog({
			useResult: false,
		});
	}
}

import Vue from 'vue';
import template from './tag-filter.html';
import './tag-filter.scss';
import Component from 'vue-class-component';
import {default as VueSlimSelect, SlimSelectInfo, SlimSelectOption} from '../vue-slim-select/vue-slim-select';

@Component({
	props: {
		allTags: Array,
	},
	watch: {
		allTags(): void {
			(this as TagFilter).tagsChanged();
		},
	},
	template: template,
})
export default class TagFilter extends Vue {
	private allTags: string[] | undefined;

	private get options(): {
		text: string;
		value: string;
	}[] {
		if (this.allTags) {
			return this.allTags.map((tag) => {
				return {
					text: tag,
					value: tag,
				};
			});
		}
		return [];
	}

	private tagsChanged(): void {
		Vue.nextTick(() => {
			(this.$refs.vueSlimSelect as VueSlimSelect).updateData();
			const tags = (this.$refs.vueSlimSelect as VueSlimSelect).selected() || [];
			this.$emit('tags-changed', tags);
		});
	}

	private selectedTagsChanged(
		info: SlimSelectInfo[],
		newElements: SlimSelectOption[],
		removedElements: SlimSelectOption[],
	): void {
		if (!info) {
			this.$emit('selected-tags-changed', [], newElements || [], removedElements || []);
		} else {
			this.$emit(
				'selected-tags-changed',
				info.map((inf) => inf.text),
				newElements || [],
				removedElements || [],
			);
		}
	}
}

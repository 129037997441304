import Vue from 'vue';
import Component from 'vue-class-component';
import template from './team-permissions.html';
import './team-permissions.scss';
import {NextFunction, Route} from 'vue-router';
import RespressoApi from '../../../api/respresso-api';
import {PendingUserMeta, TeamMeta, TeamRole, UserPublicInfo} from 'respresso';
import {DialogBuilder, NotificationBuilder} from '../../common/dialog/dialog';
import ErrorHandler from '../../../services/error-handler';
import UserService from '../../../services/user-service';
import LoadingScreen from '../../../decorators/loading-screen';
import {teamModule} from '../../../store/modules/team/index';
import {SlimSelectOption} from '../../common/vue-slim-select/vue-slim-select';
import CreateTeamInvite, {CreateTeamInviteDialogConfig} from './create-team-invite';
import {i18n} from '../../../main';
import AccessService from '../../../services/access-service';

@Component({
    template: template,
})
export default class TeamPermissions extends Vue {
    private teamId = '';
    private options: SlimSelectOption[] = [];
    private users: UserPublicInfo[] = [];
    private team: TeamMeta = {
        id: '',
        title: '',
    } as TeamMeta;

    beforeRouteEnter(to: Route, from: Route, next: NextFunction): void {
        next((vm) => {
            const res = vm as TeamPermissions;
            res.teamId = to.params.teamId;
            res.loadData();
        });
    }

    @LoadingScreen({ showImmediately: true })
    private async loadData() {
        await this.loadTeam();
    }

    private async loadTeam(): Promise<void> {
        const team = await UserService.loadTeam(this.teamId);
        if (team) {
            this.team = Object.assign({}, team);
            if (this.team.users) {
                const users = [...this.team.users];
                users.sort((a, b) => a.name.localeCompare(b.name));
                this.users = users;
            }
        }
        this.$forceUpdate();
    }

    public isTeamMember(userId: string): boolean {
        return this.users.some((u) => !u.deleted && u.id === userId);
    }

    protected isCurrentUser(userId: string): boolean {
        const currentUser = UserService.getUser();
        return !!(currentUser && currentUser.id === userId);
    }

    private get myTeamRole(): TeamRole {
        const currentUser = UserService.getUser()!;
        return this.team.users!.find((user) => user.id === currentUser.id)!.role! as TeamRole;
    }


    private canSendInvite() {
        return AccessService.canEditTeamPermissions(this.team);
    }

    private canEditUserRole(user: UserPublicInfo) {
        const myRole = this.myTeamRole;
        const role = user.role as TeamRole;
        return AccessService.canEditTeamPermissions(this.team) && AccessService.includesTeamRole(myRole, role);
    }

    protected createUserRoleSelectOption(user: UserPublicInfo): SlimSelectOption[] {
        const myRole = this.myTeamRole;
        const roles: TeamRole[] = ["OUTSIDER", "MEMBER", "ADMIN", "OWNER"];
        return roles.map((role) => {
            const option: SlimSelectOption = {
                text: this.getRole(role),
                value: role,
                selected: user.role === role,
                disabled: !AccessService.includesTeamRole(myRole, role),
            };
            return option;
        });
    }

    protected getRole(role: TeamRole) {
        if (!role) role = "MEMBER";
        return i18n.t(`team.role.${role.toLowerCase()}.name`) as string;
    }

    @LoadingScreen()
    protected async roleChanged(user: UserPublicInfo, event: any) {
        const role = event.value as TeamRole;
        const response = await ErrorHandler.tryRequest(() => RespressoApi.setTeamRole(this.teamId!, user.id, role));
        if (response != null) {
            NotificationBuilder.success('#team.settings.permissions.roleChange.success');
        }
        await this.loadTeam();
    }

    protected async deleteUser(user: UserPublicInfo) {
        await DialogBuilder.confirm(
            '#team.settings.permissions.removeUser.confirm.title',
            '#team.settings.permissions.removeUser.confirm.message',
            async () => {
                await this.executeDeleteUser(user);
            },
        );
    }

    @LoadingScreen()
    private async executeDeleteUser(user: UserPublicInfo) {
        const response = await ErrorHandler.tryRequest(() => RespressoApi.deleteTeamRole(this.teamId!, user.id));
        await this.loadTeam();
        if (response != null) {
            NotificationBuilder.success('#team.settings.permissions.removeUser.success');
        }
    }

    protected async removeInvite(invite: PendingUserMeta) {
        await DialogBuilder.confirm(
            '#team.settings.permissions.removeInvite.confirm.title',
            '#team.settings.permissions.removeInvite.confirm.message',
            async () => {
                await this.executeRemoveInvite(invite);
            },
        );
    }

    @LoadingScreen()
    protected async executeRemoveInvite(invite: PendingUserMeta) {
        const response = await ErrorHandler.tryRequest(() => RespressoApi.deleteTeamInvite(this.teamId!, invite.email));
        await this.loadTeam();
        if (response != null) {
            NotificationBuilder.success('#team.settings.permissions.removeInvite.success');
        }
    }

    @LoadingScreen({ showImmediately: true })
    protected async resendInvite(invite: PendingUserMeta) {
        const response = await ErrorHandler.tryRequest(() => RespressoApi.resendTeamInvite(this.teamId!, invite.email));
        if (response != null) {
            NotificationBuilder.success('#team.settings.permissions.resendInvite.success');
        }
    }

    protected async newInvite() {
        const dialogConfig: CreateTeamInviteDialogConfig = {
            myRole: this.myTeamRole
        }
        await DialogBuilder.createVueDialog(CreateTeamInvite, { propsData: { dialogConfig } }).closeResult.then(async (newInvite) => {
            if (newInvite) {
                const response = await ErrorHandler.tryRequest(() => RespressoApi.createTeamInvite(this.teamId!, newInvite.email, newInvite.name, newInvite.role));
                await this.loadTeam();
                if (response != null) {
                    NotificationBuilder.success('#team.settings.permissions.sendInvite.success');
                }
            }
        });
    }

    public get storeTeam(): TeamMeta | undefined {
        if (teamModule.team) {
            return teamModule.team;
        }
    }

    public get teamMembers() {
        return this.users.filter((user) => this.isTeamMember(user.id));
    }
}

/* eslint-disable */
export default function debounce(this: any, fun: (...arg: any) => void, timeout: number): (...funArgs: any[])=> void{
    const context = this;
    let timer: any = null;
    let args: any = null;

    const callLater = () => fun.apply(context, args);

    return function (...funArgs: any[]) {
        clearTimeout(timer);
        args = arguments;
        timer = setTimeout(callLater, timeout);
    };
}

import store from '../../index';
import {Action, Module, Mutation, VuexModule} from 'vuex-class-modules';
import {ProjectMeta, TeamInfo, TeamMeta} from 'respresso';

@Module
class TeamModule extends VuexModule {
	_team: TeamMeta | null = null;
	_teams: TeamInfo[] | null = null;
	_project: ProjectMeta | null = null;

	get team(): TeamMeta | null {
		return this._team;
	}
	get teams(): TeamInfo[] | null {
		return this._teams;
	}
	get project(): ProjectMeta | null {
		return this._project;
	}

	@Mutation
	SET_TEAM(payload: TeamMeta | null): void {
		this._team = payload;
		if (payload && this._teams) {
			const foundTeam = this._teams.findIndex((t) => t.id === payload.id);
			if (foundTeam !== -1) {
				this._teams.splice(foundTeam, 1, payload);
			}
		}
	}

	@Mutation
	SET_TEAMS(payload: TeamInfo[] | null): void {
		if (payload !== null) {
			payload.sort((a, b) => (a.id < b.id ? -1 : a.id > b.id ? 1 : 0));
		}
		this._teams = payload;
	}

	@Mutation
	ADD_TEAM(payload: TeamInfo): void {
		let sortedTeams: TeamInfo[] = [];
		if (this._teams !== null) {
			sortedTeams = this._teams;
		}
		sortedTeams.push(payload);
		sortedTeams.sort((a, b) => (a.id < b.id ? -1 : a.id > b.id ? 1 : 0));
		this._teams = sortedTeams;
	}

	@Mutation
	REMOVE_TEAM(): void {
		if (this._team && this._teams) {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			const foundTeam = this._teams.findIndex((team) => team.id === this._team!.id);
			if (foundTeam !== -1) {
				this._teams.splice(foundTeam, 1);
			}
		}
		this._team = null;
	}

	@Mutation
	SET_PROJECT(payload: ProjectMeta | null): void {
		this._project = payload;
	}

	@Action
	addTeam(payload: TeamInfo): void {
		this.ADD_TEAM(payload);
	}

	@Action
	removeTeam(): void {
		this.REMOVE_TEAM();
	}

	@Action
	removeProject(): void {
		this.SET_PROJECT(null);
	}
}

export const teamModule = new TeamModule({ store, name: 'team' });

import Vue from 'vue';
import Component from 'vue-class-component';
import template from './live-localization.html';
import './live-localization.scss';
import {NextFunction, Route} from 'vue-router';
import LoadingScreen from '../../../decorators/loading-screen';
import QrPresenter from './qr-presenter/qr-presenter';
import Guid from '../../../util/guid';
import {ProjectMeta, TeamMeta} from 'respresso';
import RespressoApi from '../../../api/respresso-api';
import ErrorHandler from '../../../services/error-handler';
import ResourceList from '../../common/resource-list/resource-list';
import {LocalizationResourceItem} from '../resource/localization-resource';
import {ItemState} from '../resource/resource';
import {default as VueSlimSelect, SlimSelectInfo} from '../../common/vue-slim-select/vue-slim-select';
import {NotificationBuilder} from '../../common/dialog/dialog';
import {translate} from '../../../main';
import debounce from '../../../util/debounce';
import damerauLevenshtein from './damerau-levenshtein';
import {teamModule} from '../../../store/modules/team/index';

export interface LiveUpdateConnectionInfo {
	projectToken: string;
	webSocketUrl: string;
}

interface WsFrontendFilter {
	values: string[];
	keys: string[];
}

interface WsFrontendFilter {
	id: string;
	filter: WsFrontendFilter;
}

interface WsDeviceResponse {
	result: 'OK';
	device: WsDevice;
}

interface WsDevice {
	id: string;
	name: string;
}

interface DeviceInfo {
	id: string;
	name: string;
	isActive: boolean;
	lastFilter?: WsFrontendFilter;
}

interface SortableLocalizationResourceItem {
	score: number;
	item: LocalizationResourceItem;
}

function isNotNull<T>(a: T | null): a is T {
	return a !== null;
}

@Component({
	components: {
		'qr-presenter': QrPresenter,
		'resource-list': ResourceList,
	},
	template: template,
})
export default class LiveLocalization extends Vue {
	protected projectToken = '';
	protected projectId = '';
	protected teamId = '';
	protected version = '';
	protected language = '';
	protected qrToken = '';
	protected websocket: WebSocket | null = null;
	protected connectedDevices: DeviceInfo[] = [];

	protected allItems: LocalizationResourceItem[] = [];
	protected newItems: LocalizationResourceItem[] = [];
	protected items: LocalizationResourceItem[] = [];
	protected itemsToSave: LocalizationResourceItem[] = [];
	protected syncedItems: Map<string, LocalizationResourceItem> = new Map<string, LocalizationResourceItem>();
	protected editable = true;
	protected showFilter = false;

	private ITEM_CHANGE_DEBOUNCE_TIME = 600;
	private TEXT_SEARCH_DEVIATION_TRESHOLD = 10;

	/* Routing */
	beforeRouteEnter(to: Route, from: Route, next: NextFunction): void {
		next((vm) => {
			const resource = vm as LiveLocalization;
			resource.load(resource, to, from, false);
		});
	}

	beforeDestroy(): void {
		if (this.websocket != null) {
			this.websocket.close(1000); //1000=Normal Closure
		}
	}

	get team(): TeamMeta | undefined {
		if (teamModule.team) {
			return teamModule.team;
		}
	}

	get project(): ProjectMeta | Record<string, unknown> {
		const moduleGetterTeam = teamModule.team;
		if (!moduleGetterTeam || !moduleGetterTeam.projects) {
			return {};
		}
		const project = moduleGetterTeam.projects.filter((p: ProjectMeta) => {
			return p.id === this.projectId;
		});
		if (project.length) {
			return project[0];
		} else {
			return {};
		}
	}

	setupWebsocket(url: string): void {
		this.websocket = new WebSocket(url);

		// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
		this.websocket.onopen = (ev) => {
			this.sendMessage('connect', {
				projectToken: this.projectToken,
				frontendToken: this.qrToken,
				localizationLang: this.language,
				version: this.version,
				versionToken: null,
				isWebClient: true,
			});
		};

		// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
		this.websocket.onerror = (ev) => {
			console.error(ev);
			//NotificationBuilder.error("WebSocket closed");
		};

		// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
		this.websocket.onmessage = (ev) => {
			this.handleMessage(ev);
		};

		// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
		this.websocket.onclose = (ev) => {
			this.websocket = null;
		};
	}

	private doWSPing(): void {
		if (!this.websocket) {
			return;
		}

		this.sendMessage('ping', {});

		setTimeout(this.doWSPing.bind(this), 55 * 1000);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private findLocalization<T>(parsedMessage: any[]): T {
		const find = parsedMessage.find((value) => {
			return value.id === 'localization';
		});

		if (find) {
			return find.data;
		}

		throw new Error('localization not found');
	}

	handleMessage(message: MessageEvent): void {
		const parsedMessage = JSON.parse(message.data);

		switch (parsedMessage.id) {
			case 'syncResponse':
				break;
			case 'connectionResponse':
				this.doWSPing();
				break;
			case 'filter':
				const filterData: WsFrontendFilter = this.findLocalization<WsFrontendFilter>(parsedMessage.data);
				// this.items = this.filter(parsedMessage.data);
				const deviceInf: DeviceInfo | undefined = this.connectedDevices.find((device) => {
					return device.id == filterData.id;
				});
				if (deviceInf) {
					deviceInf.lastFilter = filterData.filter;

					if (deviceInf.isActive) {
						this.updateFilter();
					}
				}

				break;
			case 'deviceConnected':
				const device: WsDevice = this.findLocalization<WsDeviceResponse>(parsedMessage.data).device;
				const deviceInfo: DeviceInfo = {
					id: device.id,
					name: device.name,
					isActive: this.connectedDevices.length === 0,
				};

				this.connectedDevices.push(deviceInfo);
				this.refreshDropdown();
				NotificationBuilder.success(translate('live.localization.device.connected') + deviceInfo.name);
				break;
			case 'mobileConnectionClosed':
				const disconnectedDevice: WsDevice = this.findLocalization<WsDeviceResponse>(parsedMessage.data).device;
				const disconnectedDeviceInfo: DeviceInfo | undefined = this.connectedDevices.find((device) => {
					return device.id == disconnectedDevice.id;
				});

				if (disconnectedDeviceInfo) {
					this.connectedDevices = this.connectedDevices.filter(
						(device) => device.id !== disconnectedDeviceInfo.id,
					);
					if (disconnectedDeviceInfo.isActive && this.connectedDevices.length > 0) {
						this.connectedDevices[0].isActive = true;
					}

					NotificationBuilder.warning(
						translate('live.localization.device.disconnected') + disconnectedDeviceInfo.name,
					);
				}

				this.updateFilter();
				this.refreshDropdown();
				break;
		}
	}

	deviceChanged(info: SlimSelectInfo): void {
		this.connectedDevices.forEach((device) => {
			device.isActive = info.value === device.id;
		});

		this.updateFilter();
	}

	private updateFilter(): void {
		const activeDevice: DeviceInfo | undefined = this.connectedDevices.find((device) => {
			return device.isActive;
		});

		if (activeDevice && activeDevice.lastFilter) {
			this.items = this.filter(activeDevice.lastFilter);
		} else {
			this.items = this.allItems;
		}
	}

	private refreshDropdown(): void {
		(this.$refs.deviceSelector as VueSlimSelect).setData(this.getDeviceSelectors(this.connectedDevices));
	}

	private getDeviceSelectors(devices: DeviceInfo[]): SlimSelectInfo[] {
		return devices.map((device) => {
			return {
				text: device.name,
				value: device.id,
				selected: device.isActive,
			};
		});
	}

	private itemChanged(item: LocalizationResourceItem): void {
		item.state = ItemState.UPDATED;
		this.debounceItemChange(item);
	}

	debounceItemChange = debounce((item: LocalizationResourceItem) => {
		this.saveItemChanged(item);
	}, this.ITEM_CHANGE_DEBOUNCE_TIME);

	private async save(): Promise<boolean> {
		let itemsToSave = this.allItems.filter((item) => item.state == ItemState.UPDATED);
		itemsToSave = itemsToSave.map((value) => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			value.state = value.state.toUpperCase() as any;
			return value;
		});
		const saveResp = await ErrorHandler.tryRequest(
			() =>
				RespressoApi.saveLocalizationVersion(
					this.teamId,
					this.projectId,
					this.version,
					this.language,
					itemsToSave,
				),
			{
				loadingScreen: true,
				loadingMessage: '#loading.processingLocalizations',
			},
		);
		if (saveResp) {
			this.itemsToSave = [];
			NotificationBuilder.success('#messages.saved');
			return true;
		} else {
			return false;
		}
	}

	private saveItemChanged(item: LocalizationResourceItem): void {
		const syncedItem = this.syncedItems.get(item.id);
		if (syncedItem && syncedItem.value == item.value) {
			return;
		}
		const savedItemIndex = this.itemsToSave.findIndex((savedItem) => savedItem.key == item.key);
		if (savedItemIndex) {
			this.itemsToSave[savedItemIndex] = item;
		} else {
			this.itemsToSave.push(item);
		}

		if (this.websocket != null) {
			this.sendMessage('itemChanged', {
				frontendToken: this.qrToken,
				item,
			});

			const itemCopy = Object.assign({}, item);
			this.syncedItems.set(item.id, itemCopy);
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private sendMessage(id: string, data: any): void {
		if (this.websocket != null) {
			this.websocket.send(
				JSON.stringify({
					id,
					data: [
						{
							id: 'localization',
							data,
						},
					],
				}),
			);
		}
	}

	private filter(wsFrontendFilter: WsFrontendFilter): LocalizationResourceItem[] {
		if (!wsFrontendFilter.keys && !wsFrontendFilter.values) {
			return this.allItems;
		}

		return this.allItems
			.map<SortableLocalizationResourceItem | null>((item) => {
				if (wsFrontendFilter.keys != null && item.key != null) {
					if (wsFrontendFilter.keys.includes(item.key)) {
						return {
							score: 0,
							item,
						} as SortableLocalizationResourceItem;
					}
				}

				if (wsFrontendFilter.values != null && item.value != null) {
					const score: number | false = this.textSearch(item.value, wsFrontendFilter.values);
					if (typeof score === 'number') {
						return {
							score,
							item,
						} as SortableLocalizationResourceItem;
					}
				}

				return null;
			})
			.filter(isNotNull)
			.sort((a, b) => a.score - b.score)
			.map<LocalizationResourceItem>((value) => value.item);
	}

	private textSearch(itemValue: string, searchedTexts: string[]): number | false {
		const returnValueFilter = (distance: number): number | false => {
			return distance <= this.TEXT_SEARCH_DEVIATION_TRESHOLD ? distance : false;
		};

		if (searchedTexts.length == 1) {
			return returnValueFilter(damerauLevenshtein.distance(itemValue, searchedTexts[0]));
		}

		const distances = searchedTexts.map((x) => damerauLevenshtein.distance(itemValue, x));
		const minDistance = Math.min(...distances);

		return returnValueFilter(minDistance);
	}

	/* Loading */
	@LoadingScreen({ showImmediately: true })
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	private async load(resource: LiveLocalization, to: Route, from: Route, update: boolean): Promise<void> {
		resource.projectId = to.params.projectId;
		resource.teamId = to.params.teamId;
		resource.version = to.params.versionNumber;
		resource.language = to.params.language;
		resource.qrToken = Guid.newGuid();

		const connectionInfo = await ErrorHandler.tryRequest(() =>
			RespressoApi.getLiveUpdateConnectionInfo(resource.teamId, resource.projectId),
		);
		if (connectionInfo) {
			this.projectToken = connectionInfo.projectToken;
			this.setupWebsocket(connectionInfo.webSocketUrl);
		}

		const data = await ErrorHandler.tryRequest(() =>
			RespressoApi.getLocalizationVersion(
				resource.teamId,
				resource.projectId,
				resource.version,
				resource.language,
			),
		);
		if (data != null) {
			this.items = data.language;
			this.allItems = data.language;
		}
	}
}

import Vue from 'vue';
import Component from 'vue-class-component';
import template from './dialog-template.html';
import './dialog-template.scss';

export interface DialogTemplateConfig {
	className: string;
}

@Component({
	template: template,
})
export default class DialogTemplate extends Vue {}

import Vue from 'vue';
import Component from 'vue-class-component';
import template from './user-preferences.html';
import './user-preferences.scss';
import {VueSingleUpload} from '../../../plugin/vue-upload/vue-single-upload';
import {ImageUploadResult} from '../../common/image/edit-image-dialog';
import {IFileDescriptor} from '@ponte/file-upload-js';
import RespressoApi from '../../../api/respresso-api';
import ErrorHandler from '../../../services/error-handler';
import UserService from '../../../services/user-service';
import {NextFunction, Route} from 'vue-router';
import {TeamMeta, User} from 'respresso';
import LoadingScreen from '../../../decorators/loading-screen';
import {DialogBuilder, NotificationBuilder} from '../../common/dialog/dialog';
import {teamModule} from '../../../store/modules/team/index';
import PasswordInput from '../../common/password-input/password-input';

const passwordRegex = /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])[\w\d!@#$%_\.]{6,40}$/;

@Component({
	template: template,
})
export default class UserPreferences extends Vue {
	private uploadHandler = 'ProfileImageUploadHandler';
	private uploadModified = false;
	private uploadResult: ImageUploadResult | null = null;
	private fileName = '';

	private isDirty = false;

	private userName = '';
	private oldPassword = '';
	private newPassword2 = '';
	private errors: {
		[field: string]: string;
	} = {};

	private newsletter = true;

	private beforeRouteEnter(to: Route, from: Route, next: NextFunction): void {
		next((vm) => {
			const userPreferences = vm as UserPreferences;
			userPreferences.loadData();
		});
	}

	get team(): TeamMeta | null {
		return teamModule.team;
	}

	private async loadData(): Promise<void> {
		const user = await UserService.getUser();
		if (user) {
			this.userName = user.name;
			this.newsletter = user.newsletter;
		}
	}

	private async fileSelected(fileDescriptor: IFileDescriptor): Promise<void> {
		this.fileName = fileDescriptor.file.name;
		this.uploadResult = await (this.$refs.singleUpload as VueSingleUpload).uploadFile(fileDescriptor.file, {
			handler: this.uploadHandler,
		});
		this.uploadModified = true;
		this.setDirty();
	}

	public isUploadModified(): boolean {
		return this.uploadModified;
	}

	private deleteFile(): void {
		this.uploadResult = null;
		(this.$refs.singleUpload as VueSingleUpload).reset();
		this.uploadModified = true;
	}

	public get showDetails(): boolean {
		return this.uploadResult !== null;
	}

	public setUploadResult(uploadResult: ImageUploadResult): void {
		(this.$refs.singleUpload as VueSingleUpload).showDetailsScreen();
		this.uploadResult = uploadResult;
		this.fileName = uploadResult.name;
	}

	public getUploadResult(): ImageUploadResult | null {
		return this.uploadResult;
	}

	public get thumbnailUrl(): string | null {
		if (!this.uploadResult) {
			return null;
		}
		return this.uploadResult.fileId;
	}

	private get pwInput(): PasswordInput {
		return this.$refs.pwInput as PasswordInput;
	}

	private get newPassword1(): string {
		const pwInput = this.pwInput;
		if (pwInput) return pwInput.typedPassword;
		return '';
	}

	@LoadingScreen()
	public async save(): Promise<null | undefined> {
		if (this.userName.trim().length === 0) {
			NotificationBuilder.error('#user.preferences.missing.userName');
			return null;
		}

		let hasError = false;
		if (
			!(
				this.oldPassword.trim().length === 0 &&
				this.newPassword1.trim().length === 0 &&
				this.newPassword2.trim().length === 0
			)
		) {
			const valid = this.validate();
			if (!valid) {
				return null;
			}
			if (this.newPassword1 && this.newPassword1.length > 0) {
				if (!this.newPassword1.trim().length || !this.newPassword2.trim().length) {
					NotificationBuilder.error('#user.preferences.missing.password');
					hasError = true;
					return null;
				}

				if (!this.oldPassword.trim().length) {
					NotificationBuilder.error('#user.preferences.missing.oldPassword');
					hasError = true;
					return null;
				}

				if (this.newPassword1 !== this.newPassword2) {
					NotificationBuilder.error('#user.preferences.notMatching');
					hasError = true;
					return null;
				}
			}
		}

		if (!hasError) {
			const fileId = this.uploadResult ? this.uploadResult.fileId : null;

			const user: User | null = await ErrorHandler.tryRequest(() =>
				RespressoApi.saveUser(fileId, this.userName, this.oldPassword, this.newPassword1, this.newsletter),
			);
			if (user) {
				NotificationBuilder.success('#success.user.modified');

				if (fileId) {
					this.$bus.$emit('avatarUpdated');
				}

				UserService.saveUser(user);

				this.isDirty = false;
			}
		}
	}
	private validate(): boolean {
		let valid = true;
		this.errors = {};

		if (!this.newPassword1) {
			valid = false;
			NotificationBuilder.error('#user.preferences.missing.password');
		} else if (!this.pwInput || !this.pwInput.checkPassword()) {
			valid = false;
			NotificationBuilder.error('#serverError.respressoError.userService.password.weak');
		}

		return valid;
	}

	public async generateNew(): Promise<void> {
		const resp = await ErrorHandler.tryRequest(() => UserService.getRandomProfilePicture());
		if (resp) {
			NotificationBuilder.success('#success.user.newAvatar');
		}
		const user = await UserService.getUser();
		if (user) {
		}
		this.$bus.$emit('avatarUpdated');
	}

	public setDirty(): void {
		this.isDirty = true;
	}

	protected handleBlur(fieldName: string): void {
		if (this.errors.hasOwnProperty(fieldName)) {
			this.validate();
		}
	}

	@LoadingScreen()
	protected async deleteAccount(): Promise<void> {
		await DialogBuilder.confirm('#user.preferences.delete.dialog', '#dialog.areYouSure.simple', async () => {
			await UserService.deleteAccount();
			await UserService.logout();
			await this.$router.push({ name: 'login' });
			NotificationBuilder.success('#success.user.delete');
		});
	}
}

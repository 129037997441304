import Vue from 'vue';
import template from './start-resource-merge-dialog.html';
import Component from 'vue-class-component';
import DialogTemplate from '../dialog/dialogs/dialog-template/dialog-template';
import {IDialogComponent} from '@ponte/dialog-vue';
import {IDialog} from '@ponte/dialog';
import {Prop, Provide} from 'vue-property-decorator';
import {ResourceVersions} from 'respresso';
import './start-resource-merge-dialog.scss';
import {SlimSelectInfo} from '../vue-slim-select/vue-slim-select';

export interface StartResourceMergeDialogConfig {
	teamId: string;
	projectId: string;
	category: string;
	currentVersion: string;
	versions: ResourceVersions;
}

@Component({
	components: {
		'dialog-template': DialogTemplate,
	},
	template: template,
})
export default class StartResourceMergeDialog extends Vue implements IDialogComponent<void> {
	@Prop() dialogConfig!: StartResourceMergeDialogConfig;
	@Provide() uniqueKeys = true;

	dialog!: IDialog<void>;

	@Provide()
	selectedVersion: string | null = null;

	public beforeMount(): void {
		const versions = this.dialogConfig.versions.versions || [];
		versions.forEach((version) => {
			if (!this.selectedVersion) {
				if (version.isChangeSet && version.versionNumber !== this.dialogConfig.currentVersion) {
					this.selectedVersion = version.versionNumber;
					console.log(`Version is preselected ${this.selectedVersion}`);
				} else {
					console.log(`Version is not selectable: ${version.versionNumber}`);
				}
			}
		});
	}

	public get versionOptions(): SlimSelectInfo[] {
		const versions = this.dialogConfig.versions.versions || [];
		return versions.map((version) => {
			let text = version.versionNumber;
			const isCurrent = version.versionNumber === this.dialogConfig.currentVersion;
			const nonChangeSet = !version.isChangeSet;
			if (isCurrent) {
				text += this.$t('version.merge.currentVersionSuffixLabel');
			} else if (nonChangeSet) {
				text += this.$t('version.merge.nonChangeSetSuffixLabel');
			}
			return {
				text: text,
				value: version.versionNumber,
				disabled: isCurrent || nonChangeSet,
				selected: version.versionNumber === this.selectedVersion,
			};
		});
	}

	onSelectionChanged(selected: SlimSelectInfo) {
		if (selected.value) {
			this.selectedVersion = selected.value;
		}
	}

	public startMerge(): void {
		const selectedVersion = this.selectedVersion;
		if (selectedVersion) {
			this.cancel();
			this.$router.push({
				name: 'localizationVersionMerge',
				params: {
					teamId: this.dialogConfig.teamId,
					projectId: this.dialogConfig.projectId,
					resourceId: this.dialogConfig.category,
					versionNumber: this.dialogConfig.currentVersion,
					changeSetVersion: selectedVersion,
				},
				query: {
					uniqueKeys: this.uniqueKeys ? 'true' : undefined,
				},
			});
		}
		// VueRouter
	}

	public cancel(): void {
		if (this.dialog.dialogController) this.dialog.dialogController.close();
	}
}

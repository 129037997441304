import Vue from 'vue';
import Component from 'vue-class-component';
import template from './logo.html';

@Component({
	template: template,
})
export class Logo extends Vue {
	appMode = '';

	public mounted(): void {
		this.appMode = process.env.VUE_APP_appmode;
	}
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Vue from 'vue';
import Component from 'vue-class-component';
import template from './dashboard.html';
import './dashboard.scss';
import {NextFunction, Route} from 'vue-router';
import RespressoApi from '../../../api/respresso-api';
import ErrorHandler from '../../../services/error-handler';
import UserService from '../../../services/user-service';
import LoadingService from '../../../services/loading-service';
import LoadingScreen from '../../../decorators/loading-screen';
import {TeamMeta} from 'respresso';
import {teamModule} from '../../../store/modules/team/index';
import {ProjectUpdateMeta} from "../project/project";
import ProjectLastUpdatesList from "../../common/project/project-last-updates-list";
import AccessService from "../../../services/access-service";

export interface LastupdatedTeamResult {
	lastupdated: LastupdatedTeamMeta[];
	lastupdates: ProjectUpdateMeta[];
	totalElements: number;
}

export interface LastupdatedTeamMeta {
	event: string;
	resource: string;
	project: string;
	projectTitle: string;
	lastModified: number;
	params: LastupdatedTeamMetaParams[];
}

export interface LastupdatedTeamMetaParams {
	userId: string;
	fromName: string;
	toName: string;
	domain: string;
	version: string;
	isPatch: boolean;
	isCopy: boolean;
}

export interface LastUserActivityInTeamResult {
	lastUpdaterUsers: LastUserActivityMeta[];
	totalElements: number;
}

export interface LastUserActivityMeta {
	username: string;
	userid: string;
	project: string;
	projectTitle: string;
	lastModified: number;
}

@Component({
	template: template,
	components: {
		'project-last-updates-list': ProjectLastUpdatesList,
	},
})
export default class Dashboard extends Vue {
	private teamId: string | undefined;
	private latestTeamUpdates: LastupdatedTeamMeta[] = [];
	private latestUpdates: ProjectUpdateMeta[] = [];
	private teamActivities: LastUserActivityMeta[] = [];

	get team(): TeamMeta | undefined {
		if (teamModule.team) {
			return teamModule.team;
		}
	}

	beforeRouteEnter(to: Route, from: Route, next: NextFunction): void {
		(async (): Promise<void> => {
			const projectList = await LoadingService.waitForPromise(UserService.getJoinableProjects(to.params.teamId));
			const hasProjectPermissions = projectList.projects.length > 0;

			next(async (vm) => {
				const res = vm as Dashboard;

				if (!res.team || !res.team.projects) {
					return;
				}

				vm.$startLoading();
				if (res.team.projects.length === 0 || !hasProjectPermissions) {
					res.$router.push({ name: 'joinProject', params: { teamId: res.team.id } });
					vm.$finishLoading();
					return;
				}
				await res.loadTeamResources(res.team.id);
				vm.$finishLoading();
			});
		})();
	}

	beforeRouteUpdate(to: Route, from: Route, next: NextFunction): void {
		this.$startLoading();
		(async (): Promise<void> => {
			const projectList = await UserService.getJoinableProjects(to.params.teamId);
			const hasProjectPermissions = projectList.projects.length > 0;

			const team = this.team;
			if (!team || !team.projects) {
				return;
			}

			if (team.projects.length === 0 || !hasProjectPermissions) {
				this.$router.push({ name: 'joinProject', params: { teamId: team.id } });
				this.$finishLoading();
				return;
			}
			await this.loadTeamResources(team.id);
			this.$finishLoading();
			next();
		})();
	}

	@LoadingScreen({ showImmediately: true })
	private async loadTeamResources(teamId: string): Promise<void> {
		this.teamId = teamId;
		const lastTeamUpdates = await ErrorHandler.tryRequest(() => RespressoApi.loadLastUpdatedTeamMeta(this.teamId!));
		if (lastTeamUpdates) {
			this.latestTeamUpdates = lastTeamUpdates.lastupdated;
			this.latestUpdates = lastTeamUpdates.lastupdates;
		}

		const result = await ErrorHandler.tryRequest(() => RespressoApi.loadLastModifiedTeamMeta(this.teamId!));
		if (result != null) {
			this.teamActivities = result.lastUpdaterUsers;
		}
	}

    eventRelated(event: string): boolean {
        return event.startsWith('projectSaved.version');
    }

    get isOutsider() {
        return AccessService.isOutsider(this.team);
    }
}

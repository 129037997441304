import Vue from 'vue';
import Component from 'vue-class-component';
import template from './yes-no-cancel.html';
import './yes-no-cancel.scss';

import {IDialog} from '@ponte/dialog';
import {IDialogComponent} from '@ponte/dialog-vue';
import {Prop} from 'vue-property-decorator';
import DialogTemplate from '../dialog-template/dialog-template';

export interface YesNoCancelDialogInput {
	title: string;
	message: string;
	yesCb?: () => void;
	noCb?: () => void;
	cancelCb?: () => void;
}

@Component({
	components: {
		'dialog-template': DialogTemplate,
	},
	template: template,
})
export default class YesNoCancel extends Vue implements IDialogComponent<boolean | undefined> {
	@Prop() inputData: YesNoCancelDialogInput | undefined;

	dialog!: IDialog<boolean | undefined>;

	yesClick(): void {
		if (this.dialog.dialogController) this.dialog.dialogController.close(true);
		if (this.inputData && this.inputData.yesCb) this.inputData.yesCb();
	}

	noClick(): void {
		if (this.dialog.dialogController) this.dialog.dialogController.close(false);
		if (this.inputData && this.inputData.noCb) this.inputData.noCb();
	}
	cancelClick(): void {
		if (this.dialog.dialogController) this.dialog.dialogController.close(undefined);
		if (this.inputData && this.inputData.cancelCb) this.inputData.cancelCb();
	}
}

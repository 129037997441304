import Vue from 'vue';
import template from './new-version-dialog.html';
import './new-version-dialog.scss';
import Component from 'vue-class-component';
import {IDialogComponent} from '@ponte/dialog-vue';
import {IDialog} from '@ponte/dialog';
import DialogTemplate from '../dialog/dialogs/dialog-template/dialog-template';
import {Prop} from 'vue-property-decorator';

@Component({
	components: {
		'dialog-template': DialogTemplate,
	},
	template: template,
})
export default class NewVersionDialog extends Vue implements IDialogComponent<string | null> {
	@Prop() version!: string;
	@Prop({default: null}) source!: string;

	private versionName = '';

	dialog!: IDialog<string | null>;

	public closeDialog(): void {
		if (this.dialog.dialogController) this.dialog.dialogController.close(null);
	}

	public closeAndGetVersion(): void {
		if (this.versionName && this.valid) {
			if (this.dialog.dialogController) this.dialog.dialogController.close(this.versionName);
		} else {
			if (this.dialog.dialogController) this.dialog.dialogController.close(null);
		}
	}

	public created(): void {
		this.versionName = this.version ?? '';
	}

	private get valid() {
		if (this.versionName) {
			if (/^[0-9]+\.[0-9]+\.[0-9]+$/g.test(this.versionName)) {
				return true;
			} else if (/^[a-z0-9]+([._-][a-z0-9]+)*$/g.test(this.versionName)) {
				return true;
			}
		}

		return false;
	}
}

import Vue from 'vue';
import Component from 'vue-class-component';
import template from './new-password.html';
import './new-password.scss';
import ErrorHandler from '../../../services/error-handler';
import UserService from '../../../services/user-service';
import {NotificationBuilder} from '../../common/dialog/dialog';
import LoadingScreen from '../../../decorators/loading-screen';
import PasswordInput from '../../common/password-input/password-input';

export interface PassModResponse {
	status: string;
}

@Component({
	props: {
		passToken: String,
		newPassword: String,
	},
	template: template,
})
export default class NewPassword extends Vue {
	private modifying = false;
	private missingPassToken = false;
	private passToken: string | undefined;
	private resp: any;
	private confirmPass = '';

	beforeMount(): void {
		if (!this.passToken) {
			this.missingPassToken = true;
		}
	}

	created(): void {
		if (this.missingPassToken === true) {
			this.$router.push({ name: 'login', query: { redirect: this.$route.fullPath } });
		}
	}

	@LoadingScreen({ fullScreen: true })
	protected async saveNewPassword(): Promise<void> {
		// FIXME, ha ez így nem elég, akkor máshogy validálni a két jelszót egymáshoz képest és "normális" hibaüzeneteket betenni

		if (this.newPass != null) {
			if (this.newPass === this.confirmPass) {
				const pwInput = this.$refs.pwInput as PasswordInput;
				if (!pwInput || !pwInput.checkPassword()) {
					NotificationBuilder.error('#serverError.respressoError.authService.password.weak');
					return;
				}
				this.resp = await ErrorHandler.tryRequest(() =>
					UserService.resetPassword(this.passToken!, this.newPass),
				);
				if (this.resp.state === 'OK') {
					NotificationBuilder.success('#success.password.modification');
				} else {
					NotificationBuilder.error('#user.passwordMod.error');
				}
				this.$router.push({ name: 'login' });
			} else {
				NotificationBuilder.error('#user.preferences.notMatching');
			}
		} else {
			NotificationBuilder.error('#user.preferences.fillOutAll');
		}
	}

	private get newPass(): string {
		const pwInput = this.$refs.pwInput as PasswordInput;
		if (pwInput) return pwInput.typedPassword;
		return '';
	}
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import Component from 'vue-class-component';
import template from './vue-slim-select.html';
import './slim-select.scss';

import SlimSelect from 'slim-select';
import {Option} from 'slim-select/dist/data';
import {Prop} from "vue-property-decorator";

// example: <vue-slim-select :close-on-select="true" :before-on-change="(info) => beforeLanguageChange(info)" @onChange="(info) => languageChanged(info)"></vue-slim-select>

//Events: item-added(item: SlimSelectItem), item-removed(item: SlimSelectItem), on-change(info: SlimSelectInfo | SlimSelectInfo[]),
// before-open, after-open, before-close, after-close,
// selected-items-changed(info: SlimSelectInfo | SlimSelectInfo[], newElement: SlimSelectOption[], removedElements: SlimSelectOption[])

export interface SlimSelectOption {
	text: string; // Required
	value?: string; // Optional - value will be set by text if not set
	innerHTML?: string; // Optional - will be used for dispay purposes if set
	disabled?: boolean; // Optional - default is false
	placeholder?: boolean; // Optional - default is false
	selected?: boolean; // Optional - default is false
}

export interface SlimSelectInfo extends SlimSelectOption {
	id?: string;
	selected?: boolean;
	data?: any;
}

export interface SlimSelectOptionGroup {
	label: string;
	options: SlimSelectOption[];
}

@Component({
	props: {
		placeholder: String,
		allowDeselect: Boolean,
		addable: Boolean,
		valuesUseText: Boolean,
		showSearch: Boolean,
		searchText: String,
		searchHighlight: Boolean,
		closeOnSelect: Boolean,
		showContent: String, // 'auto', 'up' or 'down'
		selectedItems: {
			type: Array,
			default: (): any[] => [],
		},
		beforeOnChange: Function,
		options: Array,
		multiSelect: Boolean,
		disabled: Boolean,
	},
	watch: {
		disabled(val: boolean, oldVal: boolean): void {
			const self = this as VueSlimSelect;
			if (val !== oldVal) {
				if (val) {
					self.disable();
				} else {
					self.enable();
				}
			}
		},
		options(val: SlimSelectOption[], oldVal: SlimSelectOption[]): void {
			const self = this as VueSlimSelect;
			self.setData(val);
		},
	},
	template: template,
})
export default class VueSlimSelect extends Vue {
	private placeholder: string | undefined;
	private allowDeselect: boolean | undefined;
	private addable: ((value: string) => string | false) | false | undefined;
	private valuesUseText: boolean | undefined;
	private showSearch: boolean | undefined;
	private searchText: string | undefined;
	private searchHighlight: boolean | undefined;
	private closeOnSelect: boolean | undefined;
	private showContent: 'auto' | 'up' | 'down' | undefined;
	private beforeOnChange: ((info: Option | Option[]) => boolean | void) | undefined;
	private options: SlimSelectOption[] | undefined;
	private selectedItems: SlimSelectOption[] | undefined;
	@Prop({default: true})
	private addOnEnter: boolean | undefined;

	private id: string = 'vue' + Math.random();

	private slimSelect: any;

	private disabled: any;

	constructor() {
		super();
	}

	public addNewItem(item: SlimSelectOption): void {
		const items = this.getSelectedItems();

		if (items.indexOf(item) === -1) {
			items.push(item);
			this.$emit('item-added', item);
		}
	}

	public removeItem(item: SlimSelectOption): void {
		const items = this.getSelectedItems();
		const index = items.indexOf(item);

		if (index !== -1) {
			items.splice(index, 1);
			this.$emit('item-removed', item);
		}
	}

	public selected(): string[] {
		if (this.slimSelect) {
			return this.slimSelect.selected();
		}
		return [];
	}

	public set(item: SlimSelectOption | SlimSelectOption[]): void {
		return this.slimSelect.set(item);
	}

	public search(): void {
		return this.slimSelect.search();
	}

	public enable(): void {
		return this.slimSelect.enable();
	}

	public disable(): void {
		return this.slimSelect.disable();
	}

	public open(): void {
		return this.slimSelect.open();
	}

	public close(): void {
		return this.slimSelect.close();
	}

	public setData(items: SlimSelectOption[] | SlimSelectOptionGroup[] | SlimSelectInfo[]): void {
		return this.slimSelect.setData(items);
	}

	public updateData(): void {
		this.setData(this.getData());
	}

	private arrayDiff(arr1: SlimSelectOption[], arr2: SlimSelectOption[]): SlimSelectOption[] {
		return arr1.filter((option) => {
			return arr2.every((option2) => option.text !== option2.text);
		});
	}

	private getOptions(): SlimSelectOption[] {
		const values = new Set<string>();
		const options: SlimSelectOption[] = [];
		this.getSelectedItems().forEach((item: SlimSelectOption) => {
			if (!item.value) {
				options.push(item);
			} else if (!values.has(item.value)) {
				values.add(item.value);
				options.push(item);
			}
		});
		if (this.options) {
			this.options.forEach((option: SlimSelectOption) => {
				if (!option.value) {
					options.push(option);
				} else if (!values.has(option.value)) {
					values.add(option.value);
					options.push(option);
				}
			});
		}

		return options;
	}

	private getData(): SlimSelectInfo[] {
		const selectedItems = this.getSelectedItems();
		const selectedValues = this.selected();
		return this.getOptions().map((item) => {
			const it = item as SlimSelectInfo;
			const foundItem = selectedItems.find((selectedItem) => {
				return (
					selectedItem.text === item.text ||
					(selectedItem.value !== undefined && item.value !== undefined && selectedItem.value === item.value)
				);
			});
			if (foundItem) {
				it.selected = true;
			}
			selectedValues.forEach((selectedValue) => {
				if (selectedValue === item.value) {
					it.selected = true;
				}
			});
			return it;
		});
	}

	private getSelectedItems(): SlimSelectOption[] {
		if (!this.selectedItems) {
			this.selectedItems = [];
		}
		return this.selectedItems;
	}

	private getAddableFunction(): (value: string) => boolean | string {
		const addable = this.addable;
		if (typeof addable === 'boolean') {
			return (): boolean => addable;
		}
		if (typeof addable === 'function') {
			return addable;
		}
		return function (this: any, value: string): string | boolean {
			const containsValue = this.data.data.filter(function (item: any): string | boolean {
				return item.value === value;
			});

			if (containsValue.length !== 0) {
				return false;
			}

			return value;
		};
	}

	private tryToAddCurrentSearchTextAsElement() {
		// This is pretty much a hack but works fine
		// Simulate that the add button is clicked by the user on enter key down
		const element = this.$refs.selectElement as Element | undefined;
		if(element) {
			const sibling = element.nextElementSibling;
			if(sibling && sibling.classList.contains("ss-main")) {
				const inputs = sibling.getElementsByTagName("input");
				if(inputs && inputs.length) {
					const input = inputs[0] as HTMLInputElement;
					if(input === document.activeElement) {
						const searchValue = input.value;
						if(searchValue) {
							const button = input.nextElementSibling as HTMLElement;
							if(button && button.classList.contains("ss-addable")) {
								button.click();
							}
						}
					}
				}
			}
		}
	}

	private onKeyDown(event: KeyboardEvent): void {
		if(this.addOnEnter && !event.ctrlKey && !event.metaKey && !event.shiftKey && !event.altKey) {
			if (event.keyCode === 13 || event.key === 'Enter') {
				this.tryToAddCurrentSearchTextAsElement();
			}
		}
	}

	mounted(): void {
		const data = this.getData();

		const select = this.$refs.selectElement;
		if (!(select instanceof Element)) {
			throw new Error('Select element must be an Element');
		}

		this.slimSelect = new SlimSelect({
			select,
			data,
			placeholder: this.placeholder || 'items...',
			allowDeselect: this.allowDeselect || false,
			addable: (s: string): string => {
				return s;
			},
			onChange: (info: Option | Option[]): void => {
				// TODO SlimSelect-et ellenőrizni, ő Option-t mond, de valószínű nem igaz
				let slimSelectItems: any[] = [];

				if (Array.isArray(info)) {
					slimSelectItems = (info as Option[]).map(function (item: Option) {
						return item;
					});
				} else {
					slimSelectItems = [info];
				}

				const currentValues = this.getSelectedItems();

				const newElements = this.arrayDiff(slimSelectItems, currentValues);
				const removedElements = this.arrayDiff(currentValues, slimSelectItems);

				newElements.forEach((value: SlimSelectOption) => {
					this.addNewItem(value);
				});

				removedElements.forEach((value: SlimSelectOption) => {
					this.removeItem(value);
				});

				this.$emit('on-change', info);

				if (newElements.length !== 0 || removedElements.length !== 0) {
					this.$emit('selected-items-changed', info, newElements, removedElements);
				}
			},
			showSearch: this.showSearch || false,
			searchText: this.searchText || 'No Result',
			searchHighlight: this.searchHighlight || false,
			closeOnSelect: this.closeOnSelect !== undefined ? this.closeOnSelect : true,
			showContent: this.showContent || 'auto',
			beforeOnChange: this.beforeOnChange,
			beforeOpen: (): VueSlimSelect => this.$emit('before-open'),
			afterOpen: (): VueSlimSelect => this.$emit('after-open'),
			beforeClose: (): VueSlimSelect => this.$emit('before-close'),
			afterClose: (): VueSlimSelect => this.$emit('after-close'),
			valuesUseText: this.valuesUseText || false,
		});

		if (this.disabled) {
			this.disable();
		} else {
			window.addEventListener('keydown', this.onKeyDown);
		}
	}

	private unmounted(): void {
		window.removeEventListener('keydown', this.onKeyDown);
	}

	beforeDestroy(): void {
		this.slimSelect.destroy();
	}
}

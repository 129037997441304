import Vuex, {StoreOptions} from 'vuex';
import Vue from 'vue';
import {RootState} from './types';

Vue.config.devtools = process.env.VUE_APP_vueDebug || true;

Vue.use(Vuex);
export const store: StoreOptions<RootState> = new Vuex.Store({});

export default new Vuex.Store<RootState>(store);

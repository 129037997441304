import LoadingService from '../services/loading-service';

export const LoadingPlugin = {
	install(vue: any): void {
		vue.prototype.$startLoading = function (message?: string): void {
			this.__loaderDoneFunctions__ = this.__loaderDoneFunctions__ || [];
			this.__loaderDoneFunctions__.push(LoadingService.startWithTimeout({ message, showImmediately: true }));
		};
		vue.prototype.$finishLoading = function (): void {
			if (this.__loaderDoneFunctions__ && this.__loaderDoneFunctions__.length > 0) {
				this.__loaderDoneFunctions__.pop()();
			}
		};
	},
};

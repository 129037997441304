import Vue from 'vue';
import Component from 'vue-class-component';
import template from './register.html';
import './register.scss';

@Component({
	props: {
		regToken: String,
	},
	template: template,
})
export default class Register extends Vue {
	private regToken: string | undefined;
}

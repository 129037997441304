import {app} from '../main';

export class AnalyticsServiceImpl {
	public sendEvent(eventCategory: string, eventAction: string, eventLabel?: string, eventValue?: number): void {
		(app as any).$ga.event({
			//FIXME typings
			eventCategory,
			eventAction,
			eventLabel,
			eventValue,
		});
	}

	public unimplementedFeature(name = 'UNKNOWN', requested = false): void {
		this.sendEvent('Not Implemented Feature', 'Click', name, requested ? 1 : 0);
	}
}

export const AnalyticsService = new AnalyticsServiceImpl();
export default AnalyticsService;

import Vue from 'vue';
import Component from 'vue-class-component';
import template from './menu.html';
import './menu.scss';
import UserService from '../../services/user-service';
import {ProjectMeta, TeamInfo, TeamMeta} from 'respresso';
import {teamModule} from '../../store/modules/team/index';
import {menuModule} from '../../store/modules/menu/index';
import AccessService from '../../services/access-service';
import {landingHost} from "../../env";
import StorageService from "../../services/storage-service";

@Component({
	template: template,
})
export default class Menu extends Vue {
	private search = '';

	get teams(): TeamInfo[] | null {
		return teamModule.teams;
	}

	get team(): TeamMeta | null {
		return teamModule.team;
	}

	public isFiltered(projectMeta: ProjectMeta): boolean {
		if (!this.search) {
			return true;
		}

		return projectMeta.title.toLowerCase().indexOf(this.search.toLocaleLowerCase()) !== -1;
	}

	public showWhatsNew(): void {
		menuModule.showWhatsNew();
	}

	private isTeamAdmin() {
		return AccessService.hasTeamRole('ADMIN', this.team);
	}

	private highlightImageConverter() {
		return StorageService.getIsSignedUpInThisBrowser() && StorageService.getImageConverterLastUsage() === null
	}

	private get docUrl() {
		return `${landingHost}/docs`
	}

	created() {
		UserService.getOrLoadTeams().then(()=>{
			this.teams;
			this.team;
		});
	}
}

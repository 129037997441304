import store from '../../index';
import {Action, Module, Mutation, VuexModule} from 'vuex-class-modules';

@Module
class MenuModule extends VuexModule {
	_hideMenu = false;
	_hideWhatsNew = true;

	get hideMenu(): boolean {
		return this._hideMenu;
	}
	get hideWhatsNew(): boolean {
		return this._hideWhatsNew;
	}

	@Mutation
	HIDE_MENU(payload: boolean): void {
		this._hideMenu = payload;
	}
	@Mutation
	HIDE_WHATSNEW(payload: boolean): void {
		this._hideWhatsNew = payload;
	}

	@Action
	showWhatsNew(): void {
		this.HIDE_WHATSNEW(false);
	}

	@Action
	closeWhatsNew(): void {
		this.HIDE_WHATSNEW(true);
	}

	@Action
	remove(): void {
		this.HIDE_MENU(true);
		this.HIDE_WHATSNEW(true);
	}
}

export const menuModule = new MenuModule({ store, name: 'menu' });

import Vue from 'vue';
import Component from 'vue-class-component';
import template from './version-list.html';
import './version-list.scss';
import {ProjectMeta, ResourceVersions, TeamMeta, VersionMeta, VersionNumber} from 'respresso';
import RespressoApi from '../../../api/respresso-api';
import {NextFunction, RawLocation, Route} from 'vue-router';
import StorageService from '../../../services/storage-service';
import {DialogBuilder, NotificationBuilder} from '../../common/dialog/dialog';
import NewVersionDialog from '../../common/version/new-version-dialog';
import ErrorHandler from '../../../services/error-handler';
import LoadingScreen from '../../../decorators/loading-screen';
import LoadingService from '../../../services/loading-service';
import {teamModule} from '../../../store/modules/team/index';
import AccessService from '../../../services/access-service';
import {i18n} from '../../../main';
import {routingModule} from '../../../store/modules/routing';

const supportedResourceTypes = ['localization', 'image', 'color', 'font', 'appIcon', 'raw'];

interface TargetVersionList {
	teamId: string;
	projectId: string;
	resourceId: string;
	versions: ResourceVersions | null;
	autoVersion: string | null;
	safeAutoVersion: string | null;
}

@Component({
	template: template,
})
export default class VersionList extends Vue {
	private teamId = '';
	private projectId = '';
	private resourceId = '';

	private versions: ResourceVersions | null = null;
	private currentVersion = '';

	async beforeRouteEnter(to: Route, from: Route, next: NextFunction): Promise<void> {
		const target = await VersionList.preloadData(to);
		if (target) {
			if (target.autoVersion) {
				const lastLang = VersionList.getLastLocalizationLanguage(target.teamId, target.projectId);
				if (target.autoVersion == target.safeAutoVersion) {
					NotificationBuilder.success(
						i18n.t('resource.list.autoOpenedVersion', { version: target.autoVersion }) as string,
					);
				} else {
					NotificationBuilder.warning(
						i18n.t('resource.list.autoOpenedUnsafeVersion', {
							version: target.autoVersion,
							safeVersion: target.safeAutoVersion,
						}) as string,
					);
				}
				routingModule.disallowRouteReuse();
				next(
					VersionList.getOpenVersionRoute(
						target.teamId,
						target.projectId,
						target.resourceId,
						lastLang,
						target.autoVersion,
					),
				);
			} else {
				next((vm) => {
					const versionList = vm as VersionList;
					versionList.loadData(versionList, to, target);
				});
			}
		} else {
			next((vm) => {
				const versionList = vm as VersionList;
				versionList.$router.push({
					name: 'project',
					params: { teamId: versionList.teamId, projectId: versionList.projectId },
				});
				DialogBuilder.alert(
					'#resource.notFoundTitle',
					versionList.$t('resource.notFound', {
						resourceId: versionList.resourceId,
					}) as string,
				);
			});
		}
	}

	async beforeRouteUpdate(to: Route, from: Route, next: NextFunction): Promise<void> {
		try {
			const result = await this.loadData(this, to);
			if (result) {
				next(this.getOpenVersionRoute(result));
			} else {
				next();
			}
		} catch (e) {
			console.log(e);
		}
	}

	@LoadingScreen({ showImmediately: true })
	private async loadData(
		vm: VersionList,
		route: Route,
		preloaded?: TargetVersionList | null,
	): Promise<string | null> {
		if (preloaded === undefined) {
			preloaded = await VersionList.preloadData(route);
		}
		if (preloaded === null) {
			vm.$router.push({ name: 'project', params: { teamId: vm.teamId, projectId: vm.projectId } });
			DialogBuilder.alert(
				'#resource.notFoundTitle',
				vm.$t('resource.notFound', {
					resourceId: vm.resourceId,
				}) as string,
			);
		} else {
			vm.teamId = preloaded.teamId;
			vm.projectId = preloaded.projectId;
			vm.resourceId = preloaded.resourceId;
			vm.versions = preloaded.versions;
		}
		return preloaded && preloaded.autoVersion ? preloaded.autoVersion : null;
	}

	@LoadingScreen({ showImmediately: true })
	private static async preloadData(route: Route): Promise<TargetVersionList | null> {
		const teamId = route.params.teamId;
		const projectId = route.params.projectId;
		const resourceId = route.params.resourceId;
		let autoVersion = null;
		let safeAutoVersion = null;
		if (supportedResourceTypes.indexOf(resourceId) === -1) {
			return null;
		}
		const versions = await ErrorHandler.tryRequest(
			() => RespressoApi.getResourceVersions(teamId, projectId, resourceId),
			{
				loadingScreen: true,
			},
		);
		const disableAutoOpen = route.params.disableAutoOpen || route.query.hasOwnProperty('disableAutoOpen');
		if (versions && !disableAutoOpen) {
			const largestVersion = VersionList.getLargestVersion(versions);
			const largestVersionString = largestVersion ? VersionList.versionNumberString(largestVersion) : null;
			const lastOpenedVersionString = StorageService.getLastOpenedVersion(teamId, projectId, resourceId);
			safeAutoVersion = largestVersionString;
			if (lastOpenedVersionString && VersionList.versionExists(versions, lastOpenedVersionString)) {
				if (largestVersionString !== lastOpenedVersionString) {
					autoVersion = lastOpenedVersionString;
				} else {
					autoVersion = lastOpenedVersionString;
				}
			} else if (largestVersion) {
				autoVersion = largestVersionString;
			}
		}
		return {
			teamId,
			projectId,
			resourceId,
			versions,
			autoVersion,
			safeAutoVersion,
		};
	}

	get team(): TeamMeta | null {
		return teamModule.team;
	}

	get project(): ProjectMeta | null {
		const moduleGetterTeam = teamModule.team;
		if (!moduleGetterTeam || !moduleGetterTeam.projects) {
			return null;
		}
		const project = moduleGetterTeam.projects.filter((p: ProjectMeta) => {
			return p.id === this.projectId;
		});
		if (project.length) {
			return project[0];
		} else {
			return null;
		}
	}

	get canVersionControl(): boolean {
		return AccessService.canVersionControl(this.project);
	}

	private get lastLocalizationLanguage(): string | null {
		return VersionList.getLastLocalizationLanguage(this.teamId, this.projectId);
	}

	private static getLastLocalizationLanguage(teamId: string, projectId: string): string | null {
		return StorageService.getLastLocalizationLanguage(teamId, projectId);
	}

	private getOpenVersionRoute(version: string): RawLocation {
		return VersionList.getOpenVersionRoute(
			this.teamId,
			this.projectId,
			this.resourceId,
			this.lastLocalizationLanguage,
			version,
		);
	}

	public static getOpenVersionRoute(
		teamId: string,
		projectId: string,
		resourceId: string,
		lastLocalizationLanguage: string | null,
		version: string,
	): RawLocation {
		StorageService.setLastOpenedVersion(teamId, projectId, resourceId, version);
		if (resourceId === 'localization' && lastLocalizationLanguage) {
			return {
				name: 'localizationResourceWithLanguage',
				params: {
					teamId: teamId,
					projectId: projectId,
					resourceId: resourceId,
					versionNumber: version,
					language: lastLocalizationLanguage,
				},
			};
		} else {
			return {
				name: resourceId + 'Resource',
				params: {
					teamId: teamId,
					projectId: projectId,
					resourceId: resourceId,
					versionNumber: version,
				},
			};
		}
	}

	private openVersion(version: string) {
		this.$router.push(this.getOpenVersionRoute(version));
	}

	private async copy(version: VersionMeta): Promise<void> {
		const newVersionNumber = VersionList.stringToVersionNumber(version.versionNumber);

		const newVersion = await this.getNewVersion(newVersionNumber || version.versionNumber);

		if (newVersion) {
			await LoadingService.waitForPromise(
				this.doCreateVersion(newVersion, '#success.copy', version.versionNumber),
			);
		}
	}

	@LoadingScreen()
	private async patch(version: VersionMeta): Promise<void> {
		const response = await ErrorHandler.tryRequest(() =>
			RespressoApi.createPatchVersion(this.teamId, this.projectId, this.resourceId, version.versionNumber),
		);
		if (response) {
			await this.loadData(this, this.$route);
		}
		setTimeout(() => {
			this.$forceUpdate();
		}, 3500);
	}

	private async remove(version: VersionMeta): Promise<void> {
		await DialogBuilder.confirm('#version.list.delete.title', '#dialog.areYouSure.simple', async () => {
			await this.executeVersionRemove(version);
		});
	}

	@LoadingScreen()
	private async executeVersionRemove(version: VersionMeta) {
		const response = await ErrorHandler.tryRequest(() =>
			RespressoApi.deleteVersion(this.teamId, this.projectId, this.resourceId, version.versionNumber),
		);
		if (response) {
			await this.loadData(this, this.$route);
		}
		setTimeout(() => {
			this.$forceUpdate();
		}, 3500);
	}

	private async newVersion(): Promise<void> {
		const newVersion = await this.getNewVersion();
		if (newVersion) {
			await LoadingService.waitForPromise(this.doCreateVersion(newVersion, '#messages.created'));
		}
	}

	private async doCreateVersion(versionNumber: string, successMsg: string, copyVersion?: string): Promise<void> {
		this.currentVersion = versionNumber;

		const result = await ErrorHandler.tryRequest(() =>
			RespressoApi.createVersion(this.teamId, this.projectId, this.resourceId, versionNumber, copyVersion),
		);
		if (result) {
			NotificationBuilder.success(successMsg);
			await this.loadData(this, this.$route);

			setTimeout(() => {
				this.currentVersion = '';
			}, 3500);
		} else {
			this.currentVersion = '';
		}
	}

	private async getNewVersion(copyVersion?: VersionNumber | string): Promise<string | null> {
		return new Promise<string | null>((resolve): void => {
			let version = '1.0.0';
			if (copyVersion) {
				if (typeof copyVersion === 'string') {
					version = copyVersion + '_copy';
				} else {
					version = `${copyVersion.major}.${copyVersion.minor + 1}.0`;
				}
			} else if (this.versions && this.versions.versions && this.versions.versions.length > 0) {
				const largest = VersionList.getLargestVersion(this.versions)!;
				version = `${largest.major}.${largest.minor + 1}.0`;
			}
			const dialogController = DialogBuilder.createVueDialog(NewVersionDialog, {
				propsData: {
					version,
					source: copyVersion ? (typeof copyVersion === "string" ? copyVersion : VersionList.versionNumberString(copyVersion)) : null,
				},
			});
			dialogController.closeResult.then((res): void => {
				resolve(res);
			});
		});
	}

	private static getLargestVersion(versions: ResourceVersions): VersionNumber | null {
		if (versions && versions.versions && versions.versions.length > 0) {
			let largest: VersionNumber | null = null;
			for (let i = 0; i < versions.versions.length; i++) {
				if (largest) {
					const v = VersionList.stringToVersionNumber(versions.versions[i].versionNumber);
					if (v) {
						if (v.major > largest.major) {
							largest = v;
						} else if (v.major === largest.major) {
							if (v.minor > largest.minor) {
								largest = v;
							}
							// Patch does not matter
						}
					}
				} else {
					largest = VersionList.stringToVersionNumber(versions.versions[i].versionNumber);
				}
			}
			return largest;
		}
		return null;
	}

	private static versionExists(versions: ResourceVersions, version: string): boolean {
		if (versions && versions.versions && versions.versions.length > 0) {
			return versions.versions.some((v) => v.versionNumber === version);
		}
		return false;
	}

	private static stringToVersionNumber(version: string): VersionNumber | null {
		if (/^[0-9]+\.[0-9]+\.[0-9]+$/g.test(version)) {
			const parts = version.split('.');
			return {
				major: parseInt(parts[0], 10),
				minor: parseInt(parts[1], 10),
				patch: parseInt(parts[2], 10),
			};
		} else {
			return null;
		}
	}

	private static versionString(major: number, minor = 0, patch = 0): string {
		return `${major}.${minor}.${patch}`;
	}

	private static versionNumberString(version: VersionNumber): string {
		return VersionList.versionString(version.major, version.minor, version.patch);
	}

	@LoadingScreen()
	public finalize(version: VersionMeta): void {
		DialogBuilder.confirm('#resource.list.finalize.title', '#resource.list.finalize.warning', async () => {
			const response = await ErrorHandler.tryRequest(() =>
				RespressoApi.finalizeVersion(this.teamId, this.projectId, this.resourceId, version.versionNumber),
			);
			if (response) {
				NotificationBuilder.success('#success.finalize');
				version.finalizable = false;
				this.loadData(this, this.$route);
			}
		});
		setTimeout(() => {
			this.$forceUpdate();
		}, 3500);
	}
}

export class DamerauLevenshtein {
	private initMatrix(s1: string[], s2: string[]): number[][] | null {
		if (undefined == s1 || undefined == s2) {
			return null;
		}

		const d: number[][] = [];
		for (let i = 0; i <= s1.length; i++) {
			d[i] = [];
			d[i][0] = i;
		}
		for (let j = 0; j <= s2.length; j++) {
			d[0][j] = j;
		}

		return d;
	}

	private damerau(i: number, j: number, s1: string[], s2: string[], d: number[][], cost: number) {
		if (i > 1 && j > 1 && s1[i - 1] === s2[j - 2] && s1[i - 2] === s2[j - 1]) {
			d[i][j] = Math.min.apply(null, [d[i][j], d[i - 2][j - 2] + cost]);
		}
	}

	private dlDistance(s1: string[], s2: string[]): number {
		if (undefined == s1 || undefined == s2) {
			return -1;
		}

		const d = this.initMatrix(s1, s2);
		if (null === d) {
			return -1;
		}
		for (let i = 1; i <= s1.length; i++) {
			let cost: number;
			for (let j = 1; j <= s2.length; j++) {
				if (s1[i - 1] === s2[j - 1]) {
					cost = 0;
				} else {
					cost = 1;
				}

				d[i][j] = Math.min.apply(null, [d[i - 1][j] + 1, d[i][j - 1] + 1, d[i - 1][j - 1] + cost]);

				this.damerau(i, j, s1, s2, d, cost);
			}
		}
		return d[s1.length][s2.length];
	}

	private splitSentence(str: string): string[] {
		const words = str.toLowerCase().match(/\S+/g);
		return words ? words : [];
	}

	public async distancePromise(s1: string, s2: string): Promise<number> {
		return new Promise((resolve, reject) => {
			const result = this.distance(s1, s2);
			if (0 <= result) {
				resolve(result);
			} else {
				reject(result);
			}
		});
	}

	public distance(string1: string, string2: string): number {
		const words1 = this.splitSentence(string1);
		const words2 = this.splitSentence(string2);

		const length = Math.max(words1.length, words2.length);
		const x = this.dlDistance(words1, words2);

		return Math.ceil((x / length) * 100);
	}
}

const damerauLevenshtein = new DamerauLevenshtein();
export default damerauLevenshtein;

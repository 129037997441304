import Vue from 'vue';
import Component from 'vue-class-component';
import template from './project-permissions.html';
import './project-permissions.scss';
import {NextFunction, Route} from 'vue-router';
import ErrorHandler from '../../../services/error-handler';
import UserService from '../../../services/user-service';
import {DialogBuilder, NotificationBuilder} from '../../common/dialog/dialog';
import LoadingScreen from '../../../decorators/loading-screen';
import {PendingUserMeta, ProjectMeta, ProjectRole, TeamMeta, UserPublicInfo} from 'respresso';
import {teamModule} from '../../../store/modules/team/index';
import AccessService from '../../../services/access-service';
import RespressoApi from '../../../api/respresso-api';
import {MemberStatus, ProjectBasicData, ProjectMemberInfo} from '../project-settings/project-settings';
import {SlimSelectOption} from '../../common/vue-slim-select/vue-slim-select';
import {translate} from '../../../main';
import CreateProjectInvite from "./create-project-invite";

interface ProjectMemberInfoExtended extends ProjectMemberInfo {
	userInfo?: UserPublicInfo;
}

@Component({
	template: template,
})
export default class ProjectPermissions extends Vue {
	private teamId = '';
	private projectId = '';

	private projectTitle = '';
	private projectInfo: ProjectBasicData | undefined;
	private members: ProjectMemberInfoExtended[] = [];
	private pending: ProjectMemberInfoExtended[] = [];
	private mayAdd: UserPublicInfo[] = [];
	private mayAddRoles: Map<string, ProjectRole> = new Map<string, ProjectRole>();

	beforeRouteEnter(to: Route, from: Route, next: NextFunction): void {
		next((vm) => {
			const res = vm as ProjectPermissions;
			res.loadProjectMembers(to);
		});
	}

	@LoadingScreen({ showImmediately: true })
	private async loadProjectMembers(to: Route): Promise<void> {
		this.teamId = to.params.teamId;
		this.projectId = to.params.projectId;

		await this.fetchProjectMembers();
	}

	private async fetchProjectMembers() {
		if (this.canEditProjectPermissions) {
			const resp = await ErrorHandler.tryRequest(() => RespressoApi.getProject(this.teamId!, this.projectId!));
			if (resp) {
				this.projectInfo = resp;
				this.projectTitle = resp.title;
				const associated = new Set(
					resp.members.filter((value) => value.member !== MemberStatus.false).map((value) => value.userId),
				);
				const team = this.team;
				this.members = this.prepareMemberInfo(
					resp.members.filter((value) => value.role != null),
					team,
				);
				this.pending = this.prepareMemberInfo(
					resp.members.filter((value) => value.member.toLowerCase() === MemberStatus.pending.toLowerCase()),
					team,
				);
				this.mayAdd = team!.users!.filter((value) => !associated.has(value.id));
				this.$forceUpdate();
			}
		} else {
			DialogBuilder.alert(
				'#project.permissionDeniedTitle',
				this.$t('project.permissionDenied', {
					projectName: this.projectId,
				}) as string,
			);
			UserService.loadTeam(this.teamId);
			this.$router.push({ name: 'dashboard', params: { teamId: this.teamId } });
		}
	}

	private prepareMemberInfo(members: ProjectMemberInfoExtended[], team: TeamMeta | null): ProjectMemberInfo[] {
		members.forEach((it) => this.attachInfo(it, team));
		return members.sort((a, b) => a.userInfo!.name.localeCompare(b.userInfo!.name));
	}

	private attachInfo(member: ProjectMemberInfoExtended, team: TeamMeta | null) {
		if (!member.userInfo) {
			member.userInfo = team?.users?.find((it) => it.id === member.userId);
		}
	}

	private isCurrentUser(user: ProjectMemberInfoExtended) {
		const currentUser = UserService.getUser();
		return !!(currentUser && currentUser.id === user.userId);
	}

	get projectName(): string | undefined {
		const project = this.project;
		if (project) {
			return project.title;
		}
	}

	get team(): TeamMeta | null {
		return teamModule.team;
	}

	get project(): ProjectMeta | null {
		const moduleGetterTeam = this.team;
		if (!moduleGetterTeam || !moduleGetterTeam.projects) {
			return null;
		}
		const project = moduleGetterTeam.projects.filter((p: ProjectMeta) => {
			return p.id === this.projectId;
		});
		if (project.length) {
			return project[0];
		} else {
			return null;
		}
	}

	get projectInvites(): PendingUserMeta[] {
		const team = this.team;
		if (team && team.pendingUsers) {
			return team.pendingUsers.filter(it => it.projects && it.projects.some(p => p.projectId === this.projectId));
		}
		return [];
	}

	get canEditProjectPermissions() {
		return AccessService.canEditProjectPermissions(this.project);
	}

	get canEditTeamPermissions() {
		return AccessService.canEditTeamPermissions(this.team);
	}

	get hasAnyPendingRequest() {
		return this.pending && this.pending.length > 0;
	}

	get hasAnyInvites() {
		return this.projectInvites && this.projectInvites.length > 0;
	}

	get hasAnyNotYetUser() {
		return this.mayAdd && this.mayAdd.length > 0;
	}

	private getRole(role: string) {
		return translate(`project.role.${role.toLowerCase()}.name`);
	}

	private createUserRoleSelectOption(user: UserPublicInfo): SlimSelectOption[] {
		const roles: ProjectRole[] = ['VIEWER', 'MEMBER', 'EDITOR', 'ADMIN'];
		let userRole: string | undefined = user.role;
		if (this.mayAddRoles.has(user.id)) {
			userRole = this.mayAddRoles.get(user.id);
		}
		return roles.map((role) => {
			const option: SlimSelectOption = {
				text: this.getRole(role),
				value: role,
				selected: userRole === role,
			};
			return option;
		});
	}

	@LoadingScreen()
	private async roleChanged(user: ProjectMemberInfoExtended, event: any) {
		const role = event.value as ProjectRole;
		const response = await ErrorHandler.tryRequest(() =>
			RespressoApi.setProjectRole(this.teamId!, this.projectId!, user.userId, role),
		);
		await this.fetchProjectMembers();
		if (response != null) {
			NotificationBuilder.success('#project.settings.permissions.member.changeRole.success');
		}
	}

	private nonProjectMemberRoleChanged(user: UserPublicInfo, event: any) {
		const role = event.value as ProjectRole;
		this.mayAddRoles.set(user.id, role);
		this.$forceUpdate();
	}

	@LoadingScreen()
	private async addUser(user: UserPublicInfo) {
		const role = this.mayAddRoles.get(user.id) || "VIEWER";
		const response = await ErrorHandler.tryRequest(() => RespressoApi.setProjectRole(this.teamId!, this.projectId!, user.id, role));
		await this.fetchProjectMembers();
		if (response != null) {
			NotificationBuilder.success('#project.settings.permissions.member.addFromTeam.success');
		}
	}

	private async removeUser(user: ProjectMemberInfoExtended) {
		await DialogBuilder.confirm(
			'#project.settings.permissions.member.remove.confirm.title',
			'#project.settings.permissions.member.remove.confirm.message',
			async () => {
				await this.executeRemoveUser(user);
			},
		);
	}

	@LoadingScreen()
	private async executeRemoveUser(user: ProjectMemberInfoExtended) {
		const response = await ErrorHandler.tryRequest(() =>
			RespressoApi.deleteProjectRole(this.teamId!, this.projectId!, user.userId),
		);
		await this.fetchProjectMembers();
		if (response != null) {
			NotificationBuilder.success('#project.settings.permissions.member.remove.success');
		}
	}

	@LoadingScreen()
	private async acceptPendingUser(user: ProjectMemberInfoExtended) {
		const response = await ErrorHandler.tryRequest(() =>
			RespressoApi.acceptPendingMember(this.teamId!, this.projectId!, user.userId),
		);
		await this.fetchProjectMembers();
		if (response != null) {
			NotificationBuilder.success('#project.settings.permissions.pending.accept.success');
		}
	}

	private async rejectPendingUser(user: ProjectMemberInfoExtended) {
		await DialogBuilder.confirm(
			'#project.settings.permissions.pending.reject.confirm.title',
			'#project.settings.permissions.pending.reject.confirm.message',
			async () => {
				await this.executeRejectPendingUser(user);
			},
		);
	}

	@LoadingScreen()
	private async executeRejectPendingUser(user: ProjectMemberInfoExtended) {
		const response = await ErrorHandler.tryRequest(() =>
			RespressoApi.rejectPendingMember(this.teamId!, this.projectId!, user.userId),
		);
		await this.fetchProjectMembers();
		if (response != null) {
			NotificationBuilder.success('#project.settings.permissions.pending.reject.success');
		}
	}

	@LoadingScreen()
	private async loadTeam(): Promise<void> {
		const team = await UserService.loadTeam(this.teamId);
		this.$forceUpdate();
	}

	protected async removeInvite(invite: PendingUserMeta) {
		await DialogBuilder.confirm('#project.settings.permissions.removeInvite.confirm.title', '#project.settings.permissions.removeInvite.confirm.message', async () => {
			await this.executeRemoveInvite(invite);
		});
	}

	@LoadingScreen()
	protected async executeRemoveInvite(invite: PendingUserMeta) {
		const response = await ErrorHandler.tryRequest(() => RespressoApi.deleteProjectInvite(this.teamId!, this.projectId!, invite.email));
		await this.loadTeam();
		if (response != null) {
			NotificationBuilder.success("#project.settings.permissions.removeInvite.success")
		}
	}

	protected async newInvite() {
		await DialogBuilder.createVueDialog(CreateProjectInvite).closeResult.then(async (newInvite) => {
			if (newInvite) {
				const response = await ErrorHandler.tryRequest(() => RespressoApi.createProjectInvite(this.teamId!, this.projectId!, newInvite.email, newInvite.name, newInvite.role));
				await this.loadTeam();
				if (response != null) {
					NotificationBuilder.success("#project.settings.permissions.sendInvite.success")
				}
			}
		});
	}

}

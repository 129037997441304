import Vue from 'vue';
import Component from 'vue-class-component';
import {loadingModule} from '../store/modules/loading/index';

export const LOADING_START_DELAY = 200;
export const LOADING_MIN_DURATION = 500;

export interface Loader {
	message?: string;
	fullScreen?: boolean;
	showImmediately?: boolean;
}

@Component
export class LoadingServiceImpl extends Vue {
	private loaders: Loader[] = [];
	private loaderVisibleTime?: Date;
	private startDelayTimer: number | null = null;

	public start(loader?: Loader): void {
		loadingModule.SET_LOADING({ loading: true, loader });
		this.loaderVisibleTime = new Date();
	}

	public stop(): void {
		loadingModule.SET_LOADING(false);
		this.loaderVisibleTime = undefined;
	}

	public async waitForPromise<T>(promise: Promise<T>, loader?: Loader): Promise<T> {
		const removeLoader = this.addLoader(loader);
		try {
			return await promise;
		} finally {
			removeLoader();
		}
	}

	public startWithTimeout(loader?: Loader): () => void {
		return this.addLoader(loader);
	}

	private addLoader(loader: Loader = {}): () => void {
		this.loaders.push(loader);
		this.setLoader(loader);
		if (this.loaders.length === 1) {
			this.startLoading(loader.showImmediately);
		}
		return (): void => {
			this.removeLoader(loader);
		};
	}

	private removeLoader(loader: Loader): void {
		const index = this.loaders.indexOf(loader);
		if (index === -1) {
			return;
		}
		if (index === this.loaders.length - 1) {
			if (this.loaders.length > 1) {
				this.loaders.pop();
				this.setLoader(this.loaders[this.loaders.length - 1]);
			} else {
				this.finishLoading(loader);
			}
		} else {
			this.loaders.splice(index, 1);
		}
	}

	private startLoading(showImmediately?: boolean): void {
		this.startDelayTimer = setTimeout(
			() => {
				if (this.loaders.length > 0) {
					this.start(this.loaders[this.loaders.length - 1]);
				}
				this.startDelayTimer = null;
			},
			showImmediately ? 1 : LOADING_START_DELAY,
		);
	}

	private finishLoading(loader: Loader): void {
		if (!this.loaderVisibleTime) {
			if (this.startDelayTimer) {
				clearTimeout(this.startDelayTimer);
				this.startDelayTimer = null;
			}
			this.loaders = [];
			return;
		}
		const timeSinceLoaderVisible = new Date().getTime() - this.loaderVisibleTime.getTime();
		const finishRemoveLoader = (): void => {
			if (this.loaders.length === 1) {
				this.loaders = [];
				this.stop();
			} else {
				this.removeLoader(loader);
			}
		};
		if (timeSinceLoaderVisible < LOADING_MIN_DURATION) {
			setTimeout(() => {
				finishRemoveLoader();
			}, LOADING_MIN_DURATION - timeSinceLoaderVisible);
		} else {
			finishRemoveLoader();
		}
	}

	private setLoader(loader: Loader): void {
		loadingModule.SET_LOADING_MESSAGE(loader.message);
		loadingModule.SET_LOADING_FULLSCREEN(loader.fullScreen);
	}
}

const LoadingService = new LoadingServiceImpl();
export default LoadingService;

import Component from 'vue-class-component';
import template from './youtube.html';
import './youtube.scss';
import Vue from 'vue';
import {Prop} from 'vue-property-decorator';

@Component({
	template: template,
	props: {
		videoId: String,
		width: Number,
		height: Number,
	},
})
export default class Youtube extends Vue {
	@Prop()
	videoId!: string;
	@Prop({ default: 560 })
	width!: number;
	@Prop({ default: 315 })
	height!: number;
}

import VueI18n from 'vue-i18n';

const dateTimeFormats = {
	en: {
		'date-time': {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
		},
	},
	hu: {
		'date-time': {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
		},
	},
};

export function getDateTimeFormats(): VueI18n.DateTimeFormats {
	return dateTimeFormats;
}

import Vue from 'vue';
import Component from 'vue-class-component';
import template from './accept-invite.html';
import './accept-invite.scss';
import UserService from '../../../services/user-service';
import ErrorHandler from '../../../services/error-handler';

@Component({
	props: {
		inviteToken: String,
	},
	template: template,
})
export default class AcceptInvite extends Vue {
	private inviteToken: string | undefined;

	private isLoggedIn = false;
	private accepting = false;
	private missingInviteToken = false;

	beforeMount(): void {
		this.isLoggedIn = UserService.isLoggedIn();
		if (!this.inviteToken) {
			this.missingInviteToken = true;
		}
	}

	async mounted(): Promise<void> {
		if (this.missingInviteToken === true) {
			this.$router.push({ name: 'login', query: { redirect: this.$route.fullPath } });
		}
		if (!this.isLoggedIn) {
			this.register();
		}

		if (this.isLoggedIn && this.inviteToken) {
			this.accepting = true;
			const teamId = await ErrorHandler.tryRequest(() => UserService.joinByInvitation(this.inviteToken!), {
				loadingScreen: true,
				loadingFullScreen: true,
			});
			if (teamId) {
				this.$router.push({ name: 'dashboard', params: { teamId } });
			} else {
				const redirect = (this.$route.query.redirect as string) || '/';
				this.$router.push(redirect);
			}
			this.accepting = false;
		}
	}

	private login(): void {
		this.$router.push({ name: 'login', query: { inviteToken: this.inviteToken!, redirect: this.$route.fullPath } });
	}

	private register(): void {
		this.$router.push({ name: 'userInfo', query: { inviteToken: this.inviteToken! } });
	}
}

import Vue from 'vue';
import VueRouter, {Location, NextFunction, RawLocation, Route, RouteConfig} from 'vue-router';
import {DialogBuilder} from './components/common/dialog/dialog';
import {DisclaimerPage} from './components/common/disclaimer/disclaimer-page';
import AddProject from './components/view/add-project/add-project';
import JoinProject from './components/view/connect-project/join-project';
import CreateTeam from './components/view/create-team/create-team';
import Dashboard from './components/view/dashboard/dashboard';
import LastTeamActivities from './components/view/dashboard/last-team-activities';
import LastTeamUpdates from './components/view/dashboard/last-team-updates';
import Design from './components/view/design/design';
import JoinTeam from './components/view/join-team/join-team';
import NewPassword from './components/view/new-password/new-password';
import ProjectSettings from './components/view/project-settings/project-settings';
import ProjectPermissions from './components/view/project-permissions/project-permissions';
import LastProjectUpdates from './components/view/project/last-project-updates';
import Project from './components/view/project/project';
import ResetPassword from './components/view/reset-password/reset-password';
import AppIconResource from './components/view/resource/app-icon-resource';
import ColorResource from './components/view/resource/color-resource';
import FontResource from './components/view/resource/font-resource';
import ImageResource from './components/view/resource/image-resource';
import LocalizationResource from './components/view/resource/localization-resource';
import VersionList from './components/view/resource/version-list';
import TeamSettings from './components/view/team-settings/team-settings';
import TeamPermissions from './components/view/team-permissions/team-permissions';
import UserPreferences from './components/view/user-preferences/user-preferences';
import AcceptInvite from './components/view/user/accept-invite';
import Login from './components/view/user/login';
import AcceptSocial from './components/view/user/accept-social';
import Activate from './components/view/user/registration/activate';
import ManageTeams from './components/view/user/registration/manage-teams';
import Register from './components/view/user/registration/register';
import UserInfo from './components/view/user/registration/user-info';
import {translate} from './main';
import LoadingService from './services/loading-service';
import StorageService from './services/storage-service';
import UserService from './services/user-service';
import {teamModule} from './store/modules/team/index';
import {menuModule} from './store/modules/menu/index';
import RawResource from './components/view/resource/raw-resource';
import {TeamInfo} from 'respresso';
import Flow from './components/view/flow/flow';
import LiveLocalization from './components/view/live-edit/live-localization';
import TeamSubscriptions from './components/view/team-subscriptions/team-subscriptions';
import ChangeProjectSubscription from './components/view/team-subscriptions/change-project-subscription';
import PaddleTeamSubscriptionReceipts from './components/view/team-subscriptions/paddle-team-subscription-receipts';
import ServerLicense from './components/view/license/server-license';
import ZeplinIntegration from './components/view/integration/zeplin/zeplin-integration';
import StartZeplinIntegration from './components/view/integration/zeplin/start-zeplin-integration';
import SetupSync from './components/view/setup-sync/setup-sync';
import PendingImports from './components/view/pending-imports/pending-imports';
import {routingModule} from './store/modules/routing';
import MergeLocalizationVersions from './components/view/merge-versions/merge-localization-versions';
// @ts-ignore
import ImageConverter from "./components/view/tools/image-converter";

const NAME = {
	autoPageTitle: true,
};

function manageMenuState(to: Route): void {
	if (to.matched.some((record) => record.meta.hideMenu)) {
		menuModule.HIDE_MENU(true);
	} else {
		menuModule.HIDE_MENU(false);
	}
}

function manageWhatsNewState(to: Route): void {
	if (to.matched.some((record) => record.meta.hideWhatsNew)) {
		menuModule.closeWhatsNew();
	}
}

function manageRouteReuse(to: Route): void {
	const disallow = to.matched.some((record) => record.meta.reuse === false);
	if (disallow) {
		routingModule.disallowRouteReuse();
	} else {
		routingModule.allowRouteReuse();
	}
}

function setRouteTitle(to: Route): void {
	let title = 'Respresso';
	const metaTitle = to.meta?.title;
	const skipTeamAndProject = to.meta?.titleSkipTeamAndProject;
	let separator = '|';
	if (metaTitle === false) {
		return;
	}
	if (!skipTeamAndProject) {
		if (to.params.teamId && teamModule.team) {
			title += ` ${separator} ${teamModule.team.title}`;
			separator = '-';
			if (to.params.projectId && teamModule.project) {
				title += ` ${separator} ${teamModule.project.title}`;
			}
		}
	}
	if (metaTitle) {
		if (metaTitle === NAME) {
			title += ` ${separator} ${translate('title.' + to.matched.map((r) => r.name).join('.'))}`;
		} else if (typeof metaTitle === 'string') {
			if (metaTitle.startsWith('#')) {
				title += ` ${separator} ${translate(metaTitle)}`;
			} else {
				title += ` ${separator} ${metaTitle}`;
			}
		} else if (typeof metaTitle === 'function') {
			title += ` ${separator} ${metaTitle(to)}`;
		}
	}
	document.title = title;
}

const routes: RouteConfig[] = [
	{
		path: '',
		name: 'teams',
		meta: {
			auth: true,
			hideMenu: true,
			hideWhatsNew: true,
			title: NAME,
		},
		beforeEnter: (to, from, next): void => {
			let team: TeamInfo | null = teamModule.team;
			if (!team) {
				team = StorageService.getTeam();
			}
			if (!team) {
				const teams = teamModule.teams;
				if (teams) {
					team = teams[0];
				}
			}

			let nextLocation: RawLocation;
			if (team) {
				nextLocation = { name: 'dashboard', params: { teamId: team.id } };
			} else {
				nextLocation = { name: 'manageTeams' };
			}

			//Fixes weird behaviour when clicking the logo and the route doesn't change
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			const resolved = resolveRoute(nextLocation);
			if (resolved.route.fullPath === from.fullPath) {
				setRouteTitle(resolved.route);
				manageMenuState(resolved.route);
			}

			next(nextLocation);
		},
	},
	{
		path: '/login',
		name: 'login',
		component: Login,
		meta: {
			hideMenu: true,
			hideWhatsNew: true,
			title: NAME,
		},
	},
	{
		path: '/accept-social',
		name: 'accept-social',
		component: AcceptSocial,
		meta: {
			auth: false,
			hideMenu: true,
			hideWhatsNew: true,
			title: NAME,
		},
	},
	{
		path: '/privacy',
		name: 'privacy',
		component: DisclaimerPage,
		meta: {
			hideMenu: true,
			hideWhatsNew: true,
			title: NAME,
		},
	},
	{
		path: '/reset-password',
		name: 'reset-password',
		component: ResetPassword,
		meta: {
			hideMenu: true,
			hideWhatsNew: true,
			title: NAME,
		},
	},
	{
		path: '/passreset',
		name: 'new-password',
		component: NewPassword,
		//eslint-disable-next-line
        props: (route: Route): any => {
			if (route.query.passToken) {
				return {
					passToken: route.query.passToken,
				};
			}
			return {};
		},
		meta: {
			auth: false,
			hideMenu: true,
			hideWhatsNew: true,
			title: NAME,
		},
	},
	{
		path: '/registration',
		name: 'registration',
		component: Register,
		//eslint-disable-next-line
        props: (route: Route): any => {
			if (route.query.regToken) {
				return {
					regToken: route.query.regToken,
				};
			}
			return {};
		},
		meta: {
			hideMenu: true,
			hideWhatsNew: true,
		},
		children: [
			{
				path: '',
				name: 'userInfo',
				component: UserInfo,
				//eslint-disable-next-line
                props: (route: Route): any => {
					if (route.query.inviteToken) {
						return {
							inviteToken: route.query.inviteToken,
						};
					}
					return {};
				},
				meta: {
					title: NAME,
				},
			},
			{
				path: 'activate',
				name: 'activate',
				component: Activate,
				//eslint-disable-next-line
                props: (route: Route): any => {
					const props: { [key: string]: string | (string | null)[] } = {
						authUserId: route.query.authUserId,
					};
					if (route.query.inputToken) {
						props.inputToken = route.query.inputToken;
					}
					if (route.query.inviteToken) {
						props.inviteToken = route.query.inviteToken;
					}
					return props;
				},
				meta: {
					title: NAME,
				},
			},
			{
				path: 'teams',
				name: 'manageTeams',
				component: ManageTeams,
				meta: {
					auth: true,
					title: NAME,
				},
			},
		],
	},
	{
		path: '/invite',
		name: 'accept-invite',
		component: AcceptInvite,
		meta: {
			hideMenu: true,
			hideWhatsNew: true,
			title: NAME,
		},
		//eslint-disable-next-line
        props: (route: Route): any => {
			if (route.query.inviteToken) {
				return {
					inviteToken: route.query.inviteToken,
				};
			}
			return {};
		},
	},
	{
		path: '/design',
		name: 'design',
		component: Design,
		meta: {
			hideMenu: true,
			hideWhatsNew: true,
			title: NAME,
		},
	},
	{
		path: '/license/server',
		name: 'serverLicense',
		component: ServerLicense,
		meta: {
			auth: true,
			hideMenu: true,
			hideWhatsNew: true,
			title: NAME,
		},
	},
	{
		path: '/user/edit',
		name: 'userPreferences',
		component: UserPreferences,
		meta: {
			auth: true,
			title: NAME,
		},
	},
	{
		path: '/:teamId/add',
		name: 'addProject',
		component: AddProject,
		meta: {
			auth: true,
			title: NAME,
		},
	},
	{
		path: '/join-team',
		name: 'joinTeam',
		component: JoinTeam,
		meta: {
			auth: true,
			hideMenu: true,
			hideWhatsNew: true,
			title: NAME,
		},
	},
	{
		path: '/:teamId/join',
		name: 'joinProject',
		component: JoinProject,
		meta: {
			auth: true,
			title: NAME,
		},
	},
	{
		path: '/:teamId/settings',
		name: 'teamSettings',
		component: TeamSettings,
		meta: {
			auth: true,
			title: NAME,
		},
	},
	{
		path: '/:teamId/permissions',
		name: 'teamPermissions',
		component: TeamPermissions,
		meta: {
			auth: true,
			title: NAME,
		},
	},
	{
		path: '/:teamId/subscriptions',
		name: 'teamSubscriptions',
		component: TeamSubscriptions,
		meta: {
			auth: true,
			title: NAME,
		},
	},
	{
		path: '/:teamId/subscriptions/paddle/payments',
		name: 'paddleTeamSubscriptionPayments',
		component: PaddleTeamSubscriptionReceipts,
		meta: {
			auth: true,
			title: NAME,
		},
	},
	{
		path: '/create',
		name: 'createTeam',
		component: CreateTeam,
		meta: {
			auth: true,
			hideMenu: true,
			hideWhatsNew: true,
			title: NAME,
		},
	},
	{
		path: '/integration/zeplin/start',
		name: 'startZeplinIntegration',
		component: StartZeplinIntegration,
		meta: {
			auth: true,
			title: (to: Route): string => {
				return translate(`title.startZeplinIntegration`);
			},
			hideMenu: true,
		},
	},
	{
		path: '/tools/image-converter',
		name: 'imageConverter',
		component: ImageConverter,
		meta: {
			auth: true,
			title: NAME,
		},
	},
	{
		path: '/:teamId',
		name: 'dashboard',
		component: Dashboard,
		meta: {
			auth: true,
			title: NAME,
		},
	},
	{
		path: '/:teamId/lastupdates',
		name: 'lastTeamUpdates',
		component: LastTeamUpdates,
		meta: {
			auth: true,
			title: NAME,
		},
	},
	{
		path: '/:teamId/lastactivities',
		name: 'lastTeamActivities',
		component: LastTeamActivities,
		meta: {
			auth: true,
			title: NAME,
		},
	},
	{
		path: '/:teamId/:projectId',
		name: 'project',
		component: Project,
		meta: {
			auth: true,
		},
	},
	{
		path: '/:teamId/:projectId/lastupdates',
		name: 'lastProjectUpdates',
		component: LastProjectUpdates,
		meta: {
			auth: true,
			title: NAME,
		},
	},
	{
		path: '/:teamId/:projectId/settings',
		name: 'projectSettings',
		component: ProjectSettings,
		meta: {
			auth: true,
			title: NAME,
		},
	},
	{
		path: '/:teamId/:projectId/permissions',
		name: 'projectPermissions',
		component: ProjectPermissions,
		meta: {
			auth: true,
			title: NAME,
		},
	},
	{
		path: '/:teamId/:projectId/setup-sync',
		name: 'setupSync',
		component: SetupSync,
		meta: {
			auth: true,
			title: NAME,
		},
	},
	{
		path: '/:teamId/:projectId/pending-imports',
		name: 'pendingImports',
		component: PendingImports,
		meta: {
			auth: true,
			title: NAME,
		},
	},
	{
		path: '/:teamId/:projectId/changesubscription',
		name: 'changeProjectSubscription',
		component: ChangeProjectSubscription,
		meta: {
			auth: true,
			title: NAME,
		},
	},
	{
		path: '/:teamId/:projectId/:resourceId',
		name: 'versionList',
		component: VersionList,
		meta: {
			auth: true,
			title: (to: Route): string => {
				return translate(`title.versionList.${to.params.resourceId}`);
			},
		},
	},
	{
		path: '/:teamId/:projectId/:resourceId/flow-editor/:versionNumber',
		name: 'flowEditor',
		component: Flow,
		meta: {
			auth: true,
			title: (): string => {
				return translate(`title.versionList.flow.editor.title`);
			},
		},
	},
	{
		path: '/:teamId/:projectId/localization/qr/:versionNumber/:language',
		name: 'qr',
		component: LiveLocalization,
		meta: {
			auth: true,
			title: (): string => {
				return translate(`title.liveUpdate`);
			},
		},
	},
	{
		path: '/:teamId/:projectId/color/:versionNumber',
		name: 'colorResource',
		component: ColorResource,
		meta: {
			auth: true,
			reuse: false,
			title: (to: Route): string => {
				return translate(`title.colorResource`) + ' - ' + to.params.versionNumber;
			},
		},
	},
	{
		path: '/:teamId/:projectId/font/:versionNumber',
		name: 'fontResource',
		component: FontResource,
		meta: {
			auth: true,
			reuse: false,
			title: (to: Route): string => {
				return translate(`title.fontResource`) + ' - ' + to.params.versionNumber;
			},
		},
	},
	{
		path: '/:teamId/:projectId/image/:versionNumber',
		name: 'imageResource',
		component: ImageResource,
		meta: {
			auth: true,
			reuse: false,
			title: (to: Route): string => {
				return translate(`title.imageResource`) + ' - ' + to.params.versionNumber;
			},
		},
	},
	{
		path: '/:teamId/:projectId/appIcon/:versionNumber',
		name: 'appIconResource',
		component: AppIconResource,
		meta: {
			auth: true,
			reuse: false,
			title: (to: Route): string => {
				return translate(`title.appIconResource`) + ' - ' + to.params.versionNumber;
			},
		},
	},
	{
		path: '/:teamId/:projectId/raw/:versionNumber',
		name: 'rawResource',
		component: RawResource,
		meta: {
			auth: true,
			reuse: false,
			title: (to: Route): string => {
				return translate(`title.rawResource`) + ' - ' + to.params.versionNumber;
			},
		},
	},
	{
		path: '/:teamId/:projectId/localization/:versionNumber',
		name: 'localizationResource',
		component: LocalizationResource,
		meta: {
			auth: true,
			reuse: false,
			title: (to: Route): string => {
				return translate(`title.localizationResource`) + ' - ' + to.params.versionNumber;
			},
		},
	},
	{
		path: '/:teamId/:projectId/localization/:versionNumber/merge/:changeSetVersion',
		name: 'localizationVersionMerge',
		component: MergeLocalizationVersions,
		meta: {
			auth: true,
			reuse: false,
			title: (to: Route): string => {
				return (
					translate(`title.localizationResourceMerge`) +
					' - ' +
					to.params.versionNumber +
					' <- ' +
					to.params.changeSetVersion
				);
			},
		},
	},
	{
		path: '/:teamId/:projectId/localization/:versionNumber/:language',
		name: 'localizationResourceWithLanguage',
		component: LocalizationResource,
		meta: {
			auth: true,
			reuse: false,
			title: (to: Route): string => {
				return (
					translate(`title.localizationResource`) +
					' - ' +
					to.params.versionNumber +
					' - ' +
					to.params.language
				);
			},
		},
	},
	{
		path: '/:teamId/:projectId/integration/zeplin/:connectionId',
		name: 'zeplinIntegration',
		component: ZeplinIntegration,
		meta: {
			auth: true,
			title: (to: Route): string => {
				return translate(`title.zeplinIntegration`);
			},
		},
	},
];

Vue.use(VueRouter);
export const router = new VueRouter({
	mode: 'history',
	routes,
});

function resolveRoute(to: RawLocation): {
	location: Location;
	route: Route;
	href: string;
	// backwards compat
	normalizedTo: Location;
	resolved: Route;
} {
	return router.resolve(to);
}

async function loadRouteData(to: Route, from: Route, next: NextFunction): Promise<boolean> {
	const team = teamModule.team;
	const teamId = to.params.teamId;
	if (teamId && (!team || team.id !== teamId)) {
		const newTeam = await LoadingService.waitForPromise(UserService.loadTeam(teamId));
		if (newTeam === null) {
			return false;
		}
	}

	const project = teamModule.project;
	const projectId = to.params.projectId;
	if (teamId && projectId && (!project || project.id !== projectId)) {
		const newTeam = teamModule.team;
		if (newTeam && newTeam.projects) {
			const newProject = newTeam.projects.find((proj) => proj.id === projectId);
			if (newProject) {
				teamModule.SET_PROJECT(newProject);
			} else {
				next({
					name: 'dashboard',
					params: {
						teamId: newTeam.id,
					},
				});
				DialogBuilder.alert(
					'#serverError.respressoError.title',
					translate('serverError.respressoError.teamService.project.notFound.byName', {
						projectName: projectId,
					}),
				);
				return false;
			}
		}
	}
	return true;
}

class DocCokies {
	public getItem(sKey: string): string | null {
		if (!sKey) {
			return null;
		}
		return (
			decodeURIComponent(
				document.cookie.replace(
					new RegExp(
						'(?:(?:^|.*;)\\s*' +
							encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, '\\$&') +
							'\\s*\\=\\s*([^;]*).*$)|^.*$',
					),
					'$1',
				),
			) || null
		);
	}

	public setItem(
		sKey: string,
		sValue: string,
		vEnd: string | Date | undefined,
		sPath: string | undefined,
		sDomain: string | undefined,
		bSecure: string | undefined,
	) {
		if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) {
			return false;
		}
		let sExpires = '';
		if (vEnd) {
			switch (vEnd.constructor) {
				case Number:
					sExpires = '; max-age=' + vEnd;
					break;
				case String:
					sExpires = '; expires=' + vEnd;
					break;
				case Date:
					sExpires = `; expires=${(vEnd as Date).toUTCString()}`;
					break;
			}
		}
		document.cookie =
			encodeURIComponent(sKey) +
			'=' +
			encodeURIComponent(sValue) +
			sExpires +
			(sDomain ? '; domain=' + sDomain : '') +
			(sPath ? '; path=' + sPath : '') +
			(bSecure ? '; secure' : '');
		return true;
	}

	public removeItem(sKey: string, sPath?: string, sDomain?: string) {
		if (!this.hasItem(sKey)) {
			return false;
		}
		document.cookie =
			encodeURIComponent(sKey) +
			'=; expires=Thu, 01 Jan 1970 00:00:00 GMT' +
			(sDomain ? '; domain=' + sDomain : '') +
			(sPath ? '; path=' + sPath : '');
		return true;
	}

	public hasItem(sKey: string) {
		if (!sKey) {
			return false;
		}
		return new RegExp('(?:^|;\\s*)' + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, '\\$&') + '\\s*\\=').test(
			document.cookie,
		);
	}
	public keys() {
		const aKeys = document.cookie
			.replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, '')
			.split(/\s*(?:\=[^;]*)?;\s*/);
		for (let nLen = aKeys.length, nIdx = 0; nIdx < nLen; nIdx++) {
			aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]);
		}
		return aKeys;
	}
}

const docCookies: DocCokies = new DocCokies();

const clearMautic = (): void => {
	docCookies.removeItem('mtc_id');
	docCookies.removeItem('mtc_sid');
	docCookies.removeItem('mautic_device_id');
	localStorage.removeItem('mtc_sid');
	localStorage.removeItem('mtc_id');
	localStorage.removeItem('mautic_device_id');
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
router.afterEach(async (to, from) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const mt: any = (window as any)['mt'];
	if (typeof mt === 'function') {
		try {
			clearMautic();
			const isLoggedIn = await UserService.isLoggedInAsync();
			if (isLoggedIn) {
				const user = UserService.getUser();
				if (user != null) {
					mt('send', 'pageview', { email: user.email, publicid: user.mauticId, name: user.name });
				} else {
					//mt('send', 'pageview');
				}
			} else {
				//mt('send', 'pageview');
			}
		} catch (e) {
			//ignore
		}
	}
});

router.beforeEach(async (to, from, next) => {
	manageMenuState(to);
	manageWhatsNewState(to);
	manageRouteReuse(to);
	if (to.matched.some((record) => record.meta.auth)) {
		let isLoggedIn = false;
		try {
			isLoggedIn = await UserService.isLoggedInAsync();
		} catch (e) {
			//ignore
		}

		if (!isLoggedIn) {
			next({
				path: '/login',
				query: { redirect: to.fullPath },
			});
			return;
		}
	}
	const routeEnabled = await loadRouteData(to, from, next);

	if (routeEnabled) {
		setRouteTitle(to);
		next();
	}
});

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import store from '../../index';
import {User} from 'respresso';
import {Action, Module, Mutation, VuexModule} from 'vuex-class-modules';
import {SelectedPlatform, TutorialStatus} from '../../../services/user-service';

@Module
class UserModule extends VuexModule {
	_user: User | null = null;
	_selectedPlatform: SelectedPlatform = '';

	get user(): User | null {
		return this._user;
	}
	get isLoggedIn(): boolean {
		return this._user !== null;
	}

	get tutorialStatus(): TutorialStatus | null {
		return this._user!.tutorialStatus;
	}

	get selectedPlatform(): SelectedPlatform {
		return this._selectedPlatform;
	}

	@Mutation
	SET_USER(payload: User | null): void {
		this._user = payload;
	}

	@Mutation
	SET_TUTORIALSTATUS(payload: TutorialStatus): void {
		this._user!.tutorialStatus = payload;
	}

	@Mutation
	SET_SELECTEDPLATFORM(payload: SelectedPlatform): void {
		this._selectedPlatform = payload;
	}

	@Action
	remove(): void {
		this.SET_USER(null);
	}

	@Action
	setUser(payload: User): void {
		if (payload) {
			this.SET_USER(payload);
		}
	}

	@Action
	setTutorialStatus(payload: TutorialStatus): void {
		if (payload) {
			this.SET_TUTORIALSTATUS(payload);
		}
	}

	@Action
	setSelectedPlatform(payload: SelectedPlatform): void {
		if (payload) {
			this.SET_SELECTEDPLATFORM(payload);
		}
	}
}

export const userModule = new UserModule({ store, name: 'user' });

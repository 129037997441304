import Vue from 'vue';
import Component from 'vue-class-component';
import template from './team-subscriptions.html';
import './team-subscriptions.scss';
import {NextFunction, Route} from 'vue-router';
import RespressoApi from '../../../api/respresso-api';
import {ProjectSubscription, ProjectSubscriptionState, TeamMeta, TeamSubscriptionsResponse} from 'respresso';
import ErrorHandler from '../../../services/error-handler';
import UserService from '../../../services/user-service';
import LoadingScreen from '../../../decorators/loading-screen';
import {sleep} from '../../../utils';
import {DialogBuilder} from '../../common/dialog/dialog';

@Component({
	template: template,
})
export default class TeamSubscriptions extends Vue {
	private teamId = '';
	private subscriptions: TeamSubscriptionsResponse = { projects: [] };
	private team: TeamMeta = {
		id: '',
		title: '',
	} as TeamMeta;

	private pollers: Map<string, ProjectSubscriptionStatePoller> = new Map<string, ProjectSubscriptionStatePoller>();

	beforeRouteEnter(to: Route, from: Route, next: NextFunction): void {
		next((vm) => {
			const res = vm as TeamSubscriptions;
			res.teamId = to.params.teamId;
			res.loadSubscriptions();
		});
	}

	@LoadingScreen({ showImmediately: true })
	private async loadSubscriptions(): Promise<void> {
		const [team, subscriptions] = await Promise.all([
			UserService.loadTeam(this.teamId),
			ErrorHandler.tryRequest(() => RespressoApi.getTeamSubscriptions(this.teamId!)),
		]);
		if (team) {
			this.team = Object.assign({}, team);
			if (this.team.users) {
				const users = [...this.team.users];
				users.sort((a, b) => a.name.localeCompare(b.name));
			}
		}
		if (subscriptions) {
			this.subscriptions = subscriptions;
		}
		this.$forceUpdate();
	}

	private canPause(subscription: ProjectSubscription): boolean {
		return (
			!TeamSubscriptions.isPauseScheduled(subscription) &&
			!TeamSubscriptions.isCancelledOrScheduledForCancel(subscription) &&
			TeamSubscriptions.isPaddleInState(subscription, 'ACTIVE', 'PAST_DUE', 'TRIALING')
		);
	}

	private canResume(subscription: ProjectSubscription): boolean {
		return (
			!TeamSubscriptions.isCancelledOrScheduledForCancel(subscription) &&
			(TeamSubscriptions.isPauseScheduled(subscription) ||
				TeamSubscriptions.isPaddleInState(subscription, 'PAUSED'))
		);
	}

	private canCancel(subscription: ProjectSubscription): boolean {
		return (
			!TeamSubscriptions.isCancelledOrScheduledForCancel(subscription) &&
			TeamSubscriptions.isPaddleInState(subscription, 'ACTIVE', 'PAST_DUE', 'TRIALING', 'PAUSED')
		);
	}

	private canEdit(subscription: ProjectSubscription): boolean {
		return (
			(!TeamSubscriptions.isPauseScheduled(subscription) &&
				!TeamSubscriptions.isPaddleInState(subscription, 'PAUSED')) ||
			TeamSubscriptions.isCancelledOrScheduledForCancel(subscription)
		);
	}

	private async pauseSubscription(subscription: ProjectSubscription) {
		if (TeamSubscriptions.isPaddle(subscription)) {
			await DialogBuilder.confirm(
				'#team.settings.subscriptions.project.action.pause.confirm.title',
				'#team.settings.subscriptions.project.action.pause.confirm.message',
				async () => {
					await this.executePauseSubscription(subscription);
				},
			);
		}
	}

	@LoadingScreen()
	private async executePauseSubscription(subscription: ProjectSubscription) {
		await ErrorHandler.tryRequest(() => RespressoApi.pausePaddleSubscription(subscription.projectId));
		this.startPolling(subscription, 'PAUSED');
	}

	private async resumeSubscription(subscription: ProjectSubscription) {
		if (TeamSubscriptions.isPaddle(subscription)) {
			await DialogBuilder.confirm(
				'#team.settings.subscriptions.project.action.resume.confirm.title',
				'#team.settings.subscriptions.project.action.resume.confirm.message',
				async () => {
					await this.executeResumeSubscription(subscription);
				},
			);
		}
	}

	@LoadingScreen()
	private async executeResumeSubscription(subscription: ProjectSubscription) {
		await ErrorHandler.tryRequest(() => RespressoApi.resumePaddleSubscription(subscription.projectId));
		this.startPolling(subscription, 'ACTIVE');
	}

	private async cancelSubscription(subscription: ProjectSubscription) {
		if (TeamSubscriptions.isPaddle(subscription)) {
			await DialogBuilder.confirm(
				'#team.settings.subscriptions.project.action.cancel.confirm.title',
				'#team.settings.subscriptions.project.action.cancel.confirm.message',
				async () => {
					await this.executeCancelSubscription(subscription);
				},
			);
		}
	}

	@LoadingScreen()
	private async executeCancelSubscription(subscription: ProjectSubscription) {
		await ErrorHandler.tryRequest(() => RespressoApi.cancelPaddleSubscription(subscription.projectId));
		this.startPolling(subscription, 'CANCELLED');
	}

	private startPolling(subscription: ProjectSubscription, state: ProjectSubscriptionState) {
		const poller = new ProjectSubscriptionStatePoller(
			this.teamId,
			subscription.projectName,
			subscription.subscription.plan.id,
			state,
		);
		this.pollers.set(subscription.projectId, poller);
		poller.poll().then(async () => {
			await this.loadSubscriptions();
		});
		this.$forceUpdate();
	}

	private isPolling(subscription: ProjectSubscription) {
		const poller = this.pollers.get(subscription.projectId);
		return poller && poller.polling && poller.enable;
	}

	private hasPolled(subscription: ProjectSubscription) {
		const poller = this.pollers.get(subscription.projectId);
		return poller != undefined;
	}

	private wasSuccessfulStateChange(subscription: ProjectSubscription) {
		const poller = this.pollers.get(subscription.projectId);
		return poller && poller.success;
	}

	private static isPaddle(subscription: ProjectSubscription) {
		return subscription.subscription.plan.provider === 'PADDLE';
	}

	private static isPauseScheduled(subscription: ProjectSubscription) {
		return subscription.subscription.pauseFrom != undefined;
	}

	private static isCancelled(subscription: ProjectSubscription) {
		return this.inState(subscription, 'CANCELLED');
	}

	private static isCancelScheduled(subscription: ProjectSubscription) {
		return subscription.subscription.cancelFrom != undefined;
	}

	private static isCancelledOrScheduledForCancel(subscription: ProjectSubscription) {
		return TeamSubscriptions.isCancelled(subscription) || TeamSubscriptions.isCancelScheduled(subscription);
	}

	private static inState(subscription: ProjectSubscription, ...states: ProjectSubscriptionState[]) {
		const state = subscription.subscription.state;
		return states.some((it) => it === state);
	}

	private static isPaddleInState(subscription: ProjectSubscription, ...states: ProjectSubscriptionState[]) {
		return TeamSubscriptions.isPaddle(subscription) && TeamSubscriptions.inState(subscription, ...states);
	}
}

class ProjectSubscriptionStatePoller {
	team: string;
	project: string;
	plan: string;
	state: ProjectSubscriptionState;
	polling: boolean;
	enable: boolean;
	private retries: number;
	success: boolean;

	constructor(team: string, project: string, plan: string, state: ProjectSubscriptionState) {
		this.team = team;
		this.project = project;
		this.plan = plan;
		this.state = state;
		this.enable = true;
		this.retries = 100;
		this.polling = false;
		this.success = false;
	}

	public release() {
		this.enable = false;
	}

	async poll() {
		this.polling = true;
		if (this.enable && this.retries > 0) {
			let success = false;
			try {
				const response = await RespressoApi.pollCheckoutState(this.team, this.project, this.plan, this.state);
				if (response && response.matching) {
					success = true;
				}
			} catch (e) {
				console.log(e);
			} finally {
				if (!success) {
					this.retries--;
					await sleep(1000);
					await this.poll();
				} else {
					this.success = true;
					this.polling = false;
				}
			}
		}
	}
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import Component from 'vue-class-component';
import template from './resource-list.html';
import './resource-list.scss';
import {ItemState, ResourceItem} from '../../view/resource/resource';
import TagFilter from './../resource/tag-filter';
import TextSearch from './../resource/text-search';
import ShowEmptyValues from './../resource/show-empty-values';
import debounce from '../../../util/debounce';
import TagEditor from '../vue-slim-select/tag-editor';
import {Logger} from '../../../util/logger';
import {DialogBuilder} from '../dialog/dialog';
import {SortOrder} from '../../../services/sort-service';
import {ProjectMeta, TeamMeta} from 'respresso';
import {teamModule} from '../../../store/modules/team';
import AccessService from '../../../services/access-service';
import {translate} from '../../../main';

const VIRTUAL_BUFFER_SIZE = 5;
const VIRTUAL_STEP_SIZE = 1;
const VIRTUAL_LIST_MARGIN = 40;

const SEARCH_DEBOUNCE_TIME = 300;

@Component({
	props: {
		deletable: Boolean,
		saveable: Boolean,
		taggable: Boolean,
		downloadable: Boolean,
		editable: Boolean,
		showFilter: { type: Boolean, default: true },
		showEditButton: Boolean,
		showNewButton: Boolean,
		showDownloadVersionButton: Boolean,
		items: Array,
		newItems: Array,
		allTags: Array,
		emptyColumns: Array,
		searchFields: Array,
		searchInTags: Boolean,
		virtual: Boolean,
		lineHeight: Number,
		customGoBack: Function,
		sortKey: String,
		sortOrder: { type: Number, default: 0 },
		projectId: String,
	},
	components: {
		'tag-filter': TagFilter,
		'text-search': TextSearch,
		'tag-editor': TagEditor,
		'show-empty-values': ShowEmptyValues,
	},
	watch: {
		items(): void {
			(this as ResourceList).updateFilteredItems();
		},
	},
	template: template,
})
export default class ResourceList extends Vue {
	private deletable: boolean | undefined;
	private saveable: boolean | undefined;
	private downloadable: boolean | undefined;
	private taggable: boolean | undefined;
	private localization: boolean | undefined;
	private editable: boolean | undefined;
	private showFilter = true;
	private showEditButton: boolean | undefined;
	showNewButton: boolean | undefined;
	showDownloadVersionButton: boolean | undefined;
	private showEmpty = false;
	private emptyColumns: string[] | undefined;
	private searchFields: string[] | undefined;
	private searchInTags: boolean | undefined;
	private virtual: boolean | undefined;
	private lineHeight: number | undefined;
	private allTags: string[] | undefined;
	private customGoBack: (() => void) | undefined;

	private filterTags: string[] = [];
	private searchText = '';
	private added = false;
	private items: ResourceItem[] | undefined;
	private newItems: ResourceItem[] | undefined;
	private filteredItems: ResourceItem[] = [];
	private virtualItems: ResourceItem[] = [];

	private scrollBase?: HTMLElement;
	private virtualScrollEventListener?: (event: Event) => void;
	private virtualScrollResizeListener?: (event: Event) => void;
	private virtualScrollHeight = 0;
	private newItemsContainerHeight = 0;
	private virtualScrollCalculatedOnUpdate = false;
	private renderedScrollItemCount = 0;
	private firstVirtualElement = 0;
	private virtualScrollPlaceholderTopHeight = 0;
	private virtualScrollPlaceholderBottomHeight = 0;
	private itemsContainerOffsetBottom = 0;
	private virtualScrollOffsetTop = 0;
	private virtualScrollOffsetBottom = 0;
	private searchListener?: (event: KeyboardEvent) => void;
	private newItemsMaxHeight = 100;
	private showNewItems = true;
	public numberOfNewItems = 0;
	private difference?: number;
	private sortKey?: string;
	private sortOrder?: SortOrder;
	private projectId?: string;

	get team(): TeamMeta | null {
		return teamModule.team;
	}

	get project(): ProjectMeta | null {
		const moduleGetterTeam = this.team;
		if (!moduleGetterTeam || !moduleGetterTeam.projects) {
			return null;
		}
		const project = moduleGetterTeam.projects.filter((p: ProjectMeta) => {
			return p.id === this.projectId;
		});
		if (project.length) {
			return project[0];
		} else {
			return teamModule.project;
		}
	}

	private mounted(): void {
		this.scrollBase = document.getElementsByClassName('main')[0] as HTMLElement;
		if (this.virtual) {
			let rafId: number | undefined;
			this.virtualScrollEventListener = (): void => {
				//Schedule update for next paint
				if (rafId !== undefined) return;
				rafId = requestAnimationFrame(() => {
					rafId = undefined;
					const virtualScrollPosition = this.getVirtualScrollPosition();
					this.onVirtualScroll(virtualScrollPosition);
				});
			};
			if (!this.scrollBase) {
				Logger.warn('Virtual scroll base not found');
				this.virtual = false;
				return;
			}
			this.scrollBase.addEventListener('scroll', this.virtualScrollEventListener, { passive: false });

			this.virtualScrollResizeListener = (): void => {
				this.reloadVirtualScroll(false);
			};
			window.addEventListener('resize', this.virtualScrollResizeListener);
		}

		this.searchListener = (event: KeyboardEvent): void => {
			if ((event.ctrlKey || event.metaKey) && event.key === 'f') {
				event.preventDefault();
				this.scrollToTop();
				if (this.$refs.textSearch as TextSearch) {
					(this.$refs.textSearch as TextSearch).focus();
					(this.$refs.textSearch as TextSearch).select();
				}
			}
		};
		window.addEventListener('keydown', this.searchListener);
	}

	private updated(): void {
		if (!this.virtualScrollCalculatedOnUpdate && this.virtual) {
			this.computeVirtualScrollSizes();
			this.virtualScrollCalculatedOnUpdate = true;
		}
	}

	private beforeDestroy(): void {
		if (this.scrollBase && this.virtualScrollEventListener) {
			this.scrollBase.removeEventListener('scroll', this.virtualScrollEventListener);
		}
		if (this.virtualScrollResizeListener) {
			window.removeEventListener('resize', this.virtualScrollResizeListener);
		}
		if (this.searchListener) {
			window.removeEventListener('keydown', this.searchListener);
		}
	}

	private numberOfNewItemsAsText(): string {
		return (
			this.numberOfNewItems +
			' ' +
			(this.numberOfNewItems < 2 ? translate('resource.newItem') : translate('resource.newItems'))
		);
	}
	private get showActionsColumn(): boolean | undefined {
		return (this.deletable && this.canDeleteResource) || (this.downloadable && this.canDownload);
	}

	private get showFilterCheckbox(): boolean | undefined {
		if (!this.items || !this.items.length || !this.showFilter) {
			return false;
		}
		let showCb = false;
		this.items.forEach((item) => {
			for (let i = 0; i < this.searchFields!.length; i++) {
				if (this.emptyColumns && this.emptyColumns.length > 0) {
					showCb = item.hasOwnProperty(this.emptyColumns![i]);
				}
				if (showCb) {
					return;
				}
			}
		});
		return showCb;
	}

	private editButtonClicked(item: ResourceItem): void {
		if (this.editable) {
			this.$emit('edit-item', item);
		}
	}

	private downloadButtonClicked(item: ResourceItem): void {
		if (this.downloadable) {
			this.$emit('download-item', item);
		}
	}

	private uploadButtonClicked(item: ResourceItem): void {
		this.$emit('upload', item);
	}

	private deleteButtonClicked(item: ResourceItem): void {
		if (item.state === ItemState.DELETED) {
			this.$emit('undelete-item', item);
		} else {
			this.$emit('delete-item', item);
		}
	}

	private deleteNewItemButtonClicked(item: ResourceItem): void {
		this.$emit('delete-new-item', item);
		this.numberOfNewItems--;
	}

	private newItemButtonClicked(): void {
		if (this.editable) {
			this.$emit('new-item');
			this.numberOfNewItems++;
		}

		if (!this.virtual) {
			this.scrollToTop();
		}
	}

	private saveButtonClicked(): void {
		if (this.editable && this.saveable) {
			this.$emit('save');
		}
	}

	private downloadVersionClicked(): void {
		if (!this.saveable) {
			this.$emit('download-version');
		}
	}

	public tagAdded(item: ResourceItem, tag: string): void {
		item.tags = item.tags || [];
		if (item.tags.indexOf(tag) === -1) {
			item.tags.push(tag);
			this.$emit('item-changed', item);
			this.$emit('tag-added', tag);
		}
		if (this.items) {
			this.items.forEach((item) => {
				return (item.now = false);
			});
			item.now = true;
		}
	}

	public tagRemoved(item: ResourceItem, tag: string): void {
		if (item.tags) {
			const index = item.tags.indexOf(tag);
			if (index !== -1) {
				item.tags.splice(index, 1);
				this.$emit('item-changed', item);
				this.$emit('tag-removed', tag);
			}
		}
	}

	public isShowNoContentSlot(): boolean {
		if (this.virtual) {
			if (!this.items) {
				return true;
			}

			return this.items.length === 0;
		} else {
			if (!this.items || !this.newItems) {
				return true;
			}

			return this.items.length === 0 && this.newItems.length === 0;
		}
	}

	public get hasSavedItems(): boolean {
		if (this.items && this.items.length > 0) {
			return true;
		} else {
			return false;
		}
	}

	private selectedTagsChanged(tags: string[]): void {
		this.filterTags = tags;
		this.updateFilteredItems();
	}

	debounceSearch = debounce(function (resourceList: ResourceList) {
		resourceList.updateFilteredItems();
	}, SEARCH_DEBOUNCE_TIME);

	private searchChanged(search: string): void {
		this.searchText = search;
		this.debounceSearch(this);
	}

	private showEmptyValues(showEmpty: boolean): void {
		this.showEmpty = showEmpty;
		this.updateFilteredItems();
	}

	protected updateFilteredItems(): void {
		if (!this.items || !this.items.length) {
			this.filteredItems = [];
			this.virtualItems = [];
			return;
		}
		this.filteredItems = this.items.filter((item) => {
			if (this.checkItemState(item)) {
				return true;
			}
			if (this.checkFilterTags(item) === false) {
				return false;
			}
			if (this.checkEmptyValues(item) === false) {
				return false;
			}
			return this.matchesSearch(item, this.searchText);
		});
		if (this.virtual) {
			this.reloadVirtualScroll();
		}
	}

	private matchesSearch(item: any, search: string): boolean {
		if (this.searchFields === undefined && this.searchInTags === undefined) {
			return true;
		}

		if (!search) {
			return true;
		}

		let searchValue = '';

		if (this.searchFields) {
			this.searchFields.forEach((searchField) => {
				if (item.hasOwnProperty(searchField) && item[searchField]) {
					searchValue += item[searchField];
				}
			});
		}

		if (this.searchInTags) {
			if (item.tags) {
				searchValue += item.tags.join(' ') as string[];
			}
		}

		if (!searchValue) {
			return false;
		}

		return searchValue.toLowerCase().includes(search.toLocaleLowerCase());
	}

	private checkItemState(item: ResourceItem): void | boolean {
		if (item.state === ItemState.NEW) {
			return true;
		}
	}

	private checkFilterTags(item: ResourceItem): void | boolean {
		if (this.filterTags && item.tags) {
			const hasSelectedTags = this.filterTags!.every((filterTag) => item.tags!.indexOf(filterTag) !== -1);
			if (!hasSelectedTags) {
				return false;
			}
		}
	}

	private checkEmptyValues(item: ResourceItem): void | boolean {
		if (this.showEmpty && this.emptyColumns && this.emptyColumns!.length) {
			for (let i = 0; i < this.emptyColumns!.length; i++) {
				if ((item as any)[this.emptyColumns![i]]) {
					return false;
				}
			}
		}
	}

	public getFilterTags(): string[] {
		return this.filterTags;
	}

	private onVirtualScroll(virtualScrollPosition: number, force = false): void {
		let topHideItemCount = Math.min(
			this.filteredItems.length - this.renderedScrollItemCount,
			Math.max(0, Math.floor(virtualScrollPosition / this.lineHeight!) - VIRTUAL_BUFFER_SIZE),
		);

		const diff = topHideItemCount - this.firstVirtualElement;

		if (topHideItemCount < 0) {
			topHideItemCount = 0;
		}

		if (diff !== 0 || force) {
			const lastVirtualElement = Math.min(
				topHideItemCount + this.renderedScrollItemCount,
				this.filteredItems.length,
			);
			if (
				Math.abs(diff) >= VIRTUAL_STEP_SIZE ||
				topHideItemCount === 0 ||
				lastVirtualElement === this.filteredItems.length ||
				force
			) {
				this.firstVirtualElement = topHideItemCount;
				this.virtualItems = this.filteredItems.slice(topHideItemCount, lastVirtualElement);
				this.virtualScrollPlaceholderTopHeight = Math.max(0, topHideItemCount * this.lineHeight!);
				this.virtualScrollPlaceholderBottomHeight = Math.max(
					0,
					(this.filteredItems.length - (topHideItemCount + this.renderedScrollItemCount)) * this.lineHeight!,
				);
			}
		}
	}

	private computeVirtualScrollSizes(): void {
		const viewPortHeight = this.scrollBase!.getBoundingClientRect().height;
		const headerHeight = (this.$refs.resourceListHeader as HTMLElement).getBoundingClientRect().height;
		const bottomContainerHeight = (this.$refs.resourceListBottomContainer as HTMLElement).getBoundingClientRect()
			.height;
		const resourceListOffsetTop = (this.$refs.resourceList as HTMLElement).offsetTop;
		const resourceListTableOffsetTop = (this.$refs.resourceListTable as HTMLElement).offsetTop;
		const resourceListRowsOffsetTop = (this.$refs.resourceListRows as HTMLElement).offsetTop;
		this.virtualScrollOffsetTop = resourceListOffsetTop + resourceListTableOffsetTop + resourceListRowsOffsetTop;
		this.virtualScrollOffsetBottom = (this.$refs.resourceList as HTMLElement).offsetTop;
		if (this.isOpen && this.lineHeight != undefined) {
			const openedList = document.getElementsByClassName('open');
			if (openedList.length) {
				this.difference = openedList[0].getBoundingClientRect().height - this.lineHeight;
			} else {
				this.difference = 0;
			}
		}
		this.virtualScrollHeight =
			viewPortHeight - headerHeight - bottomContainerHeight - VIRTUAL_LIST_MARGIN - this.difference!;
		this.virtualScrollHeight = viewPortHeight - headerHeight - bottomContainerHeight - VIRTUAL_LIST_MARGIN;
		this.renderedScrollItemCount = Math.ceil(this.virtualScrollHeight / this.lineHeight!) + VIRTUAL_BUFFER_SIZE * 2;
	}

	private reloadVirtualScroll(scrollToTop = true): void {
		this.computeVirtualScrollSizes();
		if (scrollToTop) {
			this.scrollToTop();
		}
		const virtualScrollPosition = this.getVirtualScrollPosition();
		this.onVirtualScroll(virtualScrollPosition, true);
	}

	private getVirtualScrollPosition(): number {
		return Math.max(0, this.scrollBase!.scrollTop - this.virtualScrollOffsetTop);
	}

	private scrollToTop(): void {
		try {
			this.scrollBase!.scrollTo(0, 0);
			return;
		} catch (e) {}
		try {
			this.scrollBase!.scrollTop = 0;
		} catch (e) {}
	}

	isOpen(item: ResourceItem): boolean {
		if (item.lastOpened) {
			return true;
		} else {
			return false;
		}
	}

	getBack(newItems: ResourceItem[]): void | boolean {
		this.resetNewItems(newItems);
		if (this.customGoBack) {
			this.customGoBack();
		} else {
			window.history.go(-1);
		}
		return false;
	}

	protected xNewItems(newItems: ResourceItem[]): void {
		DialogBuilder.confirm(
			'#dialog.areYouSure.simple',
			'#dialog.areYouSure.deleteNewItems.message', //TODO change the dialog button labels to yes / no
			async () => {
				this.resetNewItems(newItems);
			},
		);
	}

	private resetNewItems(newItems: ResourceItem[]): void {
		if (newItems != undefined && newItems.length > 0) {
			while (newItems.length !== 0) {
				this.deleteNewItemButtonClicked(newItems[0]);
			}
		}
	}

	get canAddResource(): boolean {
		return AccessService.canAddResource(this.project);
	}

	get canDeleteResource(): boolean {
		return AccessService.canDeleteResource(this.project);
	}

	get canModifyResource(): boolean {
		return AccessService.canModifyResource(this.project);
	}

	get canDownload(): boolean {
		return AccessService.canDownload(this.project);
	}
}

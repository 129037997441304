import Vue from 'vue';
import Component from 'vue-class-component';
import template from './confirm.html';
import './confirm.scss';

import {IDialog} from '@ponte/dialog';
import {IDialogComponent} from '@ponte/dialog-vue';
import {Prop} from 'vue-property-decorator';
import DialogTemplate from '../dialog-template/dialog-template';

export interface ConfirmDialogInput {
	title: string;
	message: string;
	okCb?: () => void;
	cancelCb?: () => void;
}

@Component({
	components: {
		'dialog-template': DialogTemplate,
	},
	template: template,
})
export default class Confirm extends Vue implements IDialogComponent<boolean> {
	@Prop() inputData: ConfirmDialogInput | undefined;

	dialog!: IDialog<boolean>;

	okClick(): void {
		if (this.dialog.dialogController) this.dialog.dialogController.close(true);
		if (this.inputData && this.inputData.okCb) this.inputData.okCb();
	}

	cancelClick(): void {
		if (this.dialog.dialogController) this.dialog.dialogController.close(false);
		if (this.inputData && this.inputData.cancelCb) this.inputData.cancelCb();
	}
}

import Vue from 'vue';
import PQueue from 'p-queue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import template from './multi-upload-block.html';
import {IFileDescriptor} from '@ponte/file-upload-js';
import {UploadListItem} from '../../../plugin/vue-upload/vue-upload-list';
import {Logger} from '../../../util/logger';
import './multi-upload-block.scss';
import {VueFileSelector} from '../../../plugin/vue-upload/vue-file-selector';
import {NotificationBuilder} from '../dialog/dialog';

export interface UploadBlob {
	blob: Blob;
	name: string;
}

export interface SubmittedUpload {
	/**
	 * Never call startUpload manually..
	 */
	uploader: UploadListItem;
	awaitFinish: Promise<void>;
	name?: string;
}

export type UploadItem = File | UploadBlob;

export type UploadState = 'empty' | 'active' | 'idle';

@Component({
	props: {
		uploadHandler: String,
		fileSelectedHandler: Function,
		baseUrl: String,
		accept: Array,
		allowDirectorySelection: Boolean,
	},
	template: template,
})
export default class MultiUploadBlock extends Vue {
	@Prop() uploadHandler!: string;
	@Prop({ default: '/api/ui/upload' }) baseUrl!: string;

	@Prop({ default: [] })
	private accept!: string[];

	@Prop()
	private fileSelectedHandler?: (file: IFileDescriptor) => Promise<boolean>;

	@Prop()
	private allowDirectorySelection: boolean | undefined;

	private _onUploadSucceeded?: (upload: SubmittedUpload) => Promise<void>;
	private _onUploadFailed?: (upload: SubmittedUpload) => Promise<void>;

	private taskQueue: PQueue;

	private activeUploads = 0;
	private pendingUploads = 0;
	private remainingBytesToUpload = 0;
	private state: UploadState = 'empty';

	private uploadTasks: SubmittedUpload[] = [];

	constructor() {
		super();
		this.taskQueue = new PQueue({ concurrency: 12 });
		this.taskQueue.on('add', this.updateQueueState);
		this.taskQueue.on('next', this.updateQueueState);
		this.taskQueue.on('idle', () => {
			this.changeState('idle');
		});
		this.taskQueue.on('active', () => {
			this.changeState('active');
		});
	}

	private changeState(state: UploadState) {
		if (this.state !== state) {
			this.state = state;
			this.$emit('upload-status', state);
		}
	}

	private updateQueueState(param: any) {
		this.activeUploads = this.taskQueue.pending;
		this.pendingUploads = this.taskQueue.size;
	}

	public submitUpload(file: UploadItem, userInfo?: object): SubmittedUpload {
		if (this.taskQueue.pending === 0 && this.taskQueue.size === 0 && !this.taskQueue.isPaused) {
			this.uploadTasks = [];
		}
		let data = { handler: this.uploadHandler };
		if(userInfo) {
			data = Object.assign(data, userInfo)
		}

		const uploader = new UploadListItem(file, data, this.baseUrl || '');
		let size = uploader.uploadSize;
		if (size > 0) {
			this.remainingBytesToUpload += size;
		} else {
			size = 0;
		}
		const submittedUpload: SubmittedUpload = {
			uploader,
			awaitFinish: Promise.resolve(), // Placeholder, replaced in next line
			name: file.name,
		};
		submittedUpload.awaitFinish = this.taskQueue.add(() => {
			return this.startUpload(size, submittedUpload);
		});
		this.uploadTasks.push(submittedUpload);
		this.uploadTasks = this.uploadTasks;
		return submittedUpload;
	}

	private async startUpload(size: number, upload: SubmittedUpload) {
		try {
			await upload.uploader.startUpload();
		} finally {
			this.remainingBytesToUpload -= size;
			await this.onUploadFinished(upload);
		}
	}

	private async onUploadFinished(upload: SubmittedUpload) {
		try {
			if (upload.uploader.isSuccessful()) {
				if (this._onUploadSucceeded) {
					await this._onUploadSucceeded(upload);
				}
			} else {
				if (this._onUploadFailed) {
					await this._onUploadFailed(upload);
				} else {
					NotificationBuilder.error(`#Failed to process a file: ${upload.name}.`);
				}
			}
		} catch (e) {
			console.log(e);
		}
	}

	public async fileSelected(fileDescriptor: IFileDescriptor) {
		if (this.fileSelectedHandler) {
			if (await this.fileSelectedHandler(fileDescriptor)) {
				return;
			}
		}
		this.submitUpload(fileDescriptor.file);
	}

	private fileSelectorError(errorMessageKey: string, asd: any): void {
		Logger.error(errorMessageKey);
		NotificationBuilder.error("#" + (this.$t(errorMessageKey) as string));
	}

	public setUploadHandler(uploadHandler: string): void {
		this.uploadHandler = uploadHandler;
	}

	public getFinishedUploads(remove: boolean): [SubmittedUpload[], SubmittedUpload[]] {
		const succeededTasks: SubmittedUpload[] = [];
		const failedTasks: SubmittedUpload[] = [];
		const remainingTasks: SubmittedUpload[] = [];
		this.uploadTasks.forEach((task) => {
			if (task.uploader.isSuccessful()) {
				succeededTasks.push(task);
			} else if (task.uploader.isFinished()) {
				failedTasks.push(task);
			} else {
				remainingTasks.push(task);
			}
		});
		if (remove) {
			this.uploadTasks = remainingTasks;
		}
		return [succeededTasks, failedTasks];
	}

	public onUploadSucceeded(value: (upload: SubmittedUpload) => Promise<void>) {
		this._onUploadSucceeded = value;
	}

	public onUploadFailed(value: (upload: SubmittedUpload) => Promise<void>) {
		this._onUploadFailed = value;
	}

	public triggerFileSelect() {
		if(this.allowDirectorySelection) {
			const fileSelector = this.$refs.secondaryFileSelector as VueFileSelector | undefined;
			if(fileSelector) {
				fileSelector.triggerFileSelect();
				return;
			}
		}
		const selector = this.$refs.fileSelector as VueFileSelector;
		if (selector) {
			selector.triggerFileSelect();
		}
	}

	public getState() {
		return this.state;
	}

	public onIdle() {
		return this.taskQueue.onIdle();
	}
}

import Vue from 'vue';
import Component from 'vue-class-component';
import template from './accept-social.html';
import './accept-social.scss';
import {translate} from '../../../main';
import {DialogBuilder} from '../../common/dialog/dialog';
import LoadingScreen from '../../../decorators/loading-screen';
import {DisclaimerDialog} from '../../../../src/components/common/disclaimer/disclaimer-dialog';

@Component({
	template: template,
})
export default class AcceptSocial extends Vue {
	private newsletter = true;
	private policy = false;
	private errors: { [field: string]: string } = {};

	@LoadingScreen({ fullScreen: true })
	private proceed(): void {
		if (this.validate()) {
			// TODO REDIRECT CREATE / JOIN TEAM AFTER AUTH
		}
	}

	private validate(): boolean {
		let valid = true;
		this.errors = {};

		if (!this.policy) {
			valid = false;
			this.errors['policy'] = translate('registration.validationErrors.required');
		}
		return valid;
	}

	private showPolicy(): void {
		DialogBuilder.createVueDialog(DisclaimerDialog);
	}

	protected handleBlur(filedName: string): void {
		if (this.errors.hasOwnProperty(filedName)) {
			this.validate();
		}
	}
}

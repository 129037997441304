import {default as LoadingService, Loader} from '../services/loading-service';

export default function LoadingScreen(loader?: Loader) {
	return function (target: any, propertyKey: string, descriptor?: PropertyDescriptor): PropertyDescriptor {
		if (descriptor == null) {
			descriptor = Object.getOwnPropertyDescriptor(target, propertyKey) || {};
		}

		const originalMethod = descriptor.value;

		descriptor.value = function asd(...args: any[]): Promise<unknown> {
			const result = originalMethod.apply(this, args);
			return LoadingService.waitForPromise(result, loader);
		};

		return descriptor;
	};
}

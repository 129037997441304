import Vue from 'vue';
import Component from 'vue-class-component';
import {FileSelector, IFileDescriptor} from '@ponte/file-upload-js';
import template from './vue-file-selector.html';
import './vue-file-selector.scss';
import {Prop} from 'vue-property-decorator';

export const FILE_SELECTED_EVENT = 'file-selected';
export const ERROR_EVENT = 'error';

@Component({
	props: {
		allowDirectorySelection: Boolean,
		allowFileSelectionWhenSelectingDirectory: Boolean,
		accept: Array,
		customContent: Boolean,
	},
	template: template,
})
export class VueFileSelector extends Vue {
	public allowDirectorySelection: boolean | undefined;
	public allowFileSelectionWhenSelectingDirectory: boolean | undefined;
	public accept: string[] | undefined;
	@Prop({ default: false })
	public customContent!: boolean;

	private fileSelector: FileSelector | undefined;
	private fileSelectorElement: HTMLDivElement | undefined;
	private dndUpdate: any;

	mounted(): void {
		this.fileSelectorElement = document.createElement('div');
		this.fileSelectorElement.className = 'file-selector';
		this.$el.appendChild(this.fileSelectorElement);
		this.fileSelector = new FileSelector({
			fileSelected: this.fileSelected,
			element: this.fileSelectorElement,
			allowDirectorySelection: this.allowDirectorySelection,
			allowFileSelectionWhenSelectingDirectory: this.allowFileSelectionWhenSelectingDirectory,
			errorCallback: (errorMessageKey: string): VueFileSelector => this.$emit(ERROR_EVENT, errorMessageKey),
			accept: this.accept,
		});
	}

	private fileSelected(fileDescriptor: IFileDescriptor): VueFileSelector {
		this.$emit(FILE_SELECTED_EVENT, fileDescriptor);
		clearTimeout(this.dndUpdate);
		this.fileSelectorElement?.classList.remove('user-dragging');
		return this;
	}

	private dragStarted(e: Event) {
		this.fileSelectorElement?.classList.add('user-dragging');
		clearTimeout(this.dndUpdate);
	}

	private dragEnded(e: Event) {
		this.dndUpdate = setTimeout(() => {
			this.fileSelectorElement?.classList.remove('user-dragging');
		}, 300);
	}

	public triggerFileSelect() {
		const input = this.fileSelectorElement?.querySelector('input');
		if (input) {
			input.click();
		}
	}

	beforeDestroy(): void {
		if (this.fileSelectorElement) {
			this.$el.removeChild(this.fileSelectorElement);
			this.fileSelectorElement = undefined;
		}
		if (this.fileSelector) {
			this.fileSelector = undefined;
		}
	}
}

import Vue from 'vue';
import Component from 'vue-class-component';
import template from './last-team-activities.html';
import './last-team-activities.scss';
import RespressoApi, {Paging} from '../../../api/respresso-api';
import ErrorHandler from '../../../services/error-handler';
import {NextFunction, Route} from 'vue-router';
import {LastUserActivityMeta} from './dashboard';
import Pager from '../../common/pager/pager';
import LoadingScreen from '../../../decorators/loading-screen';
import {TeamMeta} from 'respresso';
import {teamModule} from '../../../store/modules/team/index';

@Component({
	components: {
		pager: Pager,
	},
	template: template,
})
export default class LastTeamActivities extends Vue {
	private teamId: string | undefined;

	private latestTeamActivities: LastUserActivityMeta[] = [];
	private totalSize = 0;
	private paging: Paging = {
		page: 0,
		size: 10,
	};

	beforeRouteEnter(to: Route, from: Route, next: NextFunction): void {
		next((vm) => {
			const res = vm as LastTeamActivities;

			res.teamId = to.params.teamId;
			res.loadData();
		});
	}

	protected setPage(pageIndex: number): void {
		this.paging.page = pageIndex;

		this.loadData();
	}

	@LoadingScreen()
	protected async loadData(): Promise<void> {
		const lastTeamActivitiesResult = await ErrorHandler.tryRequest(() =>
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			RespressoApi.loadLastModifiedTeamMeta(this.teamId!, this.paging),
		);

		if (lastTeamActivitiesResult) {
			this.latestTeamActivities = lastTeamActivitiesResult.lastUpdaterUsers;
			this.totalSize = lastTeamActivitiesResult.totalElements;
		}
	}

	get team(): TeamMeta | undefined {
		if (teamModule.team) {
			return teamModule.team;
		}
	}
}

import Vue from 'vue';
import template from './no-resources.html';
import './no-resources.scss';
import Component from 'vue-class-component';
import {IFileDescriptor} from '@ponte/file-upload-js';
import {NotificationBuilder} from '../dialog/dialog';

@Component({
	props: {
		category: String,
		title: String,
		canImport: Boolean,
		showImport: Boolean,
		showImportButton: Boolean,
		canUpload: Boolean,
		showUpload: Boolean,
		showUploadButton: Boolean,
		canAdd: Boolean,
		importTypes: String,
		importFormats: Array,
		uploadTypes: String,
		uploadFormats: Array,
		loading: Boolean,
	},
	template: template,
})
export default class NoResources extends Vue {
	category: string | undefined;
	title: string | undefined;
	loading: boolean | undefined;

	private importFileSelected(fileDescriptor: IFileDescriptor): void {
		this.$emit('import-file', fileDescriptor);
	}

	private importFileSelectorError(errorKey: string): void {
		NotificationBuilder.error(this.$t(errorKey) as string);
	}

	private uploadFileSelected(fileDescriptor: IFileDescriptor): void {
		this.$emit('upload-file', fileDescriptor);
	}

	private uploadFileSelectorError(errorKey: string): void {
		NotificationBuilder.error(this.$t(errorKey) as string);
	}

	private addResource(): void {
		this.$emit('add-resource');
	}
}

import {DirectiveOptions} from 'vue';
import AnalyticsService from '../services/analytics-service';
import {DialogBuilder} from '../components/common/dialog/dialog';

const listener = (event: Event, name?: string): void => {
	DialogBuilder.yesNoCancel(
		'#notimplemented.title',
		'#notimplemented.message',
		() => {
			AnalyticsService.unimplementedFeature(name, true);
		},
		() => {
			AnalyticsService.unimplementedFeature(name, false);
		},
		() => {
			AnalyticsService.unimplementedFeature(name, false);
		},
	);
};

export const notImplemented: DirectiveOptions = {
	inserted: (el, binding, vnode) => {
		if (binding.modifiers.click || binding.modifiers.focus) {
			if (binding.modifiers.click) {
				el.addEventListener('click', (e) => listener(e, binding.value));
			} else if (binding.modifiers.focus) {
				el.addEventListener('focus', (e) => listener(e, binding.value));
			}
		} else {
			el.addEventListener('click', (e) => listener(e, binding.value));
		}
	},
};
export default notImplemented;

import './index.scss';
// Has to be before component imports
import './util/class-component-hooks';

import Vue from 'vue';
import EventBus from 'vue-bus-ts';
import Ribbon from './components/ribbon/ribbon';
import Navbar from './components/navbar/navbar';
import Menu from './components/menu/menu';
import WhatsNew from './components/whats-new/whats-new';
import VueI18n from 'vue-i18n';
import {getMessages} from './messages';
import {store} from './store/index';
import {router} from './router';
import UserService from './services/user-service';
import VueUploader from './plugin/vue-upload/vue-uploader';
import VueSlimSelect from './components/common/vue-slim-select/vue-slim-select';
import ByteFilter from './filters/byte-filter';
import PercentFilter from './filters/percent-filter';
import UppercaseFilter from './filters/uppercase-filter';
import DateFilter from './filters/date-filter';
import SubscriptionDateFilter from './filters/subscription-date-filter';
import TimeAgoFilter from './filters/time-ago-filter';
import UserNameFilter from './filters/user-name-filter';
import ProjectTitleFilter from './filters/project-title-filter';
import {getDateTimeFormats} from './date-time-formats';
import {VueFileSelector} from './plugin/vue-upload/vue-file-selector';
import {VueUploadList} from './plugin/vue-upload/vue-upload-list';
import {VueSingleUpload} from './plugin/vue-upload/vue-single-upload';
import VueAnalytics from 'vue-analytics';
import {Route} from 'vue-router';
import notImplemented from './directives/not-implemented';
import LoadingService from './services/loading-service';
import {LoadingPlugin} from './plugin/loading-plugin';
import template from './index.html';
import {Logger} from './util/logger';
import {Logo} from './components/common/logo';
import {Disclaimer} from './components/common/disclaimer/disclaimer';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import VTooltip from 'v-tooltip';
import vClickOutside from 'v-click-outside';
import {menuModule} from './store/modules/menu';
import {loadingModule} from './store/modules/loading';
import {userModule} from './store/modules/user';
import {User} from 'respresso';
import {initMautic} from './mautic';
import SocialLogin from './components/view/user/social-login';
import NoResources from './components/common/resource/no-resources';
// @ts-ignore
import VueShepherd from 'vue-shepherd';
import 'shepherd.js/dist/css/shepherd.css';
import MultiUploadBlock from './components/common/upload/multi-upload-block';
import ProgressBar from './components/common/progress/progress-bar';
import StructureInput from './components/common/structure-input/structure-input';
import StructureValueInput from './components/common/structure-input/structure-value-input';
import StructureValueName from './components/common/structure-input/structure-value-name';
import StructureObjectInput from './components/common/structure-input/structure-object-input';
import StructureBooleanInput from './components/common/structure-input/structure-boolean-input';
import StructureEnumInput from './components/common/structure-input/structure-enum-input';
import StructureIntegerInput from './components/common/structure-input/structure-integer-input';
import StructureStringInput from './components/common/structure-input/structure-string-input';
import Youtube from './components/common/youtube/youtube';
import {routingModule} from './store/modules/routing';
import QuestionBanner from "./components/common/question/question-banner.vue";

window.addEventListener('error', (error) => {
	Logger.error(error);
});

initMautic(process.env.VUE_APP_mauticUrl);

// this solves slow focus problem with modal windows
// without this, one can open the modal window multiple times by hitting space fast if the focus is on the button
window.addEventListener('click', () => {
	if (
		document.activeElement &&
		document.activeElement.tagName.toLowerCase() === 'button' &&
		document.activeElement.classList.contains('function-button')
	) {
		(document.activeElement as HTMLButtonElement).blur();
	}
});

Vue.config.devtools = process.env.VUE_APP_vueDebug;
Vue.config.performance = process.env.VUE_APP_vueDebug;

const messages = getMessages();
const dateTimeFormats = getDateTimeFormats();

Vue.use(EventBus);

Vue.use(VueI18n);
export const i18n = new VueI18n({
	locale: 'en',
	fallbackLocale: 'en',
	messages,
	dateTimeFormats,
});

Vue.use(VueAnalytics, {
	//eslint-disable-next-line
    id: (window as any).GA_ID || process.env.VUE_APP_gaId,
	debug: {
		sendHitTask: process.env.VUE_APP_appmode === 'production',
	},
	router,
	autoTracking: {
		pageviewTemplate(route: Route): { page: string; title: string; location: string } {
			return {
				page: route.matched.map((r) => r.name).join('/'),
				title: document.title,
				location: window.location.href,
			};
		},
		pageviewOnLoad: false,
		exception: true,
	},
});

Vue.use(LoadingPlugin);
Vue.use(VTooltip);
Vue.use(vClickOutside);
Vue.use(VueShepherd);

export const app = new Vue({
	router,
	store,
	i18n,
	components: {
		navbar: Navbar,
		ribbon: Ribbon,
		'sidebar-menu': Menu,
		'whats-new': WhatsNew,
		'question-banner': QuestionBanner,
	},
	computed: {
		user(): User | null {
			return userModule.user;
		},
		routeKey(): string | null {
			return routingModule.reuse ? null : router.currentRoute.path + Math.random();
		},
		hideMenu(): boolean {
			return menuModule.hideMenu;
		},
		hideWhatsNew(): boolean {
			return menuModule.hideWhatsNew;
		},
		loading(): boolean {
			return loadingModule.loading;
		},
		loadingMessage(): string {
			return loadingModule.loadingMessage;
		},
		loadingFullScreen(): boolean {
			return loadingModule.loadingFullScreen;
		},
	},
	async created() {
		LoadingService.waitForPromise(UserService.init(), { fullScreen: true });
	},
	template: template,
	//eslint-disable-next-line
} as any);

Vue.component('uploader', VueUploader);
Vue.component('vue-slim-select', VueSlimSelect);
Vue.component('vue-file-selector', VueFileSelector);
Vue.component('vue-upload-list', VueUploadList);
Vue.component('vue-single-upload', VueSingleUpload);
Vue.component('respresso-logo', Logo);
Vue.component('disclaimer', Disclaimer);
Vue.component('vue-slider', VueSlider);
Vue.component('social-login', SocialLogin);
Vue.component('no-resources', NoResources);
Vue.component('multi-upload-block', MultiUploadBlock);
Vue.component('progress-bar', ProgressBar);
Vue.component('password-input', (resolve) => require(['./components/common/password-input/password-input'], resolve));
Vue.component('structure-input', StructureInput);
Vue.component('structure-value-name', StructureValueName);
Vue.component('structure-value-input', StructureValueInput);
Vue.component('structure-object-input', StructureObjectInput);
Vue.component('structure-boolean-input', StructureBooleanInput);
Vue.component('structure-integer-input', StructureIntegerInput);
Vue.component('structure-string-input', StructureStringInput);
Vue.component('structure-enum-input', StructureEnumInput);
Vue.component('youtube', Youtube);

Vue.filter('byte', ByteFilter);
Vue.filter('percent', PercentFilter);
Vue.filter('uppercase', UppercaseFilter);
Vue.filter('date', DateFilter);
Vue.filter('subscriptionDate', SubscriptionDateFilter);
Vue.filter('timeAgo', TimeAgoFilter);
Vue.filter('userName', UserNameFilter);
Vue.filter('projectTitle', ProjectTitleFilter);

Vue.directive('not-implemented', notImplemented);

app.$mount('#app');

export function translate(key: VueI18n.Path, values?: VueI18n.Values): string {
	const translateResult = i18n.t(key, values);

	if (typeof translateResult === 'string') {
		return translateResult;
	} else {
		return translateResult.toString();
	}
}

export function createVueVMInstance<T extends Vue>(
	viewModel: new (options?: Record<string, unknown>) => T,
	extra?: Record<string, unknown>,
): T {
	return new viewModel({
		router,
		store,
		i18n,
		...(extra ? extra : {}),
	});
}

import Vue from 'vue';
import Component from 'vue-class-component';
import template from './pending-imports.html';
import './pending-imports.scss';
import {NextFunction, Route} from 'vue-router';
import LoadingScreen from '../../../decorators/loading-screen';
import {BulkImportFile, BulkImportTarget, BulkImportTargets, ProjectMeta, TeamMeta} from 'respresso';
import {teamModule} from '../../../store/modules/team/index';
import ErrorHandler from '../../../services/error-handler';
import RespressoApi from '../../../api/respresso-api';
import FlagUtil from '../../../util/flag-util';
import AndroidImageSizePicker from "../tools/image-size-picker/android-image-size-picker.vue";
import IosImageSizePicker from "../tools/image-size-picker/ios-image-size-picker.vue";
import WebImageSizePicker from "../tools/image-size-picker/web-image-size-picker.vue";
import {convertImageSizesToPickedSizes} from "../tools/image-size-picker/image-size-picker.vue";

interface OverridableTargetBulkImportFile extends BulkImportFile {
	selected: boolean;
	version?: string;
}

interface OverridableTargetBulkImportTarget extends BulkImportTarget {
	files: OverridableTargetBulkImportFile[];
}

export interface OverridableTargetBulkImportTargets {
	targets: OverridableTargetBulkImportTarget[];
}

@Component({
	template: template,
	components: {
		'android-image-size-picker': AndroidImageSizePicker,
		'ios-image-size-picker': IosImageSizePicker,
		'web-image-size-picker': WebImageSizePicker,
	},
})
export default class PendingImports extends Vue {
	private teamId = '';
	private projectId = '';
	private pendingImports: OverridableTargetBulkImportTargets | null = null;

	beforeRouteEnter(to: Route, from: Route, next: NextFunction): void {
		next((vm) => {
			const res = vm as PendingImports;
			res.load(to);
		});
	}

	@LoadingScreen({ showImmediately: true })
	private async load(to: Route): Promise<void> {
		this.teamId = to.params.teamId;
		this.projectId = to.params.projectId;
		await this.loadData();
	}

	private async loadData() {
		const pendingImports = await ErrorHandler.tryRequest(() =>
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			RespressoApi.getPendingImports(this.teamId!, this.projectId!),
		);
		if (pendingImports && pendingImports.imports) {
			this.pendingImports = {
				targets: pendingImports.imports.targets.map((target) => {
					return {
						category: target.category,
						version: target.version,
						files: target.files.map((file) => {
							return {
								id: file.id,
								name: file.name,
								mimeType: file.mimeType,
								size: file.size,
								created: file.created,
								source: file.source,
								config: file.config,
								selected: true,
							};
						}),
					};
				}),
			};
		} else {
			this.pendingImports = null;
		}
	}

	private flagByLanguage(lang: string) {
		return FlagUtil.getFlagByLang(lang);
	}

	@LoadingScreen({ showImmediately: true })
	private async executeSelectedImports() {
		const imports: BulkImportTargets = { targets: [] };
		this.pendingImports?.targets.forEach((target) => {
			target.files.forEach((file) => {
				if (file.selected) {
					const version = file.version ? file.version : target.version;
					let fileTarget: BulkImportTarget | undefined = imports.targets.find(
						(t) => t.category === target.category && t.version === version,
					);
					if (!fileTarget) {
						fileTarget = {
							category: target.category,
							version: version,
							files: [],
						};
						imports.targets.push(fileTarget);
					}
					fileTarget.files.push({
						id: file.id,
						name: file.name,
						size: file.size,
						mimeType: file.mimeType,
						created: file.created,
						source: file.source,
						config: file.config,
					});
				}
			});
		});
		await ErrorHandler.tryRequest(() => {
			return RespressoApi.bulkImport(this.teamId, this.projectId, imports);
		});
		await this.loadData();
	}

	private getPickedSizes(file: OverridableTargetBulkImportFile, platform: string) {
		if(file.config && file.config.fileData && file.config.fileData.hasOwnProperty(platform)) {
			const platformConfig = file.config.fileData[platform];
			//console.log(`Platform config: ${JSON.stringify(platformConfig)}`)
			return convertImageSizesToPickedSizes(platformConfig["sizes"]);
		}
		return [];
	}

	get projectName(): string | undefined {
		const project = this.project;
		if (project) {
			return project.title;
		}
	}

	get team(): TeamMeta | null {
		return teamModule.team;
	}

	get project(): ProjectMeta | null {
		const moduleGetterTeam = this.team;
		if (!moduleGetterTeam || !moduleGetterTeam.projects) {
			return null;
		}
		const project = moduleGetterTeam.projects.filter((p: ProjectMeta) => {
			return p.id === this.projectId;
		});
		if (project.length) {
			return project[0];
		} else {
			return null;
		}
	}
}

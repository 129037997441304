import Vue from 'vue';
import template from './project-last-updates-list.html';
import './project-last-updates-list.scss';
import Component from 'vue-class-component';
import {ProjectUpdateMeta} from '../../view/project/project';

@Component({
	template: template,
	props: {
		latestUpdates: Array,
		teamId: String,
		projectId: String,
		detailed: Boolean,
	},
})
export default class ProjectLastUpdatesList extends Vue {
	private latestUpdates?: ProjectUpdateMeta[];
	private teamId?: string;
	private projectId?: string;
	private detailed?: boolean;
}

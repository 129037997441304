import Component from 'vue-class-component';
import template from './structure-input.html';
import './structure-input.scss';
import Vue from 'vue';
import {Prop, Provide} from 'vue-property-decorator';
import {StructureDefinition, StructureDefinitions} from 'respresso';

@Component({
	template: template,
	props: {
		definitions: Object,
		initialValue: Object,
		editablePaths: Array,
		forbiddenPaths: Array,
	},
})
export default class StructureInput extends Vue {
	@Prop()
	definitions?: StructureDefinitions;
	@Prop()
	initialValue?: any;
	@Prop({ default: () => [''] })
	editablePaths?: string[];
	@Prop({ default: () => [] })
	forbiddenPaths?: string[];

	@Provide()
	value?: any = null;

	beforeMount() {
		this.value = this.initialValue;
	}

	private getStructureDefinition(id: string, version: number): StructureDefinition | undefined {
		if (this.definitions) {
			return this.definitions.definitions.find((def) => def.id == id && def.version == version);
		}
		return undefined;
	}

	private get root(): StructureDefinition | undefined {
		return this.definitions
			? this.getStructureDefinition(this.definitions.rootDefinition.id, this.definitions.rootDefinition.version)
			: undefined;
	}

	private onValueChanged(value?: any) {
		const prevValue = this.value;
		this.value = value;
		if (this.value !== prevValue) {
			console.log('Config changed', JSON.stringify(this.value, null, 2));
			this.$emit('value-changed', this.value);
		}
	}
}

import Vue from 'vue';
import {IFileDescriptor} from '@ponte/file-upload-js';
import {VueSingleUpload} from '../../../plugin/vue-upload/vue-single-upload';
import Component from 'vue-class-component';
import template from './edit-image-dialog.html';
import {IDialogComponent} from '@ponte/dialog-vue';
import {IDialog} from '@ponte/dialog';
import DialogTemplate from '../dialog/dialogs/dialog-template/dialog-template';
import {Prop} from 'vue-property-decorator';

export interface ImageUploadResult {
	fileId: string;
	mimeType: string;
	name: string;
	total: number;
}

type PreviewImageUrlHandler = (uploadResult: ImageUploadResult) => string | null;

export interface ImageUploadDialogConfig {
	button: ImageUploadButton;
	fileFilter: string[];
	message: string;
	title: string;
	uploadResult: ImageUploadResult | null;
	uploadHandler: string;
	previewImageUrlHandler: PreviewImageUrlHandler;
}

export interface ImageUploadButton {
	text: string;
	fn: (uploadResult: ImageUploadResult | null) => void;
}

@Component({
	components: {
		'dialog-template': DialogTemplate,
	},
	template: template,
})
export class EditImageDialog extends Vue implements IDialogComponent<boolean> {
	@Prop() dialogConfig!: ImageUploadDialogConfig;

	private uploadHandler = 'ImageUploadHandler';
	private uploadModified = false;
	private uploadResult: ImageUploadResult | null = null;
	private fileName = '';
	private accept: string[] = [];
	private _previewUrlHandler: (uploadResult: ImageUploadResult) => string | null = () => null;

	private message = '';
	private title = '';

	dialog!: IDialog<boolean>;

	public created(): void {
		if (!this.dialogConfig) return;
		this.setFileFilter(...this.dialogConfig.fileFilter);
		this.previewImageUrlHandler = this.dialogConfig.previewImageUrlHandler;
		this.setUploadHandler(this.dialogConfig.uploadHandler);
		if (this.dialogConfig.uploadResult) {
			this.setUploadResult(this.dialogConfig.uploadResult);
		}
	}

	private async fileSelected(fileDescriptor: IFileDescriptor): Promise<void> {
		this.fileName = fileDescriptor.file.name;
		this.uploadResult = await (this.$refs.singleUpload as VueSingleUpload).uploadFile(fileDescriptor.file, {
			handler: this.uploadHandler,
		});
		this.uploadModified = true;
	}

	public isUploadModified(): boolean {
		return this.uploadModified;
	}

	public closeDialog(): void {
		if (this.dialog.dialogController) this.dialog.dialogController.close(true);
	}

	public saveAndClose(): void {
		if (!this.isUploadModified) {
			this.closeDialog();
			return;
		}
		this.dialogConfig.button.fn(this.getUploadResult());
		this.closeDialog();
	}

	private deleteFile(): void {
		this.uploadResult = null;
		(this.$refs.singleUpload as VueSingleUpload).reset();
		this.uploadModified = true;
	}

	public get showDetails(): boolean {
		return this.uploadResult !== null;
	}

	public setUploadResult(uploadResult: ImageUploadResult): void {
		(this.$refs.singleUpload as VueSingleUpload).showDetailsScreen();
		this.uploadResult = uploadResult;
		this.fileName = uploadResult.name;
	}

	public getUploadResult(): ImageUploadResult | null {
		return this.uploadResult;
	}

	public get thumbnailUrl(): void | null | string {
		if (!this.uploadResult) {
			return null;
		}

		if (this._previewUrlHandler) {
			return this._previewUrlHandler(this.uploadResult);
		} else {
			return;
		}
	}

	public get downloadUrl(): string {
		return '#'; //FIXME
	}

	public get previewImageUrlHandler(): PreviewImageUrlHandler {
		return this._previewUrlHandler;
	}

	public set previewImageUrlHandler(handler: PreviewImageUrlHandler) {
		this._previewUrlHandler = handler;
	}

	public setUploadHandler(uploadHandler: string): void {
		this.uploadHandler = uploadHandler;
	}

	public setMessage(value: string): void {
		this.message = value;
	}

	public setTitle(value: string): void {
		this.title = value;
	}

	public setFileFilter(...accept: string[]): void {
		this.accept = accept;
	}
}

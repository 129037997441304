export default class FlagUtil {
	public static getFlagUrlByLang(lang: string, miniFlag = true): string | undefined {
		const flag = FlagUtil.getFlagByLang(lang);
		const type = miniFlag ? 'mini' : 'normal';
		if (flag) {
			return `/api/assets/img/flags/${type}/${flag}.png`;
		}
		return `/api/assets/img/flags/${type}_default.png`;
	}

	public static getFlagByLang(lang: string): string | undefined {
		let flaglang: string = lang;

		if (lang.length === 2 || lang.length === 3) {
			switch (lang) {
				case 'gu':
				case 'hi':
				case 'kok':
				case 'ta':
				case 'te':
				case 'as':
				case 'bo':
				case 'ks':
				case 'ml':
				case 'mr':
				case 'or':
					flaglang = lang + '-IN';
					break;
				case 'eu':
				case 'gl':
				case 'ca':
					flaglang = lang + '-ES';
					break;
				case 'ns':
				case 'zu':
				case 'xh':
				case 'af':
					flaglang = lang + '-ZA';
					break;
				case 'cy':
				case 'gd':
				case 'kw':
					flaglang = lang + '-GB';
					break;
				case 'ha':
				case 'ig':
				case 'yo':
					flaglang = lang + '-NG';
					break;
				case 'br':
					flaglang = lang + '-FR';
					break;
				case 'zh':
				case 'ii':
					flaglang = lang + '-CN';
					break;
				case 'da':
				case 'kl':
					flaglang = lang + '-DK';
					break;
				case 'lu':
				case 'ln':
					flaglang = lang + '-CD';
					break;
				case 'am':
				case 'om':
					flaglang = lang + '-ET';
					break;
				case 'ce':
				case 'os':
					flaglang = lang + '-RU';
					break;
				case 'cs':
					flaglang = 'cs-CZ';
					break;
				case 'dv':
					flaglang = 'dv-MV';
					break;
				case 'el':
					flaglang = 'el-GR';
					break;
				case 'fa':
					flaglang = 'fa-IR';
					break;
				case 'he':
					flaglang = 'he-IL';
					break;
				case 'hy':
					flaglang = 'hy-AM';
					break;
				case 'ja':
					flaglang = 'ja-JP';
					break;
				case 'ka':
					flaglang = 'ka-GE';
					break;
				case 'kk':
					flaglang = 'kk-KZ';
					break;
				case 'ko':
					flaglang = 'ko-KR';
					break;
				case 'ky':
					flaglang = 'ky-KG';
					break;
				case 'mi':
					flaglang = 'mi-NZ';
					break;
				/*				case ("ms"):
					flaglang = "ms-BN";
					break;*/
				case 'nb':
					flaglang = 'nb-NO';
					break;
				case 'ps':
					flaglang = 'ps-AF';
					break;
				case 'qu':
					flaglang = 'qu-BO';
					break;
				case 'sq':
					flaglang = 'sq-AL';
					break;
				case 'sw':
					flaglang = 'sw-KE';
					break;
				case 'syr':
					flaglang = 'syr-SY';
					break;
				case 'uk':
					flaglang = 'uk-UA';
					break;
				case 'ur':
					flaglang = 'ur-PK';
					break;
				case 'vi':
					flaglang = 'vi-VN';
					break;
				case 'ki':
					flaglang = 'ki-KE';
					break;
				case 'ak':
					flaglang = 'ak-GH';
					break;
				case 'bm':
					flaglang = 'bm-ML';
					break;
				case 'ar':
					flaglang = 'ar-AE';
					break;
				case 'be':
					flaglang = 'be-BL';
					break;
				case 'my':
					flaglang = 'my-MI';
					break;
				case 'bn':
					flaglang = 'bn-BD';
					break;
				case 'bs':
					flaglang = 'bs-BA';
					break;
				case 'dz':
					flaglang = 'dz-BT';
					break;
				case 'ee':
					flaglang = 'ee-TG';
					break;
				case 'et':
					flaglang = 'et-EE';
					break;
				case 'fy':
					flaglang = 'fy-NL';
					break;
				case 'ga':
					flaglang = 'ga-IE';
					break;
				case 'km':
					flaglang = 'km-KH';
					break;
				case 'kn':
					flaglang = 'kn-CA';
					break;
				case 'lb':
					flaglang = 'lb-LU';
					break;
				case 'lg':
					flaglang = 'lg-UG';
					break;
				case 'lo':
					flaglang = 'lo-LA';
					break;
				case 'ms':
					flaglang = 'ms-MY';
					break;
				case 'nd':
				case 'sn':
					flaglang = lang + '-ZW';
					break;
				case 'ne':
					flaglang = 'ne-NP';
					break;
				case 'nn':
					flaglang = 'nn-NO';
					break;
				case 'pa':
					flaglang = 'pa-PK';
					break;
				case 'rm':
					flaglang = 'rm-CH';
					break;
				case 'rn':
					flaglang = 'rn-BI';
					break;
				case 'sg':
					flaglang = 'sg-CF';
					break;
				case 'si':
					flaglang = 'si-LK';
					break;
				case 'sl':
					flaglang = 'sl-SI';
					break;
				case 'sr':
					flaglang = 'sr-RS';
					break;
				case 'sv':
					flaglang = 'sv-SE';
					break;
				case 'ti':
					flaglang = 'ti-ER';
					break;
				case 'tk':
					flaglang = 'tk-TM';
					break;
				case 'ug':
					flaglang = 'ug-CN';
					break;
				case 'ff':
					flaglang = 'ff-BF';
					break;
				default:
					flaglang = lang + '-' + lang.toUpperCase();
					break;
			}
		}

		if (flaglang.indexOf('-') !== -1) {
			flaglang = flaglang.substring(flaglang.indexOf('-') + 1);
			if (flaglang === 'EO' || flaglang === 'YI' || flaglang === 'VO') {
				return undefined;
			}
			return flaglang.toLocaleLowerCase();
		} else {
			return undefined;
		}
	}
}

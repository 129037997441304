/* eslint-disable @typescript-eslint/no-explicit-any */
import './color-picker.scss';
import {Logger} from '../../../util/logger';

const hexAlphaToColor = function (hexAlpha: string): { [key: string]: number } | undefined {
	const valid = /(^#?[0-9A-F]{6}$)|(^#?[0-9A-F]{8}$)|(^#?[0-9a-f]{6}$)|(^#?[0-9a-f]{8}$)/i.test(hexAlpha);

	if (valid !== true) {
		return;
	}

	if (hexAlpha[0] === '#') {
		hexAlpha = hexAlpha.slice(1, hexAlpha.length);
	}

	return {
		red: parseInt(hexAlpha.substr(0, 2), 16),
		green: parseInt(hexAlpha.substr(2, 2), 16),
		blue: parseInt(hexAlpha.substr(4, 2), 16),
		alpha: hexAlpha.length === 8 ? parseInt(hexAlpha.substr(6, 2), 16) : 255,
	};
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const colorToHexAlpha = (color: any): string => {
	let r = color.red.toString(16);
	let g = color.green.toString(16);
	let b = color.blue.toString(16);
	let a = color.alpha.toString(16);
	if (color.red < 16) r = '0' + r;
	if (color.green < 16) g = '0' + g;
	if (color.blue < 16) b = '0' + b;
	if (color.alpha < 16) a = '0' + a;
	return '#' + r + g + b + a;
};

const setMouseTracking = function setMouseTracking(elem: HTMLElement, callback: (e: MouseEvent) => void): void {
	elem.addEventListener('mousedown', function (e: MouseEvent) {
		callback(e);
		document.addEventListener('mousemove', callback);
	});

	document.addEventListener('mouseup', function () {
		document.removeEventListener('mousemove', callback);
	});
};

export class Color {
	public r: number;
	public g: number;
	public b: number;
	public a: number;
	public hue: number;
	public saturation: number;
	public value: number;
	public lightness: number;
	public format: string;
	public alpha: number | undefined;

	/**
	 * RGBA Color class
	 *
	 * HSV/HSB and HSL (hue, saturation, value / brightness, lightness)
	 * @param color
	 */
	constructor(color?: Color) {
		this.r = 0;
		this.g = 0;
		this.b = 0;
		this.a = 255;
		this.hue = 0;
		this.saturation = 0;
		this.value = 0;
		this.lightness = 0;
		this.format = 'HSV';

		if (color instanceof Color) {
			this.copy(color);
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	copy(obj: any): void {
		if (obj instanceof Color !== true) {
			Logger.info('Typeof parameter not Color');
			return;
		}

		this.r = obj.r;
		this.g = obj.g;
		this.b = obj.b;
		this.a = obj.a;
		this.hue = obj.hue;
		this.saturation = obj.saturation;
		this.value = obj.value;
		this.format = '' + obj.format;
		this.lightness = obj.lightness;
	}

	setFormat(format: string): void {
		if (format === 'HSV') {
			this.format = 'HSV';
		}
		if (format === 'HSL') {
			this.format = 'HSL';
		}
	}

	/*========== Methods to set Color Properties ==========*/
	static isValidRGBValue(value?: number): boolean {
		return typeof value === 'number' && isNaN(value) === false && value >= 0 && value <= 255;
	}

	setA(alpha?: number): void {
		if (Color.isValidRGBValue(alpha) === true) {
			this.a = alpha === 0 ? 0 : alpha || 255;
		}
	}

	setRGBA(red: number, green: number, blue: number, alpha?: number): void {
		if (
			Color.isValidRGBValue(red) === false ||
			Color.isValidRGBValue(green) === false ||
			Color.isValidRGBValue(blue) === false
		) {
			return;
		}

		this.r = red | 0;
		this.g = green | 0;
		this.b = blue | 0;

		this.setA(alpha);
	}

	setByName(name: string, value: number): void {
		if (name === 'r' || name === 'g' || name === 'b' || name === 'a') {
			if (Color.isValidRGBValue(value) === false) {
				return;
			}

			this[name] = value;
			this.updateHSX();
		}
	}

	setHSV(hue: number, saturation: number, value: number): void {
		this.hue = hue;
		this.saturation = saturation;
		this.value = value;
		this.HSVtoRGB();
	}

	setHSL(hue: number, saturation: number, lightness: number): void {
		this.hue = hue;
		this.saturation = saturation;
		this.lightness = lightness;
		this.HSLtoRGB();
	}

	setHue(value: number): void {
		if (typeof value !== 'number' || isNaN(value) === true || value < 0 || value > 359) {
			return;
		}
		this.hue = value;
		this.updateRGB();
	}

	setSaturation(value: number): void {
		if (typeof value !== 'number' || isNaN(value) === true || value < 0 || value > 100) {
			return;
		}
		this.saturation = value;
		this.updateRGB();
	}

	setValue(value: number): void {
		if (typeof value !== 'number' || isNaN(value) === true || value < 0 || value > 100) {
			return;
		}
		this.value = value;
		this.HSVtoRGB();
	}

	setLightness(value: number): void {
		if (typeof value !== 'number' || isNaN(value) === true || value < 0 || value > 100) {
			return;
		}
		this.lightness = value;
		this.HSLtoRGB();
	}

	setHexa(value: string): void {
		const c = hexAlphaToColor(value);
		if (c) {
			this.r = c.red || 0;
			this.g = c.green || 0;
			this.b = c.blue || 0;
			this.a = c.alpha === 0 ? 0 : c.alpha || 255;
		}

		this.alpha = this.a;
		this.RGBtoHSV();
	}

	/*========== Conversion Methods ==========*/
	convertToHSL(): void {
		if (this.format === 'HSL') {
			return;
		}

		this.setFormat('HSL');
		this.RGBtoHSL();
	}

	convertToHSV(): void {
		if (this.format === 'HSV') {
			return;
		}

		this.setFormat('HSV');
		this.RGBtoHSV();
	}

	/*========== Update Methods ==========*/
	updateRGB(): void {
		if (this.format === 'HSV') {
			this.HSVtoRGB();
			return;
		}

		if (this.format === 'HSL') {
			this.HSLtoRGB();
			return;
		}
	}

	updateHSX(): void {
		if (this.format === 'HSV') {
			this.RGBtoHSV();
			return;
		}

		if (this.format === 'HSL') {
			this.RGBtoHSL();
			return;
		}
	}

	HSVtoRGB(): void {
		const sat: number = this.saturation / 100;
		const value: number = this.value / 100;
		let C: number = sat * value;
		const H: number = this.hue / 60;
		let X: number = C * (1 - Math.abs((H % 2) - 1));
		let m: number = value - C;
		const precision = 255;

		C = ((C + m) * precision) | 0;
		X = ((X + m) * precision) | 0;
		m = (m * precision) | 0;

		if (H >= 0 && H < 1) {
			this.setRGBA(C, X, m);
			return;
		}
		if (H >= 1 && H < 2) {
			this.setRGBA(X, C, m);
			return;
		}
		if (H >= 2 && H < 3) {
			this.setRGBA(m, C, X);
			return;
		}
		if (H >= 3 && H < 4) {
			this.setRGBA(m, X, C);
			return;
		}
		if (H >= 4 && H < 5) {
			this.setRGBA(X, m, C);
			return;
		}
		if (H >= 5 && H < 6) {
			this.setRGBA(C, m, X);
			return;
		}
	}

	HSLtoRGB(): void {
		const sat = this.saturation / 100;
		const light = this.lightness / 100;
		let C = sat * (1 - Math.abs(2 * light - 1));
		const H = this.hue / 60;
		let X = C * (1 - Math.abs((H % 2) - 1));
		let m = light - C / 2;
		const precision = 255;

		C = ((C + m) * precision) | 0;
		X = ((X + m) * precision) | 0;
		m = (m * precision) | 0;

		if (H >= 0 && H < 1) {
			this.setRGBA(C, X, m);
			return;
		}
		if (H >= 1 && H < 2) {
			this.setRGBA(X, C, m);
			return;
		}
		if (H >= 2 && H < 3) {
			this.setRGBA(m, C, X);
			return;
		}
		if (H >= 3 && H < 4) {
			this.setRGBA(m, X, C);
			return;
		}
		if (H >= 4 && H < 5) {
			this.setRGBA(X, m, C);
			return;
		}
		if (H >= 5 && H < 6) {
			this.setRGBA(C, m, X);
			return;
		}
	}

	RGBtoHSV(): void {
		const red = this.r / 255;
		const green = this.g / 255;
		const blue = this.b / 255;

		const cmax = Math.max(red, green, blue);
		const cmin = Math.min(red, green, blue);
		const delta = cmax - cmin;
		let hue = 0;
		let saturation = 0;

		if (delta) {
			if (cmax === red) {
				hue = (green - blue) / delta;
			}
			if (cmax === green) {
				hue = 2 + (blue - red) / delta;
			}
			if (cmax === blue) {
				hue = 4 + (red - green) / delta;
			}
			if (cmax) saturation = delta / cmax;
		}

		this.hue = (60 * hue) | 0;
		if (this.hue < 0) this.hue += 360;
		this.saturation = (saturation * 100) | 0;
		this.value = (cmax * 100) | 0;
	}

	RGBtoHSL(): void {
		const red = this.r / 255;
		const green = this.g / 255;
		const blue = this.b / 255;

		const cmax = Math.max(red, green, blue);
		const cmin = Math.min(red, green, blue);
		const delta = cmax - cmin;
		let hue = 0;
		let saturation = 0;
		const lightness = (cmax + cmin) / 2;
		const X = 1 - Math.abs(2 * lightness - 1);

		if (delta) {
			if (cmax === red) {
				hue = (green - blue) / delta;
			}
			if (cmax === green) {
				hue = 2 + (blue - red) / delta;
			}
			if (cmax === blue) {
				hue = 4 + (red - green) / delta;
			}
			if (cmax) saturation = delta / X;
		}

		this.hue = (60 * hue) | 0;
		if (this.hue < 0) this.hue += 360;
		this.saturation = (saturation * 100) | 0;
		this.lightness = (lightness * 100) | 0;
	}

	/*========== Get Methods ==========*/
	getHexa(): string {
		return colorToHexAlpha({ red: this.r, green: this.g, blue: this.b, alpha: this.a });
	}

	getRGBA(): string {
		const rgb = '(' + this.r + ', ' + this.g + ', ' + this.b;
		let a = '';
		let v = '';
		const x = this.a / 255;
		if (x !== 1) {
			a = 'a';
			v = ', ' + x;
		}

		return 'rgb' + a + rgb + v + ')';
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	getHSLA(): any {
		if (this.format === 'HSV') {
			const color = new Color(this);
			color.setFormat('HSL');
			color.updateHSX();
			return color.getHSLA();
		}

		let a = '';
		let v = '';
		const hsl = '(' + this.hue + ', ' + this.saturation + '%, ' + this.lightness + '%';
		const x = this.a / 255;
		if (x !== 1) {
			a = 'a';
			v = ', ' + x;
		}

		return 'hsl' + a + hsl + v + ')';
	}

	getColor(): string {
		if (this.a !== 255) {
			return this.getHexa();
		}
		return this.getRGBA();
	}
}

export class ColorPicker {
	private color: Color;
	private node: HTMLElement;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private subscribers: { [key: string]: (data: any) => void } = {};
	private topic: string;
	private picker_mode: string | null;
	private preview_color: HTMLDivElement;
	private picking_area: HTMLElement;
	private color_picker: HTMLElement;
	private hue_area: HTMLElement;
	private hue_picker: HTMLElement;
	private alpha_area: HTMLDivElement;
	private alpha_mask: HTMLDivElement;
	private alpha_picker: HTMLDivElement;

	constructor(node: HTMLElement) {
		this.color = new Color();
		this.node = node;

		const type: string | null = this.node.getAttribute('data-mode');
		const topic: string | null = this.node.getAttribute('data-topic');

		if (!topic || !type) {
			throw new Error();
		}

		this.topic = topic;
		this.picker_mode = type === 'HSL' ? 'HSL' : 'HSV';
		this.color.setFormat(this.picker_mode);

		this.picking_area = document.createElement('div');
		this.color_picker = document.createElement('div');
		this.initPickingArea();
		this.hue_area = document.createElement('div');
		this.hue_picker = document.createElement('div');
		this.initHueArea();

		this.newInputComponent('H', 'hue', this.inputChangeHue.bind(this));
		this.newInputComponent('S', 'saturation', this.inputChangeSaturation.bind(this));
		this.newInputComponent('V', 'value', this.inputChangeValue.bind(this));
		this.newInputComponent('L', 'lightness', this.inputChangeLightness.bind(this));

		this.alpha_area = document.createElement('div');
		this.alpha_mask = document.createElement('div');
		this.alpha_picker = document.createElement('div');
		this.initAlphaArea();

		this.newInputComponent('R', 'red', this.inputChangeRed.bind(this));
		this.newInputComponent('G', 'green', this.inputChangeGreen.bind(this));
		this.newInputComponent('B', 'blue', this.inputChangeBlue.bind(this));

		this.preview_color = document.createElement('div');
		this.initPreviewBox();
		// this.createChangeModeButton();

		this.newInputComponent('alpha', 'alpha', this.inputChangeAlpha.bind(this));
		this.newInputComponent('hexa', 'hexa', this.inputChangeHexa.bind(this), '#rgba');

		this.setColor(this.color);
	}

	public getColor(): Color {
		return new Color(this.color);
	}

	/*************************************************************************/
	//				Function for generating the color-picker
	/*************************************************************************/
	initPickingArea(): void {
		this.picking_area.className = 'picking-area';
		this.color_picker.className = 'picker';

		setMouseTracking(this.picking_area, this.updateColor.bind(this));

		this.picking_area.appendChild(this.color_picker);
		this.node.appendChild(this.picking_area);
	}

	initHueArea(): void {
		this.hue_area.className = 'hue';
		this.hue_picker.className = 'slider-picker';

		setMouseTracking(this.hue_area, this.updateHueSlider.bind(this));

		this.hue_area.appendChild(this.hue_picker);
		this.node.appendChild(this.hue_area);
	}

	initAlphaArea(): void {
		this.alpha_area.className = 'alpha';
		this.alpha_mask.className = 'alpha-mask';
		this.alpha_picker.className = 'slider-picker';

		setMouseTracking(this.alpha_area, this.updateAlphaSlider.bind(this));

		this.alpha_area.appendChild(this.alpha_mask);
		this.alpha_mask.appendChild(this.alpha_picker);
		this.node.appendChild(this.alpha_area);
	}

	initPreviewBox(): void {
		const preview_box = document.createElement('div');

		preview_box.className = 'preview';
		this.preview_color.className = 'preview-color';

		preview_box.appendChild(this.preview_color);
		this.node.appendChild(preview_box);
	}

	newInputComponent(title: string, topic: string, onChangeFunc: (e: Event) => void, titleTag?: string): void {
		const wrapper: HTMLDivElement = document.createElement('div');
		const input: HTMLInputElement = document.createElement('input');
		const info: HTMLSpanElement = document.createElement('span');

		wrapper.classList.add('input');
		wrapper.classList.add('meee');
		wrapper.setAttribute('data-topic', topic);
		info.textContent = title;
		info.className = 'name';
		input.setAttribute('type', 'text');

		if (titleTag) {
			wrapper.title = titleTag;
		}

		wrapper.appendChild(info);
		wrapper.appendChild(input);
		this.node.appendChild(wrapper);

		input.addEventListener('change', onChangeFunc);
		input.addEventListener('click', (e: MouseEvent) => {
			(e.target as HTMLInputElement).select();
		});

		this.subscribe(topic, (value: string) => {
			input.value = value;
		});
	}

	createChangeModeButton(): void {
		const button = document.createElement('div');
		button.className = 'switch_mode';
		button.addEventListener('click', () => {
			if (this.picker_mode === 'HSV') {
				this.setPickerMode('HSL');
			} else {
				this.setPickerMode('HSV');
			}
		});

		this.node.appendChild(button);
	}

	/*************************************************************************/
	//					Updates properties of UI elements
	/*************************************************************************/
	updateColor(e: MouseEvent): void {
		const pickingAreaBoundingRect = this.picking_area.getBoundingClientRect();
		let x = e.pageX - pickingAreaBoundingRect.left;
		let y = e.pageY - pickingAreaBoundingRect.top;
		const picker_offset = 5;

		// width and height should be the same
		const size = this.picking_area.clientWidth;

		if (x > size) x = size;
		if (y > size) y = size;
		if (x < 0) x = 0;
		if (y < 0) y = 0;

		const value = (100 - (y * 100) / size) | 0;
		const saturation = ((x * 100) / size) | 0;

		if (this.picker_mode === 'HSV') {
			this.color.setHSV(this.color.hue, saturation, value);
		}
		if (this.picker_mode === 'HSL') {
			this.color.setHSL(this.color.hue, saturation, value);
		}

		this.color_picker.style.left = x - picker_offset + 'px';
		this.color_picker.style.top = y - picker_offset + 'px';

		this.updateAlphaGradient();
		this.updatePreviewColor();

		this.notify('value', value);
		this.notify('lightness', value);
		this.notify('saturation', saturation);

		this.notify('red', this.color.r);
		this.notify('green', this.color.g);
		this.notify('blue', this.color.b);
		this.notify('hexa', this.color.getHexa());

		this.notify(this.topic, this.color);
	}

	updateHueSlider(e: MouseEvent): void {
		let x = e.pageX - this.hue_area.getBoundingClientRect().left;
		const width = this.hue_area.clientWidth;

		if (x < 0) x = 0;
		if (x > width) x = width;

		// TODO 360 => 359
		const hue = ((359 * x) / width) | 0;
		// if (hue === 360) hue = 359;

		ColorPicker.updateSliderPosition(this.hue_picker, x);
		this.setHue(hue);
	}

	updateAlphaSlider(e: MouseEvent): void {
		let x = e.pageX - this.alpha_area.getBoundingClientRect().left;
		const width = this.alpha_area.clientWidth;

		if (x < 0) x = 0;
		if (x > width) x = width;

		this.color.a = Math.floor((x / width) * 255);

		ColorPicker.updateSliderPosition(this.alpha_picker, x);
		this.updatePreviewColor();

		this.notify('alpha', this.color.a);
		this.notify('hexa', this.color.getHexa());
		this.notify(this.topic, this.color);
	}

	setHue(value: number): void {
		this.color.setHue(value);

		this.updatePickerBackground();
		this.updateAlphaGradient();
		this.updatePreviewColor();

		this.notify('red', this.color.r);
		this.notify('green', this.color.g);
		this.notify('blue', this.color.b);
		this.notify('hexa', this.color.getHexa());
		this.notify('hue', this.color.hue);

		this.notify(this.topic, this.color);
	}

	// Updates when one of Saturation/Value/Lightness changes
	updateSLV(): void {
		this.updatePickerPosition();
		this.updateAlphaGradient();
		this.updatePreviewColor();

		this.notify('red', this.color.r);
		this.notify('green', this.color.g);
		this.notify('blue', this.color.b);
		this.notify('hexa', this.color.getHexa());

		this.notify(this.topic, this.color);
	}

	/*************************************************************************/
	//				Update positions of various UI elements
	/*************************************************************************/
	updatePickerPosition(): void {
		const size = this.picking_area.clientWidth;
		let value = 0;
		const offset = 5;

		if (this.picker_mode === 'HSV') {
			value = this.color.value;
		}
		if (this.picker_mode === 'HSL') {
			value = this.color.lightness;
		}

		const x = ((this.color.saturation * size) / 100) | 0;
		const y = (size - (value * size) / 100) | 0;

		this.color_picker.style.left = x - offset + 'px';
		this.color_picker.style.top = y - offset + 'px';
	}

	static updateSliderPosition(elem: HTMLElement, pos: number): void {
		elem.style.left = Math.max(pos - 3, -2) + 'px';
	}

	updateHuePicker(): void {
		const size = this.hue_area.clientWidth;
		const offset = 1;
		const pos = ((this.color.hue * size) / 360) | 0;
		this.hue_picker.style.left = pos - offset + 'px';
	}

	updateAlphaPicker(): void {
		const size = this.alpha_area.clientWidth;
		const offset = 1;
		const pos = ((this.color.a / 255) * size) | 0;
		this.alpha_picker.style.left = pos - offset + 'px';
	}

	/*************************************************************************/
	//						Update background colors
	/*************************************************************************/
	updatePickerBackground(): void {
		const nc = new Color(this.color);
		nc.setHSV(nc.hue, 100, 100);
		let hex = nc.getHexa();
		if (hex.length === 9) {
			hex = hex.substr(0, 7);
		}
		this.picking_area.style.backgroundColor = hex;
	}

	updateAlphaGradient(): void {
		let hex = this.color.getHexa();
		if (hex.length === 9) {
			hex = hex.substr(0, 7);
		}
		this.alpha_mask.style.backgroundColor = hex;
	}

	updatePreviewColor(): void {
		this.preview_color.style.backgroundColor = this.color.getColor();
	}

	/*************************************************************************/
	//						Update input elements
	/*************************************************************************/
	inputChangeHue(e: any): void {
		const value: number = parseInt(e.target.value, 0);
		this.setHue(value);
		this.updateHuePicker();
	}

	inputChangeSaturation(e: any): void {
		const value = parseInt(e.target.value, 0);
		this.color.setSaturation(value);
		e.target.value = this.color.saturation;
		this.updateSLV();
	}

	inputChangeValue(e: any): void {
		const value = parseInt(e.target.value, 0);
		this.color.setValue(value);
		e.target.value = this.color.value;
		this.updateSLV();
	}

	inputChangeLightness(e: any): void {
		const value = parseInt(e.target.value, 0);
		this.color.setLightness(value);
		e.target.value = this.color.lightness;
		this.updateSLV();
	}

	inputChangeRed(e: any): void {
		const value = parseInt(e.target.value, 0);
		this.color.setByName('r', value);
		e.target.value = this.color.r;
		this.setColor(this.color);
	}

	inputChangeGreen(e: any): void {
		const value = parseInt(e.target.value, 0);
		this.color.setByName('g', value);
		e.target.value = this.color.g;
		this.setColor(this.color);
	}

	inputChangeBlue(e: any): void {
		const value = parseInt(e.target.value, 0);
		this.color.setByName('b', value);
		e.target.value = this.color.b;
		this.setColor(this.color);
	}

	inputChangeAlpha(e: any): void {
		const value = parseInt(e.target.value, 0);
		this.color.setByName('a', value);
		e.target.value = this.color.a;
		this.updateAlphaPicker();
		this.setColor(this.color);
	}

	inputChangeHexa(e: any): void {
		const value = e.target.value;
		this.color.setHexa(value);
		this.setColor(this.color);
	}

	/*************************************************************************/
	//							Internal Pub/Sub
	/*************************************************************************/
	subscribe(topic: string, callback: (data: any) => void): void {
		this.subscribers[topic] = callback;
	}

	notify(topic: string, value: any): void {
		if (this.subscribers[topic]) {
			this.subscribers[topic](value);
		}
	}

	/*************************************************************************/
	//							Set Picker Properties
	/*************************************************************************/
	setColor(color: Color): void {
		if (this.picker_mode && color.format !== this.picker_mode) {
			color.setFormat(this.picker_mode);
			color.updateHSX();
		}

		this.color.copy(color);
		this.updateHuePicker();
		this.updatePickerPosition();
		this.updatePickerBackground();
		this.updateAlphaPicker();
		this.updateAlphaGradient();
		this.updatePreviewColor();

		this.notify('red', this.color.r);
		this.notify('green', this.color.g);
		this.notify('blue', this.color.b);

		this.notify('hue', this.color.hue);
		this.notify('saturation', this.color.saturation);
		this.notify('value', this.color.value);
		this.notify('lightness', this.color.lightness);

		this.notify('alpha', this.color.a);
		this.notify('hexa', this.color.getHexa());
		this.notify(this.topic, this.color);
	}

	setPickerMode(mode: string): void {
		if (mode !== 'HSV' && mode !== 'HSL') {
			return;
		}

		this.picker_mode = mode;
		this.node.setAttribute('data-mode', this.picker_mode || '');
		this.setColor(this.color);
	}
}

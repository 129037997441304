import {UserPublicInfo} from 'respresso';
import {teamModule} from '../store/modules/team/index';
import {translate} from "../main";
import RespressoApi from "../api/respresso-api";

const cache: { [key: string]: UserPublicInfo } = {};

export default function(input: string): string {
    if (cache[input]) {
        return cache[input].name;
    }

    const users = teamModule.team!.users;

    if (users) {
        for (let i = 0; i < users.length; i++) {
            if (users[i].id === input) {
                return users[i].name;
            }
        }
    }

    //TODO ErrorHandler.tryRequest vagy valami más hibakezelés
    RespressoApi.getUserMetaInfo(input)
        .then(function(userInfo: UserPublicInfo) {
            cache[input] = userInfo;
        })
        .catch((reason) => {
            cache[input] = {
                id: input,
                name: input,
            };
        });

    return translate("user.unknown.name", { id: input });
}

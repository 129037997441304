import Component from 'vue-class-component';
import template from './structure-value-input.html';
import Vue from 'vue';
import {Prop} from 'vue-property-decorator';
import {StructureTypeDefinition} from 'respresso';

@Component({
	template: template,
	props: {
		name: String,
		definition: Object,
		value: [Boolean, Number, String, Array, Object],
	},
})
export default class StructureValueInput extends Vue {
	@Prop()
	name!: string;
	@Prop()
	definition!: StructureTypeDefinition;
	@Prop()
	value?: string;

	public async valueChanged(value: any): Promise<void> {
		if (value !== this.value) {
			console.log('generic input value changed', value);
			this.$emit('value-changed', value);
		} else {
			console.log('generic input value the same', value);
		}
	}
}

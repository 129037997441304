import Vue from 'vue';
import template from './create-project-dialog.html';
import './create-project-dialog.scss';
import Component from 'vue-class-component';
import {IDialogComponent} from '@ponte/dialog-vue';
import {IDialog} from '@ponte/dialog';
import {Prop} from 'vue-property-decorator';
import DialogTemplate from '../../dialog/dialogs/dialog-template/dialog-template';
import {TeamMeta} from 'respresso';
import CreateProject from "../create-project.vue";
import {teamModule} from "../../../../store/modules/team";


export interface CreateProjectDialogConfig {
	title?: string;
	team?: TeamMeta;
}

@Component({
	components: {
		'dialog-template': DialogTemplate,
		'create-project': CreateProject,
	},
	template: template,
})
export default class CreateProjectDialog extends Vue implements IDialogComponent<string | null> {
	@Prop() dialogConfig!: CreateProjectDialogConfig;
	private projectName: string = "";
	private minLength: number = 1;
	private error: string = "";

	dialog!: IDialog<string | null>;

	private get team():  TeamMeta | null | undefined {
		let t: TeamMeta | null | undefined = this.dialogConfig.team;
		if(!t) {
			t = teamModule.team;
		}
		return t;
	}

	public closeDialog(result: string | null): void {
		console.log("Close create ")
		if (this.dialog.dialogController) {
			this.dialog.dialogController.close(result);
		} else {
			console.log("Dialog controller not found", result);
		}
	}
	private onProjectCreated(projectId: string) {
		this.closeDialog(projectId);
	}

	private onCancelled() {
		this.closeDialog(null);
	}

}

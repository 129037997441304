import Component from 'vue-class-component';
import template from './color-resource.html';
import './color-resource.scss';
import {ItemState, Resource, ResourceItem} from './resource';
import ResourceList from '../../common/resource-list/resource-list';
import ColorSwatch from '../../common/color-picker/color-swatch';
import {colorToHexAlpha} from '../../common/color-picker/color-picker';
import {DialogBuilder, NotificationBuilder} from '../../common/dialog/dialog';
import RespressoApi from '../../../api/respresso-api';
import ErrorHandler from '../../../services/error-handler';
import {translate} from '../../../main';
import ColorPickerDialog from '../../../../src/components/common/color-picker/color-picker-dialog';
import {IFileDescriptor} from '@ponte/file-upload-js';
import ResourceImportDialog, {ResourceImportDialogConfig,} from '../../common/resource-import-dialog/resource-import-dialog';

export interface GetColorDataResponse {
	versionNumber: string;
	versionEditable: boolean;
	colorImporters: string[];
	colorData: ColorKeyData[];
}

export interface ColorKeyData extends ResourceItem {
	key: string;
	color: ColorKeyDataColor;
	description?: string;
}

export interface ColorKeyDataColor {
	red: number;
	green: number;
	blue: number;
	alpha: number;
}

export interface ColorDataToSave {
	id: string;
	key: string;
	color: ColorKeyDataColor;
	description?: string;
	state?: string;
}

@Component({
	components: {
		'resource-list': ResourceList,
		'color-swatch': ColorSwatch,
	},
	template: template,
})
export default class ColorResource extends Resource {
	static defItem: Partial<ColorKeyData> = {
		key: 'primary_color',
		color: {
			red: 55,
			green: 188,
			blue: 155,
			alpha: 255,
		},
		description: 'Primary color of our brand',
	};
	protected items: ColorKeyData[] = [];
	protected newItems: ColorKeyData[] = [];
	protected colorImporters: string[] = [];
	protected acceptFiles: string[] = [
		'.xml',
		'.aco',
		'.ase',
		'.sketchpalette',
		'.png',
		'.jpg',
		'.bmp',
		'.gif',
		'.zip',
		'.css',
	];

	protected showModal = false;

	protected colorKeyData: ColorKeyDataColor = {} as ColorKeyDataColor;

	protected async loadData<ColorResource>(): Promise<void> {
		await this.updateData();
	}

	protected async updateData(): Promise<void> {
		const resp = await ErrorHandler.tryRequest(
			() => RespressoApi.getColorVersion(this.teamId, this.projectId, this.version),
			{
				loadingScreen: true,
			},
		);

		if (resp) {
			this.setItemFromServer(resp.colorData);

			this.editable = resp.versionEditable;
			this.colorImporters = resp.colorImporters;
		}
		this.$nextTick(() => {
			this.startTour(this.$refs.resourceList as ResourceList);
		});
	}

	protected async doSave(): Promise<boolean> {
		const itemsToSave = this.items.splice(0);
		itemsToSave.push(...this.newItems);

		const saveThis: ColorDataToSave[] = itemsToSave.map(
			(value: ColorKeyData) =>
				({
					id: value.id,
					key: value.key,
					color: value.color,
					description: value.description,
					state: value.state.toUpperCase(),
				} as ColorDataToSave),
		);

		const saveResp = await ErrorHandler.tryRequest(
			() => RespressoApi.saveColorVersion(this.teamId, this.projectId, this.version, saveThis),
			{
				loadingScreen: true,
				loadingMessage: '#loading.processingColors',
			},
		);
		if (saveResp) {
			this.setItemFromServer(saveResp);
			NotificationBuilder.success('#messages.saved');
			return true;
		} else {
			return false;
		}
	}

	protected getResourceId(): string {
		return 'color';
	}

	private setItemFromServer(data: ColorKeyData[]): void {
		this.items = data.map((item) => {
			item.state = ItemState.UNCHANGED;
			item.listId = this.lastListId++;
			return item;
		});

		this.items.sort((a, b) => {
			if (!a.key && !b.key) {
				return 0;
			}
			if (!a.key) {
				return -1;
			}
			if (!b.key) {
				return 1;
			}

			return a.key.localeCompare(b.key);
		});
	}

	protected makeNewItem(defaults?: Partial<ColorKeyData>): ColorKeyData {
		return Object.assign(
			{
				state: ItemState.NEW,
				key: '',
				color: {
					red: 255,
					green: 255,
					blue: 255,
					alpha: 255,
				},
				listId: this.lastListId++,
			},
			defaults || {},
		);
	}

	protected newItem(defaults?: Partial<ColorKeyData>): void {
		const item = this.makeNewItem(defaults);
		this.items.splice(0, 0, item);

		this.$nextTick(() => {
			const element: HTMLInputElement | null = this.$el.querySelector('.color-resource .content-item-key input');
			if (element && element.focus) {
				element.focus();
			}
		});
	}

	formatHEXColor(c: ColorKeyDataColor): string {
		if (!c) {
			return '';
		}

		if (!c.alpha) {
			c.alpha = 255;
		}
		return colorToHexAlpha(c);
	}

	formatRGBAColor(c: ColorKeyDataColor): string {
		if (c) {
			return `${c.red},${c.green},${c.blue}${c.alpha ? ',' + c.alpha : ''}`;
		} else {
			return '';
		}
	}

	openColorEditor(data: ColorKeyData): void {
		const dialogController = DialogBuilder.createVueDialog(ColorPickerDialog, {
			propsData: {
				colorKeyData: data,
			},
		});
		dialogController.closeResult.then((res): void => {
			if (res) {
				data.color.red = res.r;
				data.color.green = res.g;
				data.color.blue = res.b;
				data.color.alpha = res.a;

				if (data.state === ItemState.UNCHANGED) {
					data.state = ItemState.UPDATED;
				}
			}
		});
	}

	protected importFiles(preSelectedFile?: IFileDescriptor): void {
		const dialogConfig: ResourceImportDialogConfig = {
			title: translate('resource.color.importTitle'),
			resource: this.getResourceId(),
			resourceImporterTypes: this.colorImporters,
			accept: this.acceptFiles,
			uploadHandler: 'ColorImportUploadHandler',
			fileDescriptor: preSelectedFile,
			executeImport: async (uploadResult, selectedImporter, config) => {
				if (uploadResult && selectedImporter) {
					const response = await ErrorHandler.tryRequest(() =>
						RespressoApi.importColor(
							this.teamId,
							this.projectId,
							this.version,
							uploadResult.fileId,
							selectedImporter,
							config,
						),
					);
					if (response) {
						NotificationBuilder.success('#messages.imported');
						await this.updateData();
						return true;
					} else {
						return false;
					}
				} else {
					return false;
				}
			},
		};
		const controller = DialogBuilder.createVueDialog(ResourceImportDialog, {
			propsData: {
				dialogConfig,
			},
		});
		controller.closeResult.then((result) => {
			if (!result) {
				this.resumeTour();
			}
		});
		if (preSelectedFile) {
			controller.vueInstance.fileSelected(preSelectedFile);
		}
	}

	private importSelectedFile(file: IFileDescriptor) {
		this.importFiles(file);
		return true;
	}

	protected tourTryImport(): (() => void) | null {
		return this.importFiles;
	}

	protected tourTryAdd(): ((resourceList: ResourceList) => void) | null {
		return () => {
			this.newItem(ColorResource.defItem);
			setTimeout(() => {
				this.resumeTour();
			}, 1000);
		};
	}

	protected tourTrySave(): ((resourceList: ResourceList) => void) | null {
		return () => {
			if (!this.isModified()) {
				this.newItem(ColorResource.defItem);
			}
			this.save().then(() => this.resumeTour());
		};
	}

	protected tourCanTrySave(): () => boolean {
		return () => this.canModifyRestrictedResourceData;
	}

	protected tourTryDownload(): ((resourceList: ResourceList) => void) | null {
		return (resourceList: ResourceList) => {
			const modified = this.isModified();
			if (modified || !resourceList.hasSavedItems) {
				if (!modified && !resourceList.hasSavedItems) {
					this.newItem(ColorResource.defItem);
				}
				this.save().then(() => {
					this.resumeTour();
					this.startDownload(this.getVersionDownloadUrl());
				});
			} else {
				this.resumeTour();
				this.startDownload(this.getVersionDownloadUrl());
			}
		};
	}

	protected tourCanTryDownload(): () => boolean {
		return () => {
			const rl = this.getResourceList();
			return this.canModifyRestrictedResourceData || (rl !== null && rl.hasSavedItems);
		};
	}
}

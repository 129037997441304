import Vue from 'vue';
import Component from 'vue-class-component';
import template from './navbar.html';
import './navbar.scss';
import UserService from '../../services/user-service';
import {TeamInfo, User} from 'respresso';
import {teamModule} from '../../store/modules/team/index';
import {userModule} from '../../store/modules/user/index';
import AccessService from '../../services/access-service';

@Component({
	template: template,
})
export default class Navbar extends Vue {
	private avatarCachedUrl = '';

	mounted(): void {
		this.$bus.$on('avatarUpdated', () => {
			if (this.user) {
				this.avatarCachedUrl = `/api/ui/user/avatar/${this.user.id}?v=${Math.floor(Math.random() * 10000000)}`;
			}
		});
	}

	get teamName(): string | undefined {
		if (teamModule.team) {
			return teamModule.team.title;
		}
	}

	get teamId(): string | undefined {
		if (teamModule.team) {
			return teamModule.team.id;
		}
	}

	get user(): User | undefined {
		if (userModule.user) {
			return userModule.user;
		}
	}

	get avatarUrl(): string {
		if (!this.avatarCachedUrl && this.user) {
			this.avatarCachedUrl = `/api/ui/user/avatar/${this.user.id}`;
		}
		return this.avatarCachedUrl;
	}

	get teams(): TeamInfo[] | undefined {
		if (teamModule.teams) {
			return teamModule.teams;
		}
	}

	private serverExpiration(): string | undefined {
		// const expiration = Date.now() + 31 * 24 * 60 * 60 * 1000
		const expiration = AccessService.getServerLicenseExpiration();
		if (expiration != undefined) {
			const diff = expiration - Date.now();
			const formatted = new Date(expiration).toLocaleString();
			const noticeParams = { when: formatted };
			if (diff > 0) {
				// License is valid
				if (diff < 30 * 24 * 60 * 60 * 1000) {
					return this.$t('license.server.banner.upcomingExpirationNotice', noticeParams) as string;
				} else {
					return undefined; // Hide banner as expiration is in the far future
				}
			} else {
				// License already expired
				return this.$t('license.server.banner.expiredNotice', noticeParams) as string;
			}
		} else {
			return undefined;
		}
	}

	private async logout() {
		await UserService.logout();
		await this.$router.push({ name: 'login' });
	}

	private toggleProfileSettings(): void {
		const profile = document.getElementsByClassName('profile')[0];
		if (!profile) {
			return;
		}
		if (profile.classList.contains('open')) {
			profile.classList.remove('open');
		} else {
			profile.classList.add('open');
		}
	}

	private closeProfileSettings(): void {
		const profile = document.getElementsByClassName('profile')[0];
		if (!profile) {
			return;
		}
		if (profile.classList.contains('open')) {
			profile.classList.remove('open');
		}
	}
}

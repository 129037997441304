import Component from 'vue-class-component';
import template from './structure-object-input.html';
import './structure-object-input.scss';
import Vue from 'vue';
import {Prop, Provide} from 'vue-property-decorator';
import {StructureDefinition, StructureDefinitions, StructureTypeDefinition} from 'respresso';

@Component({
	template: template,
	props: {
		id: String,
		version: Number,
		fieldName: String,
		definitions: Object,
		typeDefinition: Object,
		value: Object,
		editablePaths: Array,
		forbiddenPaths: Array,
	},
})
export default class StructureObjectInput extends Vue {
	@Prop()
	id?: string;
	@Prop()
	version?: number;
	@Prop()
	fieldName?: string;
	@Prop()
	definitions?: StructureDefinitions;
	@Prop()
	typeDefinition?: StructureTypeDefinition;
	@Prop()
	value?: any;
	@Prop({ default: () => [''] })
	editablePaths?: string[];
	@Prop({ default: () => [] })
	forbiddenPaths?: string[];
	@Provide()
	newKey = '';

	private getStructureDefinition(id: string, version: number): StructureDefinition | undefined {
		if (this.definitions) {
			return this.definitions.definitions.find((def) => def.id == id && def.version == version);
		}
		return undefined;
	}

	private get definition(): StructureDefinition | undefined {
		return this.definitions && this.id && this.version
			? this.getStructureDefinition(this.id, this.version)
			: undefined;
	}

	private onFieldValueChanged(name: string, value?: any) {
		let copy = this.value ? { ...this.value } : undefined;
		const prevValue = copy ? copy[name] : undefined;
		if (value != null) {
			if (!copy) {
				copy = {};
			}
			copy[name] = value;
		} else if (copy) {
			delete copy[name];
		}
		const newValue = copy ? copy[name] : undefined;
		if (newValue !== prevValue || typeof newValue == 'object' || Array.isArray(newValue)) {
			this.$emit('value-changed', copy);
		}
	}

	private addNewKey() {
		if (this.newKey) {
			const copy = this.value ? { ...this.value } : {};
			copy[this.newKey] = null;
			this.$emit('value-changed', copy);
			this.newKey = '';
		}
	}

	private get hasDescription() {
		if (this.typeDefinition && this.typeDefinition.description) {
			return this.typeDefinition.description.length > 0;
		}
		return false;
	}

	private _isFieldEditable(field: string) {
		const editable = this.isFieldEditable(field);
		console.log(JSON.stringify(this.editablePaths), JSON.stringify(this.forbiddenPaths), field, editable);
		return editable;
	}

	private isFieldEditable(field: string) {
		const editablePaths = this.editablePaths || [];
		const forbiddenPaths = this.forbiddenPaths || [];
		// Explicit rules
		if (forbiddenPaths.some((it) => it === field)) {
			return false;
		}
		if (editablePaths.some((it) => it === field)) {
			return true;
		}

		// Nested rules
		if (editablePaths.some((it) => it === '')) {
			return true;
		}
		const prefix = field + '.';
		if (editablePaths.some((it) => it.startsWith(prefix))) {
			return true;
		}
		return false;
	}

	private getNestedPaths(currentPaths: string[] | undefined, field: string) {
		const paths = [];
		const editablePaths = currentPaths || [];
		if (editablePaths.some((it) => it === '' || it === field)) {
			paths.push('');
		}
		const prefix = field + '.';
		editablePaths.forEach((path) => {
			if (path.startsWith(prefix)) {
				paths.push(path.substr(prefix.length));
			}
		});
		console.log(JSON.stringify(editablePaths), field, paths);
		return paths;
	}

	private getNestedEditablePaths(field: string) {
		return this.getNestedPaths(this.editablePaths, field);
	}

	private getNestedForbiddenPaths(field: string) {
		return this.getNestedPaths(this.forbiddenPaths, field);
	}
}

import Vue from 'vue';
import template from './download-resource-dialog.html';
import './download-resource-dialog.scss';
import Component from 'vue-class-component';
import {IDialogComponent} from '@ponte/dialog-vue';
import {IDialog} from '@ponte/dialog';
import DialogTemplate from '../dialog/dialogs/dialog-template/dialog-template';
import {Prop, Watch} from 'vue-property-decorator';
import Tabcontrol from '../../common/tabcontrol/tabcontrol';
import Tab from '../../common/tabcontrol/tab';
import {translate} from '../../../main';
import UserService, {SelectedPlatform, TutorialStatus} from '../../../services/user-service';
import {userModule} from '../../../store/modules/user';
import {teamModule} from '../../../store/modules/team';
import {ProjectMeta} from 'respresso';
import {copyTextToClipboard} from '../../../util/clipboard';
import {NotificationBuilder} from '../dialog/dialog';

@Component({
	components: {
		'dialog-template': DialogTemplate,
		tabcontrol: Tabcontrol,
		tab: Tab,
	},
	template: template,
})
export default class DownloadResourceDialog extends Vue implements IDialogComponent<string | null> {
	translate = translate;
	@Prop() opened!: string;
	@Prop() teamId!: string;
	@Prop() projectId!: string;
	@Prop() resourceId!: string;
	@Prop() version!: string;
	@Prop() tutorialStatus!: TutorialStatus;
	@Prop() downloadUrl!: string;

	private activeAccordion = '';

	@Watch('$route', { immediate: true, deep: true })
	private onUrlChange(): void {
		this.closeDialog();
	}

	dialog!: IDialog<string | null>;

	public mounted(): void {
		if (this.tutorialStatus) {
			this.activeAccordion = this.tutorialStatus.toLowerCase();
		} else {
			this.activeAccordion = 'integration';
		}
		if (!this.selectedPlatform) {
			this.setSelectedPlatform('iOS');
		}
		window.addEventListener('keyup', (event): void => {
			if (event.keyCode === 27) {
				this.closeDialog();
			}
		});
		this.$bus.$on('tabSelected', (activeTab: SelectedPlatform): void => {
			this.setSelectedPlatform(activeTab);
		});
	}

	get project(): ProjectMeta | null {
		return teamModule.project;
	}

	get selectedPlatform(): SelectedPlatform {
		return userModule.selectedPlatform;
	}

	public setAccordion(accordionName: string): void {
		this.activeAccordion = accordionName;
	}

	public setSelectedPlatform(selectedPlatform: SelectedPlatform): void {
		userModule.setSelectedPlatform(selectedPlatform);
	}

	public closeDialog(): void {
		if (!this.dialog) {
			return;
		}
		if (userModule.tutorialStatus === 'SYNC') {
			this.updateTutorialStatus('DONE');
		}
		if (this.dialog.dialogController) this.dialog.dialogController.close(null);
	}

	public goToSync(): void {
		if (userModule.tutorialStatus === 'INTEGRATION') {
			this.updateTutorialStatus('SYNC');
		}
		this.activeAccordion = 'sync';
	}

	private updateTutorialStatus(newStatus: TutorialStatus): void {
		userModule.setTutorialStatus(newStatus);
		UserService.updateTutorialStatus(newStatus);
	}

	private async copyCode(event: Event): Promise<void> {
		if (event && event.target && event.target instanceof HTMLElement && event.target.textContent) {
			const content = event.target.textContent;
			const success = await copyTextToClipboard(content);
			if (success) {
				NotificationBuilder.success('#project.clipboard.copied');
			} else {
				NotificationBuilder.error('#project.clipboard.copyFailed');
			}
		}
	}

	private async downloadCode(event: Event): Promise<void> {
		if (event && event.target && event.target instanceof HTMLElement && event.target.textContent) {
			const content = event.target.textContent;
			const element = document.createElement('a');
			element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content));
			element.setAttribute(
				'download',
				event.target.dataset && event.target.dataset.filename
					? event.target.dataset.filename
					: 'respresso_default.json',
			);
			element.style.display = 'none';
			document.body.appendChild(element);
			element.click();
			document.body.removeChild(element);
		}
	}
}

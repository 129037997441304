import template from './pager.html';
import './pager.scss';
import Vue from 'vue';
import Component from 'vue-class-component';
import {Paging} from '../../../api/respresso-api';

@Component({
	props: {
		totalSize: Number,
		maxPageCountToShow: Number,
		paging: Object,
	},
	template: template,
})
export default class Pager extends Vue {
	private totalSize = 0;
	private maxPageCountToShow: number | undefined;
	private paging: Paging = {
		size: 0,
		page: 0,
	};

	protected setPage(pageIndex: number): void {
		this.$emit('setPage', pageIndex);
	}

	protected getMaxPageCountToShow(): number {
		return this.maxPageCountToShow ? this.maxPageCountToShow : 2;
	}

	protected getClickablePages(): number[] {
		const result: number[] = [];
		const numberOfPages = this.countOfPages();

		if (numberOfPages <= this.getMaxPageCountToShow() * 2) {
			for (let i = 0; i < this.getMaxPageCountToShow() * 2; i++) {
				if (i < numberOfPages) {
					result.push(i);
				}
			}
		} else {
			let left = this.paging.page - this.getMaxPageCountToShow();

			while (left !== this.paging.page) {
				if (left >= 0) {
					result.push(left);
				}
				left++;
			}

			const right = this.getMaxPageCountToShow() * 2 - result.length;

			result.push(this.paging.page);

			for (let i = 1; i <= right; i++) {
				const val = this.paging.page + i;
				if (val < numberOfPages) {
					result.push(val);
				}
			}
		}

		return result;
	}

	protected countOfPages(): number {
		if (this.totalSize === 0) {
			return 0;
		}

		return Math.ceil(this.totalSize / this.paging.size);
	}
}

import Vue from 'vue';
import template from './project-pending-imports-banner.html';
import './project-pending-imports-banner.scss';
import Component from 'vue-class-component';
import {BulkImportTargets} from 'respresso';
import {Prop} from 'vue-property-decorator';

@Component({
	template: template,
	props: {
		teamId: String,
		projectId: String,
		pendingImports: Object,
	},
})
export default class ProjectPendingImportsBanner extends Vue {
	private teamId?: string;
	private projectId?: string;
	@Prop({ default: null })
	private pendingImports!: BulkImportTargets | null;

	private get pendingFileCount(): number {
		let count = 0;
		if (this.pendingImports && this.pendingImports.targets) {
			this.pendingImports.targets.forEach((target) => {
				count += target.files.length;
			});
		}
		return count;
	}

	private get pendingImportCategories(): string[] {
		const categories: string[] = [];
		if (this.pendingImports && this.pendingImports.targets) {
			this.pendingImports.targets.forEach((target) => {
				categories.push(target.category);
			});
		}
		return categories;
	}
}

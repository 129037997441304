import Component from 'vue-class-component';
import template from './structure-boolean-input.html';
import './structure-boolean-input.scss';
import Vue from 'vue';
import {Prop} from 'vue-property-decorator';
import {StructureTypeDefinition} from 'respresso';
import {SlimSelectInfo} from '../vue-slim-select/vue-slim-select';

@Component({
	template: template,
	props: {
		name: String,
		definition: Object,
		value: Boolean,
	},
})
export default class StructureBooleanInput extends Vue {
	@Prop()
	name!: string;
	@Prop()
	definition!: StructureTypeDefinition;
	@Prop()
	value?: boolean;

	private get selectorOptions(): SlimSelectInfo[] {
		if (this.definition && this.definition.type === 'Boolean') {
			const options: SlimSelectInfo[] = [];
			const nullable =
				this.definition.optional && ['true', 'false'].indexOf(this.definition.defaultBehaviour) < 0;
			if (nullable) {
				options.push({
					text: this.$t('input.structure.boolean.option.default', this.definition) as string,
					selected: this.value === undefined,
					value: 'default',
				});
			}
			options.push({
				text: this.$t('input.structure.boolean.option.true') as string,
				selected: this.value === true || (!nullable && this.definition.defaultBehaviour !== 'false'),
				value: 'true',
			});
			options.push({
				text: this.$t('input.structure.boolean.option.false') as string,
				selected: this.value === false || (!nullable && this.definition.defaultBehaviour === 'false'),
				value: 'false',
			});
			return options;
		} else {
			return [];
		}
	}

	public async selectionChanged(selected: SlimSelectInfo): Promise<void> {
		let newValue: boolean | undefined = this.value;
		switch (selected.value) {
			case 'default':
				newValue = undefined;
				break;
			case 'true':
				newValue = true;
				break;
			case 'false':
				newValue = false;
				break;
		}
		if (this.value !== newValue) {
			this.$emit('value-changed', newValue);
		}
	}
}

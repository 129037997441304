import Vue from 'vue';
import Component from 'vue-class-component';
import template from './manage-teams.html';
import './manage-teams.scss';
import UserService from '../../../../services/user-service';
import {JoinableTeams, TeamInfo} from 'respresso';
import ErrorHandler from '../../../../services/error-handler';
import LoadingScreen from '../../../../decorators/loading-screen';
import RespressoApi from '../../../../api/respresso-api';
import StorageService from "../../../../services/storage-service";

@Component({
	template: template,
})
export default class ManageTeams extends Vue {
	private joinableTeams: JoinableTeams | undefined;
	private teams: TeamInfo[] = [];
	private teamsInvited: TeamInfo[] | undefined = [];

	@LoadingScreen({ fullScreen: true })
	async created(): Promise<void> {
		const joinableTeams = await ErrorHandler.tryRequest(() => UserService.getJoinableTeams());
		if (
			joinableTeams &&
			((joinableTeams.inviteTeams && joinableTeams.inviteTeams.length > 0) ||
				(joinableTeams.teams && joinableTeams.teams.length > 0))
		) {
			this.joinableTeams = joinableTeams;
			this.teams = this.joinableTeams.teams;
			this.teamsInvited = this.joinableTeams.inviteTeams;
		} else {
			const teams = await ErrorHandler.tryRequest(() => UserService.loadTeams());
			if (teams && teams.length > 0) {
				this.create();
			} else {
				StorageService.setIsSignedUpInThisBrowser();
				const result = await ErrorHandler.tryRequest(() => RespressoApi.quickStartSetup());
				if (result) {
					this.$router.push({
						name: 'project',
						params: {
							teamId: result.teamId,
							projectId: result.projectId,
						},
					});
				} else {
					this.create();
				}
			}
		}
	}

	@LoadingScreen({ fullScreen: true })
	private async join(team: TeamInfo): Promise<void> {
		const response = await ErrorHandler.tryRequest(() => UserService.joinTeam(team));
		if (response) {
			await UserService.refreshData();
			this.$router.push({ name: 'dashboard', params: { teamId: team.id } });
		}
	}

	@LoadingScreen({ fullScreen: true })
	async joinByInvitation(team: TeamInfo): Promise<void> {
		const response = await ErrorHandler.tryRequest(() => UserService.joinByInvitationLater(team));
		if (response) {
			await UserService.refreshData();
			this.$router.push({ name: 'dashboard', params: { teamId: team.id } });
		}
	}

	private async create(): Promise<void> {
		this.$router.push({ name: 'createTeam' });
	}
}

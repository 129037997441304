import Vue from 'vue';
import Component from 'vue-class-component';
import template from './vue-single-upload.html';
import './vue-single-upload.scss';
import {IFileDescriptor, IUpload, IUploadState, ModernUpload, UploadState} from '@ponte/file-upload-js';
import {ERROR_EVENT, FILE_SELECTED_EVENT, VueFileSelector} from './vue-file-selector';

const defaultUploadState: IUploadState = {
	state: UploadState.preparing,
	progress: 0,
	customStatusMessage: null,
	totalSize: 0,
	uploadedSize: 0,
};

@Component({
	props: {
		showDetails: Boolean,
		readonly: Boolean,
		compact: Boolean,
		baseUrl: String,
		accept: Array,
	},
	template: template,
})
export class VueSingleUpload extends Vue {
	private showDetails: boolean | undefined;
	private readonly: boolean | undefined;
	private compact: boolean | undefined;
	private baseUrl: string | undefined;
	private accept: string[] | undefined;

	private screen: 'details' | 'progress' | 'error' = 'progress';
	private showScreens = false;
	private upload: IUpload | undefined;
	private uploadState: IUploadState | null = null;
	private fileName = '';
	private fileSize = 0;

	mounted(): void {
		this.reset();
	}

	destroyed(): void {
		this.cancelUpload();
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	async uploadFile(file: File, userInfo: any): Promise<any> {
		this.fileName = file.name;
		this.fileSize = file.size;
		this.upload = new ModernUpload({
			file,
			userInfo,
			uploadIdURL: this.baseUrl + '/getid',
			uploadURL: this.baseUrl + '/upload',
			progressQueryURL: this.baseUrl + '/getstatus',
			headers: {
				'X-Requested-With': 'Respresso',
			},
		});
		this.uploadState = this.upload.uploadState;
		this.upload.addUploadStateChangeListener(() => {
			if (this.upload !== undefined) {
				this.uploadState = this.upload.uploadState;
			}
		});
		this.screen = 'progress';
		this.showScreens = true;
		return this.upload
			.startUpload()
			.then((result) => {
				this.screen = 'details';
				if (result.name != null) {
					this.fileName = result.name;
				}
				return result;
			})
			.catch((err) => {
				this.screen = 'error';
				throw err;
			});
	}

	public reset(): void {
		this.showScreens = false;
		this.uploadState = null;
	}

	cancelUpload(): Error | undefined {
		if (this.upload) {
			return this.upload.abortUpload();
		}
		return undefined;
	}

	showDetailsScreen(): void {
		this.screen = 'details';
		this.showScreens = true;
		this.uploadState = null;
	}

	get uploadPercent(): string {
		if (!this.uploadState || !this.uploadState.progress) {
			return '0%';
		}
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		return Math.round(this.uploadState!.progress * 10000) / 100 + '%';
	}

	fileSelected(fileDescriptor: IFileDescriptor): void {
		this.$emit(FILE_SELECTED_EVENT, fileDescriptor);
	}

	fileSelectorError(errorMessageKey: string): void {
		this.$emit(ERROR_EVENT, errorMessageKey);
	}

	public configure(baseUrl: string, showDetails: boolean, readonly: boolean, compact: boolean): void {
		this.baseUrl = baseUrl;
		this.showDetails = showDetails;
		this.readonly = readonly;
		this.compact = compact;
	}

	private get dataUploadState(): string {
		return this.uploadState !== null ? this.uploadState.state : 'default';
	}

	public triggerFileSelect() {
		const selector: VueFileSelector = this.$refs.fileSelector as VueFileSelector;
		if (selector) {
			selector.triggerFileSelect();
		}
	}
}

let currentCheckoutHandler: ((event: PaddleCheckoutEvent) => void) | undefined = undefined;

function internalPaddleCheckoutHandler(event: PaddleCheckoutEvent) {
	if (currentCheckoutHandler) {
		currentCheckoutHandler(event);
	}
}

export function initPaddle(vendor: string, cb: (event: PaddleCheckoutEvent) => void): void {
	console.log('initPaddle', vendor);

	if (!vendor) {
		return;
	}

	// const paddleScript = document.getElementById("paddle_script");
	//
	// if (!paddleScript) {
	// 	const sc = document.createElement("script");
	// 	sc.setAttribute("id", "paddle_script");
	// 	sc.setAttribute("src", "https://cdn.paddle.com/paddle/paddle.js");
	// 	sc.setAttribute("type", "text/javascript");
	// 	document.head.appendChild(sc);
	// } else {
	// 	console.log("IPaddle script already exists");
	// }

	currentCheckoutHandler = cb;
	// @ts-ignore
	if (Paddle) {
		// @ts-ignore
		Paddle.Setup({ vendor: Number.parseInt(vendor, 10), eventCallback: internalPaddleCheckoutHandler });
	} else {
		console.log('Paddle not loaded.');
	}
}

export function releasePaddleCallback() {
	currentCheckoutHandler = undefined;
}

export interface PaddleCheckoutEvent {
	event?: string;
	eventData?: any;
}

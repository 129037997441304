/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import Component from 'vue-class-component';
import template from './flow-editor.html';
import './flow-editor.scss';
import {default as VueSlimSelect, SlimSelectInfo, SlimSelectOption,} from '../../components/common/vue-slim-select/vue-slim-select';
import CodeMirror from '../codemirror/code-mirror-wrapper';
import ErrorHandler from '../../services/error-handler';
import RespressoApi from '../../api/respresso-api';
import {NotificationBuilder} from '../../components/common/dialog/dialog';

export interface FlowEditorConfig {
	resourceId: string;
	teamId: string;
	projectId: string;
	version: string;
}

@Component({
	template: template,
})
export class FlowEditorViewer extends Vue {
	protected config: FlowEditorConfig = {} as any;
	protected editor: CodeMirror.EditorFromTextArea | null = null;
	private flowId = '';
	private selectedFlow: SlimSelectInfo | null = null;
	private modified = 0;

	public async init(config: FlowEditorConfig): Promise<void> {
		this.modified = 0;
		this.config = config;

		const options = this.flowOptions();
		const option = options[0];

		if (option) {
			this.selectedFlow = option;
		}

		this.initEditor();

		if (option) {
			await this.loadFlow(option.text);
		}
	}

	private mounted(): void {
		if (this.$refs.flowSelectorElement) {
			(this.$refs.flowSelectorElement as VueSlimSelect).setData(this.flowOptions());
		}
	}

	public isModified(): boolean {
		return this.modified > 1;
	}

	public destroy(): void {
		(this.editor as any).toTextArea(); //destroy
	}

	public async save(rerun: boolean): Promise<boolean> {
		if (!this.editor) {
			return Promise.resolve(false);
		}

		const flow = this.editor.getValue();

		const saveResp = await ErrorHandler.tryRequest(
			() =>
				RespressoApi.saveFlow(
					this.config.teamId,
					this.config.projectId,
					this.config.version,
					this.config.resourceId,
					this.flowId,
					flow,
					rerun,
				),
			{
				loadingScreen: true,
				loadingMessage: '#loading.processingFlow',
			},
		);
		const successSave = !!saveResp;

		if (successSave) {
			this.modified = 0;
			NotificationBuilder.success('#messages.saved');
		}

		return successSave;
	}

	public redraw(): void {
		if (this.editor) {
			this.editor.refresh();
		}
	}

	public overwriteFlow(flow: SlimSelectInfo, content: string): void {
		if (this.editor) {
			this.modified = 0;
			this.editor.setValue(content);
		}
		this.redraw();
		NotificationBuilder.success('#flow.reset.success');
	}

	protected async initEditor(): Promise<void> {
		if (!this.$refs.editorElement) {
			return;
		}

		if (this.editor) {
			(this.editor as any).toTextArea(); //destroy
		}

		this.editor = CodeMirror.fromTextArea(
			this.$refs.editorElement as HTMLTextAreaElement,
			{
				lineNumbers: true,
				autoCloseBrackets: false,
				autoCloseTags: true,
				mode: {
					name: 'xml',
					options: {
						matchClosing: true,
						alignCDATA: true,
					},
				},
			} as any,
		);

		const height = (document.body.clientHeight - 200) / 2;
		this.editor.setSize(null, height);

		this.editor.on('change', this.debounce(this.showFlow, 200));
	}

	private debounce(func: () => void, wait: number): () => void {
		let timer: any;
		return (): void => {
			clearTimeout(timer);
			timer = setTimeout(func, wait);
		};
	}

	private flowOptions(): SlimSelectOption[] {
		const files = [
			'appIcon-make-flow',
			'appIcon-convert-single-resource-flow',
			'color-make-flow',
			'color-convert-all-resources-flow',
			'font-make-flow',
			'font-convert-single-resource-flow',
			'font-convert-all-resources-flow',
			'image-make-flow',
			'image-convert-single-resource-flow',
			'image-convert-all-resources-flow',
			'image-post-generate-flow',
			'localization-make-flow',
			'localization-convert-all-resources-flow',
			'raw-make-flow',
			'raw-convert-single-resource-flow',
		];
		if (this.config.resourceId === '') return [];

		const result: SlimSelectOption[] = files
			.filter((f) => f.startsWith(this.config.resourceId))
			.map((f) => ({ text: f, value: f, selected: this.selectedFlow?.text === f }));
		return result;
	}

	private async showFlow(): Promise<void> {
		if (!this.editor) {
			return;
		}

		this.modified += 1;
		// TODO ErrorHandler.tryRequest ?
		const result = await RespressoApi.getFlowSVG(
			this.config.teamId,
			this.config.projectId,
			this.config.version,
			this.config.resourceId,
			this.flowId,
			this.editor.getValue(),
		);
		(this.$refs.previewContainerElement as HTMLElement).innerHTML = result.svg;
		(this.$refs.previewErrorElement as HTMLElement).innerText = result.error;
	}

	public async flowSelected(selected: SlimSelectInfo): Promise<void> {
		if (!this.editor) {
			return;
		}

		this.selectedFlow = selected;

		if (selected.value) {
			this.loadFlow(selected.value);
		}
	}

	private async loadFlow(flowId: string): Promise<void> {
		if (!this.editor) {
			return;
		}

		this.flowId = flowId;
		const result = await ErrorHandler.tryRequest(
			() =>
				RespressoApi.getFlow(
					this.config.teamId,
					this.config.projectId,
					this.config.version,
					this.config.resourceId,
					this.flowId,
				),
			{
				loadingScreen: true,
				loadingMessage: '#loading.loading',
			},
		);

		this.modified = 0;
		this.editor.setValue(result && result.flow ? result.flow : '');
		this.editor.getDoc().clearHistory();
	}

	getSelectedFlow(): SlimSelectInfo | null {
		return this.selectedFlow;
	}
}

import Component from 'vue-class-component';
import template from './structure-integer-input.html';
import './structure-integer-input.scss';
import Vue from 'vue';
import {Prop} from 'vue-property-decorator';
import {StructureTypeDefinition} from 'respresso';
// @ts-ignore
import VueNumericInput from 'vue-numeric-input';

@Component({
	template: template,
	components: {
		'vue-numeric-input': VueNumericInput,
	},
	props: {
		name: String,
		definition: Object,
		value: Number,
	},
})
export default class StructureBooleanInput extends Vue {
	@Prop()
	name!: string;
	@Prop()
	definition!: StructureTypeDefinition;
	@Prop()
	value?: number;

	defValue?: number;

	created() {
		try {
			this.defValue = Number.parseInt(this.definition.defaultBehaviour.trim(), 10);
		} catch (e) {
			// Ignore
			console.log(e);
		}
	}

	public selectionChanged(selected?: number) {
		if (this.value !== selected) {
			this.$emit('value-changed', selected);
		}
	}
}

import {Component, Vue} from 'vue-property-decorator';
import template from './tabcontrol.html';
import './tabcontrol.scss';

interface TabData {
	name: string | undefined;
	isActive: boolean;
	tabClass: string | undefined;
}

type TabElement = Vue & TabData;

@Component({
	template: template,
})
export default class Tabcontrol extends Vue {
	tabs: TabElement[] = [];

	public mounted(): void {
		this.tabs = this.$children as TabElement[];
	}

	public selectTab(tab: TabElement): void {
		this.$bus.$emit('tabSelected', tab.name);
	}
}

import Vue from 'vue';
import Component from 'vue-class-component';
import template from './subscription-error-viewer.html';
import './subscription-error-viewer.scss';
import {Prop} from 'vue-property-decorator';
import DialogTemplate from '../../../src/components/common/dialog/dialogs/dialog-template/dialog-template';
import {IDialogComponent} from '@ponte/dialog-vue';
import {IDialog} from '@ponte/dialog';
import AccessService from '../../services/access-service';
import UserService from '../../services/user-service';
import {TeamRole, UserPublicInfo} from 'respresso';
import {RespressoError} from '../../services/http-service';

export interface SubscriptionErrorViewerConfig {
	title: string;
	description: string;
	teamId: string;
	projectId: string;
	upgradeQueryParams: { [name: string]: any };
	actual?: number;
	limit?: number;
	error?: RespressoError<any>;
}

@Component({
	components: {
		'dialog-template': DialogTemplate,
	},
	template: template,
})
export class SubscriptionErrorViewer extends Vue implements IDialogComponent<boolean> {
	@Prop() dialogConfig!: SubscriptionErrorViewerConfig;

	private error?: RespressoError<any> | undefined;
	private teamId?: string;
	private projectId?: string;
	private loaded = false;
	private canEditSubscriptions = false;
	private owners: UserPublicInfo[] = [];

	dialog!: IDialog<boolean>;

	public created(): void {
		this.init();
	}

	public init(): void {
		this.error = this.dialogConfig.error;
		this.teamId = this.dialogConfig.teamId;
		this.projectId = this.dialogConfig.projectId;
		this.loadData().catch((reason) => {
			this.closeDialog(false);
		});
	}

	private async loadData() {
		const team = await UserService.loadTeam(this.teamId!);
		if (team) {
			this.canEditSubscriptions = AccessService.canEditTeamSubscriptions(team);
			if (team.users) {
				this.owners = team.users.filter((user) => {
					const ownerRole: TeamRole = 'OWNER'; // Ensure we are typo safe
					return user.role === ownerRole;
				});
			}
			this.loaded = true;
		} else {
			this.closeDialog(false);
		}
	}

	private editSubscriptions() {
		const routeData = this.$router.resolve({
			name: 'changeProjectSubscription',
			params: { teamId: this.teamId!, projectId: this.projectId! },
			query: this.dialogConfig.upgradeQueryParams,
		});
		window.open(routeData.href, '_blank');
		this.closeDialog(true);
	}

	public closeDialog(result = true): void {
		if (this.dialog.dialogController) this.dialog.dialogController.close(result);
	}
}

import Vue from 'vue';
import Component from 'vue-class-component';
import template from './server-license.html';
import './server-license.scss';
import RespressoApi, {GetServerLicenseResponse} from '../../../api/respresso-api';
import {NextFunction, Route} from 'vue-router';
import LoadingScreen from '../../../decorators/loading-screen';
import ErrorHandler from '../../../services/error-handler';
import AccessService from '../../../services/access-service';

@Component({
	template: template,
})
export default class ServerLicense extends Vue {
	private serverLicense: GetServerLicenseResponse | null = null;
	private errors: {
		[field: string]: string;
	} = {};

	private beforeRouteEnter(to: Route, from: Route, next: NextFunction): void {
		next((vm) => {
			const serverLicense = vm as ServerLicense;
			serverLicense.loadData();
		});
	}

	get formattedExpiration(): string {
		if (this.serverLicense && this.serverLicense.expires) {
			return new Date(this.serverLicense.expires).toLocaleString();
		} else {
			return this.$t('license.server.expiration.never') as string;
		}
	}

	get formattedMaxTeams(): string {
		if (this.serverLicense && this.serverLicense.maxTeams != null) {
			return '' + this.serverLicense.maxTeams;
		} else {
			return this.$t('license.server.maxTeams.unlimited') as string;
		}
	}

	get formattedMaxProjectsPerTeam(): string {
		if (this.serverLicense && this.serverLicense.maxProjectsPerTeam != null) {
			return '' + this.serverLicense.maxProjectsPerTeam;
		} else {
			return this.$t('license.server.maxProjectsPerTeam.unlimited') as string;
		}
	}

	private async loadData(): Promise<void> {
		this.serverLicense = await ErrorHandler.tryRequest(() => RespressoApi.getServerLicense());
	}

	@LoadingScreen()
	private async reloadLicense(): Promise<void> {
		this.serverLicense = await ErrorHandler.tryRequest(() => RespressoApi.reloadServerLicense(), {
			loadingScreen: true,
			loadingMessage: '#loading.reloadServerLicense',
		});
		await AccessService.loadServerLicense();
	}
}

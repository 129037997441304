import Vue from 'vue';
import template from './resource-import-dialog.html';
import './resource-import-dialog.scss';
import Component from 'vue-class-component';
import {IFileDescriptor} from '@ponte/file-upload-js';
import {IDialogComponent} from '@ponte/dialog-vue';
import {IDialog} from '@ponte/dialog';
import {Prop} from 'vue-property-decorator';
import DialogTemplate from '../dialog/dialogs/dialog-template/dialog-template';
import {StructureDefinitions} from 'respresso';
import {VueSingleUpload} from '../../../plugin/vue-upload/vue-single-upload';
import LoadingService from '../../../services/loading-service';

export interface ResourceImportDialogConfig {
	title: string;
	resourceImporterTypes: string[];
	accept: string[];
	uploadHandler: string;
	resource: string;
	fileDescriptor?: IFileDescriptor;
	executeImport: (file: ResourceUploadResult, importer: string | null, config?: any) => Promise<boolean>;
	editablePaths?: string[];
	forbiddenPaths?: string[];
}

export interface ResourceUploadResult {
	fileId: string;
	mimeType: string;
	name: string;
	total: number;
	selectedImporter?: string;
	selectedImporterConfig?: StructureDefinitions;
}

@Component({
	components: {
		'dialog-template': DialogTemplate,
	},
	template: template,
})
export default class ResourceImportDialog extends Vue implements IDialogComponent<boolean> {
	@Prop() dialogConfig!: ResourceImportDialogConfig;
	private uploadHandler = '';

	protected resourceImporterTypes: string[] = [];
	private uploadResult: ResourceUploadResult | null = null;
	private resource = '';
	private accept: string[] = ['*'];
	private fileName = '';
	private selectedImporter = '';
	private manualImporterSelect = false;
	private config?: any;
	private configStructure: StructureDefinitions | null = null;

	dialog!: IDialog<boolean>;

	public created(): void {
		this.resourceImporterTypes = this.dialogConfig.resourceImporterTypes;
		this.uploadHandler = this.dialogConfig.uploadHandler;
		this.resource = this.dialogConfig.resource;
		this.accept = this.dialogConfig.accept;
	}

	private mounted(): void {
		if (this.dialogConfig.fileDescriptor) {
			this.fileSelected(this.dialogConfig.fileDescriptor);
		} else {
			(this.$refs.singleUpload as VueSingleUpload).triggerFileSelect();
		}
	}

	public closeDialog(result = false): void {
		if (this.dialog.dialogController) {
			this.dialog.dialogController.close(result);
		}
	}

	public saveAndClose(): void {
		if (this.uploadResult) {
			LoadingService.waitForPromise(
				this.dialogConfig
					.executeImport(this.uploadResult, this.selectedImporter, this.config)
					.then((result) => {
						if(result) {
							this.closeDialog(true);
						}
					})
					.catch(() => this.closeDialog(false)),
			);
		} else {
			this.closeDialog(false);
		}
	}

	public async fileSelected(fileDescriptor: IFileDescriptor): Promise<void> {
		this.fileName = fileDescriptor.file.name;
		this.uploadResult = await (this.$refs.singleUpload as VueSingleUpload).uploadFile(fileDescriptor.file, {
			handler: this.uploadHandler,
		});

		if (this.uploadResult) {
			if (this.uploadResult.selectedImporter && this.selectedImporter === '') {
				this.selectedImporter = this.uploadResult.selectedImporter;
			}
			if (this.uploadResult.selectedImporterConfig) {
				this.configStructure = this.uploadResult.selectedImporterConfig;
				console.log('Set config structure', this.configStructure);
			}
		}
		if (!this.selectedImporter) {
			this.manualImporterSelect = true;
		}
	}

	private onConfigChanged(value: any) {
		this.config = value;
		this.$forceUpdate();
	}
}

import {RespressoError} from './http-service';
import {RespressoErrorHandlerAdapter} from './error-handler';
import {DialogBuilder} from '../components/common/dialog/dialog';
import {FlowErrorViewer, FlowErrorViewerConfig} from '../plugin/flow-error-viewer/flow-error-viewer';
import {translate} from '../main';

export interface FlowErrorData {
	subError: RespressoError<any>;
	logs: string;
	resourceId: string;
	resourceData: ResourceConversionErrorData;
}

export interface ResourceConversionErrorData {
	id: string;
	data?: any;
	files?: ResourceConversionErrorFileData[];
}

export interface ResourceConversionErrorFileData {
	id: string;
	mimeType?: string;
	size: any;
	data?: any;
}

export class FlowErrorHandlerAdapter implements RespressoErrorHandlerAdapter {
	async handle(error: RespressoError<any>): Promise<boolean> {
		if (error.type === 'FlowExecutionException' && error.key === 'flowExecutionError') {
			const dialogConfig: FlowErrorViewerConfig = {
				error: error.data as FlowErrorData,
				title: translate('serverError.respressoError.' + error.key),
			};

			DialogBuilder.createVueDialog(FlowErrorViewer, {
				propsData: {
					dialogConfig,
				},
			});

			return true;
		}

		return false;
	}

	order(): number {
		return 0;
	}

	name(): string {
		return 'FlowErrorHandlerAdapter';
	}
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {NotificationBuilder} from '../components/common/dialog/dialog';
import {InternalServerError, NetworkError, NotFoundError, RespressoError} from './http-service';
import {app} from '../main';
import LoadingService from './loading-service';
import {Logger} from '../util/logger';
import {DefaultRespressoErrorHandlerAdapter} from './default-handler-adapter';
import {FlowErrorHandlerAdapter} from './flow-error-handler-adapter';
import {SubscriptionErrorHandlerAdapter} from './subscription-error-handler-adapter';

//Return value: Whether the error was handled. If false, defaultErrorHandler will be called
export type RespressoErrorHandler = (error: RespressoError<any>, errorMsg?: string) => Promise<boolean> | boolean;

export interface RequestOptions {
	retryOnNetworkError?: number;
	respressoErrorHandler?: RespressoErrorHandler;
	loadingScreen?: boolean;
	loadingMessage?: string;
	loadingFullScreen?: boolean;
}

export interface RespressoErrorHandlerAdapter {
	handle(error: RespressoError<any>, errorMsg?: string): Promise<boolean>;

	name(): string;

	order(): number;
}

export class ErrorHandlerImpl {
	readonly defaultRequestOptions: RequestOptions = {
		retryOnNetworkError: 0,
	};

	protected errorHandlerAdapters: RespressoErrorHandlerAdapter[] = [
		new FlowErrorHandlerAdapter(),
		new SubscriptionErrorHandlerAdapter(),
		new DefaultRespressoErrorHandlerAdapter(),
	].sort();

	public addRespressoErrorHandlerAdapter(adapter: RespressoErrorHandlerAdapter): void {
		if (this.errorHandlerAdapters.some((value) => value.name() === adapter.name())) {
			return;
		}

		this.errorHandlerAdapters.push(adapter);
		this.errorHandlerAdapters.sort();
	}

	public async tryRequest<T>(request: () => Promise<T>, requestOptions?: RequestOptions): Promise<T | null> {
		const options: RequestOptions = Object.assign({}, this.defaultRequestOptions, requestOptions || {});
		return this.tryRequestInternal(request, options, 0);
	}

	public async handleError(error: Error, errorMsg?: string): Promise<void> {
		return this.handleErrorInternal(error, errorMsg);
	}

	private async tryRequestInternal<T>(
		request: () => Promise<T>,
		options: RequestOptions,
		attempt: number,
	): Promise<T | null> {
		try {
			const promise = request();
			if (options.loadingScreen) {
				LoadingService.waitForPromise(promise, {
					message: options.loadingMessage,
					fullScreen: options.loadingFullScreen,
				});
			}
			return await promise;
		} catch (error) {
			if (error instanceof NetworkError && attempt < options.retryOnNetworkError!) {
				return this.tryRequestInternal(request, options, attempt + 1);
			}
			await this.handleErrorInternal(error, undefined, options.respressoErrorHandler!);
			return null;
		}
	}

	private async handleErrorInternal(
		error: Error,
		errorMsg: string | undefined,
		respressoErrorHandler?: RespressoErrorHandler,
	): Promise<void> {
		if (error instanceof NetworkError) {
			NotificationBuilder.error(errorMsg ? errorMsg : '#serverError.networkError');
			Logger.warn('Network Error', error);
		} else if (error instanceof NotFoundError) {
			NotificationBuilder.error(errorMsg ? errorMsg : '#serverError.notFoundError');
			Logger.warn('Not Found Error', error);
		} else if (error instanceof InternalServerError) {
			NotificationBuilder.error(errorMsg ? errorMsg : '#serverError.internalServerError');
			Logger.warn('Internal Server Error', error);
		} else if (
			error instanceof RespressoError &&
			error.type === 'TeamServiceException' &&
			error.key === 'team.byName.not.found'
		) {
			app.$router.push({ name: 'teams' });
		} else if (error instanceof RespressoError) {
			Logger.warn('Respresso Error', error);
			let errorHandlerResult = false;
			if (respressoErrorHandler) {
				errorHandlerResult = await respressoErrorHandler(error, errorMsg);
			}
			if (!errorHandlerResult) {
				await this.callErrorHandlerAdapters(error, errorMsg);
			}
		} else {
			NotificationBuilder.error(errorMsg ? errorMsg : '#serverError.unknownError');
			Logger.warn('Unknown Error', error);
		}
	}

	private async callErrorHandlerAdapters(error: RespressoError<any>, errorMsg?: string): Promise<boolean> {
		for (let i = 0; i < this.errorHandlerAdapters.length; i++) {
			const respressoErrorHandlerAdapter = this.errorHandlerAdapters[i];

			const handled = await respressoErrorHandlerAdapter.handle(error, errorMsg);

			if (handled) {
				return true;
			}
		}

		return false;
	}
}

const ErrorHandler = new ErrorHandlerImpl();
export default ErrorHandler;

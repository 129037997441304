import {
    AutoMergeResult,
    BulkImportTargets,
    ConversionToolResponse,
    CreateProjectInviteRequest,
    CreateProjectRequest,
    CreateProjectResponse,
    GetConfigResponse,
    InvitationVerifiedRegistrationResponse,
    JoinableTeams,
    LocalizationResourceInfoResponse,
    PaddleProjectSubscriptionPayment,
    PendingUserMeta,
    PollProjectSubscriptionCheckoutStateResponse,
    PollProjectSubscriptionCheckoutSuccessResponse,
    ProjectRole,
    ProjectSubscriptionOptionsResponse,
    ProjectSubscriptionState,
    ResourceCategoryChangeStructure,
    ResourceType,
    ResourceVersions,
    StartProjectSubscriptionCheckoutResponse,
    TeamInfo,
    TeamInfoList,
    TeamMeta,
    TeamRole,
    TeamSubscriptionsResponse,
    User,
    UserPublicInfo,
    UserQuestionAnswer,
    UserQuestions,
    UserSaveData,
    VersionMeta,
} from 'respresso';
import {FetchHttpService, HttpService} from '../services/http-service';
import {
    GetLocalizationData,
    LocalizationInfo,
    LocalizationResourceItem,
    LocalizationSaveResponse,
} from '../components/view/resource/localization-resource';
import {ResourceItem} from '../components/view/resource/resource';
import {ImageDataToSave, ImageResourceItem, LoadImageResourceResponse,} from '../components/view/resource/image-resource';
import {FontResourceItem, FontResourceToSave, GetFontDataResponse} from '../components/view/resource/font-resource';
import {ColorDataToSave, ColorKeyData, GetColorDataResponse} from '../components/view/resource/color-resource';
import {TeamModificationMeta} from '../components/view/team-settings/team-settings';
import {CreateTeamData, CreateTeamResponse} from '../components/view/create-team/create-team';
import {LastupdatedProjectResult, LoadProjectResourcesMeta} from '../components/view/project/project';
import {MetaModProjectRequest, ProjectBasicData} from '../components/view/project-settings/project-settings';
import {LastupdatedTeamResult, LastUserActivityInTeamResult} from '../components/view/dashboard/dashboard';
import {
    AppIconDataForSave,
    GetAppIconDataResponse,
    PreviewAppIconDataResponse,
    SaveAppIconDataResponse,
} from '../components/view/resource/app-icon-resource';
import {JoinProjectResponse, ProjectsList} from '../components/view/connect-project/join-project';
import {PassModResponse} from '../components/view/new-password/new-password';
import {RawData, RawDataResponse, SaveRequest} from '../components/view/resource/raw-resource';
import {News} from '../components/whats-new/whats-new';
import LoadingScreen from '../decorators/loading-screen';
import {FlowData} from '../components/view/flow/flow';
import {LiveUpdateConnectionInfo} from '../components/view/live-edit/live-localization';
import {TutorialStatus} from 'src/services/user-service';

interface AuthRequest {
    email: string;
    password: string;
}

interface RegistrationRequest {
    email: string;
    name: string;
    password: string;
    newsletter: boolean;
    recaptcha: string;
    workRole: UserWorkRole;
    invitation?: string;
}

export const enum UserWorkRole {
    DEVELOPER = 'developer',
    DESIGNER = 'designer',
    MANAGER = 'manager',
    TRANSLATOR = 'translator',
    COPYWRITER = 'copywriter',
    MARKETING = 'marketing',
    QA = 'qa',
    OTHER = 'other',
}


interface RegistrationResponse {
    authUserId: string;
}

interface SendPasswordResetRequest {
    email: string;
}

interface ActivateUserRequest {
    authUserId: string;
    inputToken: string;
}

interface PasswordResetRequest {
    passToken: string;
    newPassword: string;
}

interface JoinTeamRequest {
    team: string;
}

interface JoinByInvitationRequest {
    inviteToken: string;
    user: string;
}

interface JoinByInvitationResponse {
    teamId: string;
}

interface QuickStartResponse {
    teamId: string;
    projectId: string;
}

interface CreatePatchVersionRequest {
    versionNumber: string;
}

interface CreatePatchVersionResponse {
    patchVersion: string;
}

interface CreateVersionRequest extends CreatePatchVersionRequest {
    copyVersionNumber?: string;
}

interface ChangeVersionMetaRequest {
    name: string;
}

export interface AuthResponse {
    user: User;
}

export interface Paging {
    page: number;
    size: number;
}

export interface PermissionResponse {
    hasPermission: boolean;
}

export interface CreateTeamInviteRequest {
    email: string;
    name?: string;
    role?: TeamRole;
}

export interface SetTeamRoleRequest {
    role: TeamRole;
}

export interface SetProjectRoleRequest {
    role: ProjectRole;
}

export interface AcceptProjectPendingMemberRequest {
    role?: ProjectRole;
}

export interface CheckFeatureResponse {
    enabled: boolean;
}

export interface GetFlowResponse {
    flow: string;
}

export interface GetFlowSvgResponse {
    svg: string;
    error: string;
}

export interface GetRecaptchaSettingsResponse {
    recaptchaId?: string;
    recaptchaRequired: boolean;
}

export interface GetServerLicenseResponse {
    expires?: number; // UTC milliseconds
    maxTeams?: number; // positive whole number
    maxProjectsPerTeam?: number; // positive whole number
}

export interface IntegrationTokenInfo {
    teamId: string;
    teamName: string;
    teamTitle: string;
    projectId: string;
    projectName: string;
    projectTitle: string;
    categories: IntegrationCategoryInfo[];
}

export interface IntegrationCategoryConfigInfo {
    config: any;
}

export interface LocalizationConfigStoredStructure {
    defaultLanguage?: string;
    languages?: string[];
    variables?: LocalizationVariableConfigStructure;
}

export interface LocalizationVariableConfigStructure {
    overrides: { [format: string]: string };
}

export interface IntegrationCategoryInfo {
    categoryId: string;
    versions: IntegrationCategoryVersionInfo[];
}

export interface IntegrationCategoryVersionInfo {
    version: string;
    editable: boolean;
}

export interface IntegrationImportPreviewResponse {
    categoryImportPreviews: IntegrationCategoryImportPreview[];
}

export interface IntegrationCategoryImportPreview {
    categoryId: string;
    version: string;
    importPreview: ImportPreview;
}

export interface ImportPreview {
    changeResult: ImportChangeResultPreview;
    change?: any; // (The whole patch is here but it is not relevant)
}

export interface ImportChangeResultPreview {
    changes: { [key: string]: AppliedResourceChangeStructure };
    configChanges: DataChangeStructure[];
    configChanged: boolean;
}

export interface AppliedResourceChangeStructure {
    id: string;
    type: AppliedResourceChangeType;
    dataChanges: DataChangeStructure[];
    fileChanges: AppliedResourceFileChangeStructure[];
}

export const enum AppliedResourceChangeType {
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    UPDATE_ONLY_DATA = 'UPDATE_ONLY_DATA',
    UPDATE_ONLY_FILES = 'UPDATE_ONLY_FILES',
    DELETE = 'DELETE',
    NONE = 'NONE',
}

export interface DataChangeStructure {
    type: DataChangeType;
    path: string;
    value?: any;
}

export const enum DataChangeType {
    OVERRIDE = 'OVERRIDE',
    MERGE = 'MERGE',
}

export interface AppliedResourceFileChangeStructure {
    fileId: string;
    type: AppliedResourceFileChangeType;
    dataChanges: DataChangeStructure[];
}

export const enum AppliedResourceFileChangeType {
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE',
    NONE = 'NONE',
}

export interface IntegrationInfoResponse {
    teams: IntegrationTeamInfo[];
}

export interface IntegrationTeamInfo {
    id: string;
    name: string;
    title: string;
    projects: IntegrationProjectInfo[];
}

export interface IntegrationProjectInfo {
    id: string;
    name: string;
    title: string;
}

export interface WebsocketConnectionInfo {
    integrationWebsocketUrl: string;
}

interface GetProjectPendingImportsResponse {
    imports: BulkImportTargets;
}

export class RespressoApiImpl {
    private httpService: HttpService;

    constructor() {
        this.httpService = new FetchHttpService();
    }

    // User related functions
    public async getCurrentUser(): Promise<User> {
        return await this.httpService.get('/api/ui/user');
    }

    public async getUserMetaInfo(userId: string): Promise<UserPublicInfo> {
        return await this.httpService.get(`/api/ui/user/${userId}/meta`);
    }

    public async getRandomProfilePicture(): Promise<void> {
        return await this.httpService.post(`/api/ui/user/newavatar`);
    }

    public async hasPermission(teamId: string, projectId: string, permissionType: string): Promise<PermissionResponse> {
        return this.httpService.get(`/api/ui/user/${teamId}/${projectId}/${permissionType}`);
    }

    public async checkFeature(featureName: string): Promise<CheckFeatureResponse> {
        return this.httpService.get(`/api/ui/check/feature/${featureName}`);
    }

    public async updateTutorialStatus(tutorialStatus: TutorialStatus): Promise<User> {
        return this.httpService.post('/api/ui/user/settutorialstatus', tutorialStatus);
    }

    public async getQuestions(): Promise<UserQuestions> {
        return this.httpService.get('/api/ui/user/questions/list');
    }

    public async answerQuestion(answer: UserQuestionAnswer): Promise<UserQuestions> {
        return this.httpService.post(`/api/ui/user/questions/${answer.id}/answer`, answer);
    }

    public async denyQuestion(questionId: string): Promise<UserQuestions> {
        return this.httpService.post(`/api/ui/user/questions/${questionId}/deny`, {});
    }

    public async callImageConverter(request: any): Promise<ConversionToolResponse> {
        return this.httpService.post(`/api/ui/tools/convert/image`, request);
    }

    public async saveUser(
        fileId: string | null,
        fullName: string | null,
        oldpassword: string | null,
        newpassword: string | null,
        newsletter: boolean | null,
    ): Promise<User> {
        const request: UserSaveData = {
            pictureId: fileId,
            fullName,
            oldpassword,
            newpassword,
            newsletter,
        };

        return this.httpService.post(`/api/ui/user/modify`, request);
    }

    public async deleteAccount(): Promise<void> {
        return this.httpService.delete(`/api/ui/auth/deleteme`);
    }

    public async sendResetPassword(email: string): Promise<string> {
        const sendPasswordResetRequest: SendPasswordResetRequest = {
            email,
        };

        return await this.httpService.post('/api/ui/auth/sendpassreminder', sendPasswordResetRequest);
    }

    public async resetPassword(passToken: string, newPassword: string): Promise<PassModResponse> {
        const passResetRequest: PasswordResetRequest = {
            passToken,
            newPassword,
        };
        return this.httpService.post('/api/ui/auth/modifypassword', passResetRequest);
    }

    // Registration related functions
    public async loadRecaptchaKey(): Promise<string | undefined> {
        const settings: GetRecaptchaSettingsResponse = await this.httpService.get(`/api/ui/auth/recaptcha`);
        return settings.recaptchaRequired ? settings.recaptchaId : undefined;
    }

    public async register(
        email: string,
        fullName: string,
        password: string,
        newsletter: boolean,
        recaptcha: string,
        workRole: UserWorkRole,
        invitation?: string,
    ): Promise<RegistrationResponse | InvitationVerifiedRegistrationResponse> {
        const registrationRequest: RegistrationRequest = {
            email,
            name: fullName,
            password,
            newsletter,
            recaptcha,
            workRole,
            invitation,
        };

        const response: RegistrationResponse | InvitationVerifiedRegistrationResponse = await this.httpService.post(
            '/api/ui/auth/registration',
            registrationRequest,
        );
        return response;
    }

    public async activateUser(authUserId: string, inputToken: string): Promise<User> {
        const activateRequest: ActivateUserRequest = {
            authUserId,
            inputToken,
        };

        const response: AuthResponse = await this.httpService.post('/api/ui/auth/activate', activateRequest);
        return response.user;
    }

    public async login(email: string, password: string): Promise<User> {
        const authRequest: AuthRequest = {
            email,
            password,
        };

        const response: AuthResponse = await this.httpService.post('/api/ui/auth/login', authRequest);
        return response.user;
    }

    public async logout(): Promise<void> {
        return await this.httpService.post(`/api/ui/auth/logout`);
    }

    public async getJoinableTeams(): Promise<JoinableTeams> {
        return await this.httpService.get('/api/ui/auth/selectteam');
    }

    public async joinTeam(team: TeamInfo): Promise<void> {
        const request: JoinTeamRequest = {
            team: team.id,
        };
        return await this.httpService.post('/api/ui/auth/jointeam', request);
    }

    public async joinByInvitation(inviteToken: string): Promise<string> {
        const user = await this.getCurrentUser();
        const request: JoinByInvitationRequest = {
            inviteToken,
            user: user.id,
        };
        const response: JoinByInvitationResponse = await this.httpService.post(
            '/api/ui/auth/joinbyinvitation',
            request,
        );
        return response.teamId;
    }

    public async joinByInvitationLater(team: TeamInfo): Promise<string> {
        const request: JoinTeamRequest = {
            team: team.id,
        };
        const response: JoinByInvitationResponse = await this.httpService.post('/api/ui/auth/jointeam', request);
        return response.teamId;
    }

    public async getInvitationInfo(token: string): Promise<PendingUserMeta> {
        return await this.httpService.get(`/api/ui/auth/invitation/${token}`);
    }

    public async quickStartSetup(): Promise<QuickStartResponse> {
        return await this.httpService.post('/api/ui/auth/quickstart', {});
    }

    // Team related functions
    public async getTeams(): Promise<TeamInfoList> {
        return await this.httpService.get('/api/ui/team/list');
    }

    public async getTeam(teamId: string): Promise<TeamMeta> {
        return await this.httpService.get(`/api/ui/team/${teamId}`);
    }

    public async getTeamSubscriptions(teamId: string): Promise<TeamSubscriptionsResponse> {
        return await this.httpService.get(`/api/ui/team/${teamId}/subscriptions`);
    }

    public async createTeam(data: CreateTeamData): Promise<CreateTeamResponse> {
        return await this.httpService.post(`/api/ui/auth/createteam`, data);
    }

    public async saveTeam(teamId: string, data: TeamModificationMeta): Promise<void> {
        return await this.httpService.post(`/api/ui/team/${teamId}/save`, data);
    }

    public async deleteTeam(teamId: string): Promise<void> {
        return await this.httpService.post(`/api/ui/team/${teamId}/delete`);
    }

    public async leaveTeam(teamId: string): Promise<void> {
        return await this.httpService.post(`/api/ui/team/${teamId}/leave`);
    }

    // Team role modification request
    public async setTeamRole(teamName: string, userId: string, role: TeamRole): Promise<void> {
        const request: SetTeamRoleRequest = { role: role };
        return await this.httpService.post(`/api/ui/team/${teamName}/role/${userId}`, request);
    }

    public async deleteTeamRole(teamName: string, userId: string): Promise<void> {
        return await this.httpService.delete(`/api/ui/team/${teamName}/role/${userId}`);
    }

    // Team invite modification requests
    public async createTeamInvite(teamName: string, email: string, name?: string, role?: TeamRole): Promise<void> {
        const request: CreateTeamInviteRequest = { email, name, role };
        return await this.httpService.post(`/api/ui/team/${teamName}/invite`, request);
    }

    public async resendTeamInvite(teamName: string, email: string): Promise<void> {
        return await this.httpService.put(`/api/ui/team/${teamName}/invite/${email}`);
    }

    public async deleteTeamInvite(teamName: string, email: string): Promise<void> {
        return await this.httpService.delete(`/api/ui/team/${teamName}/invite/${email}`);
    }

    // Project related functions
    public async getProject(teamId: string, projectId: string): Promise<ProjectBasicData> {
        return await this.httpService.get(`/api/ui/team/${teamId}/${projectId}`);
    }

    public async getJoinableProjects(teamId: string): Promise<ProjectsList> {
        return await this.httpService.get(`/api/ui/team/${teamId}/allprojects`);
    }

    public async joinProject(teamId: string, projectId: string): Promise<JoinProjectResponse> {
        return await this.httpService.post(`/api/ui/team/${teamId}/${projectId}/join`, null);
    }

    public async generateProjectToken(teamId: string, projectId: string): Promise<string> {
        const resp = await this.httpService.get(`/api/ui/team/${teamId}/${projectId}/newtoken`);
        return resp.newtoken;
    }

    public async getIntegrationInfo(): Promise<IntegrationInfoResponse> {
        return await this.httpService.get('/api/ui/team/integration/list');
    }

    public async getIntegrationToken(teamId: string, projectId: string): Promise<string> {
        const resp = await this.httpService.get(`/api/ui/team/${teamId}/${projectId}/newintegrationtoken`);
        return resp.token;
    }

    public async getIntegrationTokenInfo(token: string): Promise<IntegrationTokenInfo> {
        const headers = new Map<string, string>();
        headers.set('Authorization', 'Bearer ' + token);
        return await this.httpService.get(`/api/integration/v1/info`, headers);
    }

    public async getIntegrationCategoryConfigInfo(
        token: string,
        category: string,
        version: string,
    ): Promise<IntegrationCategoryConfigInfo> {
        const headers = new Map<string, string>();
        headers.set('Authorization', 'Bearer ' + token);
        return await this.httpService.get(`/api/integration/v1/config/${category}/${version}`, headers);
    }

    public async archiveProject(teamId: string, projectId: string): Promise<void> {
        return await this.httpService.post(`/api/ui/team/${teamId}/${projectId}/archive`, null);
    }

    public async saveProject(teamId: string, projectId: string, data: MetaModProjectRequest): Promise<void> {
        return await this.httpService.post(`/api/ui/team/${teamId}/${projectId}/save`, data);
    }

    public async createProject(teamId: string, data: CreateProjectRequest): Promise<CreateProjectResponse> {
        return await this.httpService.post(`/api/ui/team/${teamId}/addproject`, data);
    }

    public async createProjectInvite(teamName: string, projectName: string, email: string, name?: string, role?: ProjectRole): Promise<void> {
        const request: CreateProjectInviteRequest = { email, name, role };
        return await this.httpService.post(`/api/ui/team/${teamName}/${projectName}/invite`, request);
    }

    public async deleteProjectInvite(teamName: string, projectName: string, email: string): Promise<void> {
        return await this.httpService.delete(`/api/ui/team/${teamName}/${projectName}/invite/${email}`);
    }

    public async loadProjectResourcesMeta(teamId: string, projectId: string): Promise<LoadProjectResourcesMeta[]> {
        return await this.httpService.get(`/api/ui/team/${teamId}/${projectId}/lastresources`);
    }

    public async loadLastUpdatedProjectMeta(
        teamId: string,
        projectId: string,
        paging?: Paging,
    ): Promise<LastupdatedProjectResult> {
        let url = `/api/ui/team/${teamId}/${projectId}/lastupdated`;
        if (paging) {
            url = `/api/ui/team/${teamId}/${projectId}/lastupdated?page=${paging.page}&size=${paging.size}`;
        }
        return await this.httpService.get(url);
    }

    public async loadLastUpdatedTeamMeta(teamId: string, paging?: Paging): Promise<LastupdatedTeamResult> {
        let url = `/api/ui/team/${teamId}/lastupdated`;
        if (paging) {
            url = `/api/ui/team/${teamId}/lastupdated?page=${paging.page}&size=${paging.size}`;
        }

        return await this.httpService.get(url);
    }

    public async loadLastModifiedTeamMeta(teamId: string, paging?: Paging): Promise<LastUserActivityInTeamResult> {
        let url = `/api/ui/team/${teamId}/lastmodifiers`;
        if (paging) {
            url = `/api/ui/team/${teamId}/lastmodifiers?page=${paging.page}&size=${paging.size}`;
        }

        return await this.httpService.get(url);
    }

    public async loadProjectSubscriptionPlanOptions(
        teamId: string,
        projectId: string,
    ): Promise<ProjectSubscriptionOptionsResponse> {
        return await this.httpService.get(`/api/ui/team/${teamId}/${projectId}/subscriptionoptions`);
    }

    public async loadCheckoutInfo(
        teamId: string,
        projectId: string,
        planId: string,
    ): Promise<StartProjectSubscriptionCheckoutResponse> {
        return await this.httpService.get(`/api/ui/team/${teamId}/${projectId}/checkout/start/${planId}`);
    }

    public async pollCheckoutSuccess(
        teamId: string,
        projectId: string,
        planId: string,
    ): Promise<PollProjectSubscriptionCheckoutSuccessResponse> {
        return await this.httpService.get(`/api/ui/team/${teamId}/${projectId}/checkout/poll/plan/${planId}`);
    }

    public async pollCheckoutState(
        teamId: string,
        projectId: string,
        planId: string,
        state: ProjectSubscriptionState,
    ): Promise<PollProjectSubscriptionCheckoutStateResponse> {
        return await this.httpService.get(`/api/ui/team/${teamId}/${projectId}/checkout/poll/state/${planId}/${state}`);
    }

    public async proceedInternalCheckout(verify: string): Promise<any> {
        return await this.httpService.post(`/api/ui/subscription/internal/project/proceed`, { verify });
    }

    public async proceedPaddleCheckout(verify: string): Promise<any> {
        return await this.httpService.post(`/api/ui/subscription/paddle/project/proceed`, { verify });
    }

    public async cancelPaddleSubscription(projectId: string): Promise<any> {
        return await this.httpService.post(`/api/ui/subscription/paddle/project/cancel/${projectId}`, {});
    }

    public async pausePaddleSubscription(projectId: string): Promise<any> {
        return await this.httpService.post(`/api/ui/subscription/paddle/project/pause/${projectId}`, {});
    }

    public async resumePaddleSubscription(projectId: string): Promise<any> {
        return await this.httpService.post(`/api/ui/subscription/paddle/project/resume/${projectId}`, {});
    }

    public async getPaddleProjectReceipts(projectId: string): Promise<PaddleProjectSubscriptionPayment[]> {
        return await this.httpService.get(`/api/ui/subscription/paddle/payments/project/${projectId}`);
    }

    public async getPaddleTeamReceipts(teamId: string): Promise<PaddleProjectSubscriptionPayment[]> {
        return await this.httpService.get(`/api/ui/subscription/paddle/payments/team/${teamId}`);
    }

    // Project role modification request
    public async setProjectRole(
        teamName: string,
        projectName: string,
        userId: string,
        role: ProjectRole,
    ): Promise<void> {
        const request: SetProjectRoleRequest = { role: role };
        return await this.httpService.post(`/api/ui/team/${teamName}/${projectName}/role/${userId}`, request);
    }

    public async deleteProjectRole(teamName: string, projectName: string, userId: string): Promise<void> {
        return await this.httpService.delete(`/api/ui/team/${teamName}/${projectName}/role/${userId}`);
    }

    // Project pending members handling requests
    public async acceptPendingMember(
        teamName: string,
        projectName: string,
        userId: string,
        role?: ProjectRole,
    ): Promise<void> {
        const request: AcceptProjectPendingMemberRequest = { role: role };
        return await this.httpService.post(`/api/ui/team/${teamName}/${projectName}/pending/${userId}`, request);
    }

    public async rejectPendingMember(teamName: string, projectName: string, userId: string): Promise<void> {
        return await this.httpService.delete(`/api/ui/team/${teamName}/${projectName}/pending/${userId}`);
    }

    // Bulk import related requests
    public async getPendingImports(teamName: string, projectName: string): Promise<GetProjectPendingImportsResponse> {
        return await this.httpService.get(`/api/ui/team/${teamName}/${projectName}/import/pending`);
    }

    public async bulkImport(teamName: string, projectName: string, targets: BulkImportTargets): Promise<void> {
        return await this.httpService.post(`/api/ui/team/${teamName}/${projectName}/import/bulk`, targets);
    }

    // Save any object - joker function
    private async doSave<T>(path: string, data: ResourceItem[]): Promise<T> {
        return this.httpService.post(path, data);
    }

    // Version related functions
    public async getResourceVersions(teamId: string, projectId: string, resourceId: string): Promise<ResourceVersions> {
        const versions: VersionMeta[] = await this.httpService.get(
            `/api/ui/common/version/${teamId}/${projectId}/${resourceId}`,
        );

        return {
            id: resourceId,
            versions,
        };
    }

    public async createVersion(
        teamId: string,
        projectId: string,
        resourceId: string,
        version: string,
        copyVersion?: string,
    ): Promise<void> {
        const request: CreateVersionRequest = {
            versionNumber: version,
            copyVersionNumber: copyVersion,
        };
        return this.httpService.post(`/api/ui/common/version/${teamId}/${projectId}/${resourceId}`, request);
    }

    public async deleteVersion(teamId: string, projectId: string, resourceId: string, version: string): Promise<void> {
        return this.httpService.delete(`/api/ui/common/version/${teamId}/${projectId}/${resourceId}/${version}`, null);
    }

    public async saveVersion(
        teamId: string,
        projectId: string,
        resourceId: ResourceType,
        version: string,
        data: ResourceItem[],
    ): Promise<void> {
        return this.doSave(`/api/ui/resource/${resourceId}/${teamId}/${projectId}/${version}`, data);
    }

    public async finalizeVersion(
        teamId: string,
        projectId: string,
        resourceId: string,
        version: string,
    ): Promise<void> {
        return this.httpService.post(
            `/api/ui/common/version/${teamId}/${projectId}/${resourceId}/${version}/finalize`,
            null,
        );
    }

    public async createPatchVersion(
        teamId: string,
        projectId: string,
        resourceId: string,
        version: string,
    ): Promise<string> {
        const request: CreatePatchVersionRequest = {
            versionNumber: version,
        };
        const response: CreatePatchVersionResponse = await this.httpService.post(
            `/api/ui/common/version/${teamId}/${projectId}/${resourceId}/patch`,
            request,
        );
        return response.patchVersion;
    }

    public async changeVersionMeta(
        teamId: string,
        projectId: string,
        resourceId: string,
        version: string,
        request: ChangeVersionMetaRequest,
    ): Promise<string> {
        const response: CreatePatchVersionResponse = await this.httpService.post(
            `/api/ui/common/version/${teamId}/${projectId}/${resourceId}/meta`,
            request,
        );
        return response.patchVersion;
    }

    // Localization resource related functions
    public async saveLocalizationVersion(
        teamId: string,
        projectId: string,
        version: string,
        language: string,
        localizationData: LocalizationResourceItem[],
    ): Promise<LocalizationSaveResponse> {
        // FIXME külön interface
        return this.doSave(`/api/ui/localization/${teamId}/${projectId}/${version}/${language}`, localizationData);
    }

    @LoadingScreen()
    public async importLocalization(
        teamId: string,
        projectId: string,
        version: string,
        fileId: string,
        selectedImporter: string,
        lang: string,
        config: any | undefined,
    ): Promise<void> {
        const data = {
            fileId,
            platform: selectedImporter,
            lang,
            config: config,
        };
        return this.httpService.post(`/api/ui/localization/${teamId}/${projectId}/${version}/import`, data);
    }

    public async getLocalizationVersionInfo(
        teamId: string,
        projectId: string,
        version: string,
    ): Promise<LocalizationInfo> {
        return this.httpService.get(`/api/ui/localization/${teamId}/${projectId}/${version}/info`);
    }

    public async getLocalizationVersion(
        teamId: string,
        projectId: string,
        version: string,
        language: string,
    ): Promise<GetLocalizationData> {
        return this.httpService.get(`/api/ui/localization/${teamId}/${projectId}/${version}/lang/${language}`);
    }

    public async getLocalizationResourceInfo(
        teamId: string,
        projectId: string,
        version: string,
        resourceId: string,
    ): Promise<LocalizationResourceInfoResponse> {
        return this.httpService.get(`/api/ui/localization/${teamId}/${projectId}/${version}/resource/${resourceId}`);
    }

    public async getLocalizationDiff(
        teamId: string,
        projectId: string,
        version: string,
        changeSetVersion: string,
        uniqueKeys: boolean,
    ): Promise<AutoMergeResult> {
        return this.httpService.get(
            `/api/ui/localization/${teamId}/${projectId}/${version}/diff/${changeSetVersion}?uniqueKeys=${uniqueKeys}`,
        );
    }

    public async patchLocalizations(
        teamId: string,
        projectId: string,
        version: string,
        changes: ResourceCategoryChangeStructure,
    ): Promise<{ state: string }> {
        return this.httpService.post(`/api/ui/localization/${teamId}/${projectId}/${version}/patch`, changes);
    }

    public async getLocalizationConfig(teamId: string, projectId: string, version: string): Promise<GetConfigResponse> {
        return this.httpService.get(`/api/ui/localization/${teamId}/${projectId}/${version}/config`);
    }

    public async setLocalizationConfig(teamId: string, projectId: string, version: string, data: any): Promise<void> {
        return this.httpService.post(`/api/ui/localization/${teamId}/${projectId}/${version}/config`, data);
    }

    public async addLanguage(teamId: string, projectId: string, version: string, language: string): Promise<void> {
        return this.httpService.post(`/api/ui/localization/${teamId}/${projectId}/${version}/lang/${language}`, null);
    }

    public async deleteLanguage(teamId: string, projectId: string, version: string, language: string): Promise<void> {
        return this.httpService.delete(`/api/ui/localization/${teamId}/${projectId}/${version}/lang/${language}`, null);
    }

    public async setAsDefaultLanguage(
        teamId: string,
        projectId: string,
        version: string,
        language: string,
    ): Promise<void> {
        return this.httpService.post(
            `/api/ui/localization/${teamId}/${projectId}/${version}/lang/${language}/default`,
            null,
        );
    }

    public async getLangs(): Promise<string[]> {
        return this.httpService.get(`/api/ui/localization/nats`);
    }

    // Image resource related functions
    public async saveImageVersion(
        teamId: string,
        projectId: string,
        version: string,
        data: ImageDataToSave[],
    ): Promise<ImageResourceItem[]> {
        return this.httpService.post(`/api/ui/image/${teamId}/${projectId}/${version}`, data);
    }

    public async getImageVersion(
        teamId: string,
        projectId: string,
        version: string,
    ): Promise<LoadImageResourceResponse> {
        return this.httpService.get(`/api/ui/image/${teamId}/${projectId}/${version}`);
    }

    public async getImageConfig(teamId: string, projectId: string, version: string): Promise<GetConfigResponse> {
        return this.httpService.get(`/api/ui/image/${teamId}/${projectId}/${version}/config`);
    }

    public async setImageConfig(teamId: string, projectId: string, version: string, data: any): Promise<void> {
        return this.httpService.post(`/api/ui/image/${teamId}/${projectId}/${version}/config`, data);
    }


    // Color resource related functions
    public async getColorVersion(teamId: string, projectId: string, version: string): Promise<GetColorDataResponse> {
        return this.httpService.get(`/api/ui/color/${teamId}/${projectId}/${version}`);
    }

    public async importImage(
        teamId: string,
        projectId: string,
        version: string,
        fileId: string,
        selectedImporter: string,
        config?: any,
    ): Promise<void> {
        const data = {
            fileId: fileId,
            importer: selectedImporter,
            config,
        };
        return this.httpService.post(`/api/ui/image/${teamId}/${projectId}/${version}/import`, data);
    }

    public async saveColorVersion(
        teamId: string,
        projectId: string,
        version: string,
        data: ColorDataToSave[],
    ): Promise<ColorKeyData[]> {
        return this.httpService.post(`/api/ui/color/${teamId}/${projectId}/${version}`, data);
    }

    public async importColor(
        teamId: string,
        projectId: string,
        version: string,
        fileId: string,
        selectedImporter: string,
        config?: any,
    ): Promise<void> {
        const data = {
            fileId,
            importer: selectedImporter,
            config,
        };
        return this.httpService.post(`/api/ui/color/${teamId}/${projectId}/${version}/import`, data);
    }

    // plain text resource related functions
    public async getRawVersion(teamId: string, projectId: string, version: string): Promise<RawDataResponse> {
        return this.httpService.get(`/api/ui/raw/${teamId}/${projectId}/${version}`);
    }

    public async saveRawVersion(
        teamId: string,
        projectId: string,
        version: string,
        data: SaveRequest,
    ): Promise<RawData> {
        return this.httpService.post(`/api/ui/raw/${teamId}/${projectId}/${version}`, data);
    }

    // Font resource related functions
    public async getFontVersion(teamId: string, projectId: string, version: string): Promise<GetFontDataResponse> {
        return this.httpService.get(`/api/ui/font/${teamId}/${projectId}/${version}`);
    }

    public async saveFontVersion(
        teamId: string,
        projectId: string,
        version: string,
        data: FontResourceToSave[],
    ): Promise<FontResourceItem[]> {
        return this.httpService.post(`/api/ui/font/${teamId}/${projectId}/${version}`, data);
    }

    public async downloadSingleFontFile(
        teamId: string,
        projectId: string,
        version: string,
        fileId: string,
    ): Promise<void> {
        return this.httpService.get(`/api/ui/font/${teamId}/${projectId}/${version}/${fileId}`);
    }

    // AppIcon resource related functions
    public async getAppIconVersion(
        teamId: string,
        projectId: string,
        version: string,
    ): Promise<GetAppIconDataResponse> {
        return this.httpService.get(`/api/ui/appIcon/${teamId}/${projectId}/${version}/info`);
    }

    public async saveAppIconVersion(
        teamId: string,
        projectId: string,
        version: string,
        data: AppIconDataForSave,
    ): Promise<SaveAppIconDataResponse> {
        return this.httpService.post(`/api/ui/appIcon/${teamId}/${projectId}/${version}`, data);
    }

    public async previewAppIcon(
        teamId: string,
        projectId: string,
        version: string,
        data: AppIconDataForSave,
    ): Promise<PreviewAppIconDataResponse> {
        return this.httpService.post(`/api/ui/appIcon/${teamId}/${projectId}/${version}/preview`, data);
    }

    // Others
    public async getFlow(
        teamId: string,
        projectId: string,
        version: string,
        resourceId: string,
        flowId: string,
    ): Promise<GetFlowResponse> {
        return this.httpService.get(`/api/ui/flow/${teamId}/${projectId}/${version}/${resourceId}/${flowId}`);
    }

    public async getDefaultFlow(flowId: string): Promise<FlowData> {
        return this.httpService.get(`/api/ui/flow/${flowId}`);
    }

    public async saveFlow(
        teamId: string,
        projectId: string,
        version: string,
        resourceId: string,
        flowId: string,
        flow: string,
        rerun: boolean,
    ): Promise<void> {
        return this.httpService.post(`/api/ui/flow/${teamId}/${projectId}/${version}/${resourceId}/${flowId}`, {
            flow,
            rerun,
        });
    }

    public async getFlowSVG(
        teamId: string,
        projectId: string,
        version: string,
        resourceId: string,
        flowId: string,
        flow: string,
    ): Promise<GetFlowSvgResponse> {
        // FIXME interface
        return this.httpService.postRaw(
            `/api/ui/flow/${teamId}/${projectId}/${version}/${resourceId}/${flowId}/show`,
            flow,
            {
                'Content-type': 'application/xml',
            },
        );
    }

    public async getNews(): Promise<News[]> {
        return this.httpService.get(`/api/ui/whatsNew`);
    }

    public async getLiveUpdateConnectionInfo(teamName: string, projectId: string): Promise<LiveUpdateConnectionInfo> {
        return this.httpService.get(`/api/ui/liveUpdate/${teamName}/${projectId}/getConnectionInfo`);
    }

    public async getWebsocketConnectionInfo(): Promise<WebsocketConnectionInfo> {
        return this.httpService.get(`/api/ui/liveUpdate/getConnectionInfo`);
    }

    public async postNewLocalizations(localizations: LocalizationResourceItem[]): Promise<void> {
        return this.httpService.post(`/api/ui/localization/save`, localizations);
    }

    public async getQrCode(token: string): Promise<string> {
        return this.httpService.get(`https://chart.googleapis.com/chart?chl=${token}`);
    }

    public async getServerLicense(): Promise<GetServerLicenseResponse> {
        return this.httpService.get(`/api/ui/license`);
    }

    public async reloadServerLicense(): Promise<GetServerLicenseResponse> {
        return this.httpService.get(`/api/ui/license/reload`);
    }
}

const RespressoApi = new RespressoApiImpl();
export default RespressoApi;

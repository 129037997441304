import Vue from 'vue';
import Component from 'vue-class-component';
import template from './merge-localization-versions.html';
import './merge-localization-versions.scss';
import {NextFunction, Route} from 'vue-router';
import LoadingScreen from '../../../decorators/loading-screen';
import {
    AutoMergeResult,
    ConflictType,
    DataChangeStructure,
    MergeChangeType,
    ProjectMeta,
    ResourceCategoryChangeStructure,
    ResourceChangeStructure,
    TeamMeta,
} from 'respresso';
import {teamModule} from '../../../store/modules/team/index';
import ErrorHandler from '../../../services/error-handler';
import RespressoApi from '../../../api/respresso-api';
import VersionList from '../resource/version-list';
import StorageService from '../../../services/storage-service';
import FlagUtil from '../../../util/flag-util';
import SelectableChange from '../../common/merge/selectable-change';
import ChangedItem from '../../common/merge/changed-item';

@Component({
	template: template,
	components: {
		'selectable-change': SelectableChange,
		'changed-item': ChangedItem,
	},
})
export default class MergeLocalizationVersions extends Vue {
	private teamId = '';
	private projectId = '';
	private targetVersion = '1.0.0';
	private changeSetVersion = '1.0.0';
	private diff: AutoMergeResult | null = null;
	private resourceChanges: ResourceChange[] = [];
	private locChanges: LocalizationChange[] = [];
	private configChange: ConfigChange | null = null;
	private uniqueKeys = false;

	private get selectedChanges(): ResourceChange[] {
		return this.resourceChanges.filter((it) => it.selected);
	}

	private getResource(resId: string) {
		return this.resourceChanges.find((it) => it.change.id === resId)!;
	}

	private isSelected(resId: string) {
		return this.getResource(resId)?.selected == true;
	}

	private invertSelectionOfResource(resId: string) {
		const res = this.getResource(resId);
		if (res) {
			res.selected = !res.selected;
		}
	}

	beforeRouteEnter(to: Route, from: Route, next: NextFunction): void {
		next((vm: any) => {
			const res = vm as MergeLocalizationVersions;
			res.load(to);
		});
	}

	@LoadingScreen({ showImmediately: true })
	private async load(to: Route): Promise<void> {
		this.teamId = to.params.teamId;
		this.projectId = to.params.projectId;
		this.targetVersion = to.params.versionNumber;
		this.changeSetVersion = to.params.changeSetVersion;
		this.uniqueKeys = !!to.query.uniqueKeys;

		const response = await ErrorHandler.tryRequest(
			() =>
				RespressoApi.getLocalizationDiff(
					this.teamId,
					this.projectId,
					this.targetVersion,
					this.changeSetVersion,
					this.uniqueKeys,
				),
			{
				loadingScreen: true,
			},
		);

		const resourcesToConfirm: ResourceChange[] = [];
		const resourcesToReview: ResourceChange[] = [];
		const resourcesAutoResolved: ResourceChange[] = [];
		const nonConflictingResources: ResourceChange[] = [];

		if (response) {
			response.change.resourceChanges.forEach((resource) => {
				if (resource.id) {
					let conflictType = response.resourceConflicts[resource.id];
					if (!conflictType) {
						conflictType = 'None';
					}
					let list = nonConflictingResources;
					let preSelect = true;
					if (conflictType === 'ManualConfirm') {
						list = resourcesToConfirm;
					} else if (conflictType === 'ManualIgnore') {
						list = resourcesToReview;
						preSelect = false;
					} else if (conflictType === 'Resolved') {
						list = resourcesAutoResolved;
					}
					list.push({
						identifier: resource.id, // FIXME
						conflict: conflictType,
						change: resource,
						selected: preSelect,
					});
				}
			});

			this.diff = response;
			this.resourceChanges = [
				...resourcesToConfirm,
				...resourcesToReview,
				...resourcesAutoResolved,
				...nonConflictingResources,
			];
			this.locChanges = this.resourceChanges.map((change) => {
				const resId = change.change.id!;
				const conflict = response.resourceConflicts[resId] ?? 'None';
				const targetRes = response.targetSnapshot.resources.find((it) => it.id === resId);
				let changeType: MergeChangeType;
				if (targetRes) {
					if (change.change.type === 'DELETE') {
						changeType = 'delete';
					} else {
						changeType = 'update';
					}
				} else {
					changeType = 'create';
				}
				const locChange: LocalizationChange = {
					resourceId: resId,
					key: this.targetKey(change) ?? this.changedKey(change) ?? 'unknown key',
					conflict: conflict,
					changeType: changeType,
					translationChanges: [],
				};
				const languages = this.getAllChangedLanguages(change);
				languages.forEach((language) => {
					const path = `translations.${language}`;
					const changedTranslation = this.patchStringValueAtPath(change, path);
					const targetTranslation = this.targetStringValueAtPath(change, path);
					if (changedTranslation !== targetTranslation) {
						locChange.translationChanges.push({
							languageCode: language,
							languageName: this.$t(`language.${language}`) as string,
							current: targetTranslation,
							resulting: changedTranslation,
						});
					}
				});
				const changedKey = this.patchStringValueAtPath(change, 'key');
				const targetKey = this.targetStringValueAtPath(change, 'key');
				if (changedKey != null && changedKey !== targetKey) {
					locChange.keyChange = {
						resulting: changedKey,
						current: targetKey,
					};
				}
				const changedDescription = this.patchStringValueAtPath(change, 'comment');
				const targetDescription = this.targetStringValueAtPath(change, 'comment');
				if (changedDescription !== targetDescription) {
					locChange.descriptionChange = {
						resulting: changedDescription,
						current: targetDescription,
					};
				}
				const changedTags = this.patchValueAtPath(change, 'tags');
				const targetTags = this.targetValueAtPath(change, 'tags');
				this.stringSetChangeDetection(changedTags, targetTags, (current, resulting) => {
					locChange.tagChange = {
						current: current,
						resulting: resulting,
					};
				});
				const changedVariables = this.patchValueAtPath(change, 'variables');
				const targetVariables = this.targetValueAtPath(change, 'variables');
				if (changedVariables && Array.isArray(changedVariables)) {
					const resultingVariables =
						changedVariables && Array.isArray(changedVariables) ? changedVariables : [];
					const currentVariables = targetVariables && Array.isArray(targetVariables) ? targetVariables : [];
					if (resultingVariables.length !== 0 || currentVariables.length !== 0) {
						locChange.variableChange = {
							current: currentVariables,
							resulting: resultingVariables,
						};
					}
				}
				return locChange;
			});
			this.configChange = {
				conflict: response.configConflict,
				selected: response.configConflict == 'ManualConfirm' || response.configConflict == 'Resolved',
			};
			const changedDefaultLanguage = this.patchValueAtPathFromList(
				response.change.configChanges,
				'defaultLanguage',
			);
			const targetDefaultLanguage = readStructurePath(response.targetSnapshot.config, 'defaultLanguage');
			if (changedDefaultLanguage && changedDefaultLanguage != targetDefaultLanguage) {
				this.configChange.defaultLanguageChange = {
					current: targetDefaultLanguage,
					resulting: changedDefaultLanguage,
				};
			}
			const changedLanguages = this.patchValueAtPathFromList(response.change.configChanges, 'languages');
			const targetLanguages = readStructurePath(response.targetSnapshot.config, 'languages');
			this.stringSetChangeDetection(changedLanguages, targetLanguages, (current, resulting) => {
				this.configChange!.languageChange = {
					current: current,
					resulting: resulting,
				};
			});
			const changedVariableOverrides = this.patchValueAtPathFromList(
				response.change.configChanges,
				'variables.overrides',
			);
			const targetVariableOverrides = readStructurePath(response.targetSnapshot.config, 'variables.overrides');
			if (changedVariableOverrides && typeof changedVariableOverrides === 'object') {
				const changedFormats = Object.keys(changedVariableOverrides);
				const targetFormats =
					targetVariableOverrides && typeof targetVariableOverrides === 'object'
						? Object.keys(targetVariableOverrides)
						: [];
				let overridesChanged = false;
				if (this.hasSetDiff(changedFormats, targetFormats)) {
					overridesChanged = true;
				} else {
					for (const format in changedFormats) {
						if (changedVariableOverrides[format] !== targetVariableOverrides[format]) {
							overridesChanged = true;
							break;
						}
					}
				}
				if (overridesChanged) {
					this.configChange!.variableOverrideChange = {
						current: targetVariableOverrides,
						resulting: changedVariableOverrides,
					};
				}
			}
		}
	}

	stringSetChangeDetection(
		changed: any | null,
		target: any | null,
		handler: (current: string[] | null, target: string[] | null) => void,
	) {
		if (changed && Array.isArray(changed)) {
			const targetArray = target && Array.isArray(target) ? target : [];
			if (this.hasSetDiff(changed, targetArray)) {
				handler(target, changed);
			}
		}
	}

	hasSetDiff(a: string[], b: string[]) {
		const aSet = new Set(a);
		const bSet = new Set(b);
		return a.some((it) => !bSet.has(it)) || b.some((it) => !aSet.has(it));
	}

	get projectName(): string | undefined {
		const project = this.project;
		if (project) {
			return project.title;
		}
	}

	get team(): TeamMeta | null {
		return teamModule.team;
	}

	get project(): ProjectMeta | null {
		const moduleGetterTeam = this.team;
		if (!moduleGetterTeam || !moduleGetterTeam.projects) {
			return null;
		}
		const project = moduleGetterTeam.projects.filter((p: ProjectMeta) => {
			return p.id === this.projectId;
		});
		if (project.length) {
			return project[0];
		} else {
			return null;
		}
	}

	targetKey(change: ResourceChange) {
		const resId = change.change.id;
		if (resId) {
			const currentRes = this.diff!.targetSnapshot.resources.find((it) => it.id === resId);
			if (currentRes) {
				return readStructurePath(currentRes.data, 'key');
			}
		}
		return this.changedKey(change);
	}

	changedKey(change: ResourceChange): string | null {
		return this.patchStringValueAtPath(change, 'key');
	}

	getAllChangedLanguages(change: ResourceChange): string[] {
		const languages = new Set<string>();
		if (change.change.type === 'DELETE') {
			const translations = this.targetValueAtPath(change, 'translations');
			if (translations && typeof translations === 'object') {
				Object.keys(translations).forEach((language) => languages.add(language));
			}
		} else {
			for (const dataChange of change.change.dataChanges) {
				const value = dataChange.value;
				const path = dataChange.path;
				let translations: any | null = null;
				if (path === '') {
					translations = readStructurePath(value, 'translations');
				} else if (path === 'translations') {
					translations = value;
				}
				if (translations && typeof translations === 'object') {
					Object.keys(translations).forEach((language) => languages.add(language));
					if (dataChange.type === 'OVERRIDE') {
						// ALl other languages are replaced with the current translations
						const existingTranslations = this.targetValueAtPath(change, 'translations');
						if (existingTranslations && typeof existingTranslations === 'object') {
							Object.keys(translations).forEach((language) => languages.add(language));
						}
					}
					continue;
				}
				if (/^translations\.[a-zA-Z]{2}(-[a-zA-Z]{2})?$/g.test(path)) {
					const language = path.substring('translations.'.length);
					if (language) {
						languages.add(language);
					}
				}
			}
		}
		// console.log(change.change.id, languages);
		return Array.from(languages);
	}

	patchStringValueAtPath(change: ResourceChange, path: string): string | null {
		const resultingValue = this.patchValueAtPath(change, path);
		if (typeof resultingValue === 'string') {
			return resultingValue;
		}
		return null;
	}

	targetStringValueAtPath(change: ResourceChange, path: string): string | null {
		const resultingValue = this.targetValueAtPath(change, path);
		if (typeof resultingValue === 'string') {
			return resultingValue;
		}
		return null;
	}

	targetValueAtPath(change: ResourceChange, path: string): any | null {
		const diff = this.diff;
		const resId = change.change.id;
		if (resId && diff) {
			const resources = diff.targetSnapshot.resources || [];
			const currentRes = resources.find((it) => it.id === resId);
			if (currentRes) {
				return readStructurePath(currentRes.data, path);
			}
		}
	}

	patchValueAtPath(change: ResourceChange, path: string, subPath: string | null = null): any | null {
		// TODO handle multiple changes on the same item -> should accumulate all of them
		// console.log(`Read path from patch '${path}' '${subPath}' on ${change.change.id} data: ${JSON.stringify(this.diff?.targetSnapshot?.resources?.find(it => it.id === change.change.id)?.data)}`)
		const changes = change.change.dataChanges || [];
		return this.patchValueAtPathFromList(changes, path, subPath);
	}

	private patchValueAtPathFromList(
		changes: DataChangeStructure[],
		path: string,
		subPath: string | null = null,
	): any | null {
		// TODO handle multiple changes on the same item -> should accumulate all of them
		for (let i = 0; i < changes.length; i++) {
			const change = changes[i];
			if (change.path === path) {
				const dataAtPath = change.value;
				// console.log(`Path match '${path}' '${subPath}' on ${change.path} data: ${JSON.stringify(dataAtPath)}`)
				if (subPath) {
					// console.log(`Reading nested path '${path}' '${subPath}' on ${change.path} data: ${JSON.stringify(dataAtPath)}`)
					return readStructurePath(dataAtPath, subPath);
				} else {
					// console.log(`Returning '${path}' '${subPath}' on ${change.path} data: ${JSON.stringify(dataAtPath)}`)
					return dataAtPath;
				}
			}
		}
		if (path !== '') {
			const pathParts = path.split('.');
			const lastPart = pathParts[pathParts.length - 1];
			let nestedSubPath = lastPart;
			if (subPath) {
				nestedSubPath = `${lastPart}.${subPath}`;
			}
			const parent = pathParts.slice(0, pathParts.length - 1).join('.');
			// console.log(`Select nested path '${parent}' '${nestedSubPath}'`)
			return this.patchValueAtPathFromList(changes, parent, nestedSubPath);
		}
		return null;
	}

	openVersion(version: string) {
		this.$router.push(
			VersionList.getOpenVersionRoute(
				this.teamId,
				this.projectId,
				'localization',
				StorageService.getLastLocalizationLanguage(this.teamId, this.projectId),
				version,
			),
		);
	}

	async applyChanges() {
		const selectedResourceChanges = this.selectedChanges;
		const change: ResourceCategoryChangeStructure = {
			configChanges: this.configChange?.selected ? this.diff?.change.configChanges ?? [] : [],
			resourceChanges: selectedResourceChanges.map((it) => it.change),
		};
		const response = await ErrorHandler.tryRequest(
			() => RespressoApi.patchLocalizations(this.teamId, this.projectId, this.targetVersion, change),
			{
				loadingScreen: true,
			},
		);
		if (response) {
			this.openVersion(this.targetVersion);
		}
	}

	getFlagClass(language: string) {
		return `flag-${FlagUtil.getFlagByLang(language)}`;
	}

	get selectedChangeCount() {
		let count = this.selectedChanges.length;
		if (this.configChange?.selected) {
			count++;
		}
		return count;
	}
}

interface ResourceChange {
	selected: boolean;
	identifier: string;
	conflict: ConflictType;
	change: ResourceChangeStructure;
}

interface ConfigChange {
	selected: boolean;
	conflict: ConflictType;
	defaultLanguageChange?: StringValueChange;
	languageChange?: StringArrayValueChange;
	variableOverrideChange?: StringArrayValueChange;
}

interface LocalizationChange {
	resourceId: string;
	key: string;
	conflict: ConflictType;
	changeType: MergeChangeType;
	keyChange?: StringValueChange;
	translationChanges: TranslationValueChange[];
	descriptionChange?: StringValueChange;
	tagChange?: StringArrayValueChange;
	variableChange?: VariableArrayValueChange;
}

interface StringValueChange {
	current: string | null;
	resulting: string | null;
}

interface TranslationValueChange extends StringValueChange {
	languageCode: string;
	languageName: string;
}

interface StringArrayValueChange {
	current: string[] | null;
	resulting: string[] | null;
}

interface VariableArrayValueChange {
	current: Variable[] | null;
	resulting: Variable[] | null;
}

interface VariableOverrideValueChange {
	current: { [format: string]: string } | null;
	resulting: { [format: string]: string } | null;
}

interface Variable {
	key: string;
	ieeeFormat: string;
	description?: string;
	overrides: { [format: string]: string };
}

function readStructurePath(data: any, path: string): any {
	if (data == null) return null;
	if (!path) return data;
	const separatorIndex = path.indexOf('.');
	let currentSelector = path;
	let remainingPath: string | null = null;
	if (separatorIndex >= 0) {
		currentSelector = path.substring(0, separatorIndex);
		remainingPath = path.substring(separatorIndex + 1); // Skip the dot
	}
	const arrayStartIndex = currentSelector.indexOf('[');
	let currentData: any;
	if (arrayStartIndex >= 0) {
		if (Array.isArray(data)) {
			const indexNumber = currentSelector.substring(arrayStartIndex + 1, currentSelector.length - 1);
			const arrayIndex = parseInt(indexNumber);
			currentData = data[arrayIndex];
		} else {
			throw new Error('Array selector used on non array object.');
		}
	} else {
		currentData = data[currentSelector];
	}

	if (remainingPath) {
		return readStructurePath(currentData, remainingPath);
	} else {
		return currentData;
	}
}

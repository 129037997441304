import {Logger} from './logger';

export function fallbackCopyTextToClipboard(text: string): boolean {
	const textArea = document.createElement('textarea');
	textArea.value = text;
	textArea.style.width = '0px';
	textArea.style.height = '0px';
	document.body.appendChild(textArea);
	textArea.focus();
	textArea.select();

	let successful = false;
	try {
		successful = document.execCommand('copy');
	} catch (err) {
		Logger.error('Fallback: Oops, unable to copy', err);
	}

	document.body.removeChild(textArea);
	return successful;
}

export async function copyTextToClipboard(text: string): Promise<boolean> {
	if (!(navigator as Navigator).clipboard) {
		return fallbackCopyTextToClipboard(text);
	}
	return (navigator as Navigator).clipboard
		.writeText(text)
		.then(() => {
			return true;
		})
		.catch(() => {
			return false;
		});
}

import Vue from 'vue';
import template from './selectable-change.html';
import './selectable-change.scss';
import Component from 'vue-class-component';
import {ConflictType, MergeChangeType} from 'respresso';
import {Prop} from 'vue-property-decorator';

@Component({
	props: {
		category: String,
		title: String,
		selected: Boolean,
		targetVersion: String,
		fromVersion: String,
		conflictType: String,
		changeType: String,
	},
	template: template,
})
export default class SelectableChange extends Vue {
	@Prop({ required: true })
	category!: string;
	@Prop({ required: true })
	title!: string | undefined;
	@Prop({ required: true })
	selected!: boolean;
	@Prop({ required: true })
	targetVersion: string | undefined;
	@Prop({ required: true })
	fromVersion: string | undefined;
	@Prop({ required: true })
	conflictType: ConflictType | undefined;
	@Prop({ required: true })
	changeType: MergeChangeType | undefined;

	private changeSelection(): void {
		this.$emit('selection-changed', !(this.selected ?? false));
	}
}

import Vue from 'vue';
import template from './project-invite-members-banner.html';
import './project-invite-members-banner.scss';
import Component from 'vue-class-component';

@Component({
	template: template,
	props: {
		teamId: String,
		projectId: String
	}
})
export default class ProjectInviteMembersBanner extends Vue {
	private teamId?: string;
	private projectId?: string;
}

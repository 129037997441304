/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Vue from 'vue';
import Component from 'vue-class-component';
import template from './project-settings.html';
import './project-settings.scss';
import {NextFunction, Route} from 'vue-router';
import RespressoApi from '../../../api/respresso-api';
import {Resource} from '../resource/resource';
import {ProjectMeta, ProjectRole, TeamMeta, User, UserPublicInfo} from 'respresso';
import {DialogBuilder, NotificationBuilder} from '../../common/dialog/dialog';
import UserService from '../../../services/user-service';
import ErrorHandler from '../../../services/error-handler';
import {copyTextToClipboard} from '../../../util/clipboard';
import LoadingScreen from '../../../decorators/loading-screen';
import {teamModule} from '../../../store/modules/team/index';
import {i18n} from '../../../main';
import AccessService from '../../../services/access-service';

export interface ProjectMemberInfo {
	userId: string;
	member: MemberStatus;
	lastActivity: number;
	deletedUser: boolean;
	role: ProjectRole;
}

export enum MemberStatus {
	true = 'TRUE',
	false = 'FALSE',
	pending = 'PENDING',
}

export interface ProjectBasicData {
	title: string;
	name: string;
	token: string;
	members: ProjectMemberInfo[];
}

export interface MetaModProjectRequest {
	title: string;
	name: string;
	membersUserIds: string[];
	pendingUserIds: string[];
}

@Component({
	template: template,
})
export default class ProjectSettings extends Vue {
	private teamId: string | undefined;
	protected projectId: string | null = null;
	private projectInfo: ProjectBasicData | undefined;
	private teamMembers: UserPublicInfo[] = [];
	private members: ProjectMemberInfo[] = [];
	private projectTitle = '';
	private token = '';
	private integrationToken = '';
	private integrationConnection = '';
	protected modified = false;
	protected ajaxRunning = false;

	beforeRouteEnter(to: Route, from: Route, next: NextFunction): void {
		next((vm) => {
			const res = vm as ProjectSettings;
			res.loadProject(to, from);
		});
	}

	get team(): TeamMeta | null {
		return teamModule.team;
	}

	get project(): ProjectMeta | null {
		const moduleGetterTeam = teamModule.team;
		if (!moduleGetterTeam || !moduleGetterTeam.projects) {
			return null;
		}
		const project = moduleGetterTeam.projects.filter((p: ProjectMeta) => {
			return p.id === this.projectId;
		});
		if (project.length) {
			return project[0];
		} else {
			return null;
		}
	}

	get canEditProjectPermissions() {
		return AccessService.canEditProjectPermissions(this.project);
	}

	get isProjectAdmin() {
		return AccessService.hasProjectRole('ADMIN', this.project);
	}

	@LoadingScreen({ showImmediately: true })
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	private async loadProject<T extends Resource>(to: Route, from: Route): Promise<void> {
		this.teamId = to.params.teamId;
		this.projectId = to.params.projectId;

		const resp = await ErrorHandler.tryRequest(() => RespressoApi.getProject(this.teamId!, this.projectId!));
		if (resp) {
			this.projectInfo = resp;
			this.projectTitle = resp.title;
			this.token = resp.token;
			this.teamMembers = teamModule.team!.users!;

			const lastActivity = await ErrorHandler.tryRequest(() =>
				RespressoApi.loadLastUpdatedProjectMeta(this.teamId!, this.projectId!),
			);
			if (lastActivity) {
				for (let i = 0; i < resp.members.length; i++) {
					const userPublicInfo = resp.members[i];
					const lastupdatedMetas = lastActivity.lastUpdatedFiles.filter(
						(value2) => value2.modifier === userPublicInfo.userId,
					);
					if (lastupdatedMetas && lastupdatedMetas.length > 0) {
						lastupdatedMetas.reduce((previousValue, currentValue) =>
							previousValue.lastModifiedDate > currentValue.lastModifiedDate
								? previousValue
								: currentValue,
						).lastModifiedDate;
						userPublicInfo.lastActivity = lastActivity.lastUpdatedFiles[0].lastModifiedDate;
					} else {
						userPublicInfo.lastActivity = 0;
					}
				}
			}
			this.members = resp.members;
		}
	}

	protected getActivity(first: string): number {
		for (let i = 0; i < this.members.length; i++) {
			if (this.members[i].userId === first) {
				return this.members[i].lastActivity;
			}
		}
		return 0;
	}

	protected getRoleName(id: string): string | undefined {
		const member = this.members.find(({ userId }) => userId === id);
		if (member) {
			const role = member?.role;
			if (role) {
				return i18n.t(`project.role.${role.toLowerCase()}.name`) as string;
			}
		}
	}

	@LoadingScreen()
	protected async saveProject(): Promise<void> {
		if (this.ajaxRunning) {
			return;
		}
		if (!this.teamId || !this.projectId || !this.projectInfo) {
			return;
		}

		this.ajaxRunning = true;

		const data: MetaModProjectRequest = {
			title: this.projectTitle,
			name: this.projectInfo.name,
			membersUserIds: this.members
				.filter((u) => {
					return u.member === MemberStatus.true;
				})
				.map((u) => {
					return u.userId;
				}),
			pendingUserIds: this.members
				.filter((u) => {
					return u.member === MemberStatus.pending;
				})
				.map((u) => {
					return u.userId;
				}),
		} as MetaModProjectRequest;

		const response = await ErrorHandler.tryRequest(() =>
			RespressoApi.saveProject(this.teamId!, this.projectId!, data),
		);

		if (response) {
			this.modified = false;
			NotificationBuilder.success('#success.project.saved');
		}

		this.ajaxRunning = false;
		this.$forceUpdate();
	}

	protected isProjectMember(user: User): boolean {
		return this.isUserStats(user, MemberStatus.true);
	}

	protected isPendingMember(user: User): boolean {
		return this.isUserStats(user, MemberStatus.pending);
	}

	protected isAddable(user: User): boolean {
		return this.isUserStats(user, MemberStatus.false);
	}

	private isUserStats(user: User, status: MemberStatus): boolean {
		if (!this.projectInfo) {
			return false;
		}

		const result = this.members.find((u) => {
			return u.userId === user.id;
		});
		if (result) {
			if (result.deletedUser) {
				return false;
			}
			return result.member === status;
		}
		return false;
	}

	protected hasAnyPendingRequest(): boolean {
		return (
			this.members.filter((u) => {
				return u.member === MemberStatus.pending;
			}).length !== 0
		);
	}

	protected hasAnyNotYetUser(): boolean {
		return (
			this.members.filter((u) => {
				return u.member === MemberStatus.false;
			}).length !== 0
		);
	}

	protected titleChanged(): void {
		this.modified = true;
	}

	protected async generateNewToken(): Promise<void> {
		DialogBuilder.confirm('#project.newToken.title', '#dialog.areYouSure.simple', async () => {
			if (!this.teamId || !this.projectId) {
				return;
			}

			const token = await ErrorHandler.tryRequest(() =>
				RespressoApi.generateProjectToken(this.teamId!, this.projectId!),
			);
			if (token) {
				this.token = token;
				NotificationBuilder.success('#success.project.tokenGenerate');
			}
		});
	}

	protected async archiveProject(): Promise<void> {
		DialogBuilder.confirm('#project.settings.archive.dialog', '#dialog.areYouSure.simple', async () => {
			if (!this.teamId || !this.projectId) {
				return;
			}

			const response = await ErrorHandler.tryRequest(() =>
				RespressoApi.archiveProject(this.teamId!, this.projectId!),
			);
			if (response) {
				NotificationBuilder.success('#success.project.archive');

				await UserService.loadTeam(this.teamId);
				this.$router.push({ name: 'dashboard', params: { teamId: this.teamId } });
			}
		});
	}

	private async copyProjectToken(): Promise<void> {
		if (this.project) {
			const success = await copyTextToClipboard(this.project.token);
			if (success) {
				NotificationBuilder.success('#project.clipboard.copied');
			} else {
				NotificationBuilder.error('#project.clipboard.copyFailed');
			}
		}
	}

	private async copyIntegrationToken(): Promise<void> {
		const success = await copyTextToClipboard(this.integrationToken);
		if (success) {
			NotificationBuilder.success('#project.clipboard.copied');
		} else {
			NotificationBuilder.error('#project.clipboard.copyFailed');
		}
	}

	private async getIntegrationToken(event: InputEvent): Promise<void> {
		const checkbox = event.target as HTMLInputElement;
		if (checkbox.checked) {
			const integrationToken = await ErrorHandler.tryRequest(() =>
				RespressoApi.getIntegrationToken(this.teamId!, this.projectId!),
			);
			this.integrationToken = integrationToken ? integrationToken : '';
		}
	}

	protected isCurrentUser(userId: string): boolean {
		const currentUser = UserService.getUser();
		return !!(currentUser && currentUser.id === userId);
	}

	private connectIntegration() {
		if (this.integrationConnection && this.teamId && this.projectId) {
			this.$router.push({
				name: 'zeplinIntegration',
				params: {
					teamId: this.teamId!,
					projectId: this.projectId!,
					connectionId: this.integrationConnection!.trim(),
				},
			});
		} else {
			alert('Please provide the connection id');
		}
	}

    get canEditProjectDetails() {
        return AccessService.canEditProjectDetails(this.project);
    }

    get canUseIntegrations() {
        return AccessService.canUseIntegrations(this.project);
    }
}

import store from '../../index';
import {Action, Module, Mutation, VuexModule} from 'vuex-class-modules';
import {Loader} from '../../../services/loading-service';
import {translate} from '../../../main';

export const defaultLoadingMessage = '#loading.loading';

function _translate(message: string): string {
	if (message.startsWith('#')) {
		return translate(message.substring(1));
	}
	return message;
}

@Module
class LoadingModule extends VuexModule {
	_loading = false;
	_loadingMessage = defaultLoadingMessage;
	_loadingFullScreen = false;

	get loading(): boolean {
		return this._loading;
	}
	get loadingMessage(): string {
		return this._loadingMessage;
	}
	get loadingFullScreen(): boolean {
		return this._loadingFullScreen;
	}

	@Mutation
	SET_LOADING(payload: boolean | { loading: boolean; loader?: Loader }): void {
		if (typeof payload === 'boolean') {
			this._loading = payload;
			this._loadingMessage = _translate(defaultLoadingMessage);
			this._loadingFullScreen = false;
		} else {
			this._loading = payload.loading;
			this._loadingMessage = _translate((payload.loader && payload.loader.message) || defaultLoadingMessage);
			let fullScreen = false;
			if (payload.loading && payload.loader && payload.loader.fullScreen) {
				fullScreen = true;
			}
			this._loadingFullScreen = fullScreen;
		}
	}

	@Mutation
	SET_LOADING_MESSAGE(payload: string | undefined): void {
		this._loadingMessage = _translate(payload || defaultLoadingMessage);
	}

	@Mutation
	SET_LOADING_FULLSCREEN(fullScreen: boolean | undefined): void {
		this._loadingFullScreen = !!fullScreen;
	}

	@Action
	remove(): void {
		this.SET_LOADING(false);
	}
}

export const loadingModule = new LoadingModule({ store, name: 'loading' });

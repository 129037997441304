import {Component, Vue} from 'vue-property-decorator';
import template from './tab.html';

@Component({
	template: template,
	props: {
		name: { required: true },
		isActive: { type: Boolean, default: false },
		tabData: String,
	},
})
export default class Tab extends Vue {
	public name!: string;
	public isActive!: boolean;
	public tabData!: string;

	activeTab: string | undefined = '';

	public mounted(): void {
		this.$bus.$on('tabSelected', (activeTab: string): void => {
			this.activeTab = activeTab;
		});
	}
}

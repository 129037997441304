import Component from 'vue-class-component';
import template from './structure-string-input.html';
import './structure-string-input.scss';
import Vue from 'vue';
import {Prop} from 'vue-property-decorator';
import {StructureTypeDefinition} from 'respresso';

@Component({
	template: template,
	props: {
		name: String,
		definition: Object,
		value: String,
	},
})
export default class StructureStringInput extends Vue {
	@Prop()
	name!: string;
	@Prop()
	definition!: StructureTypeDefinition;
	@Prop()
	value?: string;

	public async inputChanged(event: Event): Promise<void> {
		if (event.target) {
			this.$emit('value-changed', (event.target as HTMLInputElement).value);
		}
	}
}

import Vue from 'vue';
import Component from 'vue-class-component';
import template from './join-project.html';
import './join-project.scss';
import UserService from '../../../services/user-service';
import RespressoApi from '../../../api/respresso-api';
import ErrorHandler from '../../../services/error-handler';
import LoadingScreen from '../../../decorators/loading-screen';
import {TeamMeta} from 'respresso';
import {teamModule} from '../../../store/modules/team/index';
import AccessService from "../../../services/access-service";

export enum Status {
	member,
	notMember,
	hasRequest,
}

export interface ProjectsList {
	projects: ProjectJoinInfo[];
}

export interface ProjectJoinInfo {
	name: string;
	title: string;
	status: Status;
}

export interface JoinProjectResponse {
	projectId: string;
}

@Component({
	template: template,
})
export default class JoinProject extends Vue {
	private joinableProjects: ProjectJoinInfo[] = [];

	get team(): TeamMeta | undefined {
		if (teamModule.team) {
			return teamModule.team;
		}
	}

	@LoadingScreen()
	async created(): Promise<void> {
		return this.onCreated();
	}

	@LoadingScreen()
	async join(project: ProjectJoinInfo): Promise<void> {
		const team = this.team;
		if (!team) {
			return;
		}
		const result = await ErrorHandler.tryRequest(() => RespressoApi.joinProject(team.id, project.name));
		if (result != null && result.projectId != null) {
			project.status = Status.hasRequest;
			await this.onCreated();
		}
	}

	private async onCreated(): Promise<void> {
		const team = this.team;
		if (!team) {
			return;
		}
		const projectsList = await ErrorHandler.tryRequest(() => UserService.getJoinableProjects(team.id));
		if (projectsList) {
			projectsList.projects.sort((a, b) => {
				return a.name.localeCompare(b.name);
			});

			this.joinableProjects = projectsList.projects;
		}
	}

    get isOutsider() {
        return AccessService.isOutsider(this.team);
    }
}

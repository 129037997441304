import Vue from 'vue';
import template from './new-language-dialog.html';
import Component from 'vue-class-component';
import {default as VueSlimSelect, SlimSelectInfo} from '../vue-slim-select/vue-slim-select';
import FlagUtil from '../../../util/flag-util';
import DialogTemplate from '../dialog/dialogs/dialog-template/dialog-template';
import {IDialogComponent} from '@ponte/dialog-vue';
import {IDialog} from '@ponte/dialog';
import {Prop} from 'vue-property-decorator';

export interface NewLanguageDialogConfig {
	allLanguage: string[];
	languages: string[];
	addMethod: (selectedLanguage: string | null) => void;
}

class LangData {
	public langCode: string;
	public langText: string;

	constructor(langCode: string, langText: string) {
		this.langCode = langCode;
		this.langText = langText;
	}
}

@Component({
	components: {
		'dialog-template': DialogTemplate,
	},
	template: template,
})
export default class NewLanguageDialog extends Vue implements IDialogComponent<boolean> {
	@Prop() dialogConfig!: NewLanguageDialogConfig;

	private _selectedLanguage = '';
	dialog!: IDialog<boolean>;

	public mounted(): void {
		this.configure(this.dialogConfig.allLanguage, this.dialogConfig.languages);
	}

	public closeDialog(): void {
		if (this.dialog.dialogController) this.dialog.dialogController.close(true);
	}

	public saveAndClose(): void {
		this.dialogConfig.addMethod(this.selectedLanguage);
		this.closeDialog();
	}

	public configure(languages: string[], existingLanguages: string[]): void {
		if (this.$refs.languageSelector) {
			const langDatas = this.sortedLangs(languages);

			(this.$refs.languageSelector as VueSlimSelect).setData(
				this.getLanguageSelectorData(langDatas, existingLanguages),
			);
		}
	}

	private getLanguageSelectorData(languages: LangData[], existingLanguages: string[]): SlimSelectInfo[] {
		const preferredLanguages = ['en', 'es', 'de'].filter((it) => !existingLanguages.includes(it));
		const defLang = preferredLanguages.length > 0 ? preferredLanguages[0] : undefined;
		if (defLang) {
			this._selectedLanguage = defLang;
		}
		const options: SlimSelectInfo[] = languages.map((lang: LangData) => {
			return {
				text: lang.langText,
				value: lang.langCode,
				selected: lang.langCode === defLang,
				innerHTML: `<div class='language-selector-item'>
							<div>
								<span class='flag-icon flag-${FlagUtil.getFlagByLang(lang.langCode)}'>&#8203;</span>
								<span class='language-selector-item-text'>${lang.langText}</span>
							</div>
					</div>`,
				disabled: existingLanguages.some((existingLanguage) => lang.langCode === existingLanguage),
			};
		});
		options.splice(0, 0, {
			text: '',
			placeholder: true,
		});
		return options;
	}

	private sortedLangs(languages: string[]): LangData[] {
		return languages
			.map((lang) => new LangData(lang, this.$t('language.' + lang) as string))
			.sort((langData0, langData1) => langData0.langText.localeCompare(langData1.langText));
	}

	private languageChanged(info: SlimSelectInfo): void {
		if (info.value) {
			this._selectedLanguage = info.value;
		}
	}

	get selectedLanguage(): string | null {
		if (this._selectedLanguage === '') {
			return null;
		}
		return this._selectedLanguage;
	}
}

import Vue from 'vue';
import template from './color-picker-dialog.html';
import './color-picker-dialog.scss';
import Component from 'vue-class-component';
import {IDialogComponent} from '@ponte/dialog-vue';
import {IDialog} from '@ponte/dialog';
import {Prop} from 'vue-property-decorator';
import DialogTemplate from '../dialog/dialogs/dialog-template/dialog-template';
import {Color, ColorPicker} from './color-picker';
import {ColorKeyData, ColorKeyDataColor} from '../../../../src/components/view/resource/color-resource';

@Component({
	components: {
		'dialog-template': DialogTemplate,
	},
	template: template,
})
export default class ColorPickerDialog extends Vue implements IDialogComponent<Color | null> {
	@Prop() colorKeyData!: ColorKeyData;
	dialog!: IDialog<Color | null>;
	colorPicker!: ColorPicker;

	public closeDialog(): void {
		if (this.dialog.dialogController) this.dialog.dialogController.close(null);
	}

	public saveAndCloseDialog(): void {
		if (this.dialog.dialogController) this.dialog.dialogController.close(this.colorPicker.getColor());
	}

	public mounted(): void {
		const ref = this.$refs.colorPicker as HTMLElement;
		this.colorPicker = new ColorPicker(ref);
		const rgba = {
			r: this.colorKeyData.color.red,
			g: this.colorKeyData.color.green,
			b: this.colorKeyData.color.blue,
			a: this.colorKeyData.color.alpha,
		};
		const color = new Color();
		color.setRGBA(rgba.r, rgba.g, rgba.b, rgba.a);
		const extendedColor = this.convertColorData(this.colorKeyData.color);
		color.setHSV(extendedColor.hue, extendedColor.saturation, extendedColor.value);
		this.$nextTick(() => {
			if (this.colorPicker) {
				this.colorPicker.setColor(color);
			}
		});
	}

	convertColorData(colorKeyDataColor: ColorKeyDataColor): Color {
		const color = new Color();
		color.r = colorKeyDataColor.red / 255;
		color.g = colorKeyDataColor.green / 255;
		color.b = colorKeyDataColor.blue / 255;

		const cmax = Math.max(color.r, color.g, color.b);
		const cmin = Math.min(color.r, color.g, color.b);
		const delta = cmax - cmin;
		let hue = 0;
		let saturation = 0;

		if (delta) {
			if (cmax === color.r) {
				hue = (color.g - color.b) / delta;
			}
			if (cmax === color.g) {
				hue = 2 + (color.b - color.r) / delta;
			}
			if (cmax === color.b) {
				hue = 4 + (color.r - color.g) / delta;
			}
			if (cmax) saturation = delta / cmax;
		}

		color.hue = (60 * hue) | 0;
		if (color.hue < 0) color.hue += 360;
		color.saturation = (saturation * 100) | 0;
		color.value = (cmax * 100) | 0;
		color.setFormat('HSV');
		return color;
	}
}

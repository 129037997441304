import {RespressoError} from './http-service';
import {RespressoErrorHandlerAdapter} from './error-handler';
import {DialogBuilder} from '../components/common/dialog/dialog';
import {translate} from '../main';
import {SubscriptionErrorViewer, SubscriptionErrorViewerConfig,} from '../plugin/subscription-error-viewer/subscription-error-viewer';
import {LimitViolationType} from '../components/view/team-subscriptions/change-project-subscription';

export class SubscriptionErrorHandlerAdapter implements RespressoErrorHandlerAdapter {
	async handle(error: RespressoError<any>): Promise<boolean> {
		if (error.type === 'RespressoProjectSubscriptionException') {
			const teamId = (error.params && error.params['team'])!;
			const projectId = (error.params && error.params['project'])!;
			const actual = Number.parseInt((error.params && error.params['actual']) || '-1');
			const limit = Number.parseInt((error.params && error.params['limit']) || '-1');
			const category = error.params && error.params['category'];
			let type: LimitViolationType = null;
			switch (error.key) {
				case 'license.subscription.project.versionLimit':
					type = 'versionCount';
					break;
				case 'license.subscription.project.memberLimit':
					type = 'memberCount';
					break;
				case 'license.subscription.project.resource.assetCountPerVersionLimit':
					type = 'resCount';
					break;
				case 'license.subscription.project.localizationLanguagesPerVersionLimit':
					type = 'locLangCount';
					break;
			}

			const dialogConfig: SubscriptionErrorViewerConfig = {
				error: error,
				teamId: teamId,
				projectId: projectId,
				actual: actual,
				limit: limit,
				title: translate(`${error.key}.violated.title`, error.params),
				description: translate(`${error.key}.violated.description`, error.params),
				upgradeQueryParams: {
					lvt: type,
					lvv: actual,
					lvc: category,
				},
			};

			DialogBuilder.createVueDialog(SubscriptionErrorViewer, {
				propsData: {
					dialogConfig,
				},
			});

			return true;
		}

		return false;
	}

	order(): number {
		return 1;
	}

	name(): string {
		return 'SubscriptionErrorHandlerAdapter';
	}
}

import Vue from 'vue';
import Component from 'vue-class-component';
import template from './disclaimer-dialog.html';
import './disclaimer.scss';
import DialogTemplate from '../dialog/dialogs/dialog-template/dialog-template';
import {IDialogComponent} from '@ponte/dialog-vue';
import {IDialog} from '@ponte/dialog';

@Component({
	components: {
		'dialog-template': DialogTemplate,
	},
	template: template,
})
export class DisclaimerDialog extends Vue implements IDialogComponent<boolean> {
	dialog!: IDialog<boolean>;

	public closeDialog(): void {
		if (this.dialog.dialogController) this.dialog.dialogController.close(true);
	}
}

import RespressoApi from '../api/respresso-api';
import {teamModule} from '../store/modules/team/index';
import ErrorHandler from '../services/error-handler';

const cache: { [teamId: string]: { [projectId: string]: string } } = {};

export default function (input: string): string {
	const team = teamModule.team?.id;
	if (!team) return input;
	if (cache[team] && cache[team][input]) {
		return cache[team][input];
	}

	const projects = teamModule.team!.projects;

	if (projects) {
		for (let i = 0; i < projects.length; i++) {
			const project = projects[i];
			if (project.id === input) {
				if (!cache[team]) {
					cache[team] = {};
				}
				const teamCache = cache[team];
				teamCache[input] = project.title;
				return project.title;
			}
		}
	}

	ErrorHandler.tryRequest(() => RespressoApi.getProject(team, input))
		.then((project) => {
			if (project) {
				cache[team][input] = project.title;
			} else {
				cache[team][input] = input;
			}
		})
		.catch((error) => {
			console.error(error);
		});
	return input;
}

import Vue from 'vue';
import Component from 'vue-class-component';
import template from './team-settings.html';
import './team-settings.scss';
import {Resource} from '../resource/resource';
import {NextFunction, Route} from 'vue-router';
import RespressoApi from '../../../api/respresso-api';
import {TeamMeta, UserPublicInfo} from 'respresso';
import TagEditor from '../../common/vue-slim-select/tag-editor';
import {DialogBuilder, NotificationBuilder} from '../../common/dialog/dialog';
import ErrorHandler from '../../../services/error-handler';
import UserService from '../../../services/user-service';
import LoadingScreen from '../../../decorators/loading-screen';
import {app} from '../../../main';
import StorageService from '../../../services/storage-service';
import {teamModule} from '../../../store/modules/team/index';
import AccessService from '../../../services/access-service';

export interface TeamModificationMeta {
    title: string;
    domains: string[];
    newInvites: string[];
}

@Component({
    components: {
        'tag-editor': TagEditor,
    },
    template: template,
})
export default class TeamSettings extends Vue {
    private teamId = '';
    private newInvites: string[] = [];
    private users: UserPublicInfo[] = [];
    private team: TeamMeta = {
        id: '',
        title: '',
    } as TeamMeta;

    beforeRouteEnter(to: Route, from: Route, next: NextFunction): void {
        next((vm) => {
            const res = vm as TeamSettings;
            res.teamId = to.params.teamId;
            res.loadTeam();
        });
    }

    protected async doSave(): Promise<void> {
        return this.doSaveInternal();
    }

    @LoadingScreen()
    protected async doSaveInternal(): Promise<void> {
        const saveData: TeamModificationMeta = {
            title: this.team.title,
            domains: this.team.domains,
            newInvites: this.newInvites,
            users: this.users.map((user) => user.id),
        } as TeamModificationMeta;

        const result = await ErrorHandler.tryRequest(() => RespressoApi.saveTeam(this.teamId, saveData));
        if (result) {
            (this.$refs.tagEditor as TagEditor).clear();
            await this.loadTeam();
            (this.$refs.tagEditor as TagEditor).allTagsChanged();
            this.newInvites = [];
            NotificationBuilder.success('#messages.saved');
        }
        this.$forceUpdate();
    }

    @LoadingScreen({ showImmediately: true })
    private async loadTeam<T extends Resource>(): Promise<void> {
        const team = await UserService.loadTeam(this.teamId);
        if (team) {
            this.team = Object.assign({}, team);
            if (this.team.domains) {
                this.team.domains = this.team.domains.filter((d: any) => {
                    return !!d;
                });
            }
            if (this.team.users) {
                const users = [...this.team.users];
                users.sort((a, b) => a.name.localeCompare(b.name));
                this.users = users;
            }
        }
    }

    @LoadingScreen({ showImmediately: true })
    protected async deleteTeam(): Promise<void> {
        DialogBuilder.confirm('#team.settings.members.delete.dialog', '#dialog.areYouSure.simple', async () => {
            if (!this.teamId) {
                return;
            }
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const response = await ErrorHandler.tryRequest(() => RespressoApi.deleteTeam(this.teamId!));
            if (response) {
                await Promise.all([teamModule.REMOVE_TEAM(), StorageService.removeTeam()]);
                await UserService.refreshData();

                NotificationBuilder.success('#success.team.delete');
                app.$router.push({ name: 'login' });
            }
        });
    }

    @LoadingScreen({ showImmediately: true })
    protected async leaveTeam(): Promise<void> {
        DialogBuilder.confirm('#team.settings.members.leave.dialog', '#dialog.areYouSure.simple', async () => {
            if (!this.teamId) {
                return;
            }
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const response = await ErrorHandler.tryRequest(() => RespressoApi.leaveTeam(this.teamId!));
            if (response) {
                await Promise.all([teamModule.REMOVE_TEAM(), StorageService.removeTeam()]);
                await UserService.refreshData();
                NotificationBuilder.success('#success.team.leave');
                app.$router.push({ name: 'login' });
            }
        });
    }

    public tagDomain(domain: string): void {
        this.team.domains.push(domain);
    }

    public removeDomain(domain: string): void {
        const index = this.team.domains.indexOf(domain);
        if (index !== -1) {
            this.team.domains.splice(index, 1);
        }
    }

    protected isCurrentUser(userId: string): boolean {
        const currentUser = UserService.getUser();
        if (currentUser && currentUser.id === userId) {
            return true;
        } else {
            return false;
        }
    }

    public get storeTeam(): TeamMeta | undefined {
        if (teamModule.team) {
            return teamModule.team;
        }
    }

    get canEditTeamDetails() {
        return AccessService.canEditTeamDetails(this.team);
    }

    get canEditTeamPermissions() {
        return AccessService.canEditTeamPermissions(this.team);
    }

    get canEditTeamSubscriptions() {
        return AccessService.canEditTeamSubscriptions(this.team);
    }

    isTeamOwner() {
        return AccessService.hasTeamRole('OWNER', this.team);
    }

    isTeamAdmin() {
        return AccessService.hasTeamRole('ADMIN', this.team);
    }
}

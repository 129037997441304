import Component from 'vue-class-component';
import template from './raw-resource.html';
import './raw-resource.scss';
import {Resource, ResourceItem} from './resource';
import RespressoApi from '../../../api/respresso-api';
import ErrorHandler from '../../../services/error-handler';
import {NotificationBuilder} from '../../common/dialog/dialog';
import CodeMirror from '../../../plugin/codemirror/code-mirror-wrapper';
import {default as VueSlimSelect, SlimSelectInfo} from '../../common/vue-slim-select/vue-slim-select';
import {EditorConfiguration, EditorFromTextArea, ModeSpec, ModeSpecOptions} from 'codemirror';

export interface RawDataResponse {
	versionNumber: string;
	versionEditable: boolean;
	rawData: RawData;
}

export interface SaveRequest {
	id: string | null;
	content: string | null;
	type: string;
}

export interface RawData extends ResourceItem {
	content: string;
	type: string;
}

@Component({
	template: template,
})
export default class RawResource extends Resource {
	private editor: CodeMirror.Editor | null = null;

	private data: RawData | null = null;

	protected modified = false;
	protected firstValueSet = true;

	protected getSupportedLangs(): string[] {
		return ['json', 'xml', 'yaml'];
	}

	protected beforeDestroy(): void {
		if (this.editor) {
			this.editor.setValue('');
			(this.editor as EditorFromTextArea).toTextArea();
			this.data = null;
			this.modified = false;
		}
	}

	protected async loadData<ColorResource>(): Promise<void> {
		await this.updateData();
	}

	protected async updateData(): Promise<void> {
		const resp = await ErrorHandler.tryRequest(
			() => RespressoApi.getRawVersion(this.teamId, this.projectId, this.version),
			{
				loadingScreen: true,
			},
		);

		if (resp) {
			this.editable = resp.versionEditable;
			this.setItemFromServer(resp.rawData);
		}
	}

	protected async doSave(): Promise<boolean> {
		const saveReq: SaveRequest = {
			id: this.data && this.data.id ? this.data.id : null,
			content: this.editor ? this.editor.getValue() : null,
			type: this.data ? this.data.type : 'json',
		};

		const saveResp = await ErrorHandler.tryRequest(
			() => RespressoApi.saveRawVersion(this.teamId, this.projectId, this.version, saveReq),
			{
				loadingScreen: true,
				loadingMessage: '#loading.processingColors',
			},
		);
		if (saveResp) {
			this.setItemFromServer(saveResp);
			this.modified = false;
			NotificationBuilder.success('#messages.saved');
			return true;
		} else {
			return false;
		}
	}

	protected getResourceId(): string {
		return 'raw';
	}

	protected isModified(): boolean {
		return this.modified;
	}

	protected languageChanged(selectInfo: SlimSelectInfo): void {
		if (this.data && selectInfo.value) {
			this.data.type = selectInfo.value;
			this.data.content = this.editor ? this.editor.getValue() : '';
			this.setItemFromServer(this.data);
		}
	}

	private setItemFromServer(data: RawData): void {
		if (!data.type) {
			data.type = this.getSupportedLangs()[0];
		}

		this.data = data;

		if (this.editor) {
			this.editor.setOption('mode', this.convertCodeMirrorMode(this.data.type));
		} else {
			this.editor = CodeMirror.fromTextArea(
				this.$refs.editorElement as HTMLTextAreaElement,
				{
					lineNumbers: true,
					autoCloseBrackets: true,
					autoCloseTags: true,
					mode: this.convertCodeMirrorMode(this.data.type),
					readOnly: !this.editable || !this.canModifyResource ? 'nocursor' : false,
				} as EditorConfiguration,
			);

			this.editor.on('changes', () => {
				if (this.firstValueSet) {
					this.firstValueSet = false;
				} else {
					this.modified = true;
				}
			});
		}

		this.firstValueSet = true;
		this.editor.setValue(this.data.content || '');
		this.editor.getDoc().clearHistory();

		if (this.$refs.languageSelector) {
			const languageSelector = this.$refs.languageSelector as VueSlimSelect;
			languageSelector.setData(this.getLanguageSelectorData(this.getSupportedLangs(), data.type));
			if (this.editable && this.canModifyRestrictedResourceData) {
				languageSelector.enable();
			} else {
				languageSelector.disable();
			}
		}
	}

	private convertCodeMirrorMode(type: string): string | ModeSpec<ModeSpecOptions> {
		if (type === 'json') {
			return { name: 'javascript', json: true };
		}

		return type;
	}

	private getLanguageSelectorData(languages: string[], selectedLanguage: string): SlimSelectInfo[] {
		return languages.map((lang) => {
			return {
				text: this.$t('code.language.' + lang) as string,
				value: lang,
				innerHTML: `<div class='language-selector-item'><span class='language-selector-item-text'>${this.$t(
					'code.language.' + lang,
				)}</span></div>`,
				selected: lang === selectedLanguage,
			};
		});
	}
}

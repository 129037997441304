import Vue from 'vue';
import Component from 'vue-class-component';
import template from './login.html';
import './login.scss';
import UserService from '../../../services/user-service';
import {DialogBuilder, NotificationBuilder} from '../../common/dialog/dialog';
import ErrorHandler from '../../../services/error-handler';
import {translate} from '../../../main';
import {userModule} from '../../../store/modules/user/index';
import {User} from 'respresso';
import {DisclaimerDialog} from '../../../../src/components/common/disclaimer/disclaimer-dialog';

@Component({
	template: template,
})
export default class Login extends Vue {
	private email = '';
	private password = '';
	private errors: {
		[field: string]: string;
	} = {};
	private invited = false;

	private async mounted(): Promise<void> {
		const loggedIn = await UserService.isLoggedInAsync();
		if (loggedIn) {
			this.doRedirect();
		} else if (this.$route.query.redirect) {
			const redirect = this.$route.query.redirect as string;
			const resolvedRedirect = this.$router.resolve(redirect);
			if (resolvedRedirect && resolvedRedirect.route.query.inviteToken) {
				this.invited = true;
			}
		}
	}

	private async login(): Promise<void> {
		if (this.validate()) {
			const user = await ErrorHandler.tryRequest(() => UserService.login(this.email, this.password), {
				respressoErrorHandler: async (error) => {
					if (error.type === 'AuthenticationException') {
						NotificationBuilder.error(error.key || '#serverError.unknownError');
						return true;
					}
					return false;
				},
				loadingScreen: true,
				loadingFullScreen: true,
			});
			if (user) {
				this.email = '';
				this.password = '';
				this.doRedirect();

				NotificationBuilder.success('#login.success');
			}
		}
	}

	private validate(): boolean {
		let valid = true;
		this.errors = {};

		if (this.email === null || this.email.trim().length === 0) {
			this.errors['emailerror'] = translate('login.missing.email');
			valid = false;
		}
		if (this.password === null || this.password.trim().length === 0) {
			this.errors['passworderror'] = translate('login.missing.password');
			valid = false;
		}
		return valid;
	}

	get user(): User | undefined {
		const user = userModule.user;
		if (user) {
			return user;
		}
	}

	private register(): void {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const query: any = {};
		if (this.$route.query.redirect) {
			const redirect = this.$route.query.redirect as string;
			const resolvedRedirect = this.$router.resolve(redirect);
			if (resolvedRedirect && resolvedRedirect.route.query.inviteToken) {
				query.inviteToken = resolvedRedirect.route.query.inviteToken;
			}
		}
		this.$router.push({ name: 'userInfo', query });
	}

	private doRedirect(): void {
		const redirect = (this.$route.query.redirect as string) || '/';
		this.$router.push(redirect);
	}

	private showPolicy(): void {
		DialogBuilder.createVueDialog(DisclaimerDialog);
	}
}

/* eslint-disable */
import {default as RespressoApi} from '../api/respresso-api';

let recaptchaKey: any;
let recaptchaScript: any;

export interface IRecaptchaProxy {
    result(): Promise<string>;

    reset(): void;
}

export class RecaptchaUtil {
    private static apiLoadPromise: Promise<any> | undefined = undefined;

    static getRecaptchaModule() {
        if (!RecaptchaUtil.apiLoadPromise) {
            RecaptchaUtil.apiLoadPromise = new Promise((resolve, reject) => {
                const scriptElement = document.createElement('script');
                scriptElement.src = 'https://www.google.com/recaptcha/api.js?onload=recaptchaOnLoad&render=explicit';
                (window as any).recaptchaOnLoad = () => {
                    resolve((window as any).grecaptcha);
                };
                scriptElement.onerror = err => {
                    reject(err);
                };
                document.querySelector('head')!.appendChild(scriptElement);
            })
                .then(recaptcha => {
                    recaptchaScript = recaptcha;

                    return RespressoApi.loadRecaptchaKey();
                })
                .then(key => {
                    recaptchaKey = key;
                });
        }
        return RecaptchaUtil.apiLoadPromise!;
    }

    static async hasRecaptchaSupport(): Promise<boolean> {
        await this.getRecaptchaModule();
        return recaptchaKey !== '' || recaptchaKey == undefined;
    }

    static async render(element: Element, config?: { [key: string]: string }): Promise<IRecaptchaProxy> {
        await this.getRecaptchaModule();

        if (config == undefined) {
            config = {};
        }
        config.sitekey = recaptchaKey;
        config.size = "invisible";
        const captchaId = recaptchaScript.render(element, config);
        return {
            result(): Promise<string> {
                return recaptchaScript.execute(captchaId).then((result: any) => {
                    console.log(result);
                    return recaptchaScript.getResponse(captchaId);
                });
            },
            reset(): void {
                return recaptchaScript.reset(captchaId);
            },
        };
    }
}

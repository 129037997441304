/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import Component from 'vue-class-component';
import template from './vue-upload-list.html';
import './vue-upload-list.scss';
import {IUpload, IUploadState, ModernUpload, UploadResultType, UploadState} from '@ponte/file-upload-js';

export interface UploadBlob {
	blob: Blob;
	name: string;
}
const ignoreEvent: (param: any) => void = () => {
	// Ignore
};

export class UploadListItem {
	private uploadResultProm: Promise<any>;
	private resolve: (value: any) => void = ignoreEvent;
	private reject: (err: any) => void = ignoreEvent;
	private uploadState: IUploadState;
	private fileName: string;

	private upload: IUpload;
	private fileSize: number;

	constructor(file: File | UploadBlob, userInfo: any, baseUrl: string) {
		this.fileName = file.name;
		if ('size' in file) {
			this.fileSize = file.size;
		} else if ('blob' in file) {
			this.fileSize = file.blob.size;
		} else {
			this.fileSize = -1;
		}
		this.upload = new ModernUpload({
			file,
			userInfo,
			uploadIdURL: baseUrl + '/getid',
			uploadURL: baseUrl + '/upload',
			progressQueryURL: baseUrl + '/getstatus',
			headers: {
				'X-Requested-With': 'Respresso',
			},
		});
		this.uploadState = this.upload.uploadState;
		this.upload.addUploadStateChangeListener(() => {
			this.uploadState = this.upload.uploadState;
		});
		this.uploadResultProm = new Promise<any>((resolve, reject) => {
			this.resolve = resolve;
			this.reject = reject;
		});
	}

	get uploadSize(): number {
		return this.fileSize;
	}

	get uploadResult(): Promise<any> {
		return this.uploadResultProm;
	}

	async startUpload(): Promise<void> {
		try {
			const resp = await this.upload.startUpload();
			this.resolve(resp);
		} catch (err) {
			this.reject(err);
		}
	}

	abort(): void {
		this.reject(this.upload.abortUpload());
	}

	get uploadPercent(): string {
		if (!this.uploadState || !this.uploadState.progress) {
			return '0%';
		}
		return Math.round(this.uploadState.progress * 10000) / 100 + '%';
	}

	public isFinished(): boolean | undefined {
		return this.uploadState.state === UploadState.finished;
	}

	public isSuccessful(): boolean | undefined {
		return (
			this.isFinished() && this.uploadState.result && this.uploadState.result.type === UploadResultType.success
		);
	}
}

@Component({
	props: {
		baseUrl: String,
	},
	computed: {
		maxConcurrent: Number,
	},
	template: template,
})
export class VueUploadList extends Vue {
	private baseUrl: string | undefined;
	private maxConcurrent: number | undefined;

	private waitingTaskList: UploadListItem[] = [];
	private runningTaskList: UploadListItem[] = [];
	public tasks: UploadListItem[] = [];

	mounted(): void {
		if (this.maxConcurrent === undefined) {
			this.maxConcurrent = 1;
		}
	}

	destroyed(): void {
		this.cancelAllUploads();
		this.tasks = [];
		this.waitingTaskList = [];
		this.runningTaskList = [];
	}

	async uploadFile(file: File, userInfo: any): Promise<void> {
		const task = new UploadListItem(file, userInfo, this.baseUrl || '');
		this.addTask(task);
		try {
			return await task.uploadResult;
		} finally {
			this.finishedTask(task);
		}
	}

	cancelAllUploads(): void {
		this.tasks.forEach((task) => task.abort());
	}

	public isUploading(): boolean {
		return this.runningTaskList.length > 0 || this.waitingTaskList.length > 0;
	}

	private finishedTask(task: UploadListItem): void {
		const waitingIndex = this.waitingTaskList.indexOf(task);
		if (waitingIndex !== -1) {
			this.waitingTaskList.splice(waitingIndex, 1);
		}
		const runningIndex = this.runningTaskList.indexOf(task);
		if (runningIndex !== -1) {
			this.runningTaskList.splice(runningIndex, 1);
		}
		this.checkForAvailableExecutor();
	}

	private removeTask(task: UploadListItem): void {
		const index = this.tasks.indexOf(task);
		if (index !== -1) {
			this.tasks.splice(index, 1);
		}
	}

	private addTask(task: UploadListItem): void {
		this.tasks.push(task);
		this.waitingTaskList.push(task);
		this.checkForAvailableExecutor();
	}

	private checkForAvailableExecutor(): void {
		if (this.runningTaskList.length >= this.maxConcurrent!) return; //No available executor
		const nextTask = this.waitingTaskList.shift();
		if (nextTask === undefined) return; //No task left

		this.runningTaskList.push(nextTask);
		nextTask.startUpload();
	}

	public async getUploadedFiles(): Promise<any[]> {
		return await Promise.all(this.tasks.filter((task) => task.isSuccessful()).map((task) => task.uploadResult));
	}
}

import moment from 'moment';

export class TimeServiceImpl {
	public fromNow(dateNumber: number): void | string {
		if (!dateNumber) {
			return '';
		}

		return moment(dateNumber).fromNow();
	}
}

const TimeService = new TimeServiceImpl();
export default TimeService;

import Vue from 'vue';
import Component from 'vue-class-component';
import template from './whats-new.html';
import './whats-new.scss';
import ErrorHandler from '../../services/error-handler';
import RespressoApi from '../../api/respresso-api';
import {menuModule} from '../../store/modules/menu/index';

export interface News {
	title: string;
	date?: number;
	content?: string;
}

@Component({
	template: template,
})
export default class WhatsNew extends Vue {
	private search = '';
	private newsList: News[] = [];

	async mounted(): Promise<void> {
		const lastNews = await ErrorHandler.tryRequest(() => RespressoApi.getNews());
		if (lastNews) {
			lastNews.sort((a, b) => {
				if ((!a.date && !b.date) || a.date === b.date) {
					return 0;
				} else if (!a.date || (b.date != undefined && a.date > b.date)) {
					return -1;
				} else {
					return 1;
				}
			});

			this.newsList = lastNews;
		}
	}

	private hideWhatsNew(): void {
		menuModule.HIDE_WHATSNEW(true);
	}

	public isFiltered(news: News): boolean {
		if (!this.search) {
			return true;
		}
		//TODO "not implemented yet" type of function
		return true;
	}
}

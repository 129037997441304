export interface Logger {
	trace(message?: unknown, ...optionalParams: unknown[]): void;

	debug(message?: unknown, ...optionalParams: unknown[]): void;

	info(message?: unknown, ...optionalParams: unknown[]): void;

	warn(message?: unknown, ...optionalParams: unknown[]): void;

	error(message?: unknown, ...optionalParams: unknown[]): void;
}

export class ConsoleLogger implements Logger {
	trace(message?: unknown, ...optionalParams: unknown[]): void {
		//tslint:disable:no-console
		console.trace(message, ...optionalParams);
	}

	debug(message?: unknown, ...optionalParams: unknown[]): void {
		//tslint:disable:no-console
		console.debug(message, ...optionalParams);
	}

	info(message?: unknown, ...optionalParams: unknown[]): void {
		//tslint:disable:no-console
		console.info(message, ...optionalParams);
	}

	warn(message?: unknown, ...optionalParams: unknown[]): void {
		//tslint:disable:no-console
		console.warn(message, ...optionalParams);
	}

	error(message?: unknown, ...optionalParams: unknown[]): void {
		//tslint:disable:no-console
		console.error(message, ...optionalParams);
	}
}

export class ServerLogger extends ConsoleLogger {
	error(message?: unknown, ...optionalParams: unknown[]): void {
		super.error(message, ...optionalParams);
		const serializedMessages = JSON.stringify(
			[message, ...optionalParams].map((m) => JSON.stringify(m, this.serializerReplacer)),
		);

		this.info('Serialized messages: ' + serializedMessages); //TODO send to server instead
	}

	private serializerReplacer(key: string, value: unknown) {
		if (value instanceof Error) {
			if (value.stack) {
				return value.stack;
			}
			return value.message;
		}

		if (value instanceof ErrorEvent) {
			if (value.error) {
				if (value.error.stack) {
					return value.error.stack;
				}
				return value.error.message;
			}
			return value.message;
		}

		return value;
	}
}

let _logger: Logger;

export class Logger {
	public static getLogger(): Logger {
		if (!_logger) {
			_logger = new ServerLogger();
		}
		return _logger;
	}

	public static trace(message?: unknown, ...optionalParams: unknown[]): void {
		Logger.getLogger().trace(message, ...optionalParams);
	}

	public static debug(message?: unknown, ...optionalParams: unknown[]): void {
		Logger.getLogger().debug(message, ...optionalParams);
	}

	public static info(message?: unknown, ...optionalParams: unknown[]): void {
		Logger.getLogger().info(message, ...optionalParams);
	}

	public static warn(message?: unknown, ...optionalParams: unknown[]): void {
		Logger.getLogger().warn(message, ...optionalParams);
	}

	public static error(message?: unknown, ...optionalParams: unknown[]): void {
		Logger.getLogger().error(message, ...optionalParams);
	}
}

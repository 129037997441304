import Vue from 'vue';
import {NextFunction, Route} from 'vue-router';
import {DialogBuilder, NotificationBuilder} from '../../common/dialog/dialog';
import ResourceList from '../../common/resource-list/resource-list';
import Component from 'vue-class-component';
import {ProjectMeta, TeamMeta} from 'respresso';
import {teamModule} from '../../../store/modules/team/index';
import {userModule} from '../../../store/modules/user/index';
import {TutorialStatus} from '../../../services/user-service';
import DownloadResourceDialog from '../../common/download/download-resource-dialog';
import AccessService from '../../../services/access-service';
import Shepherd from 'shepherd.js';
import VueI18n from 'vue-i18n';
import StorageService from '../../../services/storage-service';
import Values = VueI18n.Values;

export enum ItemState {
	NEW = 'new',
	UNCHANGED = 'unchanged',
	UPDATED = 'updated',
	DELETED = 'deleted',
}

export interface ResourceItem {
	id?: string;
	listId?: number;
	state: ItemState;
	tags?: string[];
	now?: boolean;
	lastOpened?: boolean;
}

const TOUR_STEP_IMPORT = 'import';
const TOUR_STEP_UPLOAD = 'upload';
const TOUR_STEP_ADD = 'add';
const TOUR_STEP_SAVE = 'save';
const TOUR_STEP_DOWNLOAD = 'download';
const TOUR_STATUS_COMPLETED = 'completed';
const TOUR_STATUS_CANCELLED = 'cancelled';
const TOUR_TYPE = 'resource';

@Component
export class Resource extends Vue {
	protected items: ResourceItem[] = [];
	protected newItems: ResourceItem[] = [];
	protected editable = false;

	protected teamId = '';
	protected projectId = '';
	protected resourceId = '';
	protected version = '';

	protected itemsLoading = false;
	protected leaveConfirmed = false;

	private tagList: string[] = [];
	private allTags: string[] = [];

	protected lastListId = 0;

	private keyDownListener?: (event: KeyboardEvent) => void;
	private unloadListener?: (event: Event) => void;

	private loadState: 'empty' | 'loading' | 'loaded' | 'needs_reload' = 'empty';

	get isLoaded(): boolean {
		return !!this.teamId && !!this.projectId && !!this.resourceId && !!this.version;
	}

	get team(): TeamMeta | null {
		return teamModule.team;
	}

	get project(): ProjectMeta | null {
		const moduleGetterTeam = teamModule.team;
		if (!moduleGetterTeam || !moduleGetterTeam.projects) {
			return null;
		}
		const project = moduleGetterTeam.projects.filter((p: ProjectMeta) => {
			return p.id === this.projectId;
		});
		if (project.length) {
			return project[0];
		} else {
			return null;
		}
	}

	/* Routing */
	protected mounted(): void {
		if (['needs_reload', 'empty'].includes(this.loadState)) {
			this.load(this, this.$route, undefined, true);
		}
		this.keyDownListener = (event: KeyboardEvent): void => {
			if ((event.ctrlKey || event.metaKey) && event.key === 's') {
				event.preventDefault();
				this.saveRequestedByUser();
			}
			if (event.keyCode === 27 || event.key === 'Escape') {
				if (this.onEscapePressed()) {
					if(typeof event.preventDefault != "undefined") {
						event.preventDefault();
					}
				}
			}
			if (event.keyCode === 13 || event.key === 'Enter') {
				if (this.onEnterPressed()) {
					event.preventDefault();
				}
			}
		};
		window.addEventListener('keydown', this.keyDownListener);

		this.unloadListener = (event): void => {
			if (this.isModified()) {
				event.preventDefault();
				event.returnValue = false;
			}
		};

		window.addEventListener('beforeunload', this.unloadListener);
	}

	protected onEscapePressed(): boolean {
		return false;
	}

	protected onEnterPressed(): boolean {
		return false;
	}

	protected saveRequestedByUser() {
		if (this.editable && this.isModified()) {
			return this.save();
		}
		return Promise.reject();
	}

	private unmounted(): void {
		if (this.keyDownListener) {
			window.removeEventListener('keydown', this.keyDownListener);
		}
		if (this.unloadListener) {
			window.removeEventListener('beforeunload', this.unloadListener);
		}
	}

	beforeRouteEnter(to: Route, from: Route, next: NextFunction): void {
		next((vm) => {
			const resource = vm as Resource;
			resource.load(resource, to, from, false);
		});
	}

	async beforeRouteUpdate(to: Route, from: Route, next: NextFunction): Promise<void> {
		const canLeave = await this.canLeave();
		if (!canLeave) {
			next(false);
			return;
		}
		await this.beforeLeave();
		this.load(this, to, from, true);
		next();
	}

	async beforeRouteLeave(to: Route, from: Route, next: NextFunction): Promise<void> {
		this.loadState = 'needs_reload';
		const canLeave = await this.canLeave();
		if (!canLeave) {
			next(false);
			return;
		}
		await this.beforeLeave();
		next();
	}

	protected beforeLeave(): Promise<void> {
		return Promise.resolve();
	}

	protected canLeave(): Promise<boolean> {
		return new Promise<boolean>((resolve): void => {
			if (this.leaveBecauseError()) {
				resolve(true);
			} else if (this.leaveAlreadyConfirmed()) {
				resolve(true);
			} else if (this.isModified()) {
				DialogBuilder.yesNoCancel(
					'#dialog.save.title',
					'#dialog.save.message',
					async () => {
						// YES
						const result = await this.save();
						if (result == undefined) {
							resolve(false);
						}
						this.leaveConfirmed = true;
						resolve(true);
					},
					() => {
						// NO
						if (this.newItems.length > 0) {
							this.resetNewItems();
						}
						this.leaveConfirmed = true;
						resolve(true);
					},
					() => {
						// CANCEL
						if (this.resourceId === 'localization') {
							this.$emit('reset-new-items');
							this.$emit('adding-disabled-rows');
						}
						resolve(false);
					},
				);
			} else {
				this.leaveConfirmed = true;
				resolve(true);
			}
		});
	}

	private leaveBecauseError(): boolean {
		const team = teamModule.team;
		if (!team) {
			return true;
		}
		const project = teamModule.project;
		if (!project) {
			return true;
		}
		const projectInTeam = team.projects && team.projects.find((proj) => proj.id === project.id) !== undefined;
		return !projectInTeam;
	}

	private leaveAlreadyConfirmed(): boolean {
		return this.leaveConfirmed;
	}

	/* Loading */
	private async load<T extends Resource>(resource: T, to: Route, from?: Route, update?: boolean): Promise<void> {
		if (this.loadState === 'loading') {
			return;
		}
		this.loadState = 'loading';
		this.itemsLoading = true;
		resource.$startLoading();

		resource.teamId = to.params.teamId;
		resource.projectId = to.params.projectId;
		resource.resourceId = resource.getResourceId();
		resource.version = to.params.versionNumber;

		await resource.loadData(resource, to, from, update);

		if (resource.items) {
			resource.items.forEach((item) => {
				if (item.tags) {
					resource.tagList.push(...item.tags);
				}
				item.listId = resource.lastListId++;
			});
		}

		resource.allTags.push(...Array.from(new Set(resource.tagList)));

		resource.$finishLoading();
		this.itemsLoading = false;
		this.loadState = 'loaded';
		this.onLoaded();
	}

	protected onLoaded() {
		// Ignore
	}

	// eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
	protected async loadData<T extends Resource>(
		resource: T,
		to: Route,
		from?: Route,
		update?: boolean,
	): Promise<void> {
		// Override in descendant
	}

	/* Saving */

	//TODO change if server needs to know deleted state
	protected async save(): Promise<boolean> {
		const success = await this.doSave();
		if (success) {
			this.removeDeletedItems();
			this.makeAllItemsUnchanged();
			this.resetNewItems();
		}
		return success;
	}

	protected async doSave(): Promise<boolean> {
		return Promise.resolve(true);
	}

	private removeDeletedItems(): void {
		this.items = this.items.filter((item) => item.state !== ItemState.DELETED);
	}

	protected resetNewItems(): void {
		const resourceList: ResourceList = this.$refs.resourceList as ResourceList;
		if (resourceList && resourceList.numberOfNewItems) {
			resourceList.numberOfNewItems = 0;
		}
		this.newItems = [];
	}

	protected updateTutorialStatus(tutorialStatus: TutorialStatus): void {
		userModule.setTutorialStatus(tutorialStatus);
		if (userModule.tutorialStatus !== 'SYNC') {
			this.updateTutorialStatus(tutorialStatus);
		}
	}

	private makeAllItemsUnchanged(): void {
		this.items.forEach((item) => (item.state = ItemState.UNCHANGED));
	}

	// FIXME should be abstract
	protected getResourceId(): string {
		return 'error';
	}

	/* New items */
	protected newItem(): void {
		const item = this.makeNewItem();
		this.newItems.push(item);
	}

	/* New items */
	protected newItemToItems(defaults?: Partial<ResourceItem>): ResourceItem {
		const item = this.makeNewItem(defaults);

		this.items.splice(0, 0, item);
		// Logger.info(`New item added to items ${JSON.stringify(item)}`)
		return item;
	}

	protected makeNewItem(defaults?: Partial<ResourceItem>): ResourceItem {
		const newItem: ResourceItem = Object.assign(defaults || {}, {
			state: ItemState.NEW,
			listId: this.lastListId++,
		});
		const resourceList: ResourceList = this.$refs.resourceList as ResourceList;
		if (resourceList) {
			const filterTags = resourceList.getFilterTags();
			if (filterTags) {
				newItem.tags = new Array(...filterTags);
			}
		}
		return newItem;
	}

	/* Modified items */
	protected itemChanged(item: ResourceItem): void {
		if (item.state === ItemState.UNCHANGED) {
			item.state = ItemState.UPDATED;
		}
	}

	protected isModified(): boolean {
		return this.items.some((item) => item.state !== ItemState.UNCHANGED) || this.newItems.length > 0;
	}

	protected tagAdded(tag: string): void {
		this.tagList.push(tag);
		const allTagsIndex = this.allTags.indexOf(tag);
		if (allTagsIndex === -1) {
			this.allTags.push(tag);
		}
	}

	protected tagRemoved(tag: string): void {
		let index = this.tagList.indexOf(tag);
		if (index !== -1) {
			this.tagList.splice(index, 1);
			index = this.tagList.indexOf(tag);
			if (index === -1) {
				const allTagsIndex = this.allTags.indexOf(tag);
				if (allTagsIndex !== -1) {
					this.allTags.splice(allTagsIndex, 1);
				}
			}
		}
	}

	/* Delete items */
	protected deleteItem(item: ResourceItem): void {
		if (item.state === ItemState.NEW) {
			const index = this.items.indexOf(item);
			if (index > -1) {
				this.items.splice(index, 1);
			}
		} else {
			item.state = ItemState.DELETED;
		}
	}

	protected deleteNewItem(item: ResourceItem): void {
		const index = this.newItems.indexOf(item);
		if (index > -1) {
			this.newItems.splice(index, 1);
		}
	}

	private undeleteItem(item: ResourceItem): void {
		item.state = ItemState.UPDATED;
	}

	/* Download */
	public openDownloadResourceDialog(item: ResourceItem): void {
		if (item.state === ItemState.UNCHANGED) {
			const downloadCount = StorageService.getDownloadCount();
			StorageService.setDownloadCount(downloadCount + 1);
			const url = `/api/ui/download/resource/${this.teamId}/${this.projectId}/${this.resourceId}/${this.version}/${item.id}`;
			this.startDownload(url, 'resource');
		} else {
			NotificationBuilder.error('#messages.error_download_modified_resource');
		}
	}

	public getVersionDownloadUrl(): string {
		return `/api/ui/download/converted/${this.teamId}/${this.projectId}/${this.resourceId}/${this.version}`;
	}

	public openDownloadVersionDialog(): void {
		const url = this.getVersionDownloadUrl();
		this.startDownload(url, 'version');
	}

	startDownload(url: string, type: 'resource' | 'version' = 'version') {
		const a = document.createElement('a');
		a.setAttribute('href', url);
		a.download = 'respresso.zip';
		a.style.display = 'none';
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		this.resumeTour('download');

		const downloadCount = StorageService.getDownloadCount();
		StorageService.setDownloadCount(downloadCount + 1);
		// Every 5th time it opens the dialog, every other time it sends only a notification
		if (downloadCount % 5 === 4) {
			DialogBuilder.createVueDialog(DownloadResourceDialog, {
				propsData: {
					opened: '',
					teamId: this.teamId,
					projectId: this.projectId,
					resourceId: this.resourceId,
					version: this.version,
					tutorialStatus: 'INTEGRATION',
					downloadUrl: url,
				},
			});
		} else {
			switch (type) {
				case 'version':
					NotificationBuilder.success(this.$t('resource.download.version.converted.started') as string);
					break;
				case 'resource':
					NotificationBuilder.success(
						this.$t('resource.download.singleResource.converted.started') as string,
					);
					break;
			}
		}
	}

	// Tour
	protected resumeTour(expectedStep?: string) {
		if (Shepherd.activeTour) {
			const step = Shepherd.activeTour.getCurrentStep();
			if (expectedStep && step && step.id !== expectedStep) {
				return;
			}
			Shepherd.activeTour.next();
		}
	}

	protected completeTour() {
		if (Shepherd.activeTour) {
			Shepherd.activeTour.complete();
		}
	}

	protected createTour(): Shepherd.Tour {
		return this.$shepherd({
			useModalOverlay: true,
			defaultStepOptions: {
				popperOptions: {
					modifiers: [
						{ name: 'offset', options: { offset: [0, 10] } },
						{ name: 'preventOverflow', options: { padding: 10 } },
					],
				},
				title: 'Title..',
				cancelIcon: {
					enabled: true,
				},
				canClickTarget: false,
			},
		});
	}

	private t(keys: string[], values?: Values): string {
		let translation: string;
		let i = 0;
		do {
			translation = this.$t(keys[i], values) as string;
			i++;
		} while (translation == keys[i - 1] && i < keys.length);
		return translation;
	}

	protected getResourceList(): ResourceList | null {
		return this.$refs.resourceList as ResourceList;
	}

	protected canExecuteAddResource(): boolean {
		return this.canModifyRestrictedResourceData;
	}

	protected canExecuteSave(): boolean {
		return this.canModifyResource && this.isModified();
	}

	protected canExecuteDownload(): boolean {
		const list = this.getResourceList();
		return this.canDownload && (list == null || (list.hasSavedItems && !this.isModified()));
	}

	private tourStepTitle(step: string) {
		return this.t([`tour.resource.${this.resourceId}.${step}.title`, `tour.resource.common.${step}.title`]);
	}

	private tourStepText(step: string) {
		return this.t([`tour.resource.${this.resourceId}.${step}.text`, `tour.resource.common.${step}.text`]);
	}

	private tourStepTry(step: string) {
		return this.t([
			`tour.resource.${this.resourceId}.${step}.try`,
			`tour.resource.common.${step}.try`,
			`tour.button.try`,
		]);
	}

	private tourStepNext(step: string) {
		return this.t([
			`tour.resource.${this.resourceId}.${step}.next`,
			`tour.resource.common.${step}.next`,
			`tour.button.next`,
		]);
	}

	private tourStepFinish(step: string) {
		return this.t([
			`tour.resource.${this.resourceId}.${step}.finish`,
			`tour.resource.common.${step}.finish`,
			`tour.button.finish`,
		]);
	}

	private tourStepButtons<T>(
		step: string,
		tryArgument: T,
		tryAction: ((arg: T) => void) | null,
		canTryAction: (() => boolean) | null,
		last: boolean,
	): Shepherd.Step.StepOptionsButton[] {
		const buttons: Shepherd.Step.StepOptionsButton[] = [];
		if (tryAction != null) {
			buttons.push({
				text: this.tourStepTry(step),
				secondary: true,
				action: () => {
					const tour = Shepherd.activeTour;
					if (tour) {
						tour.hide();
					}
					tryAction(tryArgument);
				},
				disabled: () => {
					return canTryAction ? !canTryAction() : false;
				},
			});
		}
		buttons.push({
			text: last ? this.tourStepFinish(step) : this.tourStepNext(step),
			action: () => {
				const tour = Shepherd.activeTour;
				if (tour) {
					if (last) {
						tour.complete();
					} else {
						tour.next();
					}
				}
			},
		});
		return buttons;
	}

	protected onTourCancel() {
		if (Shepherd.activeTour) {
			const currentStep = Shepherd.activeTour.getCurrentStep();
			if (currentStep && currentStep.id) {
				StorageService.setTourState(TOUR_TYPE, this.resourceId, TOUR_STATUS_CANCELLED);
			}
		}
	}

	protected onTourComplete() {
		if (Shepherd.activeTour) {
			const currentStep = Shepherd.activeTour.getCurrentStep();
			if (currentStep && currentStep.id) {
				StorageService.setTourState(TOUR_TYPE, this.resourceId, TOUR_STATUS_COMPLETED);
			}
		}
	}

	protected onTourShow(event: any) {
		if (Shepherd.activeTour) {
			const currentStep = Shepherd.activeTour.getCurrentStep();
			const showedStepId =
				event && event.step && event.step.id ? event.step.id : currentStep ? currentStep.id : undefined;
			if (showedStepId) {
				const steps = [...this.tourSteps(), TOUR_STATUS_CANCELLED, TOUR_STATUS_COMPLETED];
				const currentState = StorageService.getTourState(TOUR_TYPE, this.resourceId);
				const currentIndex = currentState ? steps.indexOf(currentState) : -1;
				const desiredIndex = this.tourSteps().indexOf(showedStepId);
				if (desiredIndex > currentIndex) {
					StorageService.setTourState(TOUR_TYPE, this.resourceId, showedStepId);
				}
			}
		}
	}

	protected tourSteps() {
		return [TOUR_STEP_IMPORT, TOUR_STEP_UPLOAD, TOUR_STEP_ADD, TOUR_STEP_SAVE, TOUR_STEP_DOWNLOAD];
	}

	protected startTour(resList: ResourceList) {
		const tourState = StorageService.getTourState(TOUR_TYPE, this.resourceId);
		if (tourState === TOUR_STATUS_COMPLETED || tourState === TOUR_STATUS_CANCELLED) return;
		const tour = this.createTour();
		tour.on('show', this.onTourShow);
		tour.on('cancel', this.onTourCancel);
		tour.on('complete', this.onTourComplete);
		const importStep = this.editable && this.canImportResources && this.$refs.import;
		const uploadStep = this.editable && this.canUploadResources && this.$refs.upload;
		const addStep = this.editable && resList.showNewButton && resList.canAddResource && resList.$refs.add;
		const saveStep = this.editable && resList.canModifyResource && resList.$refs.save;
		const downloadStep = this.canDownload && resList.showDownloadVersionButton && resList.$refs.download;
		if (importStep) {
			tour.addStep({
				id: TOUR_STEP_IMPORT,
				attachTo: { element: this.$refs.import as HTMLElement, on: 'bottom' },
				title: this.tourStepTitle(TOUR_STEP_IMPORT),
				text: this.tourStepText(TOUR_STEP_IMPORT),
				buttons: this.tourStepButtons(
					TOUR_STEP_IMPORT,
					undefined,
					this.tourTryImport(),
					this.tourCanTryImport(),
					!(uploadStep || addStep || saveStep || downloadStep),
				),
			});
		}
		if (uploadStep) {
			tour.addStep({
				id: TOUR_STEP_UPLOAD,
				attachTo: { element: this.$refs.upload as HTMLElement, on: 'bottom' },
				title: this.tourStepTitle(TOUR_STEP_UPLOAD),
				text: this.tourStepText(TOUR_STEP_UPLOAD),
				buttons: this.tourStepButtons(
					TOUR_STEP_UPLOAD,
					resList,
					this.tourTryUpload(),
					this.tourCanTryUpload(),
					!(addStep || saveStep || downloadStep),
				),
			});
		}
		if (addStep) {
			tour.addStep({
				id: TOUR_STEP_ADD,
				attachTo: { element: resList.$refs.add as HTMLElement, on: 'top' },
				title: this.tourStepTitle(TOUR_STEP_ADD),
				text: this.tourStepText(TOUR_STEP_ADD),
				buttons: this.tourStepButtons(
					TOUR_STEP_ADD,
					resList,
					this.tourTryAdd(),
					this.tourCanTryAdd(),
					!(saveStep || downloadStep),
				),
			});
		}
		if (saveStep) {
			tour.addStep({
				id: TOUR_STEP_SAVE,
				attachTo: { element: resList.$refs.save as HTMLElement, on: 'top' },
				title: this.tourStepTitle(TOUR_STEP_SAVE),
				text: this.tourStepText(TOUR_STEP_SAVE),
				buttons: this.tourStepButtons(
					TOUR_STEP_SAVE,
					resList,
					this.tourTrySave(),
					this.tourCanTrySave(),
					!downloadStep,
				),
			});
		}
		if (downloadStep) {
			tour.addStep({
				id: TOUR_STEP_DOWNLOAD,
				attachTo: { element: resList.$refs.download as HTMLElement, on: 'top' },
				title: this.tourStepTitle(TOUR_STEP_DOWNLOAD),
				text: this.tourStepText(TOUR_STEP_DOWNLOAD),
				buttons: this.tourStepButtons(
					TOUR_STEP_DOWNLOAD,
					resList,
					this.tourTryDownload(),
					this.tourCanTryDownload(),
					true,
				),
			});
		}
		if (tourState) {
			tour.show(tourState);
		} else {
			tour.start();
		}
	}

	protected tourTryImport(): (() => void) | null {
		return null;
	}

	protected tourCanTryImport(): () => boolean {
		return this.canExecuteAddResource;
	}

	protected tourTryUpload(): (() => void) | null {
		return null;
	}

	protected tourCanTryUpload(): () => boolean {
		return this.canExecuteAddResource;
	}

	protected tourTryAdd(): ((resourceList: ResourceList) => void) | null {
		return null;
	}

	protected tourCanTryAdd(): () => boolean {
		return this.canExecuteAddResource;
	}

	protected tourTrySave(): ((resourceList: ResourceList) => void) | null {
		return null;
	}

	protected tourCanTrySave(): () => boolean {
		return this.canExecuteSave;
	}

	protected tourTryDownload(): ((resourceList: ResourceList) => void) | null {
		return null;
	}

	protected tourCanTryDownload(): () => boolean {
		return this.canExecuteDownload;
	}

	// Access control
	get canAddResource(): boolean {
		return AccessService.canAddResource(this.project);
	}

	get canModifyRestrictedResourceData(): boolean {
		return AccessService.canModifyRestrictedResourceData(this.project);
	}

	get canImportResources(): boolean {
		return AccessService.canImportResources(this.project);
	}

	get canUploadResources(): boolean {
		return AccessService.canUploadResources(this.project);
	}

	get canFlow(): boolean {
		return AccessService.canFlow(this.project);
	}

	get canModifyResource(): boolean {
		return AccessService.canModifyResource(this.project);
	}

	get canEditResourceCategoryConfig(): boolean {
		return AccessService.canEditResourceCategoryConfig(this.project);
	}

	get canDownload(): boolean {
		return AccessService.canDownload(this.project);
	}

	get canDeleteResource(): boolean {
		return AccessService.canDeleteResource(this.project);
	}

	get canVersionControl(): boolean {
		return AccessService.canVersionControl(this.project);
	}

}

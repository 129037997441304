import Vue from 'vue';
import Component from 'vue-class-component';
import template from './setup-sync.html';
import './setup-sync.scss';
import {NextFunction, Route} from 'vue-router';
import LoadingScreen from '../../../decorators/loading-screen';
import {ProjectMeta, TeamMeta} from 'respresso';
import {teamModule} from '../../../store/modules/team/index';
import {NotificationBuilder} from '../../common/dialog/dialog';
import Tabcontrol from '../../common/tabcontrol/tabcontrol';
import Tab from '../../common/tabcontrol/tab';
import {copyTextToClipboard} from '../../../util/clipboard';
import {SelectedPlatform} from '../../../services/user-service';
import {userModule} from '../../../store/modules/user';
import * as env from "../../../env";

@Component({
	template: template,
	components: {
		tabcontrol: Tabcontrol,
		tab: Tab,
	},
})
export default class SetupSync extends Vue {
	private teamId = '';
	private projectId = '';
	resourceId = 'localization';
	version = '1.0.0';
	landingHost = env.landingHost;

	beforeRouteEnter(to: Route, from: Route, next: NextFunction): void {
		next((vm) => {
			const res = vm as SetupSync;
			res.load(to);
		});
	}

	@LoadingScreen({ showImmediately: true })
	private async load(to: Route): Promise<void> {
		this.teamId = to.params.teamId;
		this.projectId = to.params.projectId;
	}

	mounted() {
		this.$bus.$on('tabSelected', (activeTab: SelectedPlatform): void => {
			this.setSelectedPlatform(activeTab);
		});
	}

	get projectName(): string | undefined {
		const project = this.project;
		if (project) {
			return project.title;
		}
	}

	get team(): TeamMeta | null {
		return teamModule.team;
	}

	get project(): ProjectMeta | null {
		const moduleGetterTeam = this.team;
		if (!moduleGetterTeam || !moduleGetterTeam.projects) {
			return null;
		}
		const project = moduleGetterTeam.projects.filter((p: ProjectMeta) => {
			return p.id === this.projectId;
		});
		if (project.length) {
			return project[0];
		} else {
			return null;
		}
	}

	get selectedPlatform(): SelectedPlatform {
		return userModule.selectedPlatform;
	}

	public setSelectedPlatform(selectedPlatform: SelectedPlatform): void {
		userModule.setSelectedPlatform(selectedPlatform);
	}

	get serverUrl(): string {
		return `${location.protocol}//${location.host}`;
	}

	private async copyCode(event: Event): Promise<void> {
		if (event && event.target && event.target instanceof HTMLElement && event.target.textContent) {
			const content = event.target.textContent;
			const success = await copyTextToClipboard(content);
			if (success) {
				NotificationBuilder.success('#project.clipboard.copied');
			} else {
				NotificationBuilder.error('#project.clipboard.copyFailed');
			}
		}
	}

	private async downloadCode(event: Event): Promise<void> {
		if (event && event.target && event.target instanceof HTMLElement && event.target.textContent) {
			const content = event.target.textContent;
			const element = document.createElement('a');
			element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content));
			element.setAttribute(
				'download',
				event.target.dataset && event.target.dataset.filename
					? event.target.dataset.filename
					: 'respresso_default.json',
			);
			element.style.display = 'none';
			document.body.appendChild(element);
			element.click();
			document.body.removeChild(element);
		}
	}
}

import 'whatwg-fetch';

export interface FileTypeSupport {
	webp: boolean | null;
}

const fileTypeSupport: FileTypeSupport = {
	webp: null,
};

async function supportsWebp(): Promise<boolean> {
	if (!self.createImageBitmap) return false;

	const webpData = 'data:imagea/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
	const blob = await window.fetch(webpData).then((r) => r.blob());
	return createImageBitmap(blob).then(
		() => true,
		() => false,
	);
}

(async (): Promise<void> => {
	if (await supportsWebp()) {
		fileTypeSupport.webp = true;
	} else {
		fileTypeSupport.webp = false;
	}
})();

export const supportedFiles = fileTypeSupport;

import store from '../../index';
import {Action, Module, Mutation, VuexModule} from 'vuex-class-modules';

@Module
class RoutingModule extends VuexModule {
	_reuse = true;

	get reuse(): boolean {
		return this._reuse;
	}

	@Mutation
	SET_ROUTE_REUSE(reuse: boolean): void {
		this._reuse = reuse;
	}

	@Action
	allowRouteReuse(): void {
		this.SET_ROUTE_REUSE(true);
	}

	@Action
	disallowRouteReuse(): void {
		this.SET_ROUTE_REUSE(false);
	}
}

export const routingModule = new RoutingModule({ store, name: 'routing' });

import Vue from 'vue';
import Component from 'vue-class-component';
import template from './reset-password.html';
import './reset-password.scss';
import UserService from '../../../services/user-service';
import {NotificationBuilder} from '../../common/dialog/dialog';
import ErrorHandler from '../../../services/error-handler';
import LoadingScreen from '../../../decorators/loading-screen';

@Component({
	template: template,
})
export default class ResetPassword extends Vue {
	private email = '';
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private response: any;

	@LoadingScreen({ fullScreen: true })
	async resetPassword(): Promise<void> {
		this.response = await ErrorHandler.tryRequest(() => UserService.sendResetPassword(this.email));
		if (this.response.state === 'OK') {
			this.$router.push({ name: 'login' });
			NotificationBuilder.success('#success.mail.sending');
		} else {
			NotificationBuilder.error('#user.resetPassword.error');
		}
	}
}
